import { useEffect, useState } from "react";
import { useContestContext } from "../../common/contest-context";
import { useEntryContext } from "../../common/entry-context";
import { Countdown, getCountDown } from "../../helpers/date-helpers";
import { ApiEntryItem } from "../../types/api-models";
import { ContestStatus } from "../../types/contest";

export type RemainingCellProps = {
  targetLocalDate: Date;
  item: ApiEntryItem;
};

export function RemainingCell({ targetLocalDate, item }: RemainingCellProps) {
  const [countdown, setCountdown] = useState<Countdown>(getCountDown(targetLocalDate));
  const { status } = useContestContext();
  const {
    itemIdsInChangeBetPeriod,
    notifyItemEnterChangeBetPeriod,
    notifyItemLeaveChangeBetPeriod
  } = useEntryContext();

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(getCountDown(targetLocalDate));
    }, 1000);

    return () => clearInterval(interval);
  });

  if (status === ContestStatus.InProgress && countdown && item.itemId) {
    const isInChangeBetPeriod =
      itemIdsInChangeBetPeriod.find((x) => x === item.itemId) !== undefined;
    if (countdown.totalRemainingSeconds <= 0 && isInChangeBetPeriod) {
      console.log(`item ${item.itemId} leaves change bet period.`);
      notifyItemLeaveChangeBetPeriod(item.itemId);
    } else if (
      countdown.totalRemainingSeconds > 0 &&
      countdown.totalRemainingSeconds <= 600 &&
      !isInChangeBetPeriod
    ) {
      console.log(`item ${item.itemId} enters change bet period.`);
      notifyItemEnterChangeBetPeriod(item.itemId);
    }
  }

  if (countdown === undefined) {
    return <div> - </div>;
  } else if (countdown.done) {
    return <div className="ended">Ended</div>;
  } else if (countdown.totalRemainingSeconds < 60) {
    return <div className="red">{countdown.totalRemainingSeconds} seconds</div>;
  } else if (countdown.totalRemainingSeconds < 3600) {
    return (
      <div className="red">
        <CountdownText element={countdown.days} />:
        <CountdownText element={countdown.hours} />:
        <CountdownText element={countdown.minutes} />:
        <CountdownText element={countdown.seconds} />
      </div>
    );
  } else {
    return (
      <div>
        <CountdownText element={countdown.days} />:
        <CountdownText element={countdown.hours} />:
        <CountdownText element={countdown.minutes} />:
        <CountdownText element={countdown.seconds} />
      </div>
    );
  }
}

/**
 * Component to show count down element. Append leading zeroes if element only has 1 digit to ensure it always display 2 digits.
 */
function CountdownText({ element }: { element: number }) {
  const twoNumberText = element >= 10 ? `${element}` : `0${element}`;
  return <span>{twoNumberText}</span>;
}
