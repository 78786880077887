import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { PlayerDomainInterfacesService } from "../player-api-client";
import { ApiEntryItem, ApiPlayerSearch } from "../types/api-models";
import { useRoundContext } from "./round-context";
import { useUserContext } from "./user-context";

export type EntryPlayerContextValue = {
  /**
   * items in an entry
   */
  items: ApiEntryItem[];
  player: ApiPlayerSearch | undefined | null;
  changePlayer: (contact: ApiPlayerSearch | undefined | null) => void;
};

export const EntryPlayerContext = React.createContext<EntryPlayerContextValue | null>(null);

// todo: pass in readonly or not so we are only using scoreboard context on game pages
export const EntryPlayerContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const { round } = useRoundContext();
  const { isAuthenticated } = useUserContext();
  const [items, setItems] = useState<ApiEntryItem[]>([]);
  const [player, setPlayer] = useState<ApiPlayerSearch | undefined | null>(undefined);

  const changePlayer = useCallback(
    (contact: ApiPlayerSearch | undefined | null) => {
      setPlayer(contact);
    },
    [setPlayer]
  );

  useEffect(() => {
    const fetch = async () => {
      if (round && isAuthenticated && player) {
        const response = await PlayerDomainInterfacesService.apiEntryPlayerByContestIdByPlayerIdGet(
          round.contestId!,
          player.playerId!
        );

        const fetchedItems =
          response.entry?.entryItems?.filter((x) => {
            return round.contestItems.find((a) => a.itemId === x.itemId);
          }) || [];

        setItems(fetchedItems);
      }
    };
    fetch();
  }, [round?.contestId, isAuthenticated, player]);

  return (
    <EntryPlayerContext.Provider
      value={{
        items,
        player,
        changePlayer
      }}
    >
      {children}
    </EntryPlayerContext.Provider>
  );
};

export const useEntryPlayerContext = () => {
  const context = useContext(EntryPlayerContext);
  if (!context) {
    throw new Error("useEntryPlayerContext must be used within the EntryPlayerContextProvider");
  }
  return context;
};
