import React from "react";
import "../../content/styles/common/accept-rules.scss";
import { MAX_BETS } from "../../common/constants";

type Props = {
  isShown: boolean;
  hideModal: () => void;
};

export const LimitReachedModal: React.FC<Props> = ({ isShown, hideModal }) => {
  const hideLimitReachedModal = () => {
    hideModal();
  };

  return (
    <div className={isShown ? "review-properties" : "review-properties hidden"}>
      <div className="rules-overlay" style={{ zIndex: 3 }}></div>
      <div className="no-more-bets-dialog">
        <button className="btn-close" onClick={hideLimitReachedModal}>
          &#10006;
        </button>
        <div className="heading">YOU HAVE SUBMITTED {MAX_BETS} BETS</div>
        <div className="body-text">
          <div>
            You can submit bets for up to {MAX_BETS} properties per round. To place a bet on a new property,
            remove your bet from another selection.
          </div>
        </div>
        <div className="action-button" onClick={hideLimitReachedModal}>
          DISMISS
        </div>
      </div>
    </div>
  );
};
