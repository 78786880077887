import React, { useCallback, useRef, useEffect, useState } from "react";
import { useContestContext } from "../../common/contest-context";
import { useRoundContext } from "../../common/round-context";
import { PageSize } from "../../common/constants";
import { NumberFormatter } from "../../types/formatter";
import { PlayerDomainInterfacesService } from "../../player-api-client";
import { ApiLeaderboardPlayerScore } from "../../types/api-models";
import { useReadOnly } from "../../hooks/use-read-only";
import { mapLeaderboardPlayerScore } from "../../helpers/leaderboard-player-score-mapper";

import "../../content/styles/game/winner-scroll.scss";

export const WinnersList: React.FC = () => {
  const { contest, status } = useContestContext();
  const { round, roundStatus } = useRoundContext();
  const isReadOnly = useReadOnly();
  const selectedContest = isReadOnly ? contest : round;
  const selectedStatus = isReadOnly ? status : roundStatus;
  const currentPlayerRef = useRef<HTMLElement>();
  const [leaderboardScores, setLeaderboardScores] = useState<ApiLeaderboardPlayerScore[]>([]);

  const getLeaderboard = useCallback(
    async (page: number = 1, forPlayerId: string = "") => {
      try {
        let response = await PlayerDomainInterfacesService.apiContestLeaderboardPost({
          contestId: selectedContest?.contestId,
          playerId: forPlayerId,
          page,
          pageSize: PageSize.Standard,
          requestGameSize: true
        });

        if (response) {
          const newLeaderboardScores = (response.leaderboardPlayerScores || []).map((item) =>
            mapLeaderboardPlayerScore(item, selectedContest?.round || undefined, selectedStatus)
          );
          setLeaderboardScores(newLeaderboardScores);
        }

        if (forPlayerId) {
          currentPlayerRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      } catch (err) {
        console.error(err);
      }
    },
    [selectedContest?.round, selectedContest?.contestId, selectedStatus]
  );

  useEffect(() => {
    getLeaderboard();
  }, [getLeaderboard]);

  return (
    <>
      <div className="ticker-wrap">
        <div className="ticker">
          {leaderboardScores.slice(0, 11).map((score, index) => (
            <>
              {!isNaN(score.eventWinnings!) && (
                <div key={index} className="ticker-item">
                  {NumberFormatter.FormatCurrency(score.eventWinnings!)} Winner - {score.firstname}{" "}
                  {score.lastname}, {score.companyName?.trim()}
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </>
  );
};
