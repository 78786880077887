import { useState } from "react";
import MaskedInput from "react-text-mask";
import { useLocation } from "react-router-dom";
import { useEntryContext } from "../../common/entry-context";
import { currencyMask } from "../../common/masked-input-mask";
import { useRoundContext } from "../../common/round-context";
import { ContestStatus } from "../../types/contest";
import { NumberFormatter } from "../../types/formatter";
import { ScoreboardDataRow } from "./scoreboard-mapper";
import { useUserContext } from "../../common/user-context";
import { PageView } from "../../common/constants";
import gtm from "../../helpers/gtm-helper";

type Props = {
  row: ScoreboardDataRow;
  hideCurrentRow: boolean;
  parentTableIsMounted: boolean;
  setHideCurrentRow: (hideCurrentRow: boolean) => void;
};

export function BetCell({ row, parentTableIsMounted, setHideCurrentRow }: Props) {
  const { roundStatus, round } = useRoundContext();
  const status = roundStatus;
  const {
    updateItem,
    deleteItem,
    itemIdsInChangeBetPeriod,
    isInChangeBetMode,
    changeBetEditModeItemId,
    setChangeBetEditModeItemId,
    submitChangeBet,
    onBlurChangeBet,
    allowedToSubmitChanges
  } = useEntryContext();
  const [currentBet, setCurrentBet] = useState(row.bet);
  const [previousBet, setPreviousBet] = useState(row.bet);

  const { userInfo } = useUserContext();
  let location = useLocation();

  const handleBetBlur = (e: any) => {
    setCurrentBet(row.bet);
    setChangeBetEditModeItemId("");

    if (e.target.value === "$0" && parentTableIsMounted) {
      setHideCurrentRow(true);
      deleteItem(row.entryItem.itemId!);
      allowedToSubmitChanges(true);
    }

    if (
      parseInt(e.target.value.replace(/[^0-9.]+/g, "")) < row.startingBid &&
      e.target.value !== "$0"
    ) {
      updateItem({
        itemId: row.entryItem.itemId,
        bet: previousBet
      });
      console.log("Target ID: ", e.target.id);
      onBlurChangeBet(row.entryItem.itemId!);
    } else {
      setPreviousBet(row.bet);
    }
  };

  const handleChangeBetInputFocus = () => {
    setChangeBetEditModeItemId(row.entryItem.itemId || "");
  };

  const handleChangeBetInputChange = (e: any) => {
    const newBet = parseInt(e.target.value.replace(/[^0-9.]+/g, ""));
    if (!isNaN(newBet)) {
      setCurrentBet(newBet);
    }
  };

  const handleChangeBetSubmit = () => {
    try {
      submitChangeBet({
        itemId: row.entryItem.itemId,
        bet: currentBet
      });
      gtm.trackEvent("bet_changed", {
        bet_location: "scoreboard"
      });
      setChangeBetEditModeItemId("");
    } catch (err) {
      console.log(err);
    }
  };

  if (location.pathname.includes(PageView.ScoreCard) || status === ContestStatus.NotStarted) {
    return <>{NumberFormatter.FormatCurrency(row.bet || 0)}</>;
  }

  if (
    isInChangeBetMode &&
    status === ContestStatus.InProgress &&
    itemIdsInChangeBetPeriod.length &&
    itemIdsInChangeBetPeriod.find((x) => x === row.entryItem.itemId)
  ) {
    return (
      <div className="bet-cell-wrapper">
        <div className="bet-controls">
          <MaskedInput
            className={`input-scoreboard-bet purple`}
            value={currentBet === 0 ? "-" : currentBet}
            onChange={handleChangeBetInputChange}
            placeholder="Enter Bet"
            onFocus={handleChangeBetInputFocus}
            onBlur={handleBetBlur}
            size={10}
            mask={currencyMask}
            bob-ga-data-on="blur"
            bob-ga-data-event-category="Scoreboard"
            bob-ga-data-event-action="YourBet"
            bob-ga-data-event-label={row.contestItem.listingId}
            bob-ga-data-event-value={row.contestItem.listingId}
            bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
            bob-ga-data-dimension3={round?.contestId}
            bob-ga-data-dimension4={currentBet === 0 ? "" : currentBet}
          />
          <button
            disabled={row.entryItem.itemId !== changeBetEditModeItemId}
            className="action-button-bet edit"
            onMouseDown={handleChangeBetSubmit}
            bob-ga-data-on="click"
            bob-ga-data-event-category="Scoreboard"
            bob-ga-data-event-action="RestrictedBet"
            bob-ga-data-event-label={row.contestItem.listingId}
            bob-ga-data-event-value={row.contestItem.listingId}
            bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
            bob-ga-data-dimension3={round?.contestId}
            bob-ga-data-dimension4={currentBet === 0 ? "" : currentBet}
          >
            Change <span className="hide-bet-text">Bet</span>
          </button>
        </div>
      </div>
    );
  } else {
    return <div className="bet-text-readonly">{NumberFormatter.FormatCurrency(row.bet || 0)}</div>;
  }
}
