import { IGameScheduleItem, gameScheduleTable } from "../../common/game-schedule";
import "../../content/styles/common/game-schedule-modal.scss";

interface IGameSchedule {
  hideModal: () => void;
}

export const GameSchedule: React.FC = () => {
  return (
    <div className="game-schedule-table-container">
      <table className="schedule-table">
        <thead>
          <tr>
            {gameScheduleTable.header.map((col: string, idx: number) => (
              <th key={idx}>{col}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {gameScheduleTable.data.map((item: IGameScheduleItem, idx: number) => (
            <tr key={idx}>
              {gameScheduleTable.header.map((header, headerIdx: number) => (
                <td key={headerIdx}>{(item as any)[header.toLowerCase().replace(/ /g, "_")]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const GameScheduleModal: React.FC<IGameSchedule> = ({ hideModal }) => {
  const hideGameScheduleModal = () => {
    hideModal();
  };

  return (
    <div className="game-schedule" onClick={hideGameScheduleModal}>
      <div className="game-schedule-overlay"></div>
      <div className="game-schedule-modal">
        <div className="game-schedule-modal-header">
          <button className="btn-close" onClick={hideGameScheduleModal}></button>
          <div className="game-schedule-title">Full Season 2 Game Schedule</div>
        </div>
        <div className="game-schedule-body">
          <GameSchedule />
        </div>
      </div>
    </div>
  );
};
