import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { PlayerDomainInterfacesService } from "../../player-api-client";
import { NumberFormatter } from "../../types/formatter";
import { ContestDomainClient_ViewModels_PlayerRanking } from "../../player-api-client/models/ContestDomainClient_ViewModels_PlayerRanking";
import { DEFAULT_RANK, PageView } from "../../common/constants";

type Props = {
  playerId?: string;
  leaderBoardChanged: string;
};

export const PlayerRanking: React.FC<Props> = (props) => {
  const [
    playerRanking,
    setPlayerRanking
  ] = useState<ContestDomainClient_ViewModels_PlayerRanking>();

  const [showRanking, setShowRanking] = useState(true);

  useEffect(() => {
    getPlayerRanking(props.playerId || "");
  }, [props.playerId, props.leaderBoardChanged]);

  const getPlayerRanking = async (playerId: string) => {
    try {
      const response = await PlayerDomainInterfacesService.apiContestPlayerRankingPost({
        playerId: playerId
      });
      if (response) {
        setPlayerRanking(response);
        setShowRanking(true);
      }
    } catch (e) {
      setShowRanking(false);
    }
  };

  const rank = playerRanking?.ranking || DEFAULT_RANK - 1;
  const rankstr = rank === DEFAULT_RANK - 1 ? "N/A" : NumberFormatter.FormatNumber(rank);

  return (
    <>
      {showRanking && (
        <NavLink to={PageView.WinnersList}>
          <div className="player-rank-label content-align-end">
            Current Round Rank:&nbsp;
            <span className="blue-rank">{rankstr}</span>
            &nbsp;out of&nbsp;
            <span className="blue-rank">
              {NumberFormatter.FormatNumber(playerRanking?.totalPlayers || 0)}
            </span>
          </div>
        </NavLink>
      )}
    </>
  );
};
