import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import TagManager from "react-gtm-module";
import { BrowserRouter } from "react-router-dom";
import Auth from "@aws-amplify/auth";
import { OpenAPI } from "./player-api-client";
import { AppConfig } from "./common/app_config";
import { name, version } from "../package.json";
import { v4 as uuid } from "uuid";
import ScrollToTop from "./components/common/scroll-to-top";
import { getGoogleIdCallback } from "./helpers/ga-helper";
import { datadogRum } from "@datadog/browser-rum";

Auth.configure({
  region: AppConfig.COGNITO_REGION,
  userPoolId: AppConfig.COGNITO_POOL_ID,
  userPoolWebClientId: AppConfig.COGNITO_APP_CLIENT_ID,
  oauth: {
    domain: AppConfig.COGNITO_OAUTH_DOMAIN,
    scope: ["email", "openid", "profile"],
    redirectSignIn: AppConfig.COGNITO_OAUTH_REDIRECT_SIGNIN,
    redirectSignOut: AppConfig.COGNITO_OAUTH_REDIRECT_SIGNOUT,
    responseType: "code"
  }
});

// datadogRum.init({
//   applicationId: AppConfig.DD_APPLICATION_ID,
//   clientToken: AppConfig.DD_CLIENT_TOKEN,
//   site: AppConfig.DD_SITE,
//   service: AppConfig.DD_SERVICE,
//   env: AppConfig.DD_ENV,
//   trackUserInteractions: true,
//   trackResources: true,
//   trackLongTasks: true,
//   sessionSampleRate: parseInt(AppConfig.DD_SESSION_SAMPLE_RATE),
//   sessionReplaySampleRate: parseInt(AppConfig.DD_SESSION_REPLAY_SAMPLE_RATE),
//   defaultPrivacyLevel: "mask-user-input"
// });

// TODO: enable dataDog session recording if needed in the future
// datadogRum.startSessionReplayRecording();

TagManager.initialize({
  gtmId: AppConfig.GTM_ID
});

// Configure OpenAPI for player api calls
OpenAPI.ADMIN_BASE = AppConfig.ADMIN_API_ENDPOINT;
OpenAPI.BASE = AppConfig.API_ENDPOINT;
OpenAPI.TOKEN = async () => {
  // Auth.currentSession() returns a CognitoUserSession object which contains JWT accessToken, idToken, and refreshToken.
  // This method will automatically refresh the accessToken and idToken if tokens are expired and a valid refreshToken presented.
  return Auth.currentSession()
    .then((session) => session.getAccessToken().getJwtToken())
    .catch((err) => {
      console.log(err);
      return "";
    });
};

OpenAPI.HEADERS = async () => {
  return Promise.resolve({
    "X-Correlation-ID": uuid()
  });
};

// write the app name and version
console.log(`${name} ${version}`);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
