import * as React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../content/styles/common/error-screen.scss";

type Props = {
  error: string;
  backToAction: Function;
};

export const ErrorScreen: React.FC<Props> = ({ error, backToAction }) => {
  const history = useHistory();

  useEffect(() => {
    history.push("/error");
  }, [history]);

  const goBack = () => {
    history.goBack();
    backToAction();
  };

  return (
    <>
      <div className="error-screen-container">
        <h2>Error</h2>
        <div>{error}</div>
        <button onClick={goBack}>Go Back</button>
      </div>
    </>
  );
};
