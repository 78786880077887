import { useEffect, useRef } from "react";
import { io } from "socket.io-client";
import { AppConfig } from "../common/app_config";
import { IListingUpdatedMsg } from "../types/auction";

export type ListingUpdatedMessageHandler = (message: IListingUpdatedMsg) => void;
let isMounted = false;
/**
 * subscribe to auction listing update for the specified listingIds
 * @param listingIds the tenx listingIds to subscribe for
 * @param listingUpdatedMessageHandler handler for auction listing updated message
 */

export function useAuctionSocket(
  listingIds: number[],
  listingUpdatedMessageHandler: ListingUpdatedMessageHandler
) {
  const isConnected = useRef<boolean>(false);

  useEffect(() => {
    isMounted = true;
    if (listingIds.length) {
      const url = `${AppConfig.TENX_SERVICE_ROOT}/bidding`;

      // connect to server
      if (isConnected.current === false) {
        const socket = io(url, {
          path: "/ws-bidding/socket.io",
          transports: ["websocket"],
          reconnectionAttempts: 10
        });
        socket.on("connect", () => {
          isConnected.current = true;
          console.log("socket connect");
          const engine = socket.io.engine;
          engine.on("close", (reason: string) => {
            console.log(`socket close, reason ${reason}`);
          });
        });
        // subscribe to listing update
        console.log(`subscribe listingIds ${listingIds.join(",")}`);
        listingIds.forEach((x) => {
          socket.emit("bidBoxListingSubscribe", { listingId: x });
        });
        socket.on("bidBoxListingUpdated", (data) => listingUpdatedMessageHandler(data));
        socket.on("disconnect", (reason) => {
          console.log(`socket disconnect reason: ${reason}`);
          if (isMounted && reason === "io server disconnect") {
            socket.connect();
          }
        });
        socket.on("reconnect", (data) => console.log("socket reconnect", data));
        socket.on("reconnect_error", (data) => console.log("socket reconnect_error", data));
        socket.on("reconnecting", (data) => console.log("socket reconnecting", data));
        socket.io.on("reconnect_attempt", (attempt) => {
          console.log("socket reconnecting ", attempt);
        });
        socket.io.on("reconnect_failed", () => {
          console.log("socket reconnect failed");
        });

        return () => {
          isMounted = false;
          isConnected.current = false;
          console.log("unmount use-auction-socket");
          console.log(`unsubscribe listingIds ${listingIds.join(",")}`);
          socket.off();
          socket.disconnect();
        };
      }
    }
  }, [listingIds, listingUpdatedMessageHandler]);
}
