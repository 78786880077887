import React, { useEffect, useState } from "react";
import "../../content/styles/common/nav-header.scss";
import logoLoopnet from "../../content/images/logo-loopnet.svg";
import logoTenX from "../../content/images/logo-ten-x.svg";
import logoCoStar from "../../content/images/logo-costar.svg";
import closeIcon from "../../content/images/close-icon.svg";
import profileIcon from "../../content/images/profile.png";
import { useHistory } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { PageView } from "../../common/constants";
import spinningTenX from "../../content/images/tenx-pinwheel.svg";
import { useUserContext } from "../../common/user-context";
import { useRoundContext } from "../../common/round-context";
import gtm from "../../helpers/gtm-helper";
import { createUtmFragment } from "../../helpers/utm-helpers";
import { buildGamePageRoute } from "../../helpers/game-route-builder";
import { checkEligibility } from "../../helpers/eligibility-helper";
// -- Components
import Accordion from "./accordion";
import { ProfileMenu } from "../profile/profile-menu";
import { PointsWinnings } from "../profile/points-winnings";
import { AppConfig } from "../../common/app_config";
import { LoginModal } from "../login-signup/login/";
import classnames from "classnames";
import { ELIGIBILITY_LEVEL } from "../../common/constants";
import { useContestContext } from "../../common/contest-context";

type Props = {
  contestName: string;
  isAdminPages: boolean;
  feBobIcscShow?: boolean;
  feBobShowLogin?: boolean;
  showWatchNow?: boolean;
};

export const NavHeader: React.FC<Props> = ({ isAdminPages, feBobShowLogin, showWatchNow }) => {
  const { round } = useRoundContext();
  const { contest } = useContestContext();
  const { isAuthenticated, signOut, userInfo, userEligibiltiy, loading } = useUserContext();
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [showProfileNav, setShowProfileNav] = useState(false);
  const [showPointsWinnings, setShowPointsWinnings] = useState(false);
  const [showPrizeMoneyAddress, setShowPrizeMoneyAddress] = useState(false);
  const [fixedHeader, setFixedHeader] = useState("");
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showLoginSpinner, setLogginSpinner] = useState(false);
  const [loginTimeOut, setLoginTimeOut] = useState(false);

  const history = useHistory();

  const LOGGING_IN =
    localStorage.getItem("intialLogin") === "LOGGING_IN" ||
    localStorage.getItem("intialLogin") === "INITIAL_LOGIN";

  const ACCESS = checkEligibility(round?.contestRoundType, userEligibiltiy);
  const subject = userInfo ? userInfo.caSubject : "";
  const profileImage = userInfo
    ? userInfo.profileImage !== undefined
      ? userInfo.profileImage
      : profileIcon
    : profileIcon;

  const listenScrollEvent = () => {
    if (window.scrollY < 1) {
      return setFixedHeader("");
    }
  };

  const listenResize = () => {
    if (window.innerWidth > 1260) {
      return setShowMobileNav(false);
    }
  };

  const handleLoginModal = () => {
    setShowLoginModal(!showLoginModal);
    toggleSideNav();
    document.body.classList.add("modal-active");
  };

  const handleLoginModalDesktop = () => {
    setShowLoginModal(!showLoginModal);
    document.body.classList.add("modal-active");
  };

  useEffect(() => {
    if (LOGGING_IN) {
      setLogginSpinner(true);
    }
  }, [LOGGING_IN, setLogginSpinner]);

  useEffect(() => {
    const logginTimeOut = () => {
      setLoginTimeOut(true);
      localStorage.setItem("intialLogin", "");
    };

    const timeoutId = setTimeout(logginTimeOut, 9000);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const closeModals = (e: any) => {
      let selectedAutoSuggestion = false;

      if (e.target.id.substring(0, 30) === "PlacesAutocomplete__suggestion") {
        selectedAutoSuggestion = true;
      }

      const prizeMoneyModal = document.querySelector(".prize-money-modal");
      const modalBox = document.querySelector(".modal-box");

      if (
        !prizeMoneyModal?.contains(e.target) &&
        !selectedAutoSuggestion &&
        showPrizeMoneyAddress
      ) {
        setShowPrizeMoneyAddress(false);
      }
      if (!modalBox?.contains(e.target) && showPointsWinnings) {
        setShowPointsWinnings(false);
      }
    };

    window.addEventListener("click", closeModals);
    window.addEventListener("click", offSideNav);

    window.addEventListener("scroll", listenScrollEvent);
    window.addEventListener("resize", listenResize);

    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
      window.removeEventListener("resize", listenResize);
      window.removeEventListener("click", closeModals);
      window.removeEventListener("click", offSideNav);
    };
  }, [showPointsWinnings, showPrizeMoneyAddress]);

  const renderAuthButton = () => {
    if (isAuthenticated) {
      return (
        <>
          <img
            className={
              userInfo && userInfo.profileImage !== undefined ? "profile-image" : "profile-icon"
            }
            src={profileImage}
            onClick={() => {
              setShowProfileNav(!showProfileNav);
            }}
            alt={""}
          />
          {showProfileNav && (
            <ProfileMenu
              showPointsWinnings={showPointsWinnings}
              setShowPointsWinnings={setShowPointsWinnings}
              setShowProfileNav={setShowProfileNav}
              showPrizeMoneyAddress={showPrizeMoneyAddress}
              setShowPrizeMoneyAddress={setShowPrizeMoneyAddress}
            />
          )}
        </>
      );
    } else if (showLoginSpinner && !loginTimeOut) {
      return (
        <>
          <img src={spinningTenX} className="login-spinner" alt="Ten-X Logo" />{" "}
          <span className="loggin-message">Logging In</span>
          <span className="loader__dot">.</span> <span className="loader__dot">.</span>{" "}
          <span className="loader__dot">.</span>
        </>
      );
    } else if (!isAuthenticated && !loading) {
      return (
        <div className="show-border">
          <button
            className="nav-link btn btn-link"
            onClick={handleLoginModalDesktop}
            tabIndex={-1 || undefined}
          >
            <span style={{ whiteSpace: "nowrap", color: "#0054a6" }}>LOGIN/SIGN UP</span>
          </button>
        </div>
      );
    }
  };

  const renderAuthButtonMobile = () => {
    if (isAuthenticated) {
      return (
        <>
          <div
            className="sidebar-login-logout-button"
            onClick={signOut}
            bob-ga-data-on="click"
            bob-ga-data-event-category="Profile"
            bob-ga-data-event-action="Click"
            bob-ga-data-event-label="SignOut"
            bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
          >
            Sign Out
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="sidebar-login-logout-button"
            onClick={handleLoginModal}
            bob-ga-data-on="click"
            bob-ga-data-event-category="Profile"
            bob-ga-data-event-action="Click"
            bob-ga-data-event-label="LogIn"
            bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
          >
            Login/Sign Up
          </div>
        </>
      );
    }
  };

  const toggleSideNav = () => {
    setShowMobileNav(!showMobileNav);
    if (!showMobileNav) {
      document.body.classList.add("locked");
    } else {
      document.body.classList.remove("locked");
    }
  };

  const offSideNav = (e: any) => {
    let sideNavBar = document.querySelector(".navbar.navbar-expand-lg");
    if (!sideNavBar?.contains(e.target)) {
      document.body.classList.remove("locked");
      setShowMobileNav(false);
    }
  };

  const handleLinkClick = () => {
    setShowMobileNav(!showMobileNav);
  };

  useEffect(() => {
    if (isAuthenticated && showLoginSpinner) {
      gtm.trackEvent("Login Successful", {
        contactId: userInfo?.contactId
      });
    }
  }, [showLoginSpinner, isAuthenticated]);

  return (
    <>
      {showLoginModal && (
        <div
          className={classnames({
            "modal-wrapper": true,
            active: showLoginModal
          })}
        >
          <LoginModal loginSignupModal="" setLoginSignupModal={handleLoginModal} />
        </div>
      )}
      <section id="cs-header" className={`${fixedHeader}`}>
        <div className="lock-background">&nbsp;</div>
        {showPointsWinnings && (
          <PointsWinnings
            showPointsWinnings={showPointsWinnings}
            setShowPointsWinnings={setShowPointsWinnings}
          />
        )}

        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="navbar-flex container-fluid pl-3 pr-3">
            <button
              id="ChangeToggle"
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <div id="navbar-hamburger">
                {/* <!--<span className="my-1 mx-2 close">X</span>--> */}
                <span className="navbar-toggler-icon" onClick={() => toggleSideNav()}></span>
              </div>
            </button>

            <div className="nav-logos">
              <a
                id="logo-loopnet"
                className="navbar-brand"
                href={
                  AppConfig.TENX_SERVICE_ROOT +
                  createUtmFragment("BoBGameSite", "BoB", "header", subject!)
                }
                target="_blank"
                rel="noreferrer"
                bob-ga-data-on="click"
                bob-ga-data-event-category="Header"
                bob-ga-data-event-action="Click"
                bob-ga-data-event-label="Ten-X Logo"
                bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
              >
                <img src={logoTenX} alt="Ten-X Logo" />
              </a>
              <a
                id="logo-costar"
                className="navbar-brand"
                href={
                  AppConfig.COSTAR_URL + createUtmFragment("BoBGameSite", "BoB", "header", subject!)
                }
                target="_blank"
                rel="noreferrer"
                bob-ga-data-on="click"
                bob-ga-data-event-category="Header"
                bob-ga-data-event-action="Click"
                bob-ga-data-event-label="CoStar Logo"
                bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
              >
                <img src={logoCoStar} alt="CoStar Logo" />
              </a>
              <a
                id="header-left-logo-tenx"
                className="navbar-brand"
                href={
                  AppConfig.LOOPNET_URL +
                  createUtmFragment("BoBGameSite", "BoB", "header", subject!)
                }
                target="_blank"
                rel="noreferrer"
                bob-ga-data-on="click"
                bob-ga-data-event-category="Header"
                bob-ga-data-event-action="Click"
                bob-ga-data-event-label="LoopNet Logo"
                bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
              >
                <img src={logoLoopnet} alt="LoopNet Logo" />
              </a>
            </div>

            <div
              className={`collapse ${
                showMobileNav ? "show" : ""
              } navbar-collapse border-bottom-0" id="navbarSupportedContent`}
            >
              <div className="closeIcon" onClick={() => toggleSideNav()}>
                <img src={closeIcon} alt="Close" />
              </div>

              {!isAdminPages && (
                <ul className="navbar-nav ml-auto mb-2 mb-lg-0 menu-options" id="main-menu">
                  <li className="nav-item">
                    <NavLink
                      bob-ga-data-on="click"
                      bob-ga-data-event-category="Header"
                      bob-ga-data-event-action="Click"
                      bob-ga-data-event-label="HomePage"
                      bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
                      className="nav-link"
                      activeClassName="selected"
                      to={PageView.Splash}
                      onClick={handleLinkClick}
                    >
                      <span style={{ whiteSpace: "nowrap" }}>HOME</span>
                    </NavLink>
                  </li>
                  {ACCESS && (
                    <li className="nav-item nav-gradient">
                      <NavLink
                        className="nav-link"
                        activeClassName="selected"
                        to={buildGamePageRoute(PageView.PropertySelectionPage, contest?.contestId!)}
                        onClick={handleLinkClick}
                      >
                        <span style={{ whiteSpace: "nowrap" }}>Play the Game</span>
                      </NavLink>
                    </li>
                  )}
                  <li className="nav-item">
                    <NavLink
                      bob-ga-data-on="click"
                      bob-ga-data-event-category="Header"
                      bob-ga-data-event-action="Click"
                      bob-ga-data-event-label="GameRules"
                      bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
                      className="nav-link desktop-link"
                      activeClassName="selected"
                      to={PageView.RulesPage}
                      onClick={handleLinkClick}
                    >
                      <span style={{ whiteSpace: "nowrap" }}>HOW TO PLAY</span>
                    </NavLink>
                  </li>
                  {(userEligibiltiy === ELIGIBILITY_LEVEL.LEVEL_0 ||
                    userEligibiltiy > ELIGIBILITY_LEVEL.LEVEL_2) && (
                    <li className="nav-item">
                      <NavLink
                        bob-ga-data-on="click"
                        bob-ga-data-event-category="Header"
                        bob-ga-data-event-action="Click"
                        bob-ga-data-event-label="Prizes"
                        bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
                        className="nav-link"
                        activeClassName="selected"
                        to={PageView.PrizesPage}
                        onClick={handleLinkClick}
                      >
                        <span style={{ whiteSpace: "nowrap" }}>Prizes</span>
                      </NavLink>
                    </li>
                  )}
                  <li className="nav-item winners-nav dropdown-caret-container">
                    <Accordion
                      title="WINNERS CIRCLE"
                      hrefs={[PageView.WinnersList, PageView.HallOfFame]}
                    >
                      <Link
                        bob-ga-data-on="click"
                        bob-ga-data-event-category="Header"
                        bob-ga-data-event-action="Click"
                        bob-ga-data-event-label="Winners"
                        bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
                        to={PageView.WinnersList}
                        onClick={handleLinkClick}
                      >
                        Current Season
                      </Link>
                      <Link
                        bob-ga-data-on="click"
                        bob-ga-data-event-category="Header"
                        bob-ga-data-event-action="Click"
                        bob-ga-data-event-label="HallOfFame"
                        bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
                        to={PageView.HallOfFame}
                        onClick={handleLinkClick}
                      >
                        Previous Season
                      </Link>
                    </Accordion>
                    <NavLink
                      bob-ga-data-on="click"
                      bob-ga-data-event-category="Header"
                      bob-ga-data-event-action="Click"
                      bob-ga-data-event-label="Winners"
                      bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
                      className="nav-link desktop-link"
                      activeClassName="selected"
                      to={PageView.WinnersList}
                      onClick={handleLinkClick}
                    >
                      <span>Winners Circle</span>
                      <span className="caret"></span>
                      <div className="winners-circle dropdown">
                        <ul>
                          <li className="text-nowrap">
                            <Link
                              bob-ga-data-on="click"
                              bob-ga-data-event-category="Header"
                              bob-ga-data-event-action="Click"
                              bob-ga-data-event-label="Winners"
                              bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
                              to={PageView.WinnersList}
                              onClick={handleLinkClick}
                            >
                              Current Season
                            </Link>
                          </li>
                          <li className="text-nowrap">
                            <Link
                              bob-ga-data-on="click"
                              bob-ga-data-event-category="Header"
                              bob-ga-data-event-action="Click"
                              bob-ga-data-event-label="HallOfFame"
                              bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
                              to={PageView.HallOfFame}
                              onClick={handleLinkClick}
                            >
                              Previous Season
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      bob-ga-data-on="click"
                      bob-ga-data-event-category="Header"
                      bob-ga-data-event-action="Click"
                      bob-ga-data-event-label="AboutTenx"
                      bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
                      className="nav-link"
                      activeClassName="selected"
                      to={PageView.AboutTenX}
                      onClick={handleLinkClick}
                    >
                      <span style={{ whiteSpace: "nowrap" }}>About Ten-X</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      bob-ga-data-on="click"
                      bob-ga-data-event-category="Header"
                      bob-ga-data-event-action="Click"
                      bob-ga-data-event-label="Help"
                      bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
                      className="nav-link"
                      activeClassName="selected"
                      to={PageView.Help}
                      onClick={() => setShowMobileNav(!showMobileNav)}
                    >
                      <span style={{ whiteSpace: "nowrap" }}>Help</span>
                    </NavLink>
                  </li>
                  {feBobShowLogin && !showLoginModal && showMobileNav && (
                    <>
                      {showWatchNow && showMobileNav && (
                        <li className="watch-now-mobile">
                          <NavLink
                            className="menu-watch-now"
                            to={PageView.WatchNow}
                            onClick={() => setShowMobileNav(!showMobileNav)}
                          >
                            WATCH NOW <span className="watch-dot">&#183;</span>
                          </NavLink>
                        </li>
                      )}
                      <li className="nav-item">{renderAuthButtonMobile()}</li>
                    </>
                  )}
                </ul>
              )}
            </div>
            {showWatchNow && (
              <div className="nav-right nav-auth">
                <div
                  className="menu-watch-now float-right"
                  onClick={() => history.push(PageView.WatchNow)}
                >
                  WATCH NOW <span className="watch-dot">&#183;</span>
                </div>
              </div>
            )}

            <div className="nav-right nav-auth">
              {feBobShowLogin && !showLoginModal && (
                <div id="menu-buttons" className="navbar-nav navbar-right-nav">
                  <div className="nav-item login-link" id="login-btn">
                    {renderAuthButton()}
                  </div>
                </div>
              )}
            </div>
          </div>
        </nav>
      </section>
    </>
  );
};
