import React from "react";
import classNames from "classnames";
import { NumberFormatter } from "../../types/formatter";
import { useRoundContext } from "../../common/round-context";
import { useContestContext } from "../../common/contest-context";
import { ApiLeaderboardPlayerScore } from "../../types/api-models";
import { DEFAULT_RANK } from "../../common/constants";

type Props = {
  entry: ApiLeaderboardPlayerScore;
  index: number;
  currentPlayerRef: any;
  currentPlayerId?: string;
  isSeasonView: boolean;
};

export const LeaderBoardItem: React.FC<Props> = ({
  entry,
  index,
  currentPlayerRef,
  currentPlayerId,
  isSeasonView
}) => {
  const { round } = useRoundContext();
  const { contest } = useContestContext();
  const currentPlayer = currentPlayerId === entry.playerId && currentPlayerId !== "";
  const rowClasses = () => {
    if (currentPlayer) {
      return "table-row current-player";
    } else {
      return "table-row";
    }
  };

  const rank = !isSeasonView
    ? entry.eventPointsGroupRank
      ? entry.eventPointsGroupRank
      : entry.eventPointsRank
    : entry.seasonPointsGroupRank
    ? entry.seasonPointsGroupRank
    : entry.seasonPointsRank;

  return (
    <tr key={index} className={rowClasses()}>
      <td>
        <div className="rank-content">{rank === DEFAULT_RANK ? "N/A" : rank}</div>
        {currentPlayerRef && <span ref={(elem) => (currentPlayerRef.current = elem)}></span>}
      </td>
      <td className="player-name-content-td">
        <div className="player-name-content">
          {entry.firstname} {entry.lastname}
        </div>
      </td>
      <td className="row-company-td">
        <div className="row-company-content">{entry.companyName}</div>
      </td>
      <td
        className={classNames({
          "points-content-td": true
        })}
      >
        <div className="points-content">
          {NumberFormatter.FormatNumber(
            (isSeasonView ? entry.seasonPoints : entry.eventPoints) || 0
          )}
        </div>
      </td>
      {isSeasonView && (
        <td className="bonus-points-content-td">
          <div className="bonus-points-content text-right">
            {entry.seasonBonusPoints
              ? NumberFormatter.FormatNumber(entry.seasonBonusPoints || 0)
              : "-"}
          </div>
        </td>
      )}
      {isSeasonView && (
        <td className="total-points-content-td">
          <div className="total-points-content">
            {isSeasonView
              ? NumberFormatter.FormatNumber(
                  (entry.seasonPoints! || 0) + (entry.seasonBonusPoints! || 0)
                )
              : round?.round! <= contest?.round!
              ? NumberFormatter.FormatNumber(entry.eventPoints || 0)
              : " - "}
          </div>
        </td>
      )}

      <td className="bet-accuracy-content-td">
        <div className="bet-accuracy-content text-right">
          {NumberFormatter.FormatPercent(
            Math.max((!isSeasonView ? entry.bidAccuracy : entry.seasonBidAccuracy) || 0, 0)
          )}
        </div>
      </td>

      <td className="winnings-content-td">
        <div className="winnings-content text-right">
          {isSeasonView
            ? NumberFormatter.FormatCurrency(entry.seasonWinnings || 0)
            : round?.round! > contest?.round!
            ? " - "
            : NumberFormatter.FormatCurrency(entry.eventWinnings || 0)}
        </div>
      </td>
    </tr>
  );
};
