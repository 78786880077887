import React from "react";
import jet from "../../content/images/marketing/help-footer-jet.png";
import cloudL from "../../content/images/marketing/help-footer-cloud-l.png";
import cloudR from "../../content/images/marketing/help-footer-cloud-r.png";
import { FaqTables } from "./faq-data";
import { Question } from "./help-question";
import bobLogo from "../../../src/content/images/marketing/bob-lines-logo.svg";
import smallCloud from "../../../src/content/images/marketing/help-hero-cloud.png";
import phone from "../../../src/content/images/marketing/support-phone.svg";
import email from "../../../src/content/images/marketing/support-email.svg";

import "../../../src/content/styles/common/help.scss";

const Hero = () => (
  <div className="help-hero-container">
    <img className="cloud" src={smallCloud} alt="cloud" />
    <div className="help-logo-container">
      <img className="help-bob-logo" alt="battle-of-bids-logo" src={bobLogo} />
    </div>
    <div className="help-heading-container">
      <div className="help-title">OUR TEAM IS HERE TO HELP</div>
      <div className="support-text">
        Contact customer support with any questions via phone or email.
      </div>

      <div className="support-options">
        <div className="support-option number">
          <img src={phone} alt="support-phone" />
          <a href="tel:+1-888-619-5750" style={{ textDecoration: "none" }}>
            888-619-5750
          </a>
        </div>

        <div className="support-option email">
          <img src={email} alt="support-email" />
          <a href="mailto:battleofthebids@ten-x.com">battleofthebids@ten-x.com</a>
        </div>
      </div>
    </div>
  </div>
);

const Footer = ({ feBobShowJets }: { feBobShowJets?: boolean }) => (
  <div className="help-footer-container">
    <div className="help-footer-text">
      Have more questions? Email us at{" "}
      <u
        onClick={() => {
          window.location.href = "mailto:battleofthebids@ten-x.com";
        }}
      >
        battleofthebids@ten-x.com
      </u>
    </div>
    {feBobShowJets && <img className="jet" src={jet} alt="small jet" />}
    <img className="cloud-r" src={cloudR} alt="small cloud" />
    <img className="cloud-l" src={cloudL} alt="small cloud" />
  </div>
);

const FaqSection = () => (
  <>
    <div className="help-faq-container">
      <div className="help-faq-header-container">
        <div className="faq-title">FAQ</div>
        <div className="faq-heading">
          Browse the topics below to get answers to the most common questions.
        </div>
      </div>

      <div className="table-container">
        {FaqTables.map((table: any, tableIndex: number) => {
          return (
            <div className="faq-section" key={table.header}>
              <table className="h-table">
                <thead>
                  <tr>
                    <td>
                      {table.header}
                      <div></div>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {FaqTables[tableIndex].items.map((row: any) => {
                    return <Question key={row.key} question={row.key} answer={row.value} />;
                  })}
                </tbody>
              </table>
            </div>
          );
        })}
      </div>
    </div>
  </>
);

export const Help = ({ feBobShowJets }: { feBobShowJets?: boolean }) => {
  return (
    <>
      <div className="help-container">
        <Hero />
        <FaqSection />
        <Footer feBobShowJets={feBobShowJets} />
      </div>
    </>
  );
};
