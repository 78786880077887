import React, { useEffect, useState, useRef } from "react";
import bobLogo from "../content/images/logo-battle-of-the-bids.svg";
import { Switch, Route, useHistory } from "react-router-dom";
import { ScoreBoard } from "../components/scoreboard/scoreboard";
import { LeaderBoard } from "../components/leaderboard/leaderboard";
import { ScoreCard } from "../components/score-card";
import { PropertyStats } from "../components/property-stats";
import { PropertySelection } from "../components/property-selection/property-selection";
import { useContestContext } from "../common/contest-context";
import { PageView } from "../common/constants";
import { SubHeader } from "../components/sub-header/sub-header";
import { ContestStatus } from "../types/contest";
import { ProtectedRoute } from "../components/common/protected-route";
import { useEntryContext } from "../common/entry-context";
import classNames from "classnames";
import "../content/styles/game/table-container.scss";
import { useUserContext } from "../common/user-context";
import { formatDateRange, NumberFormatter } from "../types/formatter";
import { HeaderCountdown } from "../components/header-countdown/header-countdown";
import { Footer } from "../components/footer/footer";
import { useRoundContext } from "../common/round-context";
import { buildGamePageRoute } from "../helpers/game-route-builder";
import { useStandingContext } from "../common/standing-context";
import { useUpdate } from "./../hooks/use-update";
import { HeroSection } from "../components/marketing-views";
import { MessageModal } from "../components/common/message-modal";
import { useMessageModal } from "../hooks/use-message-modal";
import { MessageModalData } from "../types/message-modal-data";
import { useLocation } from "react-router-dom";
import gtm from "../helpers/gtm-helper";
import { log } from "console";

type Props = {
  isAdminPages?: boolean;
  isPublicUser: boolean;
  pageView?: string;
  feBobShowJets?: boolean;
  feBobEndOfSeason?: boolean;
};

export const ScoreBoardScreen: React.FC<Props> = ({
  isAdminPages,
  pageView,
  isPublicUser,
  feBobShowJets,
  feBobEndOfSeason
}) => {
  const { setError } = useContestContext();
  const { round, roundStatus } = useRoundContext();
  const { items, submit, canSubmit, previouslySubmitted } = useEntryContext();
  const { standingInfo } = useStandingContext();
  const history = useHistory();
  const { isAuthenticated, userInfo } = useUserContext();

  const {
    theMessageData,
    setMsgData,
    isShowMessageModal,
    showMsgModal,
    hideMsgModal
  } = useMessageModal();
  const [scoreboardButtonText, setScoreboardButtonText] = useState<string>("Submit Changes");
  const [fixedAuctionHeader, setFixedAuctionHeader] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<any>();
  const [isBettingOpened, setIsBettingOpened] = useState(false);
  const sideLinkRef = useRef<null | HTMLDivElement>(null);
  const { pathname } = useLocation();
  const { contest } = useContestContext();

  let location = useLocation();

  useEffect(() => {
    if (sideLinkRef?.current?.scrollIntoView) {
      sideLinkRef?.current?.scrollIntoView({ behavior: "auto" });
    }
  }, [sideLinkRef, location]);

  const listenScrollEvent = () => {
    if (window.scrollY < 400) {
      return setFixedAuctionHeader(false);
    } else if (window.scrollY >= 400) {
      return setFixedAuctionHeader(true);
    }
  };

  const isPropertySelectionPage = pageView === PageView.PropertySelectionPage;
  const isPropertyStats = pageView === PageView.PropertyStats;
  const isScoreBoard = pageView === PageView.ScoreboardPage;
  const isScoreCard = pageView === PageView.ScoreCard;

  const selectProperty = (property: any) => {
    setSelectedProperty(property);
  };

  useUpdate(() => {
    setSelectedProperty(false);
  });

  useEffect(() => {
    if (isPropertySelectionPage) {
      window.addEventListener("scroll", listenScrollEvent);
    }

    if (isPropertySelectionPage) {
      setScoreboardButtonText("Submit Selections");
    } else if (previouslySubmitted && isScoreBoard) {
      setScoreboardButtonText("Submit Changes");
    }

    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, [round, roundStatus, previouslySubmitted, isScoreBoard, isPropertySelectionPage]);

  useEffect(() => {
    if (isPropertySelectionPage) {
      history.push(buildGamePageRoute(pathname, contest?.contestId!));
    }
  }, [isPropertySelectionPage, contest]);

  return (
    <>
      {round && (
        <>
          <div className="score-background">
            <MessageModal
              isShown={isShowMessageModal}
              hideModal={hideMsgModal}
              data={theMessageData}
            />

            <>
              {!feBobEndOfSeason && ( 
                 <HeroSection
                  showLeaderboard
                  setTheMessageData={setMsgData}
                  showTheMessageModal={showMsgModal}
                  isBettingOpened={isBettingOpened}
                  isPublicUser={isPublicUser}
                  feBobShowJets={feBobShowJets}
                />
              )}
              {roundStatus === ContestStatus.InProgress && (
                <div id="pagecontent" ref={sideLinkRef} />
              )}
            </>

            {!isAdminPages && <SubHeader feBobEndOfSeason={feBobEndOfSeason} />}

            <div>
              <Switch>
                <ProtectedRoute path={`${PageView.ScoreboardPage}`}>
                  <>
                    <ScoreBoard />
                  </>
                </ProtectedRoute>
                <Route path={PageView.RulesPage}>{/* <GameRules /> */}</Route>
                <ProtectedRoute path={`${PageView.PropertySelectionPage}`}>
                  <PropertySelection />
                </ProtectedRoute>
                <ProtectedRoute path={`${PageView.LeaderboardPage}`}>
                  <>
                    <LeaderBoard
                      setMessageData={
                        setMsgData as React.Dispatch<React.SetStateAction<MessageModalData>>
                      }
                      showTheMessageModal={showMsgModal}
                      roundLeaderBoard
                      feBobShowJets={feBobShowJets}
                    />
                  </>
                </ProtectedRoute>
                <ProtectedRoute path={`${PageView.ScoreCard}`}>
                  <ScoreCard />
                </ProtectedRoute>
                <ProtectedRoute path={`${PageView.PropertyStats}`}>
                  <PropertyStats selectProperty={selectProperty} />
                </ProtectedRoute>
              </Switch>
            </div>
          </div>
          <div className="scoreboard-footer-wrapper">
            <Footer feBobShowJets={feBobShowJets} />
          </div>
        </>
      )}
    </>
  );
};
