/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * None&nbsp;(0)<br/>Small&nbsp;(1)<br/>Medium&nbsp;(2)<br/>Large&nbsp;(3)<br/>ExtraLarge&nbsp;(4)<br/>ExtraExtraLarge&nbsp;(5)
 */
export enum ContestDomainClient_Models_TeeShirtSizes {
  NONE = "None",
  SMALL = "Small",
  MEDIUM = "Medium",
  LARGE = "Large",
  EXTRA_LARGE = "ExtraLarge",
  EXTRA_EXTRA_LARGE = "ExtraExtraLarge"
}
