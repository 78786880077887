import React, { useEffect } from "react";
import { NavHeader } from "../components/nav-header/nav-header";
import { ScoreBoardScreen } from "../containers/scoreboard-screen";
import { MarketingScreen } from "./marketing-screen";
import { useLocation } from "react-router-dom";
import { PageView } from "../common/constants";
import { useContestContext } from "../common/contest-context";
import { RulesAcceptanceModal } from "../components/login-signup/accept-rules";
import {
  isImpersonationError,
  isInvalidPlayerError,
  parseCognitoError
} from "../helpers/auth-error-helpers";
import { NotAuthorizedToPlay } from "../components/login-signup/not-authorized-to-play";
import { ErrorScreen } from "./error-screen";
import { getStorageRepo, StorageOption } from "../helpers/storage";
import { ReviewPropertiesModal } from "../components/login-signup/review-properties-modal";
import { NoMoreBetsModal } from "../components/login-signup/no-more-bets-modal";
import { useStandingContext } from "../common/standing-context";
import { useRoundContext } from "../common/round-context";
import { ContestStatus } from "../types/contest";
import { useUserContext } from "../common/user-context";
import { ConfettiAcceptCheck } from "../components/confetti-cannon/accept-confetti";
import { getTenXTime } from "../helpers/date-helpers";
import { ELIGIBILITY, ELIGIBILITY_LEVEL } from "../common/constants";
import { checkEligibility } from "../helpers/eligibility-helper";
import gtm from "../helpers/gtm-helper";

import moment from "moment";
import { LeadBot } from "../components/lead-bot/lead-bot";
import { useFeatureFlagContext } from "../common/feature-flag-context";

export const ScreenContainer: React.FC = () => {
  useEffect(() => {
    getTenXTime();
    const interval = setInterval(() => {
      getTenXTime();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  let location = useLocation();
  const { status, contest, error, setError } = useContestContext();
  const { standingInfo, isMinRankForPrize, winnings } = useStandingContext();
  const { roundStatus, round } = useRoundContext();
  const { userInfo, userEligibiltiy, isAuthenticated } = useUserContext();
  const [showReviewPropertiesModal, setShowReviewPropertiesModal] = React.useState(false);
  const [showNoMoreBetsModal, setShowNoMoreBetsModal] = React.useState(false);
  const { featureFlags} = useFeatureFlagContext();
  const {
    feBobPublicSeason,
    feBobIcscShow,
    feBobShowLogin,
    feBobShowWatchNow,
    feBobShowJets,
    feBobEndOfSeason
  } = featureFlags || {};

  const defaultDate: Date = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
  const startDate = moment(round?.localStartDate || contest?.localStartDate);
  const endDate = moment(round?.localEndDate || contest?.localEndDate);
  const now = moment();
  const userInfoConfettiDismissed = moment(userInfo?.confettiDismissed);

  const roundType = ELIGIBILITY.findIndex((item) => item === round?.contestRoundType);
  const ACCESS = checkEligibility(round?.contestRoundType, userEligibiltiy);

  const isPublicUser =
    userEligibiltiy !== ELIGIBILITY_LEVEL.LEVEL_1 &&
    userEligibiltiy !== ELIGIBILITY_LEVEL.LEVEL_2 &&
    roundType < ELIGIBILITY_LEVEL.LEVEL_3;

  const confettiDismissedStorage = getStorageRepo<Date>(
    "confetti_dismissed_storage",
    defaultDate,
    StorageOption.Local
  );

  const confettiDismissed = moment(confettiDismissedStorage.get());
  if (userInfo?.confettiDismissed && confettiDismissed < userInfoConfettiDismissed) {
    confettiDismissedStorage.set(userInfo?.confettiDismissed);
  }

  const rulesDeclinedStorage = getStorageRepo<boolean>(
    "game_rule_declined",
    false,
    StorageOption.Session
  );

  const isAdminPages =
    location.pathname.includes(PageView.ScoreCard) ||
    location.pathname.includes(PageView.PropertyStats);

  useEffect(() => {
    console.log("render", location.pathname);
    if (location.pathname !== "/") {
      gtm.trackEvent("page_view", {
        page_data: {
          contest_id: round?.contestId
        },
        user_data: {
          user_id: userInfo?.contactId || undefined,
          user_login_status: isAuthenticated
        }
      });
    }
  }, [location.pathname]);

  const viewMux = () => {
    const cognitoError = parseCognitoError(location.search);
    if (isInvalidPlayerError(cognitoError) || isImpersonationError(cognitoError)) {
      return <NotAuthorizedToPlay />;
    }

    if (error) {
      return <ErrorScreen error={error} backToAction={() => setError("")} />;
    }

    let gameViewPage;
    switch (true) {
      case location.pathname.includes(PageView.ScoreboardPage):
        gameViewPage = PageView.ScoreboardPage;
        break;
      case location.pathname.includes(PageView.PropertySelectionPage):
        gameViewPage = PageView.PropertySelectionPage;
        break;
      case location.pathname.includes(PageView.LeaderboardPage):
        gameViewPage = PageView.LeaderboardPage;
        break;
      case location.pathname.includes(PageView.ScoreCard):
        gameViewPage = PageView.ScoreCard;
        break;
      case location.pathname.includes(PageView.PropertyStats):
        gameViewPage = PageView.PropertyStats;
        break;
      default:
        gameViewPage = null;
    }

    if (gameViewPage && rulesDeclinedStorage.get()) {
      rulesDeclinedStorage.set(false);
    }

    if (userInfo) {
      return gameViewPage && (isAdminPages || feBobPublicSeason) ? (
        <ScoreBoardScreen
          isAdminPages={isAdminPages}
          pageView={gameViewPage}
          isPublicUser={isPublicUser}
          feBobShowJets={feBobShowJets}
          feBobEndOfSeason={feBobEndOfSeason}
        />
      ) : (
        <MarketingScreen
          isPublicUser={isPublicUser}
          isICSCShow={feBobIcscShow}
          feBobShowJets={feBobShowJets}
          feBobEndOfSeason={feBobEndOfSeason}
        />
      );
    } else {
      // Display blank until we can fetch the userInfo
      return <></>;
    }
  };
  let stat =
    (roundStatus === ContestStatus.Ended && contest?.contestId !== round?.contestId) ||
    (status === ContestStatus.Ended && contest?.contestId === round?.contestId);

  const isContestIdMatched = location.search.includes(standingInfo?.contestId!);

  return (
    <>
      <div id="cs-body-wrapper">
        <span id="top"></span>
        <NavHeader
          contestName={contest?.name || ""}
          isAdminPages={isAdminPages}
          feBobIcscShow={feBobIcscShow}
          feBobShowLogin={feBobShowLogin}
          showWatchNow={feBobShowWatchNow}
        />
        {viewMux()}
        <RulesAcceptanceModal />
        {ACCESS && (
          <ReviewPropertiesModal
            showReviewPropertiesModal={showReviewPropertiesModal}
            setShowReviewPropertiesModal={setShowReviewPropertiesModal}
          />
        )}
        {ACCESS && (
          <NoMoreBetsModal
            showNoMoreBetsModal={showNoMoreBetsModal}
            setShowNoMoreBetsModal={setShowNoMoreBetsModal}
          />
        )}
        {/* if the round has ended, the user qualifies for a prize, and the user has not dismissed the confetti modal*/}

        {stat &&
          isContestIdMatched &&
          now > endDate &&
          confettiDismissed < startDate &&
          isMinRankForPrize &&
          winnings! > 0 &&
          location.pathname !== PageView.WinnersList && <ConfettiAcceptCheck />}
        {!isAdminPages && <LeadBot isICSCShow={feBobIcscShow} />}
      </div>
    </>
  );
};
