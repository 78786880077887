import React from "react";
import "../../content/styles/common/message-modal.scss";
import { useUserContext } from "../../common/user-context";
import { MessageModalData } from "../../types/message-modal-data";

type Props = {
  isShown: boolean;
  hideModal: () => void;
  data: MessageModalData;
};

export const MessageModal: React.FC<Props> = ({ isShown, hideModal, data }) => {
  const hideMessageModal = () => {
    hideModal();
  };
  const { userInfo } = useUserContext();

  return (
    <div
      style={{ position: "absolute", zIndex: 11 }}
      className={isShown === true ? "winner-has-address" : "winner-has-address hidden"}
    >
      {/* <div className="rules-overlay" style={{ zIndex: 3 }}></div> */}
      <div className="message-modal">
        {data.videoUrl && (
          <>
            <div className="message-modal-video-body">
              <button
                onClick={hideMessageModal}
                style={{
                  color: "white",
                  backgroundColor: "transparent",
                  border: "none",
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  zIndex: 100000
                }}
              >
                &#10006;
              </button>
              <iframe
                title="messageVideo"
                src={data.videoUrl}
                className="message-modal-video"
                allowFullScreen={true}
                style={{ position: "relative" }}
              ></iframe>
            </div>
          </>
        )}
        {data.message && (
          <>
            <div className="message-modal-header">
              <button
                className="btn-close"
                onClick={hideMessageModal}
                bob-ga-data-on="click"
                bob-ga-data-event-action="Click"
                bob-ga-data-event-label="Dismissed"
                bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
              >
                &#10006;
              </button>
            </div>
            <div className="message-modal-body">
              <h2>{data.messageTitle}</h2>
              <p>{data.message}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
