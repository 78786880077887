import React, { useEffect } from "react";
import { useEntryContext } from "../../common/entry-context";

export enum ChangeBetDialogType {
  ZeroLeft,
  OneLeft,
  TwoLeft
}

type Props = {
  type: ChangeBetDialogType;
};

export const ChangeBetDialog: React.FC<Props> = ({ type }) => {
  const {
    dismissZeroBetLeftDialog,
    dismissOneBetLeftDialog,
    dismissTwoBetLeftDialog
  } = useEntryContext();

  const handleDismiss = () => {
    switch (type) {
      case ChangeBetDialogType.ZeroLeft:
        dismissZeroBetLeftDialog();
        break;
      case ChangeBetDialogType.OneLeft:
        dismissOneBetLeftDialog();
        break;
      case ChangeBetDialogType.TwoLeft:
        dismissTwoBetLeftDialog();
        break;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = "hidden";
    document.body.classList.add("locked");

    return () => {
      document.body.style.overflow = "scroll";
      document.body.classList.remove("locked");
    };
  }, []);

  return (
    <div className="change-bet-container">
      <div className="change-bet-overlay" style={{ zIndex: 3 }}></div>
      <div className="change-bet-dialog">
        <button className="btn-close" onClick={handleDismiss}>
          &#10006;
        </button>
        <div className="heading">Change up to two bets</div>

        <div className="body-text">
          {type === ChangeBetDialogType.ZeroLeft && <ZeroBetLeftContent />}
          {type === ChangeBetDialogType.OneLeft && <OneBetLeftContent />}
          {type === ChangeBetDialogType.TwoLeft && <TwoBetLeftContent />}
        </div>
        <div className="action-button" onClick={handleDismiss}>
          DISMISS
        </div>
      </div>
    </div>
  );
};

function ZeroBetLeftContent() {
  return (
    <div>
      2 of the 2 bets have been changed.
      <br />
      You have used both your bet changes
    </div>
  );
}

function OneBetLeftContent() {
  return (
    <div>
      1 of 2 bets has been changed.
      <br />
      You have 1 bet remaining that you can change
    </div>
  );
}

function TwoBetLeftContent() {
  return (
    <div>
      One or more of your properties has 10 minutes
      <br />
      or less remaining until the auction closes.
      <br />
      You can change your bet on two properties
      <br />
      for a chance to win more points!
    </div>
  );
}
