import React, { useEffect, useState } from "react";
import { PropertySelectionItem } from "../property-selection/property-selection-item";
import "../../content/styles/game/property-placard.scss";
import { ContestItem } from "../../types/contest";
import { useContestContext } from "../../common/contest-context";
import { useRoundContext } from "../../common/round-context";
import { useReadOnly } from "../../hooks/use-read-only";
import { PropertyGraph } from "./property-graph";
import { PizzaTracker } from "./pizza-tracker";
import { useAuctionListings } from "../../hooks/use-auction-listings";
import { PlayerDomainInterfacesService } from "../../player-api-client";

type Props = {
  selectProperty: any;
};

export const PropertyStats: React.FC<Props> = (props) => {
  const { contest } = useContestContext();
  const { round } = useRoundContext();
  const { auctionListings } = useAuctionListings();

  const isReadOnly = useReadOnly();
  const selectedContest = isReadOnly ? contest : round;

  const [currentProperty, setCurrentProperty] = useState<ContestItem>();
  const [contestItem, setContestItem] = useState<any>();
  const [graphData, setGraphData] = useState<any>();
  const [currentBid, setCurrentBid] = useState<number | undefined>();

  const contestProperties = selectedContest?.contestItems.map((item) => {
    const auctionListing = auctionListings?.find((x) => x.listing_id === item.tenXListingId);
    const currentBid = auctionListing?.current_bid_amt ?? item.currentBid;
    return { ...item, currentBid };
  });

  const handleSelect = (e: any) => {
    const property = selectedContest?.contestItems.find(
      (property) => property.buildingName === e.target.value
    );
    const contestProperty = contestProperties?.find(
      (property) => property.buildingName === e.target.value
    );
    setCurrentProperty(property);
    setContestItem(contestProperty);
  };

  const currentAuctionListing = auctionListings?.find(
    (x) => currentProperty?.tenXListingId === x.listing_id
  );
  useEffect(() => {
    props.selectProperty(currentAuctionListing);
    setCurrentBid(currentAuctionListing?.current_bid_amt);
  }, [currentAuctionListing?.current_bid_amt, currentProperty]);

  useEffect(() => {
    return props.selectProperty(false);
  }, []);

  useEffect(() => {
    const getPropertyStatsData = async () => {
      if (contest?.contestId && contestItem?.tenXPropertyId) {
        const response = await PlayerDomainInterfacesService.apiPropertyStatsGraphPointsByContestIdByTenXPropertyIdGet(
          contest?.contestId!,
          contestItem?.tenXPropertyId!
        );
        if (response) {
          const { averageBet, marketGuidance } = response;
          const currentBidAverage =
            contestItem?.currentBid && averageBet
              ? contestItem?.currentBid / averageBet
              : undefined;
          const currentBidMarket =
            contestItem?.currentBid && marketGuidance
              ? contestItem?.currentBid / marketGuidance
              : undefined;
          setGraphData({ ...response, currentBidAverage, currentBidMarket });
        }
      }
    };
    getPropertyStatsData();
  }, [contest, contestItem, currentProperty]);

  return (
    <div className="property-container">
      <div className="property-selection">
        <div className="selectParent">
          <select
            id="select_id"
            defaultValue={"DEFAULT"}
            className="property-select"
            onChange={handleSelect}
          >
            <option value="DEFAULT" disabled hidden>
              Select Property
            </option>
            {selectedContest?.contestItems?.map((property: ContestItem, index) => (
              <option key={index}>{property.buildingName}</option>
            ))}
          </select>
        </div>
        <div>
          {currentProperty && (
            <PropertySelectionItem
              contestItem={currentProperty}
              setLimitReached={() => {
                return false;
              }}
              isBettingOpened={false}
            />
          )}
        </div>
      </div>
      <div className="property-stats-container">
        <PizzaTracker
          currentBid={currentBid}
          numberOfBets={graphData?.numberBets}
          averageBet={graphData?.averageBet}
          marketGuidance={graphData?.marketGuidance}
          bidStatus={currentProperty?.auctionData?.auction_status!}
          currentBidAverage={graphData?.currentBidAverage}
          currentBidMarket={graphData?.currentBidMarket}
        />
        <PropertyGraph
          currentBid={currentBid}
          marketGuidance={graphData?.marketGuidance}
          averageBet={graphData?.averageBet}
          maxNumBets={graphData?.maxNumBets}
          data={graphData?.bets}
        />
      </div>
    </div>
  );
};
