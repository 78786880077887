import moment from "moment";
import { MeasurementType, SQFT_PER_ACRE } from "../common/constants";

export class NumberFormatter {
  static FormatNumber(number: number, zeroReplacement?: string) {
    return number === 0 && zeroReplacement ? zeroReplacement : Intl.NumberFormat().format(number);
  }

  static FormatCurrency(number: number, zeroReplacement?: string) {
    return number === 0 && zeroReplacement
      ? zeroReplacement
      : "$" + Intl.NumberFormat().format(number);
  }

  static FormatPercent(number: number) {
    const percentage = Intl.NumberFormat("en-US", { maximumSignificantDigits: 4 }).format(
      number * 100
    );
    if (!Number(percentage)) {
      return percentage + "%";
    }
    return parseFloat(percentage).toFixed(2) + "%";
  }
}

export class AreaFormatter {
  static AreaUnitConverter = (squareFeet?: number, measurementType?: number) => {
    return measurementType === MeasurementType.AC
      ? squareFeet && squareFeet / SQFT_PER_ACRE
      : squareFeet;
  };

  static FormatArea = (size: number, measurementType: number = MeasurementType.SF) => {
    const areaUnit = measurementType === MeasurementType.AC ? "AC" : "SF";
    return `${NumberFormatter.FormatNumber(size)} ${areaUnit}`;
  };
}

/**
 * Returns a date range in MM/DD - MM/DD format
 * @param startDate
 * @param endDate
 * @returns
 */
export function formatDateRange(startDate: Date, endDate: Date) {
  const startDateMonth = `${moment(startDate).format("MMM")}`;
  const startDateDay = moment(startDate).format("D");
  const endDateMonth = `${moment(endDate).format("MMM")}`;
  const endDateDay = moment(endDate).format("D");

  return (
    startDateMonth +
    ". " +
    startDateDay +
    "-" +
    (endDateMonth === startDateMonth ? "" : endDateMonth + ". ") +
    endDateDay
  );
}

/**
 * Returns a date range in MMM-DD - MMM-DD format
 * @param startDate
 * @param endDate
 * @returns
 */
export function formatDateRangeLong(startDate: string, endDate: string) {
  const startDateMonth = `${moment(startDate).format("MMM")}`;
  const startDateDay = moment(startDate).format("DD");
  const endDateMonth = `${moment(endDate).format("MMM")}`;
  const endDateDay = moment(endDate).format("DD");

  return `${startDateMonth} ${startDateDay} - ${endDateMonth} ${endDateDay}`;
}
