import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { useEntryContext } from "../../common/entry-context";
import { ContestStatus } from "../../types/contest";
import { useContestContext } from "../../common/contest-context";
import { useReadOnly } from "../../hooks/use-read-only";

export function ScoreboardActions() {
  const { changeBetLeft } = useEntryContext();

  const { status } = useContestContext();
  const [changeBetLeftMessage, setChangeBetLeftMessage] = useState("");

  const isReadOnly = useReadOnly();
  const maxChangeBetLimit = 2;

  useEffect(() => {
    const CheckChangeBet = () => {
      switch (changeBetLeft) {
        case 2:
          return "You can change your bet on up to two properties, but only within the last 10 minutes of the remaining auction time for each property";
        case 1:
          return "1 of 2 bets has been changed. \n You have 1 bet remaining that you can change";
        case 0:
          return "2 of the 2 bets have been changed. \n You have used both your bet changes";
        default:
          return "Change up to two bets ten \n minutes before auction ends";
      }
    };

    setChangeBetLeftMessage(CheckChangeBet());
  }, [changeBetLeft]);

  return (
    <div
      className={
        status !== ContestStatus.InProgress ? "scoreboard-actions hide" : "scoreboard-actions"
      }
    >
      <div className="auction-series">&nbsp;</div>
      <div className="auction-dates">&nbsp;</div>
      {!isReadOnly && (
        <div className="change-two-bets">
          <div className="switch-player-change-bet-remaining">
            <span className="change-bet-info-button">
              <button
                data-tip="0"
                data-for="changebetinfo"
                data-event="click"
                className="change-bet-info-i"
              >
                i
              </button>
              <ReactTooltip
                id="changebetinfo"
                globalEventOff="click"
                textColor="#000"
                backgroundColor="#fff"
                className="points-tooltip-wrapper"
              >
                <div className="change-bet-info-style">{changeBetLeftMessage}</div>
              </ReactTooltip>
            </span>
            <div className="change-your-bet-space">
              Change Bet Left: {changeBetLeft} out of {maxChangeBetLimit}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
