import React, { useState } from "react";
import { createUtmFragment } from "../../helpers/utm-helpers";
import { AppConfig } from "../../common/app_config";
import { useUserContext } from "../../common/user-context";
import styles from "../../content/styles/common/lead-bot.module.scss";
import megaphone from "../../content/images/megaphone-icon.svg";
import megaphoneRed from "../../content/images/megaphone-icon-red.svg";

interface IProps {
  isICSCShow?: boolean;
}

export const LeadBot: React.FC<IProps> = ({ isICSCShow }) => {
  const [active, setActive] = useState(false);

  const { userInfo } = useUserContext();
  const subject = userInfo ? userInfo.caSubject : "";

  const evenOrOddWeek = () => {
    return Math.floor(
      ((Date.now() - Date.UTC(2022, 0, 1, 0, 0, 0)) / (1000 * 60 * 60 * 24 * 7)) % 2
    );
  };
  const botSwitch = evenOrOddWeek();
  const label = botSwitch ? "Calling All Brokers" : "Calling All Sellers";

  const handleClick = () => {
    botSwitch
      ? window.open(
          AppConfig.TENX_SERVICE_ROOT +
            "/CallingAllBrokers" +
            createUtmFragment("bobgame", "brokersellers", "landingpage", subject!)
        )
      : window.open(
          AppConfig.TENX_SERVICE_ROOT +
            "/MakeYourBuildingAStar" +
            createUtmFragment("bobgame", "brokersellers", "landingpage", subject!)
        );

    setActive(false);
  };

  const handleMouseEnter = () => {
    setActive(true);
  };

  const handleMouseLeave = () => {
    setActive(false);
  };

  return isICSCShow ? (
    <button
      id="button-lead-bot"
      className={styles.botButton}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {active ? (
        <img src={megaphoneRed} alt="Megaphone Icon" className={styles.icon} />
      ) : (
        <img src={megaphone} alt="Megaphone Icon" className={styles.icon} />
      )}
      {label}
    </button>
  ) : null;
};
