import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { useReadOnly } from "../hooks/use-read-only";
import { PlayerDomainInterfacesService } from "../player-api-client";
import { ApiEntryItem } from "../types/api-models";
import { ContestStatus } from "../types/contest";
import { useContestContext } from "./contest-context";
import { useRoundContext } from "./round-context";
import { useUserContext } from "./user-context";
import { MAX_BETS } from "./constants";

export type EntryContextValue = {
  /**
   * items in an entry
   */
  items: ApiEntryItem[];
  /**
   * submit entry (server)
   */
  submit: () => Promise<void>;
  /**
   * submit (update) single entry (server)
   */
  submitUpdate: (item: ApiEntryItem) => void;
  /**
   * submit (add new) single entry (server)
   */
  submitAdd: (item: ApiEntryItem) => void;
  /**
   * delete an item to entry (server)
   */
  addItem: (item: ApiEntryItem) => void;
  /**
   * delete an item from entry (local)
   */
  deleteItem: (itemId: string) => void;
  /**
   * delete an item from entry (server)
   */
  deleteSubmit: (itemId: string) => void;
  /**
   * update an item in entry (local)
   */
  updateItem: (item: ApiEntryItem) => void;
  /**
   * is submit (server) allowed.
   */
  canSubmit: boolean;
  /**
   * submit a restricted update. Each player is allowed to make 2 bet changes in the last 10 minute an auction (server)
   */
  submitChangeBet: (item: ApiEntryItem) => Promise<void>;
  /**
   * number of restricted update available
   */
  changeBetLeft: number;
  /**
   * itemIds of items in change bet period.
   */
  itemIdsInChangeBetPeriod: string[];
  /**
   * indicates whether player has accepted bet change limitations
   */
  acceptBetChangeLimitations: boolean;
  /**
   * update the state when an item enters the change bet peiord
   */
  notifyItemEnterChangeBetPeriod: (itemId: string) => void;
  /**
   * update the state when an item leaves the change bet period
   */
  notifyItemLeaveChangeBetPeriod: (itemId: string) => void;
  isInChangeBetMode: boolean;
  setIsInChangeBetMode: (mode: boolean) => void;
  showZeroBetLeftDialog: boolean;
  dismissZeroBetLeftDialog: () => void;
  showOneBetLeftDialog: boolean;
  dismissOneBetLeftDialog: () => void;
  showTwoBetLeftDialog: boolean;
  dismissTwoBetLeftDialog: () => void;
  changeBetEditModeItemId: string;
  setChangeBetEditModeItemId: (itemId: string) => void;
  previouslySubmitted: boolean;
  showInvalidBetDialog: boolean;
  dismissInvalidBetDialog: (itemId: string) => void;
  onBlurChangeBet: (itemId: string) => void;
  onBlurShowInvalidBet: (itemId: string) => void;
  selectedItem: string;
  allowedToSubmitChanges: (allowedSubmission: boolean) => void;
  showChangeBetError: boolean;
  dismissChangeBetError: () => void;
  isRuleAcceptanceError: boolean;
  showBetErrorDialog: boolean;
  dismissBetErrorDialog: () => void;
};

const MAX_RESTRICTED_UPDATE_AVAILABLE = 2;

export const EntryContext = React.createContext<EntryContextValue | null>(null);

// todo: pass in readonly or not so we are only using scoreboard context on game pages
export const EntryContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const { contest, status } = useContestContext();
  const { round, roundStatus } = useRoundContext();
  const { isAuthenticated, userInfo, signOut } = useUserContext();
  const [items, setItems] = useState<ApiEntryItem[]>([]);
  const [previousItems, setPreviousItems] = useState<ApiEntryItem[]>([]);
  const [showBetErrorDialog, setShowBetErrorDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<ApiEntryItem>({});
  const [itemToUpdate, setItemToUpdate] = useState<ApiEntryItem>({});
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [restrictedUpdateCount, setRestrictedUpdatedCount] = useState(0);
  const [acceptBetChangeLimitations, setAcceptBetChangeLimitations] = useState(false);
  const changeBetLeft = Math.max(MAX_RESTRICTED_UPDATE_AVAILABLE - restrictedUpdateCount, 0);
  // store itemIds of items that are in change bet period, i.e. last 10 min of the auction.
  const [itemIdsInChangeBetPeriod, setItemIdsInChangeBetPeriod] = useState<string[]>([]);
  const [isInChangeBetMode, setIsInChangeBetMode] = useState(false);
  const [showZeroBetLeftDialog, setShowZeroBetLeftDialog] = useState(false);
  const [showOneBetLeftDialog, setShowOneBetLeftDialog] = useState(false);
  const [showTwoBetLeftDialog, setShowTwoBetLeftDialog] = useState(false);
  const [changeBetEditModeItemId, setChangeBetEditModeItemId] = useState("");
  const [previouslySubmitted, setPreviouslySubmitted] = useState(false);
  const [showInvalidBetDialog, setShowInvalidBetDialog] = useState(false);
  const [showChangeBetError, setShowChangeBetError] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [triggerSubmit, setTriggerSubmit] = useState(false);
  const [refreshItems, setRefreshItems] = useState(false);
  const [isRuleAcceptanceError, setRuleAcceptanceError] = useState(false);

  const isReadOnly = useReadOnly();

  const selectedContest = isReadOnly ? contest : round;
  const selectedStatus = isReadOnly ? status : roundStatus;

  const allowedToSubmitChanges = useCallback(
    (allowedSubmission: boolean) => {
      setCanSubmit(allowedSubmission);
    },
    [setCanSubmit]
  );

  useEffect(() => {
    if (triggerSubmit) {
      submit();
    }
    setTriggerSubmit(false);
  }, [items, triggerSubmit]);

  useEffect(() => {
    if (refreshItems) {
      fetchContest();
      setRefreshItems(false);
    }
  }, [refreshItems]);

  useEffect(() => {
    if (itemToDelete) {
      singleDelete(itemToDelete);
    }
  }, [itemToDelete]);

  useEffect(() => {
    if (itemToUpdate) {
      singleSubmit(itemToUpdate);
    }
  }, [itemToUpdate]);

  const isValid = useCallback(
    (targetItems: ApiEntryItem[] = []) => {
      let emptyBetFound = 0;
      targetItems.forEach((entry) => {
        if (isNaN(entry["bet"]!) || entry["bet"] === 0) {
          emptyBetFound = 1;
        }
      });

      return (
        selectedStatus === ContestStatus.NotStarted &&
        targetItems.length > 0 &&
        targetItems.length <= MAX_BETS &&
        !emptyBetFound
      );
    },
    [selectedStatus]
  );

  const singleDelete = useCallback(
    async (itemToDelete) => {
      if (isAuthenticated && selectedContest?.contestId && userInfo?.playerId && itemToDelete) {
        try {
          const response = await PlayerDomainInterfacesService.apiEntrySingleDelete(
            itemToDelete.itemId
          );

          if (response.statusCode === 401) {
            localStorage.setItem("intialLogin", "RELOGIN");
            signOut();
          }

          if (response?.entry?.entryItems?.length) {
            setItems([...response.entry.entryItems]);
          } else {
            // clear out the items array after deleting last entry
            setItems([]);
          }
        } catch (err) {
          console.log(err);
          // if submit fails, restore items back to previousItems
          setItems(previousItems);
          setShowBetErrorDialog(true);
          setRefreshItems(true);
        }
      }
    },
    [isAuthenticated, selectedContest?.contestId, userInfo?.playerId, itemToDelete]
  );

  const singleSubmit = useCallback(
    async (itemToUpdate: ApiEntryItem) => {
      if (
        isAuthenticated &&
        selectedContest?.contestId &&
        userInfo?.playerId &&
        itemToUpdate.itemId
      ) {
        try {
          const response = await PlayerDomainInterfacesService.apiEntrySingleSubmitPost({
            entry: {
              contestId: selectedContest.contestId,
              playerId: userInfo.playerId,
              entryItems: [
                {
                  bet: itemToUpdate.bet,
                  itemId: itemToUpdate.itemId
                }
              ]
            }
          });

          if (response.statusCode === 401) {
            localStorage.setItem("intialLogin", "RELOGIN");
            signOut();
          }

          if (response?.entry?.entryItems?.length) {
            setItems([...response.entry.entryItems]);
          } else {
            setItems([]);
          }
        } catch (err: any) {
          // if submit fails, restore items back to previousItems
          setItems(previousItems);
          setShowBetErrorDialog(true);
          if (err.status === 403 && err.body.errorMessage.indexOf("rule") !== -1) {
            setRuleAcceptanceError(true);
          }

          setRefreshItems(true);
        }
      }
    },
    [isAuthenticated, selectedContest?.contestId, userInfo?.playerId, itemToUpdate]
  );

  const submit = useCallback(async () => {
    if (isAuthenticated && selectedContest?.contestId && userInfo?.playerId) {
      try {
        const response = await PlayerDomainInterfacesService.apiEntrySubmitPost({
          entry: {
            contestId: selectedContest.contestId,
            playerId: userInfo.playerId,
            entryItems: [...items]
          }
        });

        if (response?.entry?.entryItems?.length) {
          setItems([...response.entry.entryItems]);
        }
      } catch (err) {
        // if submit fails, set items to previousItems
        setItems(previousItems);
        setShowBetErrorDialog(true);
        console.log(err);
      }
    }
  }, [isAuthenticated, selectedContest?.contestId, userInfo?.playerId, items]);

  const addItem = useCallback(
    (item: ApiEntryItem) => {
      const target = items.find((x) => x.itemId === item.itemId);
      if (target) {
        // If we are trying to addItem which already existed,
        // that means we have encountered a race condition
        // (e.g. Submit Bet and Remove Bet very quickly within 500ms)
        // Workaround is to clear the existing item so the next "Enter Bet" focus will work
        const allOthers = items.filter((x) => x.itemId !== item.itemId);
        setPreviousItems(items);
        setItems(allOthers);
        setCanSubmit(isValid(allOthers));
        throw Error(`item ${item?.itemId} already exists.`);
      }
      const allOthers = items.filter((x) => x.itemId !== item.itemId);
      const newItems = [...allOthers, { ...item }];
      setPreviousItems(items);
      setItems(newItems);
      setCanSubmit(isValid(newItems));
    },
    [items, isValid]
  );

  const updateItem = useCallback(
    (item: ApiEntryItem) => {
      const target = items.find((x) => x.itemId === item.itemId);
      if (!target) {
        throw Error(`item ${item?.itemId} not found.`);
      }
      const allOthers = items.filter((x) => x.itemId !== item.itemId);
      const newItems = [...allOthers, { ...item }];
      setPreviousItems(items);
      setItems(newItems);
      setCanSubmit(isValid(newItems));
    },
    [items, isValid]
  );

  const submitAdd = useCallback(
    (item: ApiEntryItem) => {
      addItem(item);
      setItemToUpdate(item);
    },
    [items, isValid]
  );

  const submitUpdate = useCallback(
    (item: ApiEntryItem) => {
      const target = items.find((x) => x.itemId === item.itemId);
      if (!target) {
        throw Error(`item ${item?.itemId} not found.`);
      }
      setItemToUpdate(item);
    },
    [items, isValid]
  );

  const deleteItem = useCallback(
    (itemId: string) => {
      const target = items.find((x) => x.itemId === itemId);
      if (!target) {
        throw Error(`item ${itemId} not found.`);
      }
      const allOthers = items.filter((x) => x.itemId !== itemId);
      const newItems = [...allOthers];
      setPreviousItems(items);
      setItems(newItems);
      setCanSubmit(isValid(newItems));
    },
    [items, isValid]
  );

  const deleteSubmit = useCallback(
    (itemId: string) => {
      const target = items.find((x) => x.itemId === itemId);
      if (!target) {
        throw Error(`item ${itemId} not found.`);
      }
      setItemToDelete(target);
    },
    [items, isValid]
  );

  const onBlurChangeBet = useCallback(
    (itemId: string) => {
      const target = items.find((x) => x.itemId === itemId);
      if (!target) {
        throw Error(`item ${itemId} not found.`);
      }
      setSelectedItem(itemId);
      setShowInvalidBetDialog(true);
    },
    [items]
  );

  const onBlurShowInvalidBet = useCallback(
    (itemId: string) => {
      setSelectedItem(itemId);
      setShowInvalidBetDialog(true);
    },
    [items]
  );

  const submitChangeBet = useCallback(
    async (item: ApiEntryItem) => {
      const target = items.find((x) => x.itemId === item.itemId);
      if (!target) {
        throw Error(`item ${item?.itemId} not found.`);
      }
      const allOthers = items.filter((x) => x.itemId !== item.itemId);
      setPreviousItems(items);
      setItems([...allOthers, item]);

      const newRestrictedUpdateCount = restrictedUpdateCount + 1;
      setRestrictedUpdatedCount(newRestrictedUpdateCount);

      const newChangeBetLeft = MAX_RESTRICTED_UPDATE_AVAILABLE - newRestrictedUpdateCount;

      if (newChangeBetLeft === 1) {
        setShowOneBetLeftDialog(true);
      } else if (newChangeBetLeft === 0) {
        setShowZeroBetLeftDialog(true);
        setIsInChangeBetMode(false);
      }

      if (isAuthenticated && selectedContest?.contestId && userInfo?.playerId) {
        const currentTime = new Date();
        try {
          const responce = await PlayerDomainInterfacesService.apiEntrySubmitRestrictedPost({
            entry: {
              contestId: selectedContest.contestId,
              entryItem: item
            },
            clientBrowserLocalTime: currentTime.toISOString()
          });

          if (responce.statusCode === 401) {
            localStorage.setItem("intialLogin", "RELOGIN");
            signOut();
          }
        } catch (error) {
          setRestrictedUpdatedCount(newChangeBetLeft - 1);
          setItems([...allOthers, target]);
          setShowChangeBetError(true);
          setShowZeroBetLeftDialog(false);
          setShowOneBetLeftDialog(false);
        }
      }
    },
    [isAuthenticated, selectedContest?.contestId, userInfo?.playerId, items, restrictedUpdateCount]
  );

  const notifyItemEnterChangeBetPeriod = useCallback(
    (itemId: string) => {
      const allOthers = itemIdsInChangeBetPeriod.filter((x) => x !== itemId);
      const newItems = [...allOthers, itemId];
      setItemIdsInChangeBetPeriod(newItems);

      // show two bet left dialog if first time item become eligible for change bet and player has not seen it yet
      if (
        itemIdsInChangeBetPeriod.length === 0 &&
        !acceptBetChangeLimitations &&
        changeBetLeft > 0
      ) {
        setShowTwoBetLeftDialog(true);
      }
      if (changeBetLeft > 0) {
        setIsInChangeBetMode(true);
      }
    },
    [itemIdsInChangeBetPeriod, acceptBetChangeLimitations, changeBetLeft]
  );

  const notifyItemLeaveChangeBetPeriod = useCallback(
    (itemId: string) => {
      const allOthers = itemIdsInChangeBetPeriod.filter((x) => x !== itemId);
      const newItems = [...allOthers];
      setItemIdsInChangeBetPeriod(newItems);
    },
    [itemIdsInChangeBetPeriod]
  );

  const dismissZeroBetLeftDialog = useCallback(() => {
    setShowZeroBetLeftDialog(false);
  }, [setShowZeroBetLeftDialog]);

  const dismissOneBetLeftDialog = useCallback(() => {
    setShowOneBetLeftDialog(false);
  }, [setShowOneBetLeftDialog]);

  const dismissTwoBetLeftDialog = useCallback(() => {
    setShowTwoBetLeftDialog(false);
    setAcceptBetChangeLimitations(true);

    PlayerDomainInterfacesService.apiEntryAccemptLimitsPost({
      contestId: selectedContest?.contestId
    });
  }, [setShowTwoBetLeftDialog, selectedContest]);

  const dismissInvalidBetDialog = useCallback(
    (itemId: string) => {
      setShowInvalidBetDialog(false);
      if (itemId !== "") {
        const element = document.getElementById(`input${itemId}`);
        if (element !== null) {
          element.focus();
        }
      }
    },
    [setShowInvalidBetDialog]
  );

  const dismissBetErrorDialog = useCallback(
    () => {
      setShowBetErrorDialog(false);
    },
    [showBetErrorDialog]
  );

  const dismissChangeBetError = useCallback(() => {
    setShowChangeBetError(false);
  }, [setShowChangeBetError]);

  const fetchContest = async () => {
    if (selectedContest && isAuthenticated && userInfo) {
      try {
        const response = await PlayerDomainInterfacesService.apiEntryByContestIdByPlayerIdGet(
          selectedContest.contestId!
        );
        const fetchedItems =
          response.entry?.entryItems?.filter((x) => {
            return selectedContest.contestItems.find((a) => a.itemId === x.itemId);
          }) || [];

        setRestrictedUpdatedCount(response?.entry?.restrictedUpdateCount || 0);
        setItems(fetchedItems);
        setPreviouslySubmitted(fetchedItems.length ? true : false);
        setCanSubmit(isValid(fetchedItems));
        setAcceptBetChangeLimitations(response?.entry?.acceptBetChangeLimitations || false);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    fetchContest();
  }, [selectedContest, isAuthenticated, userInfo, isValid]);

  return (
    <EntryContext.Provider
      value={{
        items,
        submit,
        submitUpdate,
        submitAdd,
        addItem,
        deleteItem,
        deleteSubmit,
        updateItem,
        canSubmit,
        submitChangeBet,
        changeBetLeft,
        acceptBetChangeLimitations,
        itemIdsInChangeBetPeriod,
        notifyItemEnterChangeBetPeriod,
        notifyItemLeaveChangeBetPeriod,
        isInChangeBetMode,
        setIsInChangeBetMode,
        showZeroBetLeftDialog,
        dismissZeroBetLeftDialog,
        showOneBetLeftDialog,
        dismissOneBetLeftDialog,
        showTwoBetLeftDialog,
        dismissTwoBetLeftDialog,
        changeBetEditModeItemId,
        setChangeBetEditModeItemId,
        previouslySubmitted,
        showInvalidBetDialog,
        dismissInvalidBetDialog,
        onBlurChangeBet,
        onBlurShowInvalidBet,
        selectedItem,
        allowedToSubmitChanges,
        showChangeBetError,
        dismissChangeBetError,
        isRuleAcceptanceError,
        showBetErrorDialog,
        dismissBetErrorDialog,
      }}
    >
      {children}
    </EntryContext.Provider>
  );
};

export const useEntryContext = () => {
  const context = useContext(EntryContext);
  if (!context) {
    throw new Error("useEntryContext must be used within the EntryContextProvider");
  }
  return context;
};
