/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * None&nbsp;(0)<br/>Investment&nbsp;(1)<br/>OwnerUser&nbsp;(2)<br/>InvestmentOrOwnerUser&nbsp;(3)
 */
export enum CoStar_Api_Listings_ViewModels_SaleType {
  NONE = "None",
  INVESTMENT = "Investment",
  OWNER_USER = "OwnerUser",
  INVESTMENT_OR_OWNER_USER = "InvestmentOrOwnerUser"
}
