import React, { useEffect, useState } from "react";
import "../../../content/styles/common/can-i-play.scss";
import BobLogo from "../../../content/images/marketing/bob-logo-clouded.png";
import CompanyLogos from "../../../content/images/company-logos/costar-company-logos.svg";
import Jet from "../../../content/images/marketing/f35jet.png";
import { StaticRulesModal } from "../../marketing-views/static-rules-modal";

export const CanIPlay = ({ feBobShowJets }: { feBobShowJets?: boolean }) => {
  const [showRulesModal, setShowRulesModal] = useState<boolean>(false);

  const showModal = () => {
    document.body.classList.add("locked");
    setShowRulesModal(true);
  };

  const hideModal = () => {
    document.body.classList.remove("locked");
    setShowRulesModal(false);
  };

  useEffect(() => {
    const handleCloseRulesModal = (e: any) => {
      let rulesContentModal = document.querySelector(".rules-content-modal");
      if (!rulesContentModal?.contains(e.target) && showRulesModal) {
        document.body.classList.remove("locked");
        setShowRulesModal(false);
      }
    };

    window.addEventListener("click", handleCloseRulesModal);
    return () => {
      window.removeEventListener("click", handleCloseRulesModal);
    };
  }, [showRulesModal]);

  return (
    <>
      <div className="can-i-play-container">
        <div className="title">CAN I PLAY?</div>
        <div className="subtitle">
          <div>There are many ways to qualify!</div>
          <div>
            If you are a U.S. resident and meet any one of the following criteria, you can play.
          </div>
        </div>
        <div className="list-company-container">
          <ul className="can-i-play-list">
            {listItems.map((item) => (
              <li key={item}>{item}</li>
            ))}
            <li className="rules" onClick={showModal}>
              SEE FULL GAME RULES HERE
            </li>
          </ul>

          <div className="bob-logo-container">
            <img src={BobLogo} />
            <div className="rules" onClick={showModal}>
              SEE FULL GAME RULES HERE
            </div>
            {feBobShowJets && <img className="jet" src={Jet} />}
          </div>
          <div className="company-logos">
            <div className="logo-container">
              <img className="logo" src={CompanyLogos} />
            </div>
          </div>
        </div>
      </div>
      <div id="rules-modal-container">
        <StaticRulesModal isShown={showRulesModal} hideModal={hideModal} />
      </div>
    </>
  );
};

const listItems = [
  "Owned or brokered a CRE property in the last 3 years?",
  "CoStar client?",
  "Ten-X client (signed a CA, or listed an asset, or registered to bid, or represented a seller or bidder)?",
  "LoopNet client?",
  "Apartments.com client?",
  "Land.com client?",
  "BizBuySell client?",
  "STR client?"
];
