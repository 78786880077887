export const SQFT_PER_ACRE = 43560;

export const MeasurementType = {
  SF: 1,
  AC: 2,
  SM: 3
};

export const PageView = {
  RulesPage: "/rules",
  PropertySelectionPage: "/game/property-selection",
  ScoreboardPage: "/game/scoreboard",
  LeaderboardPage: "/game/leaderboard/winners-list",
  Secret: "/secret",
  LandingPage: "/properties",
  PrizesPage: "/prizes",
  WinnersList: "/leaderboard",
  AboutTenX: "/about-ten-x",
  Help: "/help",
  ScoreCard: "/score-card",
  PropertyStats: "/property-stats",
  Splash: "/splash",
  HallOfFame: "/hall-of-fame",
  WatchNow: "/watch-now",
  Welcome: "/welcome/:email"
};

export const Pages = [
  ["properties", "See Properties"],
  ["rules", "How To Play"],
  ["prizes", "Prizes"],
  ["leaderboard", "Leaderboard"],
  ["broker-experts", "Broker Experts"],
  ["about-ten-x", "About Ten-X"],
  ["help", "Help"],
  ["score-card", "Score Card"],
  ["property-stats", "Property Stats"],
  ["splash", "Splash"],
  ["hall-of-fame", "Hall of Fame"]
];

export enum PrizeMoneyDialogText {
  Default = 0,
  Winning = 1
}

export const AuctionStatus = {
  HasNotStarted: "Has Not Started",
  ReserveMet: "Reserve met",
  ReserveNotMet: "Reserve not met",
  NextBidMeetsReserve: "Next bid meets reserve",
  Sold: "Sold",
  NotSold: "Unsold",
  DidNotSell: "Unsold",
  Finalizing: "Finalizing"
};

export const PageSize = {
  Standard: 25,
  Medium: 50,
  Large: 100
};

export const MaximumPages = {
  Standard: 100,
  Medium: 250,
  Large: 500,
  Xlarge: 1000
};

export const AuctionPrizes = {
  FirstPrize: 100000,
  SecondPrize: 10000,
  ThirdPrize: 100
};

export const stickyTableRow = {
  leaderboardScrollY: 583,
  winnersListScrollY: 740,
  winnersListIsAuthenticatedScrollY: 820,
  scoreboardScrollY: 370,
  innerWidth: 1440
};

export const INTERVAL_DELAY_MS = 15000;

export const ELIGIBILITY = ["NA", "TenX", "CoStar", "External"];

export const ELIGIBILITY_LEVEL = {
  LEVEL_0: 0,
  LEVEL_1: 1,
  LEVEL_2: 2,
  LEVEL_3: 3
};

// when player has no rank , backend will return Int32.MaxValue as his rank for sorting pupose, the rank is 0 based. so display number will 2147483647 + 1
export const DEFAULT_RANK = 2147483648;

export const MAX_BETS = 10;
