/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * EventPoints&nbsp;(0)<br/>SeasonPoints&nbsp;(1)
 */
export enum ContestDomainClient_Models_LeaderboardSort {
  NOT_DETERMINED = "NotDetermined",
  EVENT_POINTS = "EventPoints",
  SEASON_POINTS = "SeasonPoints"
}
