import { AuctionStatus } from "../../common/constants";
import { useStandingContext } from "../../common/standing-context";
import { useStandingPlayerContext } from "../../common/standing-player-context";
import { toLocalDate, getContestStatus, toUtcDate } from "../../helpers/date-helpers";
import { ContestDomainClient_ViewModels_ItemPoint } from "../../player-api-client/models/ContestDomainClient_ViewModels_ItemPoint";
import { ApiEntryItem } from "../../types/api-models";
import { EListingStatus, IBidBoxListing } from "../../types/auction";
import { ContestItem, ContestStatus } from "../../types/contest";
import { calculatePoints } from "./score-helpers";

type ScoreboardDataTable = {
  totalPoints?: number;
  rows: ScoreboardDataRow[];
};

type ScoreboardDataRow = {
  contestItem: ContestItem;
  entryItem: ApiEntryItem;
  bet: number;
  currentBid: number;
  bidIncrement: number;
  bidCount: number;
  points: number;
  status: string;
  startingBid: number;
  startDate: Date;
  endDate: Date;
  localStartDate: Date;
  localEndDate: Date;
};

function mapScoreboardDataRow(
  contestItem: ContestItem,
  entryItem: ApiEntryItem,
  listing?: IBidBoxListing
): ScoreboardDataRow {
  const startDate = toUtcDate(listing?.listing_start_date || contestItem.startDate);
  const localStartDate = toLocalDate(startDate)!;
  const endDate = toUtcDate(listing?.listing_end_date || contestItem.endDate);
  const localEndDate = toLocalDate(endDate)!;

  const listingStatus =
    listing?.listing_status ?? contestItem.auctionData?.listing_status ?? contestItem.auctionStatus;
  const isReserveMet = listing?.is_reserve_met ?? contestItem.auctionData?.is_reserve_met;
  const nextBidMeetsReserve =
    listing?.next_bid_meets_reserve ?? contestItem.auctionData?.next_bid_meets_reserve;

  let eventStatus = getContestStatus(localStartDate, localEndDate);
  let status = "";
  if (eventStatus === ContestStatus.NotStarted) {
    status = AuctionStatus.HasNotStarted;
  } else if (eventStatus === ContestStatus.Ended) {
    if (isReserveMet) {
      status =
        listingStatus === EListingStatus.SOLD || listingStatus === EListingStatus.UNDER_CONTRACT
          ? AuctionStatus.Sold
          : AuctionStatus.Finalizing;
    } else status = AuctionStatus.NotSold;
  } else if (eventStatus === ContestStatus.InProgress) {
    if (isReserveMet) {
      status = AuctionStatus.ReserveMet;
    } else {
      status = nextBidMeetsReserve
        ? AuctionStatus.NextBidMeetsReserve
        : AuctionStatus.ReserveNotMet;
    }
  }

  const points = calculatePoints(
    entryItem?.bet || 0,
    listing?.current_bid_amt || 0,
    eventStatus === ContestStatus.Ended,
    status === AuctionStatus.Sold
  );

  return {
    contestItem,
    entryItem,
    bet: entryItem.bet || 0,
    currentBid: listing?.current_bid_amt || 0,
    bidIncrement: listing?.bid_increment || contestItem?.bidIncrement || 0,
    bidCount: listing?.external_facing_number_of_bids || 0,
    points,
    status,
    startingBid:
      listing?.starting_bid_amt ||
      contestItem?.placard?.forSale?.auctionStartingBid2 ||
      contestItem?.startingBid ||
      0,
    startDate,
    endDate,
    localStartDate,
    localEndDate
  };
}

/**
 * Map to ScoreboardDataTable from ContestItem[], EntryItem[], IBidBoxListing[].
 * @param contestItems
 * @param entryItems
 * @param listings
 * @returns
 */
function mapScoreboardDataTable(
  roundStatus: ContestStatus,
  contestItems: ContestItem[],
  entryItems: ApiEntryItem[],
  listings: IBidBoxListing[],
  isScoreCardPage: boolean
): ScoreboardDataTable {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { standingInfo } = useStandingContext();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { standingInfo: standingPlayerInfo } = useStandingPlayerContext();

  let scoreboard: Partial<ScoreboardDataTable> = {};
  scoreboard.rows =
    contestItems
      ?.map((contestItem) => {
        const linkedEntryItem = entryItems.find((x) => x.itemId === contestItem.itemId);
        const linkedListing = listings?.find((x) => x.listing_id === contestItem?.tenXListingId);
        const itemPoints = (!isScoreCardPage ? standingInfo : standingPlayerInfo)?.itemPoints?.find(
          (x) => x.itemId === contestItem.itemId
        );
        return { linkedEntryItem, contestItem, linkedListing, itemPoints };
      })
      ?.filter((x) => x.contestItem && x.linkedEntryItem)
      //?.map((x) => mapScoreboardDataRow(x.contestItem!, x.linkedEntryItem!, x.linkedListing)) || [];
      ?.map((x) =>
        roundStatus === ContestStatus.Ended
          ? mapPreviousRoundScoreboardDataRow(
              x.contestItem!,
              x.linkedEntryItem!,
              x.itemPoints!,
              x.linkedListing
            )
          : mapScoreboardDataRow(x.contestItem!, x.linkedEntryItem!, x.linkedListing)
      ) || [];

  sortScoreboardDataTable(scoreboard.rows);

  scoreboard.totalPoints =
    scoreboard.rows?.reduce((total, current) => total + current.points, 0) || 0;
  return scoreboard as ScoreboardDataTable;
}

function sortScoreboardDataTable(rows: ScoreboardDataRow[]) {
  rows.sort((a, b) => {
    // 1. sort by status - move items with NotSold status to bottom
    if (a.status !== b.status) {
      if (b.status === AuctionStatus.NotSold) {
        return -1;
      } else if (a.status === AuctionStatus.NotSold) {
        return 1;
      }
    }

    // 2. sort by starting bid desc
    return b.startingBid - a.startingBid;
  });
}

export type { ScoreboardDataTable, ScoreboardDataRow };
export { mapScoreboardDataTable, sortScoreboardDataTable };

function mapPreviousRoundScoreboardDataRow(
  contestItem: ContestItem,
  entryItem: ApiEntryItem,
  itemPoints: ContestDomainClient_ViewModels_ItemPoint,
  listing?: IBidBoxListing
): ScoreboardDataRow {
  const startDate = toUtcDate(listing?.listing_start_date || contestItem.startDate);
  const localStartDate = toLocalDate(startDate)!;
  const endDate = toUtcDate(listing?.listing_end_date || contestItem.endDate);
  const localEndDate = toLocalDate(endDate)!;

  const listingStatus =
    listing?.listing_status ?? contestItem.auctionData?.listing_status ?? contestItem.auctionStatus;

  let status = "";

  if (
    listingStatus === EListingStatus.SOLD ||
    listingStatus === EListingStatus.UNDER_CONTRACT ||
    listingStatus === EListingStatus.GONE
  ) {
    status = AuctionStatus.Sold;
  } else {
    status = AuctionStatus.NotSold;
  }

  return {
    contestItem,
    entryItem,
    bet: entryItem.bet || 0,
    currentBid: contestItem.currentBid || 0,
    bidIncrement: contestItem?.bidIncrement || 0,
    bidCount: contestItem.numberOfBids || 0,
    points: itemPoints?.points || 0,
    status,
    startingBid: contestItem?.startingBid || 0,
    startDate,
    endDate,
    localStartDate,
    localEndDate
  };
}
