import React, { useRef } from "react";
import { AppConfig } from "../../common/app_config";
import "../../content/styles/common/about-tenx.scss";

interface IProps {
  campaignId: string | null;
}

export const AboutTenX: React.FC<IProps> = ({campaignId}: IProps) => {
  const iframeRef = useRef(null);
  const loopNetAboutTenXUrl = `${AppConfig.LOOPNET_ABOUT_TENX_URL}?embed=true`;

  return (
    <>
      <section className="main-content about-tenx">
        <div className="max-1440 page-heading"></div>
        <section className="about-tenx-container">
          <iframe
            title="About Ten-X"
            ref={iframeRef}
            src={loopNetAboutTenXUrl}
            className="about-tenx-frame"
            scrolling="no"
            loading="eager"
          />
          <div className="contact-form-container">
            <div className="contact-form-image" />
            <div className="text-form-container">
              <div className="title">LEARN MORE ABOUT PARTNERING WITH TEN-X</div>
              <div className="subtitle">YOU COULD CLOSE IN UNDER 90 DAYS</div>
              <div className="text">
                Use the power of Ten-X to close more sales. Call us at (888) 770-7332 or fill out
                the form below to find out how we can help you go from list to close in under 90
                days on Ten–X.
              </div>
              <iframe
                src={`https://go.ten-x.com/l/667613/2023-05-12/5n7dq?SourceURL=${window.location.href}&utm_campaign_id=${campaignId}`}
                className="about-tenx-form"
                width="100%"
                frameBorder="0"
                style={{ border: 0 }}
                scrolling="no"
              ></iframe>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};
