import React, { useState } from "react";
import { Link } from "react-router-dom";
import { PageView } from "../../../common/constants";
import { LoginModal } from "../../login-signup/login";
import VideoPlayer from "../../video-player";
import HowToPlaceBetsSrc from "../../../content/images/marketing/how-to-place-bets.png";
import HowToGetStarted from "../../../content/images/marketing/how-to-get-started.png";
import { useUserContext } from "../../../common/user-context";
import gtm from "../../../helpers/gtm-helper";

const SignUpToPlaySection = () => {
  const { isAuthenticated } = useUserContext();
  const [showLoginModal, setShowLoginModal] = useState(false);

  const onClickHandler = () => {
    setShowLoginModal(!showLoginModal);
    document.body.classList.add("modal-active");
  };

  const videoProps = {
    embedId: isAuthenticated
      ? "https://www.youtube.com/embed/e3MvuEJUirU?rel=0&autoplay=1"
      : "https://www.youtube.com/embed/fRbMMBmEFqY?rel=0&autoplay=1",
    thumbnailSrc: isAuthenticated ? HowToPlaceBetsSrc : HowToGetStarted
  };
  return (
    <div className="splash sign-up-form-container">
      {showLoginModal && (
        <div className="modal-wrapper active">
          <LoginModal loginSignupModal="" setLoginSignupModal={onClickHandler} />
        </div>
      )}
      <div className="content-container">
        <h3 className="title">{isAuthenticated ? "HOW TO PLACE YOUR BETS" : "SIGN UP TO PLAY"}</h3>
        <p className="text">
          {isAuthenticated
            ? "Watch this easy-to-follow guide on how to place bets. Understand how predicting the final sale price can earn you more points - the more precise, the higher your score!"
            : "Watch this easy-to-follow tutorial on how to get started."}
        </p>
        <div className="video-player-container">
          <VideoPlayer
            embedId={videoProps.embedId}
            thumbnailSrc={videoProps.thumbnailSrc}
            video_section={
              isAuthenticated ? "How To Place Your Bets Section" : "Sign Up To Play Section"
            }
          />
        </div>
        <div className="button-container">
          {isAuthenticated ? (
            <Link to={PageView.RulesPage} className="button">
              LEARN MORE
            </Link>
          ) : (
            <button onClick={onClickHandler} className="button">
              SIGN UP NOW
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignUpToPlaySection;
