import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRoundContext } from "../../common/round-context";
import { useUserContext } from "../../common/user-context";
import { PlayerDomainInterfacesService as PlayerAPI } from "../../player-api-client";
import { StorageOption, getStorageRepo } from "../../helpers/storage";

import "../../content/styles/common/accept-rules.scss";
import { RulesText } from "../common/rules-text";
import { buildGamePageRoute } from "../../helpers/game-route-builder";
import { PageView } from "../../common/constants";
import { validateRulesAccepted, REQUIRED_RULES_VERSION } from "../../helpers/rules-acceptance";
import { useEntryContext } from "../../common/entry-context";

export const RulesAcceptanceModal: React.FC = () => {
  type Acceptance = {
    acceptedTerms: boolean;
    playerId: string;
  };

  const rulesAcceptanceStorage = getStorageRepo<Acceptance>(
    "game_rule_acceptance",
    { acceptedTerms: false, playerId: "" },
    StorageOption.Session
  );

  const rulesDeclinedStorage = getStorageRepo<boolean>(
    "game_rule_declined",
    false,
    StorageOption.Session
  );

  const history = useHistory();
  const { round } = useRoundContext();
  const { userInfo, currentPlayer, isAuthenticated, updateUserInfo } = useUserContext();
  const { isRuleAcceptanceError } = useEntryContext();
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [rulesVersion, setRulesVersion] = useState<string | undefined | null>("");
  const [showTerms, setShowTerms] = useState(false);

  useEffect(() => {
    if (currentPlayer.player?.ruleAcceptanceDate) {
      setRulesVersion(currentPlayer.player?.ruleAcceptanceDate);
    } else if (currentPlayer.player && currentPlayer.player?.ruleAcceptanceDate === undefined) {
      setShowTerms(true);
    }
  }, [currentPlayer.player]);

  useEffect(() => {
    const rulesAcceptance = rulesAcceptanceStorage.get();
    const acceptedVersion = rulesVersion
      ? new Date(rulesVersion)
      : new Date(REQUIRED_RULES_VERSION);

    if (isAuthenticated && validateRulesAccepted(acceptedVersion)) {
      setShowTerms(true);
    }

    if (isRuleAcceptanceError) {
      setShowTerms(true);
    }

    // Check session value to so terms do not show during lost connection after accepting
    if (rulesAcceptance.acceptedTerms && rulesAcceptance.playerId === userInfo?.playerId) {
      setShowTerms(false);
    }

    if (rulesDeclinedStorage.get()) {
      setShowTerms(false);
    }
  }, [
    isAuthenticated,
    userInfo?.ruleAccepted,
    rulesVersion,
    userInfo,
    rulesAcceptanceStorage,
    rulesDeclinedStorage,
    isRuleAcceptanceError
  ]);

  const onAccept = async () => {
    if (!checkboxChecked || !userInfo) {
      return;
    }
    try {
      const acceptRules = await PlayerAPI.playerAcceptRulesPost();
      if (acceptRules) {
        setRulesVersion(acceptRules.player?.ruleAcceptanceDate);
      }
      userInfo.ruleAccepted = true;
      updateUserInfo(userInfo);
      rulesAcceptanceStorage.set({ acceptedTerms: true, playerId: userInfo?.playerId!.toString() });
      history.push(buildGamePageRoute(PageView.PropertySelectionPage, round?.contestId!));
    } catch (err) {
      console.error(err);
    }
    rulesDeclinedStorage.set(false);
  };

  const onDecline = async () => {
    rulesDeclinedStorage.set(true);
    history.push(PageView.Splash);
  };

  const toggleCheckbox = (e: any) => {
    const checked = e.target.checked;
    if (checked) {
      setCheckboxChecked(true);
    } else {
      setCheckboxChecked(false);
    }
  };

  return (
    <>
      <div className={showTerms ? "accept-rules" : "accept-rules hidden"}>
        <div className="rules-overlay"></div>
        <div className="rules-modal">
          <div className="rules-header">
            <h2>Official Rules</h2>
          </div>
          <div className="rules-body rules-body-accept">
            <RulesText />
          </div>
          <div className="rules-controls">
            <label className="checkbox-wrap" onClick={(e) => toggleCheckbox(e)}>
              <input type="checkbox" name="accept-terms-checkbox" className="checkbox"></input>
              <span className="checkbox-text">
                By clicking <span className="accept-text-bold">Accept & Continue</span> I herebey
                agree and consent to the Battle of the Bids Official Rules.
              </span>
            </label>
            <button className="cancel-button btn" onClick={onDecline}>
              I DON'T WANT TO PLAY
            </button>
            <button
              bob-ga-data-on="click"
              bob-ga-data-event-category="AcceptRules"
              bob-ga-data-event-action="Click"
              bob-ga-data-event-label="AcceptAndContinue"
              bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
              className="accept-button btn"
              onClick={onAccept}
              disabled={!checkboxChecked}
            >
              ACCEPT &amp; CONTINUE
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
