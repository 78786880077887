import React, { useEffect } from "react";
import "../../content/styles/game/points-tooltip.scss";

type Props = {
  showPointsModal: boolean;
  setShowPointsModal: (showPointsModal: boolean) => void;
};

export const PointsTooltip: React.FC<Props> = ({ showPointsModal, setShowPointsModal }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.classList.add("locked");

    return () => {
      document.body.style.overflow = "scroll";
      document.body.classList.remove("locked");
    };
  }, []);

  return (
    <div className="points-tooltip-wrapper">
      <div className="points-tooltip">
        <div className="close-button" onClick={() => setShowPointsModal(!showPointsModal)}>
          &#10006;
        </div>
        <table>
          <tbody>
            <tr>
              <td>
                <div className="heading">
                  <div>% Difference</div>
                </div>
              </td>
              <td>
                <div className="heading">
                  <div>Points</div>
                </div>
              </td>
            </tr>

            <tr>
              <td>Exact Estimate</td>
              <td>1000</td>
            </tr>
            <tr>
              <td>{">"}0% and &#x2264;1%</td>
              <td>800</td>
            </tr>
            <tr>
              <td>{">"}1% and &#x2264;2%</td>
              <td>700</td>
            </tr>
            <tr>
              <td>{">"}2% and &#x2264;3%</td>
              <td>600</td>
            </tr>
            <tr>
              <td>{">"}3% and &#x2264;4%</td>
              <td>500</td>
            </tr>
            <tr>
              <td>{">"}4% and &#x2264;5%</td>
              <td>400</td>
            </tr>
            <tr>
              <td>{">"}5% and &#x2264;10%</td>
              <td>300</td>
            </tr>
            <tr>
              <td>{">"}10% and &#x2264;20%</td>
              <td>200</td>
            </tr>
            <tr>
              <td>{">"}20% and &#x2264;30%</td>
              <td>150</td>
            </tr>
            <tr>
              <td>{">"}30% and &#x2264;40%</td>
              <td>100</td>
            </tr>
            <tr>
              <td>{">"}40% and &#x2264;50%</td>
              <td>50</td>
            </tr>
            <tr>
              <td>{">"}50%</td>
              <td>0</td>
            </tr>
            <tr>
              <td>Does Not Sell</td>
              <td>0</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
