/**
 * Build a route for a game page.
 * @param page
 * @param round
 * @returns
 */
export function buildGamePageRoute(page: string, roundId?: string, season?: string) {
  if (roundId) {
    return `${page}?round=${roundId}`;
  } else if (season) {
    return `${page}?season=${season}`;
  } else {
    return `${page}`;
  }
}
