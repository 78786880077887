export function createUtmFragment(
  source: string = "BoBGameSite",
  campaign: string,
  medium: string = "BoB",
  content: string
) {
  return (
    "/?utm_source=" +
    source +
    "&utm_medium=" +
    medium +
    "&utm_campaign=" +
    campaign +
    "&utm_content=" +
    content
  );
}
