import { AppConfig } from "../common/app_config";
import { ApiListingType } from "../types/api-models";
import { Contest, ContestItem, FormattedPlacard } from "../types/contest";
import { NumberFormatter } from "../types/formatter";
import { PropertyType, PropertyTypeLabels } from "../types/property";
import { toLocalDate } from "./date-helpers";

/**
 * Mapper for contest. Given the contest (returned from api), create FormattedPlacard model for each contest item to facilitate rendering.
 * @param contest
 * @returns
 */
function mapContest(contest: Contest): Contest {
  contest.localStartDate = toLocalDate(contest.startDate);
  contest.localEndDate = toLocalDate(contest.endDate);

  contest.contestItems?.forEach((item) => {
    item.localStartDate = toLocalDate(item.startDate);
    item.localEndDate = toLocalDate(item.endDate);
    item.formattedPlacard = mapFormattedPlacard(item);
  });
  return contest;
}

function mapPropertyTypeLabel(item: ContestItem): string {
  const propertyCount = item.placard?.forSale?.propCount || 0;
  const propertyTypeLabel =
    item.propertyTypeId === PropertyType.None
      ? ""
      : PropertyTypeLabels.find((type) => type.id === item.propertyTypeId)?.label || "";

  if (item?.placard?.listingType === ApiListingType.COMMERCIAL_PORTFOLIO_FOR_SALE) {
    return propertyCount <= 1
      ? `${propertyTypeLabel} Property`
      : `${propertyCount} ${propertyTypeLabel} Properties`;
  }
  return propertyTypeLabel;
}

function mapAddress(item: ContestItem): string {
  const propertyCount = item.placard?.forSale?.propCount || 0;

  if (item.placard?.listingType === ApiListingType.COMMERCIAL_PORTFOLIO_FOR_SALE) {
    if (propertyCount <= 0) {
      return "";
    } else if (!item.address?.city || !item.address?.state) {
      return "Multiple Locations";
    } else {
      return item.address?.city + ", " + item.address?.state;
    }
  }

  if (item.fullAddress) {
    return item.fullAddress;
  }

  return `${item.address?.streetNumber || ""} ${item.address?.streetName || ""} ${
    item.address?.streetType || ""
  }, ${item.address?.city}, ${item.address?.state} ${item.address?.postcode}`;
}

function mapShortAddress(item: ContestItem): string {
  return item.buildingName
    ? item.buildingName
    : `${item.address?.streetNumber || ""} ${item.address?.streetName || ""} ${
        item.address?.streetType || ""
      }`;
}

function mapFormattedPlacard(item: ContestItem): FormattedPlacard {
  const target: Partial<FormattedPlacard> = {};
  target.brokerageFirmLogoUri = item.placard?.brokerageFirm?.logo?.uri || "";
  target.brokerageFirmName = item.placard?.brokerageFirm?.name || "";
  target.address = mapAddress(item);
  target.shortAddress = mapShortAddress(item);
  target.listingUri = `${AppConfig.LOOPNET_URL}/listing/${item.listingId}`;
  target.primaryPhotoUri = item.placard?.primaryPhoto?.uri || item?.primaryPhotoUri || "";
  target.primaryPhotoUri = target.primaryPhotoUri.replace("/105/", "/117/");
  target.auctionUri = `${AppConfig.REACT_APP_BOB_ROOT_URL}${item.pdpUri}`;
  target.propertyTypeLabel = mapPropertyTypeLabel(item);
  target.startingBid = NumberFormatter.FormatCurrency(item.startingBid ?? 0);
  target.highlights = (item.placard?.propertyHighlights || []).slice(0, 2); // only show up to 2 highlights in placards

  return target as FormattedPlacard;
}

export { mapContest, mapAddress, mapPropertyTypeLabel };
