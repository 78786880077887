import React from "react";
import { ContestDomainClient_ViewModels_PropertyStatsData } from "../../player-api-client/models/ContestDomainClient_ViewModels_PropertyStatsData";
import ColumnGraph from "./column-graph";
import EmptyGraph from "./empty-graph";

type Props = {
  currentBid?: number;
  averageBet?: number;
  marketGuidance?: number;
  maxNumBets?: number;
  data?: ContestDomainClient_ViewModels_PropertyStatsData[];
};

export const PropertyGraph: React.FC<Props> = (props) => {
  const { currentBid, averageBet, marketGuidance, maxNumBets, data } = props;

  return (
    <div className="graph-container">
      {data?.length ? (
        <ColumnGraph
          bettingData={data}
          currentBid={currentBid ?? 0}
          averageBet={averageBet ?? 0}
          marketGuid={marketGuidance ?? 0}
          maxNumBets={maxNumBets ?? 0}
        />
      ) : (
        <EmptyGraph />
      )}
    </div>
  );
};
