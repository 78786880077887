import React from "react";
import spinningTenX from "../../content/images/tenx-pinwheel.svg";

export const Loading: React.FC = () => {
  return (
    <div className="loading-container" data-testid="loading-container">
      <table>
        <tbody>
          <tr>
            <td style={{ textAlign: "center", paddingTop: 30 }} colSpan={10}>
              <div className="spinner">
                <img src={spinningTenX} className="spinner-logo" alt="Ten-X Logo" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
