import React, { useCallback, useState, useEffect } from "react";
import { useUserContext } from "../../common/user-context";
import { PlayerDomainInterfacesService } from "../../player-api-client";
import { getStorageRepo, StorageOption } from "../../helpers/storage";
import { WinnerModal } from "./winner-has-address-modal";
import { useStandingContext } from "../../common/standing-context";
import { ConfettiCannon } from "./confetti-cannon";
import moment from "moment";
import { useContestContext } from "../../common/contest-context";
import { useRoundContext } from "../../common/round-context";

export const ConfettiAcceptCheck: React.FC = () => {
  const defaultDate: Date = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
  const [showHasPrizeMoneyModal, setShowHasPrizeMoneyModal] = useState(false);

  const confettiDismissedStorage = getStorageRepo<Date>(
    "confetti_dismissed_storage",
    defaultDate,
    StorageOption.Local
  );

  const ConfettiModalViewed = useCallback(() => {
    confettiDismissedStorage.set(new Date());
    PlayerDomainInterfacesService.playerDismissConfettiWarningPost();
    // setShowPrizeMoneyAddress(false);
    setShowHasPrizeMoneyModal(false);
  }, [confettiDismissedStorage]);

  const { userInfo, isAuthenticated } = useUserContext();
  const { winnings } = useStandingContext();
  const { contest } = useContestContext();
  const { round } = useRoundContext();
  const startDate = moment(round?.localStartDate || contest?.localStartDate);
  const endDate = moment(round?.localEndDate || contest?.localEndDate);
  const now = moment();

  useEffect(() => {
    window.addEventListener("click", ConfettiModalViewed);

    const confettiDismissed = moment(confettiDismissedStorage.get());
    if (now > endDate && confettiDismissed < startDate) {
      setShowHasPrizeMoneyModal(true);
    }

    return () => {
      window.removeEventListener("click", ConfettiModalViewed);
    };
  }, [
    ConfettiModalViewed,
    confettiDismissedStorage,
    endDate,
    now,
    startDate,
    userInfo?.hasPrizeAddress
  ]);

  return (
    <>
      {isAuthenticated && (
        <>
          <ConfettiCannon maxParticleCount={150} particleSpeed={2} />
          <WinnerModal
            isShown={showHasPrizeMoneyModal}
            hideModal={ConfettiModalViewed}
            winningAmount={winnings}
          />
        </>
      )}
    </>
  );
};
