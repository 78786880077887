/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Total&nbsp;(0)<br/>PerBuildingArea&nbsp;(1)<br/>PerLandArea&nbsp;(2)<br/>PerItem&nbsp;(3)
 */
export enum CoStar_Api_Listings_ViewModels_PriceRateType {
  TOTAL = "Total",
  PER_BUILDING_AREA = "PerBuildingArea",
  PER_LAND_AREA = "PerLandArea",
  PER_ITEM = "PerItem"
}
