import React, { useEffect, useState } from "react";
import { PlayerDomain_ViewModels_PlayerSearchResult } from "../../player-api-client/models/PlayerDomain_ViewModels_PlayerSearchResult";
import { NumberFormatter } from "../../types/formatter";
import { PlayerDomainInterfacesService } from "../../player-api-client/services/PlayerDomainInterfacesService";
import { ContestDomainClient_ViewModels_PlayerSeasonStats } from "../../player-api-client/models/ContestDomainClient_ViewModels_PlayerSeasonStats";

type Props = {
  currentPlayer?: PlayerDomain_ViewModels_PlayerSearchResult;
  firstName?: string;
  lastName?: string;
  currentContestId?: string;
  playerId?: string;
  companyName?: string;
};
export const PlayerStats: React.FC<Props> = ({
  currentContestId,
  firstName,
  lastName,
  playerId,
  currentPlayer,
  companyName
}) => {
  const fullName = currentPlayer?.player?.firstName + " " + currentPlayer?.player?.lastName;
  const searchName = fullName.includes("undefined") || !fullName ? "Player's Name" : fullName;
  const displayName = firstName ? firstName + " " + lastName : searchName;

  const [
    playerSeasonStats,
    setPlayerSeasonStats
  ] = useState<ContestDomainClient_ViewModels_PlayerSeasonStats>();

  useEffect(() => {
    getPlayerSeasonStats(currentContestId ?? "", playerId ?? currentPlayer?.player?.playerId ?? "");
  }, [currentContestId, playerId, currentPlayer?.player?.playerId]);

  const getPlayerSeasonStats = async (contestId: string, playerId: string) => {
    const response = await PlayerDomainInterfacesService.apiContestPlayerSeasonStatsPost({
      contestId: contestId,
      playerId: playerId
    });
    if (response) {
      setPlayerSeasonStats(response);
    }
  };

  let seasonPoints = playerSeasonStats?.seasonPoints ?? 0;
  let formattedSeasonPoints = seasonPoints.toLocaleString("en-US");

  return (
    <div className=" players-card-stats ">
      <div className="player-stats-container">
        <div className="name-company-container">
          <div className="players-name">{displayName}</div>
          <div className="players-company-name">{playerSeasonStats?.companyName}</div>
        </div>
        <div className="player-category-wrapper">
          <div className="player-stats-category ">
            <div className="player-stats-label">Season Points</div>
            <div className="season-figures">{formattedSeasonPoints}</div>
          </div>
          <div className="player-stats-category">
            <div className="player-stats-label">Season Rank</div>
            <div className="season-figures">
              {playerSeasonStats?.seasonRanking ? playerSeasonStats?.seasonRanking : "-"}
            </div>
          </div>
          <div className="player-stats-category">
            <div className="player-stats-label"> Season Accuracy</div>
            <div className="season-figures">
              {playerSeasonStats?.seasonAccuracy
                ? NumberFormatter.FormatPercent(playerSeasonStats?.seasonAccuracy)
                : "0"}
            </div>
          </div>
          <div className="player-stats-category">
            <div className="player-stats-label"> Season Winnings</div>
            <div className="season-figures season-winning">
              {playerSeasonStats?.seasonWinnings
                ? NumberFormatter.FormatCurrency(playerSeasonStats?.seasonWinnings ?? 0)
                : "0"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
