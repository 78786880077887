import React from "react";
import classNames from "classnames";
import "../../../content/styles/common/competitive-edge.scss";
import companySet1 from "../../../content/images/company-logos/company-set-1.png";
import companySet2 from "../../../content/images/company-logos/company-set-2.png";
interface IStatsProps {
  title: string;
  subtitle: string;
}

interface Props {
  feBobEndOfSeason?: boolean;
}

export const CompetitiveEdge: React.FC<Props> = ({ feBobEndOfSeason }) => {
  return (
    <>
      <div
        className={classNames("competitive-edge-container", { "end-of-season": feBobEndOfSeason })}
      >
        <div className="title">ARE YOU READY TO GAIN A COMPETITIVE EDGE?</div>
        <div className="title">TEN-X IT.</div>
        <p className="subtitle">
          Ten-X is the world’s largest online CRE auction platform where brokers, sellers and buyers
          can transact efficiently - at no additional cost to sellers or brokers.
        </p>
        <div className="stats-box-container">
          {statsArray.map((elm, index) => (
            <StatsBox key={index} title={elm.title} subtitle={elm.subtitle} />
          ))}
        </div>
        <div className="join-text-company-logos-container">
          <div className="join-text-container">
            <div className="join-text">
              JOIN THE THOUSANDS OF BROKERS AND INVESTORS SELLING TWICE AS FAST ON TEN-X.
            </div>
            <p>
              Ten-X helps brokers put their client's property in front of one of the largest active
              global investor audiences.
            </p>
            <p>
              Use the power of Ten-X to close more transactions. Call us at (888) 770-7332 or click
              below to find out how we can help you go from list to close in under 90 days on Ten–X.
            </p>
          </div>
          <div className="company-logos">
            <div className="image-container">
              <img src={companySet1} />
            </div>
            <div className="image-container">
              <img src={companySet2} />
            </div>
          </div>
        </div>
        <div className="ten-x-it-button">
          <a href="/about-ten-x">TEN-X IT</a>
        </div>
      </div>
    </>
  );
};

const StatsBox = ({ title, subtitle }: IStatsProps) => (
  <div key={title} className="stats-box">
    <div className="stat-title">{title}</div>
    <div className="stat-subtitle">{subtitle}</div>
  </div>
);

const statsArray = [
  { title: "$31B", subtitle: "Total Volume Sold" },
  { title: "10,000+", subtitle: "Total Properties Sold" },
  { title: "94 Days", subtitle: "AVG List To Close" },
  { title: "60%", subtitle: "Trade Rate" }
];
