import React from "react";

type Props = {
  yourBet: number;
  statistics: {
    averageBet: number;
    betRange: string;
    bets: [{ x: string; y: number }];
    itemId: string;
    maxNumBets: number;
    numberBets: number;
  };
};

export const PropertyCard: React.FC<Props> = ({ statistics, yourBet }) => {
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0
  });

  return (
    <div className="property-card-wrapper">
      <div className="property-col">
        <div className="box property-header">Number of Bets Placed</div>
        <div className="box property-header">Bet Range</div>
        <div className="box property-header">Average Bet</div>
        <div className="box property-header">Your Bet</div>
      </div>
      <div className="property-card-border"></div>
      <div className="property-col">
        <div className="box property-value">{statistics?.numberBets || "-"}</div>
        <div className="box property-value">{statistics?.betRange || "-"}</div>
        <div className="box property-value">{statistics?.averageBet ? USDollar.format(statistics?.averageBet) : "-"}</div>
        <div className="box property-value">{yourBet ? USDollar.format(yourBet) : "-"}</div>
      </div>
    </div>
  );
};
