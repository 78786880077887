import React from "react";

type Props = {
  email: string;
};

export const RequestInvitation: React.FC<Props> = ({ email }) => {
  return (
    <div className="request-invite-container">
      <iframe
        title={"Request Invitation"}
        src={`https://go.ten-x.com/l/667613/2023-06-01/5qcvq?email=${email}&SourceURL=${window.location.href}`}
        className="about-tenx-form"
        width="100%"
        height="100%"
        frameBorder="0"
        style={{ border: 0 }}
        scrolling="yes"
      ></iframe>
    </div>
  );
};
