import { useEffect, useRef } from "react";
import { AppConfig } from "../common/app_config";
import { useContestContext } from "../common/contest-context";

export type BroadcastMessageHandler = (message: string) => void;

export const useBroadcastPlayerSocket = (
  broadcastMessageHandler: BroadcastMessageHandler,
  playerId: string | undefined | null
) => {
  const { contest, status } = useContestContext();

  const closeRef = useRef(true);
  const connectionObj = useRef<WebSocket>();

  useEffect(() => {
    let interval = setInterval(() => {}, 1000);

    if (
      AppConfig.WSS_ENDPOINT.length > 0 &&
      contest &&
      connectionObj.current === undefined &&
      !!playerId
    ) {
      const getWssConnection = () => {
        const connection = new WebSocket(
          AppConfig.WSS_ENDPOINT + "?contestId=" + contest?.contestId + "&playerId=" + playerId
        );

        const ping = () => {
          if (connection.CLOSING) {
            if (interval !== undefined && interval !== null) clearInterval(interval);
            return;
          }
          connection.send("__ping__");
        };

        connectionObj.current = connection;

        connection.onopen = () => {
          if (connection.readyState === WebSocket.OPEN) {
            console.log(" connected to broadcast socket " + connection.readyState);
            interval = setInterval(() => {
              if (connection.readyState !== 1) {
                clearInterval(interval);
                getWssConnection();
              }
              ping();
            }, 30000);
          } else {
            console.log("not connected to broadcase socket " + connection.readyState);
          }
        };

        connection.onmessage = (evt) => {
          console.log("Socket broadcast received!");
          if (evt.data !== "Pong") broadcastMessageHandler(evt.data);
        };

        connection.onclose = () => {
          if (!closeRef.current) {
            if (interval !== undefined && interval !== null) clearInterval(interval);
            console.log("The broadcase connection was closed.");
            getWssConnection();
          }
        };

        connection.onerror = (): void => {
          console.log("Broadcase Connection Error Closed the connection.");
          connection.close();
        };
      };
      getWssConnection();
    }
    return () => {
      if (interval !== undefined && interval !== null) {
        clearInterval(interval);
        connectionObj.current?.close();
        connectionObj.current = undefined;
      }
    };
  }, [contest, status, broadcastMessageHandler, playerId]);

  useEffect(() => {
    return () => {
      closeRef.current = true;
      connectionObj.current?.close();
    };
  }, []);
};
