import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PageView } from "../common/constants";

/**
 * Returns read-only status based on the current page view
 * @returns boolean
 */
export function useReadOnly() {
  const [isReadOnly, setIsReadOnly] = useState<boolean>();

  const location = useLocation();

  const isGamePages =
    location.pathname.includes(PageView.ScoreboardPage) ||
    location.pathname.includes(PageView.PropertySelectionPage) ||
    location.pathname.includes(PageView.LeaderboardPage) ||
    location.pathname.includes(PageView.WinnersList);

  useEffect(() => {
    let mode;
    if (isGamePages) {
      mode = false;
    } else {
      mode = true;
    }
    setIsReadOnly(mode);
  }, [isGamePages, location.pathname]);

  return isReadOnly;
}
