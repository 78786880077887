import * as React from "react";
import { useContext, useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { mapContest } from "../helpers/contest-mapper";
import { getContestStatus } from "../helpers/date-helpers";
import {
  PlayerDomainInterfacesService,
  ContestDomainClient_Models_LeaderboardSort
} from "../player-api-client";
import { Contest, ContestStatus } from "../types/contest";
import { useContestContext } from "./contest-context";

export type RoundContextType = {
  round?: Contest;
  roundStatus: ContestStatus;
  setSelectedRound?: any;
  selectedRound?: any;
  seasonSelected?: any;
  setSeasonSelected?: any;
};

export const RoundContext = React.createContext<RoundContextType>(null!);

export const RoundContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { pathname } = useLocation();
  const { contest } = useContestContext();
  const [round, setRound] = useState<Contest | undefined>(contest);
  const [selectedRound, setCurrentRound] = useState({});
  const [roundStatus, setRoundStatus] = useState<ContestStatus>(ContestStatus.Unknown);
  const [seasonSelected, setIsSeasonSelected] = useState<boolean>(true);

  const setSelectedRound = useCallback((round) => {
    setCurrentRound(round);
  }, []);

  const setSeasonSelected = useCallback((value) => {
    setIsSeasonSelected(value);
  }, []);

  const fetchContestById = async (
    contestId: string | undefined | null
  ): Promise<Contest | undefined> => {
    try {
      const response = await PlayerDomainInterfacesService.apiContestByContestIdGet(contestId!);
      let responseContest = response.contest as Contest;
      return responseContest ? mapContest(responseContest) : responseContest;
    } catch (err) {
      console.log(err);
    }
  };

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let specifiedRound = params.get("round");

  useEffect(() => {
    const fetch = async () => {
      if (contest) {
        const fetchedContest = await fetchContestById(specifiedRound || contest.contestId);
        if (fetchedContest) {
          setRound(fetchedContest);
          setRoundStatus(
            getContestStatus(fetchedContest?.localStartDate, fetchedContest?.localEndDate)
          );
        }
      }
    };
    fetch();
  }, [contest, specifiedRound, pathname]);

  useEffect(() => {
    console.log(`seasonSelected ${seasonSelected}`);
  }, [seasonSelected]);

  return (
    <RoundContext.Provider
      value={{
        round,
        roundStatus,
        selectedRound,
        setSelectedRound,
        seasonSelected,
        setSeasonSelected
      }}
    >
      {props.children}
    </RoundContext.Provider>
  );
};

export const useRoundContext = () => {
  const context = useContext(RoundContext);
  if (!context) {
    throw new Error("useRoundContext must be used within the RoundContextProvider");
  }
  return context;
};
