/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * None&nbsp;(0)<br/>PropertyForSale&nbsp;(9)<br/>ResidentialPropertyForSale&nbsp;(10)<br/>CondoForSale&nbsp;(11)<br/>LandForSale&nbsp;(12)<br/>CommercialPortfolioForSale&nbsp;(13)<br/>PropertyDirectSpaceForLease&nbsp;(14)<br/>ConsolidatedCondosForSale&nbsp;(15)<br/>BuildingParkDirectSpaceLease&nbsp;(16)<br/>ShoppingCenterDirectSpaceForLease&nbsp;(17)<br/>LandDirectForLease&nbsp;(18)<br/>CommercialSublease&nbsp;(19)<br/>CommercialLeaseAssignment&nbsp;(20)<br/>PropertyCoWorking&nbsp;(21)
 */
export enum CoStar_Api_Listings_ViewModels_ListingType {
  NONE = "None",
  PROPERTY_FOR_SALE = "PropertyForSale",
  RESIDENTIAL_PROPERTY_FOR_SALE = "ResidentialPropertyForSale",
  CONDO_FOR_SALE = "CondoForSale",
  LAND_FOR_SALE = "LandForSale",
  COMMERCIAL_PORTFOLIO_FOR_SALE = "CommercialPortfolioForSale",
  PROPERTY_DIRECT_SPACE_FOR_LEASE = "PropertyDirectSpaceForLease",
  CONSOLIDATED_CONDOS_FOR_SALE = "ConsolidatedCondosForSale",
  BUILDING_PARK_DIRECT_SPACE_LEASE = "BuildingParkDirectSpaceLease",
  SHOPPING_CENTER_DIRECT_SPACE_FOR_LEASE = "ShoppingCenterDirectSpaceForLease",
  LAND_DIRECT_FOR_LEASE = "LandDirectForLease",
  COMMERCIAL_SUBLEASE = "CommercialSublease",
  COMMERCIAL_LEASE_ASSIGNMENT = "CommercialLeaseAssignment",
  PROPERTY_CO_WORKING = "PropertyCoWorking"
}
