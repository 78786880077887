import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useContestContext } from "../../common/contest-context";
import {
  PlayerDomainInterfacesService,
  ContestDomainClient_Models_LeaderboardSort
} from "../../player-api-client";
import "../../content/styles/game/round-selection.scss";
import downCaretIcon from "../../content/images/down-caret.svg";
import { useRoundContext } from "../../common/round-context";
import { formatDateRangeLong } from "../../types/formatter";
import { buildGamePageRoute } from "../../helpers/game-route-builder";
import { ApiSeasonRound } from "../../types/api-models";
import { useUserContext } from "../../common/user-context";
import { useUpdate } from "../../hooks/use-update";
import classNames from "classnames";

export const MainLeaderBoardSelection: React.FC = () => {
  const { contest } = useContestContext();
  const {
    round,
    selectedRound,
    setSelectedRound,
    setSeasonSelected,
    seasonSelected
  } = useRoundContext();
  const { pathname } = useLocation();
  const history = useHistory();

  const roundsFetched = useRef(false);

  const [rounds, setRounds] = useState<ApiSeasonRound[]>();
  const [previousRounds, setPreviousRounds] = useState<ApiSeasonRound[]>();
  const [currentRound, setCurrentRound] = useState<ApiSeasonRound>();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [contestID, setContestID] = useState<string>();
  const { userInfo } = useUserContext();

  const SEASON_LEADERBOARD: string = "Season Leaderboard";
  const location = useLocation();

  const toggleDropdown = () => {
    const active = isActive;
    setIsActive(!active);
  };

  const updateRoundSelection = (roundSelected: number, contestId: string = "") => {
    console.log("roundSelected ", contestId);

    const round = roundSelected;
    const isExpiredRound = contest?.round && round < contest.round;

    if (isExpiredRound) {
      history.push(buildGamePageRoute(pathname, contestId));
    }
    if (contestId === SEASON_LEADERBOARD) {
      setSeasonSelected(true);
      history.push(buildGamePageRoute(pathname, undefined, contestId));
    } else if (!isExpiredRound && contestId !== SEASON_LEADERBOARD) {
      setSeasonSelected(false);
      setSelectedRound(round);
      history.push(buildGamePageRoute(pathname, contestId));
    } else {
      setSeasonSelected(false);
    }
    toggleDropdown();

    setContestID(contestId);
  };

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const specifiedRound = params.get("round");
    if (specifiedRound) {
      setSeasonSelected(false);
    } else {
      setSeasonSelected(true);
    }
  }, [setSeasonSelected]);

  useEffect(() => {
    const getSeasonRounds = async () => {
      if (roundsFetched.current === false) {
        const response = await PlayerDomainInterfacesService.apiSeasonBySeasonIdRoundsGet(
          contest?.seasonId!
        );
        if (response) {
          const roundsData = response.rounds as ApiSeasonRound[];
          setRounds(roundsData);
        }
        roundsFetched.current = true;
      }
    };

    if (contest) {
      getSeasonRounds();
    }
  }, [contest, pathname]);

  useEffect(() => {
    const sortRounds = () => {
      const sortUpcomingRounds = rounds
        ?.filter((round) => new Date(round!.startDate!) > new Date(Date.now()))
        ?.sort((a, b) => b.round! - a.round!);
      const sortPreviousRounds = rounds
        ?.filter(
          (round) =>
            new Date(round!.startDate!) < new Date(Date.now()) &&
            new Date(round!.endDate!) < new Date(Date.now())
        )
        ?.sort((a, b) => b.round! - a.round!);
      let sortCurrentRound = rounds?.filter(
        (round) =>
          new Date(round!.startDate!) <= new Date(Date.now()) &&
          new Date(round!.endDate!) >= new Date(Date.now())
      )[0];

      if (sortCurrentRound === undefined) {
        sortCurrentRound = sortUpcomingRounds![sortUpcomingRounds!.length - 1];
        sortUpcomingRounds!.splice(sortUpcomingRounds!.length - 1, 1);
      }

      setPreviousRounds(sortPreviousRounds);
      setCurrentRound(sortCurrentRound);
    };

    if (rounds && rounds?.length > 0) {
      sortRounds();
      setSelectedRound(round?.round);
    }
  }, [round?.round, rounds, setSeasonSelected, setSelectedRound]);

  useEffect(() => {
    const closeModals = () => {
      if (isActive) setIsActive(false);
    };

    window.addEventListener("click", closeModals);
    return () => {
      window.removeEventListener("click", closeModals);
    };
  }, [isActive]);

  const isSeason =
    contestID === SEASON_LEADERBOARD ||
    location?.search.includes("season") ||
    !location?.search.includes("round");

  return (
    <>
      {((previousRounds && previousRounds.length > 0) || currentRound?.round) && (
        <div
          className={isActive ? "round-selection-trigger active" : "round-selection-trigger"}
          onClick={toggleDropdown}
        >
          {seasonSelected ? (contestID ? contestID : SEASON_LEADERBOARD) : round?.name}
          {rounds && rounds.length >= 1 && <img src={downCaretIcon} alt="expand" />}
          {isActive && rounds && rounds.length >= 1 && (
            <div className="round-selection">
              <div className="round-selection-dropdown">
                <ul>
                  {/* Adding the season first */}
                  <li key={contest?.seasonId} className={seasonSelected ? "current" : ""}>
                    <button
                      onClick={() => {
                        updateRoundSelection(
                          previousRounds?.length! > 0 ? previousRounds![0].round! : -1,
                          SEASON_LEADERBOARD
                        );
                        toggleDropdown();
                        setSelectedRound(() => {
                          if (previousRounds != null && previousRounds.length > 0) {
                            return previousRounds[0].round;
                          } else {
                            return currentRound?.round;
                          }
                        });
                      }}
                      bob-ga-data-on="click"
                      bob-ga-data-event-category="RoundHeader"
                      bob-ga-data-event-action="Click"
                      bob-ga-data-event-label="RoundSelection"
                      bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
                      bob-ga-data-dimension3={round ? round.contestId : ""}
                    >
                      {"Season Leaderboard"}
                    </button>
                  </li>

                  {currentRound?.round && (
                    <li
                      className={classNames("", {
                        current: selectedRound === currentRound?.round && !seasonSelected
                      })}
                    >
                      <button
                        onClick={() =>
                          updateRoundSelection(currentRound?.round!, currentRound?.contestId!)
                        }
                        bob-ga-data-on="click"
                        bob-ga-data-event-category="RoundHeader"
                        bob-ga-data-event-action="Click"
                        bob-ga-data-event-label="RoundSelection"
                        bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
                        bob-ga-data-dimension3={round ? round.contestId : ""}
                      >
                        <span>Current:</span> {currentRound?.name} -{" "}
                        {formatDateRangeLong(currentRound?.startDate!, currentRound?.endDate!)}
                      </button>
                    </li>
                  )}

                  {previousRounds &&
                    previousRounds.map((round, index) => (
                      <li
                        key={index}
                        className={classNames("", {
                          current: selectedRound === round?.round && !isSeason
                        })}
                      >
                        <button
                          onClick={() => updateRoundSelection(round.round!, round.contestId!)}
                          bob-ga-data-on="click"
                          bob-ga-data-event-category="RoundHeader"
                          bob-ga-data-event-action="Click"
                          bob-ga-data-event-label="RoundSelection"
                          bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
                          bob-ga-data-dimension3={round ? round.contestId : ""}
                        >
                          <span>Previous:</span> {round.name} -{" "}
                          {formatDateRangeLong(round.startDate!, round.endDate!)}
                        </button>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
