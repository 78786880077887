import React from "react";

interface ModalContent {
  [key: string]: string | null | undefined;
}

const MODAL_CONTENT: ModalContent = {
  SUBMITTED_MAILING: "Thank you for your interest in Battle of the Bids",
  SUBMITTED_INIVITATION:
    "Thank you for your interest in Battle of the Bids. Our representive will get in touch with you shortly"
};

const MODAL_HEADER: ModalContent = {
  SUBMITTED_MAILING: "YOU'VE SUBMITTED REQUEST TO JOIN OUR MAILING LIST!",
  SUBMITTED_INIVITATION: "YOU'VE SUBMITTED YOUR REGISTRATION FOR INVITATION REQUEST"
};

export const Confirmation: React.FC<ModalContent> = ({ confirmation }) => {
  return (
    <>
      <div className="login-modal-header">{MODAL_HEADER.SUBMITTED_MAILING}</div>
      <div className="login-modal-content"> {confirmation && MODAL_CONTENT[confirmation]}</div>
    </>
  );
};
