import React, { useState, useEffect } from "react";
import { useContestContext } from "../../common/contest-context";
import "../../content/styles/game/property-type-selection.scss";
import downCaretIcon from "../../content/images/down-caret.svg";
import { PropertyTypeDropdown } from "../../types/property";

export const PropertyTypeSelection: React.FC = () => {
  const { onPropertyTypeChange } = useContestContext();

  const [propertyTypes] = useState(PropertyTypeDropdown);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [selectedPropertyTypes, setSelectedPropertyTypes] = useState<number[]>([]);

  type PropertyType = {
    id: number;
    label: string;
  };
  const anyTypePropertyTypeId: number = -1;

  const toggleDropdown = () => {
    const active = isActive;
    setIsActive(!active);
  };

  const updatePropertyTypeSelection = (propertyTypeSelected: PropertyType) => {
    const propertyType: PropertyType = propertyTypeSelected;
    setSelectedPropertyTypes(
      propertyType.id === anyTypePropertyTypeId
        ? []
        : selectedPropertyTypes.includes(propertyType.id)
        ? selectedPropertyTypes.filter((item) => item !== propertyType.id)
        : (types) => [...types, propertyType.id]
    );
  };

  useEffect(() => {
    const closeModals = (event: any) => {
      const element: HTMLElement | null = event.target as HTMLElement;
      if (!element!.classList.contains("propertyFilter")) {
        if (isActive) setIsActive(false);
      }
    };

    window.addEventListener("click", closeModals);
    return () => {
      window.removeEventListener("click", closeModals);
    };
  }, [isActive]);

  useEffect(() => {
    onPropertyTypeChange(selectedPropertyTypes);
  }, [onPropertyTypeChange, selectedPropertyTypes]);

  return (
    <>
      <div
        className={
          isActive && selectedPropertyTypes
            ? "property-type-selection-trigger active propertyFilter"
            : "property-type-selection-trigger propertyFilter"
        }
      >
        <span onClick={toggleDropdown}>Property Filter </span>
        {selectedPropertyTypes.length === 0 ? "" : `(${selectedPropertyTypes?.length})`}
        {propertyTypes && propertyTypes.length > 1 && (
          <img
            src={downCaretIcon}
            alt="expand"
            onClick={toggleDropdown}
            className="propertyFilter"
          />
        )}
        {isActive && propertyTypes && propertyTypes.length > 1 && (
          <div className="property-type-selection propertyFilter">
            <div className="property-type-selection-dropdown propertyFilter">
              <ul className="propertyFilter">
                {PropertyTypeDropdown.map((key) => (
                  <li
                    key={key.id}
                    className={
                      selectedPropertyTypes.includes(key.id)
                        ? "selected propertyFilter"
                        : selectedPropertyTypes.length === 0 && key.id === propertyTypes[0].id
                        ? "none-selected propertyFilter"
                        : "propertyFilter"
                    }
                    id={`'${key.id}'`}
                    onClick={() => updatePropertyTypeSelection(key)}
                  >
                    <button className="propertyFilter">{key.label}</button>
                    {selectedPropertyTypes.includes(key.id) && (
                      <label className="property-type-selection-label propertyFilter">
                        &#10004;
                      </label>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
