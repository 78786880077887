export const PropertyType = {
  None: 0,
  Hospitality: 1,
  Industrial: 2,
  Land: 3,
  Office: 5,
  GeneralRetail: 6,
  Flex: 7,
  SportsAndEntertainment: 8,
  MixedUse: 9,
  Specialty: 10,
  Multifamily: 11,
  Healthcare: 13,
  Unknown: 14
};

export const PropertyTypeLabels = [
  {
    id: 0,
    label: "None"
  },
  {
    id: 1,
    label: "Hospitality"
  },
  {
    id: 2,
    label: "Industrial"
  },
  {
    id: 3,
    label: "Land"
  },
  {
    id: 5,
    label: "Office"
  },
  {
    id: 6,
    label: "Retail"
  },
  {
    id: 7,
    label: "Flex"
  },
  {
    id: 8,
    label: "Sports & Entertainment"
  },
  {
    id: 9,
    label: "Mixed Use"
  },
  {
    id: 10,
    label: "Specialty"
  },
  {
    id: 11,
    label: "Multifamily"
  },
  {
    id: 13,
    label: "Healthcare"
  },
  {
    id: 14,
    label: "Unknown"
  }
];

export const PropertyTypeDropdown = [
  {
    id: -1,
    label: "Any"
  },
  {
    id: 1,
    label: "Hospitality"
  },
  {
    id: 2,
    label: "Industrial"
  },
  {
    id: 3,
    label: "Land"
  },
  {
    id: 5,
    label: "Office"
  },
  {
    id: 6,
    label: "Retail"
  },
  {
    id: 7,
    label: "Flex"
  },
  {
    id: 8,
    label: "Sports & Entertainment"
  },
  {
    id: 9,
    label: "Mixed Use"
  },
  {
    id: 10,
    label: "Specialty"
  },
  {
    id: 11,
    label: "Multifamily"
  },
  {
    id: 13,
    label: "Healthcare"
  }
];

export const PropertyTracker = {
  currentBid: "Current Bid",
  numberOfBets: "Number of Bets",
  averageBet: "Average Bet",
  currentBidAverage: "Current Bid To Average Bets",
  marketGuidance: "Market Guidance",
  CurrentBidMarket: "Current Bid To Market Guidance ",
  tradeContinues: "UNSOLD"
};
