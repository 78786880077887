import React from "react";
import { MessageModalData } from "../../types/message-modal-data";
import { TestimonialCarousel } from "../testimonial-carousel";
import "../../../src/content/styles/common/hall-of-fame.scss";
import "../../../src/content/styles/common/sign-up-form.scss";
import bobLogo from "../../../src/content/images/marketing/bob-lines-logo.svg";
import bobWinner from "../../../src/content/images/marketing/mike-king.png";
import smallCloud from "../../../src/content/images/marketing/small-hero-cloud.png";
import round1winner from "../../../src/content/images/bob-winners/round-1-winner.png";
import round2winner from "../../../src/content/images/bob-winners/round-2-winner.png";
import round3winner from "../../../src/content/images/bob-winners/round-3-winner.png";
import round4winner from "../../../src/content/images/bob-winners/round-4-winner.png";
import round5winner from "../../../src/content/images/bob-winners/round-5-winner.png";
import round6winner from "../../../src/content/images/bob-winners/round-6-winner.png";
import round7winner from "../../../src/content/images/bob-winners/round-7-winner.png";
import round8winner from "../../../src/content/images/bob-winners/round-8-winner.png";
import cloud from "../../../src/content/images/marketing/hof-cloud.png";
import playBtn from "../../../src/content/images/marketing/play-btn.png";
import TrophyPlaceholder from "../../../src/content/images/marketing/trophy-icon.png";
import gtm from "../../../src/helpers/gtm-helper";

interface IPlayer {
  avatarSrc?: string;
  betAccuracy?: string;
  company?: string;
  name?: string;
  prize?: string;
  round?: string;
  totalPoints?: string;
  ytSrc?: string;
  inactive?: boolean;
  date?: string;
}
interface IWinnerProps {
  player: IPlayer;
  handleMessageData?: (data: MessageModalData) => void;
  inactive?: boolean;
}

interface IProps {
  setMessageData: React.Dispatch<React.SetStateAction<MessageModalData>>;
  showTheMessageModal: () => void;
  campaignId: string | null;
}
interface ISignupFormProps {
  campaignId: string | null;
}
interface VideoClickProps {
  handleMessageData: (data: MessageModalData) => void;
}

export const HallOfFame = ({ setMessageData, showTheMessageModal, campaignId }: IProps) => {
  const handleMessageData = (data: MessageModalData) => {
    showTheMessageModal();
    setMessageData(data);
    gtm.trackEvent("video_click", {
      video_section: "Hall of Fame"
    });
  };

  return (
    <div className="hall-of-fame-container">
      <Hero handleMessageData={handleMessageData} />
      <TopRoundWinners handleMessageData={handleMessageData} />
      <TestimonialCarousel testimonialData={testimonialData} />
      <SignUpForm campaignId={campaignId} />
    </div>
  );
};

const Hero = ({ handleMessageData }: VideoClickProps) => (
  <div className="hall-of-fame-hero-container">
    <img alt="cloud" className="small-cloud-top-right" src={smallCloud} />
    <div className="hall-of-fame-logo-container">
      <img alt="battle-of-bids-logo" className="hall-of-fame-bob-logo" src={bobLogo} />
    </div>
    <div className="hall-of-fame-season-1-title section-title bold-title">SEASON 1 WINNERS</div>
    <div className="hall-of-fame-season-1-winner-container">
      <div className="hall-of-fame-grand-prize-winner section-title bold-title">
        GRAND PRIZE WINNER
      </div>
      <div className="hall-of-fame-grand-prize-winner-name bold-title">MIKE KING</div>
      <div className="hall-of-fame-grand-prize-winner-sub-name bold-title">KIDDER MATHEWS</div>
      <div className="hall-of-fame-charity-container">
        <div className="text-image-container">
          <div className="hall-of-fame-charity-text-container">
            <div className="hall-of-fame-charity-text">
              <div>
                <span className="bold-title">$1,000,000 + $100,000</span>
                <div>to charity of choice</div>
                <div className="brother-sister">(Big Brothers Big Sisters)</div>
              </div>
            </div>
            <div className="divider" />
            <div className="hall-of-fame-charity-points">
              <div>
                Total points: <span className="points bold-title">28,000</span>
              </div>
              <div>
                Bet Accuracy: <span className="accuracy bold-title">92.7%</span>
              </div>
            </div>
          </div>
          <div className="hall-of-fame-winner-image-container">
            <img alt="bob winner" src={bobWinner} />
            <div className="play-button-container">
              <div
                className="cursor"
                onClick={() =>
                  handleMessageData({
                    messageTitle: "",
                    message: "",
                    videoUrl: "https://www.youtube.com/embed/M4gUQUzTx90?rel=0"
                  })
                }
              >
                <span className="play-video-text">Play video</span>{" "}
                <img alt="play-button" className="play-button" src={playBtn} />
              </div>
            </div>
          </div>
        </div>
        <div className="hall-of-fame-grand-prize-winner-quote">
          “When I started getting emails for Battle of the Bids, I said 'This is for me. This is
          exactly my kind of contest.' I think that problem solving is a big part of real estate. I
          got to pick $100,000 to give to the charity of my choice, and I picked Big Brother Big
          Sisters. It feels incredible. Thanks Ten-X and Battle of the Bids!”
        </div>
      </div>
    </div>
  </div>
);

const TopRoundWinners = ({ handleMessageData }: VideoClickProps) => {
  return (
    <div className="top-round-winners-container">
      <div className="title bold-title section-title">TOP ROUND WINNERS</div>
      <div className="round-winners-container">
        {topRoundWinners.map((elm, index) => (
          <WinnerCard key={index} handleMessageData={handleMessageData} player={elm} />
        ))}
      </div>
    </div>
  )
}

export const WinnerCard: React.FC<IWinnerProps> = ({ player, handleMessageData }: IWinnerProps) => (
  <div key={player.name} className="winner-card-container">
    <div className="round-text">Round {player.round}</div>
    {player.inactive ? (
      <div className="placeholder-container">
        <img className="placeholder-image" src={TrophyPlaceholder} />
      </div>
    ) : (
      <div className="avatar-container">
        <img alt="player-avatar" className="player-avatar" src={player.avatarSrc} />
        {!player.inactive && (
          <img
            alt="play-button"
            className="play-button cursor"
            onClick={() => {
              if (handleMessageData) {
                handleMessageData({
                  message: "",
                  messageTitle: "",
                  videoUrl: `https://www.youtube.com/embed/${player.ytSrc}?rel=0`
                });
              }
            }}
            src={playBtn}
          />
        )}
      </div>
    )}
    {player.inactive ? (
      <div className="date">
        <p>On</p> <p>{player.date}</p>
      </div>
    ) : (
      <>
        <div className="bold-title">{player.name}</div>
        <div className="player-company">{player.company}</div>
        <div className="divider-container">
          <div className="divider" />
        </div>
        <div className="prize-text bold-title">{player.prize}</div>
        <div>
          Total Points: <span className="score bold-title">{player.totalPoints}</span>
        </div>
        <div>
          Bet Accuracy: <span className="score bold-title">{player.betAccuracy}</span>
        </div>
      </>
    )}
  </div>
);

const SignUpForm = ({ campaignId }: ISignupFormProps) => (
  <div className="hof sign-up-form-container">
    <div className="section-title bold-title">WANT MORE?</div>
    <div className="section-title bold-title">SIGN UP FOR MONTHLY AUCTION INSIGHTS</div>
    <iframe
      src={`https://go.ten-x.com/l/667613/2023-05-02/5lqdb?SourceURL=${window.location.href}&utm_campaign_id=${campaignId}`}
      width="100%"
      height="400"
      frameBorder="0"
      allowTransparency={true}
      style={{ border: 0 }}
    ></iframe>
    <img className="cloud" src={cloud} />
    {/* <div>
      Have more questions? Visit our <a className="bold-title cursor">HELP</a> center
    </div> */}
  </div>
);

const topRoundWinners: Array<IPlayer> = [
  {
    avatarSrc: round1winner,
    betAccuracy: "91.9%",
    company: "NAI Bergman",
    name: "Gueorgui Chikov",
    prize: "$100,000",
    round: "1",
    totalPoints: "4,150",
    ytSrc: "7pBfUSy63VY"
  },
  {
    avatarSrc: round2winner,
    betAccuracy: "88.8%",
    company: "KW Commercial Santa Monica",
    name: "Derrick Vartanian",
    prize: "$100,000",
    round: "2",
    totalPoints: "4,150",
    ytSrc: "yJMBrZJyUc0"
  },
  {
    avatarSrc: round3winner,
    betAccuracy: "75%",
    company: "Valbridge Property Advisors",
    name: "Jesse Shelley",
    prize: "$100,000",
    round: "3",
    totalPoints: "4,050",
    ytSrc: "KgXpWx01Noc"
  },
  {
    avatarSrc: round4winner,
    betAccuracy: "77.15%",
    company: "Integra Realty Resources, Inc.",
    name: "Nicole Galvez",
    prize: "$100,000",
    round: "4",
    totalPoints: "4,950",
    ytSrc: "FAAkHmzjWfA"
  },
  {
    avatarSrc: round5winner,
    betAccuracy: "93.6%",
    company: "Marcus & Millichap",
    name: "Kyle Hynes",
    prize: "$100,000",
    round: "5",
    totalPoints: "5,700",
    ytSrc: "k7NoPbzDSf8"
  },
  {
    avatarSrc: round6winner,
    betAccuracy: "94.4%",
    company: "Marcus & Millichap",
    name: "Matthew Sheard",
    prize: "$100,000",
    round: "6",
    totalPoints: "5,150",
    ytSrc: "XuB_SeGE7-g"
  },
  {
    avatarSrc: round7winner,
    betAccuracy: "95.2%",
    company: "Colliers",
    name: "Albert Elmore",
    prize: "$100,000",
    round: "7",
    totalPoints: "4,600",
    ytSrc: "tKafgysRq7Y"
  },
  {
    avatarSrc: round8winner,
    betAccuracy: "95.9%",
    company: "Kidder Mathews",
    name: "Mike King",
    prize: "$100,000",
    round: "8",
    totalPoints: "5,250",
    ytSrc: "M4gUQUzTx90"
  }
];

const testimonialData = [
  {
    name: "Gueorgui Chikov",
    title: "Vice President of Sales and Leasing",
    company: "NAI Bergman",
    text:
      "We just recently got married and we haven't taken a real honeymoon yet, so I think that is something we'll splurge on and spend it on that and probably put some [of the $100k] away and invest in real estate. I would definitely keep playing. There are a bunch of new shots to win $100K again, at the end there is a chance to win 1 million dollars.",
    image: round1winner
  },
  {
    name: "Derrick Vartanian",
    title: "Managing Director",
    company: "KW Commercial Santa Monica",
    text:
      "Now I am going to take this check and make a few of my coworkers extremely jealous. Happy hour is on me for the next year! I use Co-Star almost on a daily basis and I started to get all of the [Battle of the Bids] emails and it sounded too good to be true. I think Ten-X is an excellent way to sell properties, especially for brokers. And it is mutually advantageous for sellers as well.",
    image: round2winner
  },
  {
    name: "Jesse Shelley",
    title: "",
    company: "Valbridge Property Advisors",
    text: "It was a lot of fun and I recommend it!",
    image: round3winner
  },
  {
    name: "Nicole Galvez",
    title: "",
    company: "Integra Realty Resources, Inc.",
    text:
      "I heard about Battle of the Bids through CoStar. Because I was slightly skeptical I didn't participate in the first round. I got in the action in round two. So for the final hour of the bidding I was working at home with my husband and kids. We were all glued to the monitor watching the numbers bounce back and forth on the leaderboard. It was very fun. I got to celebrate with my daughter and my husband! It is very surreal, I don't think anyone expects to be presented with a check like this in their life.",
    image: round4winner
  },
  {
    name: "Kyle Hynes",
    title: "",
    company: "Marcus & Millichap",
    text:
      "This is unbelievable, $100,000! Insane. I am really glad I did this. People get swept up in the game of it. Thanks Ten-X!",
    image: round5winner
  },
  {
    name: "Matthew Sheard",
    title: "",
    company: "Marcus & Millichap",
    text: "Let's go! Don't sleep on it!",
    image: round6winner
  },
  {
    name: "Albert Elmore",
    title: "",
    company: "Colliers",
    text:
      "This is my first big check! I need to find a place to hang it in the house. What are our plans? I think we are going to take a trip to Scotland for my 30th birthday. And put some in the college fund for the little man.",
    image: round7winner
  },
  {
    name: "Mike King",
    title: "",
    company: "Kidder Mathews",
    text:
      "When I started getting emails for Battle of the Bids, I said 'This is for me. This is exactly my kind of contest.' I think that problem solving is a big part of real estate. I got to pick $100,000 to give to the charity of my choice, and I picked Big Brother Big Sisters. It feels incredible. Thanks Ten-X and Battle of the Bids!",
    image: round8winner
  }
];
