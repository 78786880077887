/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * None&nbsp;(0)<br/>SquareFoot&nbsp;(1)<br/>SquareMeter&nbsp;(2)<br/>Acre&nbsp;(3)<br/>Hectare&nbsp;(4)
 */
export enum CoStar_Api_Listings_ViewModels_AreaMeasure {
  NONE = "None",
  SQUARE_FOOT = "SquareFoot",
  SQUARE_METER = "SquareMeter",
  ACRE = "Acre",
  HECTARE = "Hectare"
}
