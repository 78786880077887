export interface IGameScheduleData {
  header: string[];
  data: IGameScheduleItem[];
}

export interface IGameScheduleItem {
  round: string;
  betting_opens: string;
  betting_closes: string;
  round_ends: string;
}

export const gameScheduleTable: IGameScheduleData = {
  header: ["ROUND", "BETTING OPENS", "BETTING CLOSES", "ROUND ENDS"],
  data: [
    {
      round: "Round 1",
      betting_opens: "9/8/2023",
      betting_closes: "9/18/2023",
      round_ends: "9/21/2023"
    },
    {
      round: "Round 2",
      betting_opens: "9/22/2023",
      betting_closes: "10/2/2023",
      round_ends: "10/5/2023"
    },
    {
      round: "Round 3",
      betting_opens: "10/6/2023",
      betting_closes: "10/16/2023",
      round_ends: "10/19/2023"
    },
    {
      round: "Round 4",
      betting_opens: "10/20/2023",
      betting_closes: "10/30/2023",
      round_ends: "11/2/2023"
    },
    {
      round: "Round 5",
      betting_opens: "11/3/2023",
      betting_closes: "11/13/2023",
      round_ends: "11/16/2023"
    },
    {
      round: "Round 6",
      betting_opens: "11/17/2023",
      betting_closes: "11/27/2023",
      round_ends: "11/30/2023"
    }
  ]
};
