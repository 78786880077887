import React, { useEffect, useState } from "react";
import MaskedInput from "react-text-mask";
import { PropertyCard } from "./property-card";
import { ContestItem, ContestStatus } from "../../types/contest";
import { useEntryContext } from "../../common/entry-context";
import { currencyMask } from "../../common/masked-input-mask";
import { PropertyBidField } from "./property-bid-field";
import { useUserContext } from "../../common/user-context";
import { createUtmFragment } from "../../helpers/utm-helpers";
import { useHistory } from "react-router-dom";
import { useRoundContext } from "../../common/round-context";
import { useReadOnly } from "../../hooks/use-read-only";
import { MAX_BETS, PageView } from "../../common/constants";
import { PlayerDomainInterfacesService } from "../../player-api-client";
import classNames from "classnames";
import { EListingStatus } from "../../types/auction";
import gtm from "../../helpers/gtm-helper";

type Props = {
  contestItem: ContestItem;
  setLimitReached: () => void;
  isBettingOpened: boolean;
  contestId?: string;
};

export const PropertySelectionItem: React.FC<Props> = ({
  contestId,
  contestItem,
  setLimitReached,
  isBettingOpened
}) => {
  const { roundStatus, round } = useRoundContext();
  const {
    items,
    deleteSubmit,
    submitUpdate,
    submitAdd,
    onBlurShowInvalidBet,
    setChangeBetEditModeItemId
  } = useEntryContext();

  const {
    itemId,
    formattedPlacard,
    auctionData,
    listingId,
    startingBid,
    address,
    auctionStatus
  } = contestItem;

  const history = useHistory();
  const isReadOnly = useReadOnly();
  const itemInEntry = items.find((x) => x.itemId === itemId);

  const [focus, setFocus] = useState<boolean>(false);
  const [pendingUpdate, setPendingUpdate] = useState<Boolean>(false);
  const [valueChanged, setValueChanged] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState<boolean>(!!itemInEntry);
  const [submitValue, setSubmitValue] = useState<number>();
  const [purpleScale, setPurpleScale] = useState<boolean>(false);
  const [grayFilter, setGrayFilter] = useState<boolean>();
  const [submitStatus, setSubmitStatus] = useState<string>();
  const [statistics, setStatistics] = useState<any>();
  const [submitting, setSubmitting] = useState<Boolean>(false);

  const [revertTimer, setRevertTimer] = useState<any>();
  const timeout = 200;

  useEffect(() => {
    setIsSelected(!!itemInEntry);
    setSubmitValue(itemInEntry?.bet);
  }, [itemInEntry]);

  useEffect(() => {
    const getStatics = async () => {
      try {
        const response = await PlayerDomainInterfacesService.propertyStatistics(contestId, itemId!);
        setStatistics(response);
      } catch (error) {
        console.log(error);
      }
    };

    if (roundStatus === ContestStatus.InProgress || roundStatus === ContestStatus.Ended) {
      getStatics();
    }
  }, [contestId, itemId, roundStatus]);

  useEffect(() => {
    if (roundStatus === ContestStatus.Ended) {
      if (auctionStatus !== EListingStatus.SOLD) {
        setGrayFilter(true);
      }
    } else {
      setGrayFilter(false);
    }
  }, [roundStatus, auctionStatus]);

  let utm_medium = "";
  if (history.location.pathname.includes(PageView.LandingPage)) {
    utm_medium = "AuctionPlacard";
  }
  if (history.location.pathname.includes(PageView.PropertySelectionPage)) {
    utm_medium = "GamePropertySelection";
  }

  const canSelect = !(items.length === MAX_BETS) || itemInEntry;
  const { userInfo, userEligibleRoundType } = useUserContext();
  const subject = userInfo ? userInfo.caSubject : "";

  let updateable =
    !(roundStatus === ContestStatus.InProgress || roundStatus === ContestStatus.Ended) &&
    userEligibleRoundType === round?.contestRoundType;

  const handleBetInputClick = () => {
    if (roundStatus === ContestStatus.NotStarted && items.length === MAX_BETS) {
      setLimitReached();
    }

    if (canSelect && roundStatus === ContestStatus.NotStarted) {
      setFocus(true);
    }
  };

  const purpleOverLay = () => {
    setPurpleScale(true);

    const removePurple = () => {
      setPurpleScale(false);
    };
    const timeoutId = setTimeout(removePurple, 4000);

    return () => clearTimeout(timeoutId);
  };

  const handleBetInputChange = (e: any) => {
    setValueChanged(true);
    setSubmitValue(parseInt(e.target.value.replace(/[^0-9.]+/g, "")));
  };

  const handleRemove = () => {
    setRevertTimer(clearTimeout(revertTimer));
    purpleOverLay();
    try {
      deleteSubmit(itemId!);
      gtm.trackEvent("bet_removed", { bet_location: "property selection" });
      setSubmitStatus("removed");
    } catch (err) {
      console.log(err);
    } finally {
      setFocus(false);
    }
  };

  const handleInputOnFocus = () => {
    setFocus(true);
    if (!itemInEntry && roundStatus === ContestStatus.NotStarted && items.length >= MAX_BETS) {
      setLimitReached();
    }
  };

  const handleBetInputBlur = (e: any) => {
    e.preventDefault();
    if (e.target.className === "inputbid" && e.target.value === "") {
      console.log("Nothing Entered");
    } else {
      setRevertTimer(
        setTimeout(() => {
          revertItem();
        }, timeout)
      );
    }

    setFocus(false);
    setPendingUpdate(false);
  };

  const revertItem = () => {
    if (itemInEntry) {
      setSubmitValue(itemInEntry?.bet);
    } else {
      setSubmitValue(undefined);
    }
  };

  const goToDetail = () => {
    gtm.trackEvent("listing_click");
    const getPathFromUrl = formattedPlacard.auctionUri.split("?")[0].replace(/\/$/, "");
    window.open(getPathFromUrl);
  };

  const submitBet = () => {
    if (submitValue! < contestItem.startingBid!) {
      onBlurShowInvalidBet(itemId!);
    } else if (submitValue! > 0) {
      purpleOverLay();
      setSubmitStatus("count");

      try {
        setSubmitting(true);
        if (itemInEntry) {
          submitUpdate({
            itemId: itemId,
            bet: submitValue
          });
        } else {
          submitAdd({
            itemId: itemId,
            bet: submitValue
          });
          gtm.trackEvent("Submit Bet", trackInfo);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setSubmitting(false);
      }
    }

    setChangeBetEditModeItemId("");
    setFocus(false);
  };

  const handleOnMouseDown = (e: any) => {
    setRevertTimer(clearTimeout(revertTimer));
    e.preventDefault();

    if (!submitting) {
      submitBet();
    }
  };

  const handleChangeBetSubmit = (e: any) => {
    setRevertTimer(clearTimeout(revertTimer));
    e.preventDefault();

    if (!submitting) {
      submitBet();
    }
  };

  const renderStatus = () => {
    switch (submitStatus) {
      case "count":
        return (
          <>
            <span className="large-number">{items.length}</span> of{" "}
            <span className="large-number">{MAX_BETS}</span> bets placed
          </>
        );
      case "removed":
        return <span>Bet removed</span>;
      default:
        <></>;
    }
  };

  const isValidValue = submitValue! > 0;
  const enableSubmitBtn = isValidValue && !itemInEntry;
  const enableUpdateBtn = isValidValue && itemInEntry?.bet && itemInEntry?.bet !== submitValue;
  const enableBtn = enableSubmitBtn || enableUpdateBtn;
  const trackInfo = {
    label: listingId,
    value: listingId,
    contactId: userInfo?.contactId,
    round: round?.contestId,
    betValue: submitValue,
    bet_location: "property selection"
  };

  return (
    <div className={classNames("placard-item", { selected: isSelected && !isReadOnly })}>
      {updateable && (
        <div className="submit-wrapper">
          <div className="submit-bet-controls">
            <div className="bet-controls">
              {canSelect ? (
                <MaskedInput
                  className="inputbid"
                  value={submitValue ? submitValue : ""}
                  placeholder="Enter Bet"
                  onFocus={handleInputOnFocus}
                  onChange={handleBetInputChange}
                  onBlur={handleBetInputBlur}
                  size={11}
                  mask={currencyMask}
                  disabled={!updateable}
                  autoFocus={focus}
                  id={`input${itemId}`}
                  bob-ga-data-on={valueChanged ? "blur" : ""}
                  bob-ga-data-event-category="Placard"
                  bob-ga-data-event-action="SubmitBet"
                  bob-ga-data-event-label={listingId}
                  bob-ga-data-event-value={listingId}
                  bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
                  bob-ga-data-dimension3={round?.contestId}
                  bob-ga-data-dimension4={submitValue ? submitValue : ""}
                />
              ) : (
                <span className="inputbid" onClick={handleBetInputClick}>
                  Enter Bet
                </span>
              )}
            </div>

            <div className="bet-controls">
              <button
                onMouseDown={handleOnMouseDown}
                disabled={!enableBtn}
                className={classNames("property-submit-btn", {
                  "active-submit": focus && enableBtn
                })}
                onClick={(e) => {
                  handleChangeBetSubmit(e);
                  if (itemInEntry) {
                    gtm.trackEvent("bet_edited", trackInfo);
                  }
                }}
              >
                {purpleScale && isValidValue && itemInEntry?.bet
                  ? "SUBMITTED"
                  : isValidValue && !pendingUpdate && itemInEntry?.bet
                  ? "UPDATE BET"
                  : "SUBMIT"}
              </button>
            </div>
          </div>

          <div
            className={classNames("submit-last-div bet-controls ", {
              show: isSelected && isValidValue && itemInEntry?.bet
            })}
          >
            <button className="remove-bet-btn" onClick={handleRemove}>
              REMOVE BET
            </button>
          </div>
        </div>
      )}
      <div className="property-details">
        <div className={classNames("", { "overlay-container": purpleScale })}>
          <div className="propertyimage">
            <img
              className={classNames({ "gray-filter": grayFilter })}
              src={formattedPlacard.primaryPhotoUri}
              alt={formattedPlacard.shortAddress}
            />
          </div>
          <div className="color-overlay"></div>
          <div className="overlay-text-top overlay-text-top">{renderStatus()}</div>
          <div className="overlay-text-bottom">
            <span>You have {MAX_BETS - items.length}</span> more bets left
          </div>
        </div>
        <div className="float-twocolumns property-type-seemore">
          <div>{formattedPlacard.propertyTypeLabel}</div>
          <div className="placard-seemore" onClick={goToDetail}>
            {!isBettingOpened && contestItem.auctionStatus === "ACTIVE" ? "See More" : ""}
          </div>
        </div>
        <div className="placard-data1">
          <div>{contestItem.buildingName}</div>
          <div>{formattedPlacard.address}</div>
        </div>
        <>
          {updateable ? (
            <div className="placard-data2">
              <ul>
                {formattedPlacard?.highlights?.map((highlight, index) => (
                  <li key={index}>{highlight}</li>
                ))}
              </ul>
            </div>
          ) : (
            <PropertyCard yourBet={itemInEntry?.bet || 0} statistics={statistics} />
          )}
        </>
        <div className="logos float-twocolumns">
          <PropertyBidField
            contestStatus={roundStatus}
            startingBid={startingBid}
            currentBid={auctionData?.current_bid_amt}
            isKentucky={address?.state === "KY"}
            listingStatus={auctionStatus}
          />
          <div>
            {formattedPlacard.brokerageFirmLogoUri && (
              <img
                src={formattedPlacard.brokerageFirmLogoUri}
                alt={formattedPlacard.brokerageFirmName}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
