import React, { useCallback, useEffect, useRef, useState } from "react";
import "../../content/styles/game/animations.scss";
import { NumberFormatter } from "../../types/formatter";
import { ContestStatus } from "../../types/contest";
import { ScoreboardDataRow } from "./scoreboard-mapper";
import classNames from "classnames";
import { AnimationSetting, AnimationType } from "./animation-helpers";
import { RemainingCell } from "./remaining-cell";
import { hasStarted } from "../../helpers/date-helpers";
import { useUserContext } from "../../common/user-context";
import { createUtmFragment } from "../../helpers/utm-helpers";
import { BetCell } from "./bet-cell";
import { AuctionStatus } from "../../common/constants";
import gtm from "../../helpers/gtm-helper";
// import { getIsConnected } from "../../hooks/use-auction-socket";
// import internal from "stream";

type Props = {
  row: ScoreboardDataRow;
  previousRow?: ScoreboardDataRow;
  contestStatus: number;
  isFromScoreCardPage?: boolean;
};

let isMounted = true;

export const ScoreBoardItem: React.FC<Props> = ({
  row,
  previousRow,
  contestStatus,
  isFromScoreCardPage
}) => {
  //const [isSocketConnected, setIsSocketConnected] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const [hideCurrentRow, setHideCurrentRow] = useState(false);
  const { userInfo } = useUserContext();
  const subject = userInfo ? userInfo.caSubject : "";
  /**
   * row animation
   */
  let rowAnimationTimeoutRef = useRef<NodeJS.Timeout>();
  const [rowAnimationType, setRowAnimationType] = useState(AnimationType.NONE);
  const [rowPostAnimationClass, setRowPostAnimationClass] = useState("");
  const createRowAnimation = useCallback((animationType: number) => {
    if (!isMounted) return;
    setRowAnimationType(animationType);

    rowAnimationTimeoutRef.current && clearTimeout(rowAnimationTimeoutRef.current);
    rowAnimationTimeoutRef.current = setTimeout(() => {
      if (!isMounted)
        rowAnimationTimeoutRef.current && clearTimeout(rowAnimationTimeoutRef.current);
      else if (AnimationSetting[animationType].postAnimClassName)
        setRowPostAnimationClass(AnimationSetting[animationType].postAnimClassName);
      else setRowAnimationType(AnimationType.NONE);
    }, AnimationSetting[animationType].duration);
  }, []);

  /**
   * bid cell animation
   */
  let bidCellAnimationTimeoutRef = useRef<NodeJS.Timeout>();
  const [bidCellAnimationType, setBidCellAnimationType] = useState(AnimationType.NONE);
  const createBidCellAnimation = useCallback((animationType: number) => {
    if (!isMounted) return;
    setBidCellAnimationType(animationType);

    bidCellAnimationTimeoutRef.current && clearTimeout(bidCellAnimationTimeoutRef.current);
    bidCellAnimationTimeoutRef.current = setTimeout(() => {
      if (!isMounted)
        bidCellAnimationTimeoutRef.current && clearTimeout(bidCellAnimationTimeoutRef.current);
      else setBidCellAnimationType(AnimationType.NONE);
    }, AnimationSetting[animationType].duration);
  }, []);

  /**
   * bid count cell animation
   */
  let bidCountCellAnimationTimeoutRef = useRef<NodeJS.Timeout>();
  const [bidCountCellAnimationType, setBidCountCellAnimationType] = useState(AnimationType.NONE);
  const createBidCountCellAnimation = useCallback((animationType: number) => {
    if (!isMounted) return;
    setBidCountCellAnimationType(animationType);

    bidCountCellAnimationTimeoutRef.current &&
      clearTimeout(bidCountCellAnimationTimeoutRef.current);
    bidCountCellAnimationTimeoutRef.current = setTimeout(() => {
      if (!isMounted)
        bidCountCellAnimationTimeoutRef.current &&
          clearTimeout(bidCountCellAnimationTimeoutRef.current);
      else setBidCountCellAnimationType(AnimationType.NONE);
    }, AnimationSetting[animationType].duration);
  }, []);

  /**
   * point cell animation
   */
  let pointCellAnimationTimeoutRef = useRef<NodeJS.Timeout>();
  const [pointCellAnimationType, setPointCellAnimationType] = useState(AnimationType.NONE);
  const createPointCellAnimation = useCallback((animationType: number) => {
    if (!isMounted) return;
    setPointCellAnimationType(animationType);

    pointCellAnimationTimeoutRef.current && clearTimeout(pointCellAnimationTimeoutRef.current);
    pointCellAnimationTimeoutRef.current = setTimeout(() => {
      if (!isMounted)
        pointCellAnimationTimeoutRef.current && clearTimeout(pointCellAnimationTimeoutRef.current);
      else setPointCellAnimationType(AnimationType.NONE);
    }, AnimationSetting[animationType].duration);
  }, []);

  /**
   * status cell animation
   */
  let statusCellAnimationTimeoutRef = useRef<NodeJS.Timeout>();
  const [statusCellAnimationType, setStatusCellAnimationType] = useState(AnimationType.NONE);
  const createStatusCellAnimation = useCallback((animationType: number) => {
    if (!isMounted) return;
    setStatusCellAnimationType(animationType);

    statusCellAnimationTimeoutRef.current && clearTimeout(statusCellAnimationTimeoutRef.current);
    statusCellAnimationTimeoutRef.current = setTimeout(() => {
      if (!isMounted)
        statusCellAnimationTimeoutRef.current &&
          clearTimeout(statusCellAnimationTimeoutRef.current);
      else setStatusCellAnimationType(AnimationType.NONE);
    }, AnimationSetting[animationType].duration);
  }, []);

  useEffect(() => {
    // const checkIfSocketConnected = () => {
    //   console.log("checking");
    //   let isConnected = getIsConnected();
    //   if (isConnected) {
    //     setIsSocketConnected(true);
    //     stopInterval();
    //   }
    // };
    // const interval = setInterval(() => checkIfSocketConnected(), 1000);
    // const stopInterval = () => {
    //   if (interval !== undefined && internal !== null) clearInterval(interval);
    // };

    isMounted = true;
    if (isMounted) {
      // current bid change
      if (previousRow?.currentBid !== undefined && previousRow.currentBid !== row.currentBid) {
        createRowAnimation(AnimationType.ROW_NEW_BID);
        createBidCellAnimation(AnimationType.BID_CELL_NEW_BID);
        createBidCountCellAnimation(AnimationType.BID_COUNT_CELL_NEW_BID);
      }

      // points change
      if (previousRow?.points !== undefined && previousRow.points !== row.points) {
        if (previousRow.points < row.points) {
          createPointCellAnimation(AnimationType.POINTS_CELL_POINT_INCREASE);
        } else if (previousRow.points > row.points) {
          createPointCellAnimation(AnimationType.POINTS_CELL_POINT_DECREASE);
        }
      }

      // end time change - i.e. Time Extended or is already in time extended but state may change
      if (
        (previousRow?.localEndDate !== undefined &&
          previousRow?.localEndDate?.getTime() !== row.localEndDate.getTime()) ||
        statusCellAnimationType ===
          AnimationType.STATUS_CELL_TIME_EXTENDED_NEXT_BID_MEETS_RESERVE ||
        statusCellAnimationType === AnimationType.STATUS_CELL_TIME_EXTENDED_RESERVE_MET ||
        statusCellAnimationType === AnimationType.STATUS_CELL_TIME_EXTENDED_RESERVE_NOT_MET
      ) {
        if (row.status === AuctionStatus.ReserveMet) {
          createStatusCellAnimation(AnimationType.STATUS_CELL_TIME_EXTENDED_RESERVE_MET);
        } else if (row.status === AuctionStatus.ReserveNotMet) {
          createStatusCellAnimation(AnimationType.STATUS_CELL_TIME_EXTENDED_RESERVE_NOT_MET);
        } else if (row.status === AuctionStatus.NextBidMeetsReserve) {
          createStatusCellAnimation(AnimationType.STATUS_CELL_TIME_EXTENDED_NEXT_BID_MEETS_RESERVE);
        }
      }

      // status change - this animation could also be triggered on load (i.e. previousRow.status is undefined)
      if (previousRow?.status !== row.status) {
        if (row.status === AuctionStatus.Sold) {
          createRowAnimation(AnimationType.ROW_STATUS_SOLD);
        } else if (row.status === AuctionStatus.NotSold) {
          createRowAnimation(AnimationType.ROW_STATUS_NOT_SOLD);
        }
      }

      //Stop any animation once listing's auction has ended
      if (Date.now() > row.localEndDate.getTime()) {
        createStatusCellAnimation(AnimationType.NONE);
      }

      if (contestStatus === ContestStatus.Ended) {
        setIsEnded(true);
      }
    }

    return () => {
      isMounted = false;
      //if (interval !== undefined && internal !== null) clearInterval(interval);
    };
  }, [
    row.currentBid,
    previousRow?.currentBid,
    row.points,
    previousRow?.points,
    row.status,
    previousRow?.status,
    row.localEndDate,
    previousRow?.localEndDate,
    createRowAnimation,
    createBidCellAnimation,
    createBidCountCellAnimation,
    createPointCellAnimation,
    createStatusCellAnimation,
    contestStatus
  ]);

  const statusColorClass = () => {
    let colorClass = "";
    if (row.status === AuctionStatus.ReserveMet) {
      colorClass = "status-content reserve-met";
    } else if (row.status === AuctionStatus.NextBidMeetsReserve) {
      colorClass = "status-content next-bid-meets-reserve";
    } else {
      colorClass = "status-content";
    }
    return colorClass;
  };

  // const getItemStatus = (isNotSold: boolean): string => {
  //   return isNotSold ? AuctionStatus.DidNotSell : row.status;
  // };

  //console.log("eeeeee " + isEnded);
  return (
    <>
      {row.bet >= 0 && !hideCurrentRow && (
        <tr
          className={classNames(
            AnimationSetting[rowAnimationType].className,
            rowPostAnimationClass,
            "scoreboard",
            !!isFromScoreCardPage ? "scoreboard-score-card-page" : ""
          )}
        >
          <td
            className={
              !isEnded
                ? classNames(
                    AnimationSetting[rowAnimationType].className,
                    rowPostAnimationClass,
                    "scoreboard"
                  )
                : "scoreboard-cell-property"
            }
          >
            <div className="property-container">
              <span className="content left-table-padding">
                <span className="property-address">
                  {contestStatus !== ContestStatus.Ended &&
                  row?.contestItem?.auctionStatus === "ACTIVE" ? (
                    <>
                      <a
                        href={row.contestItem.formattedPlacard.auctionUri
                          .split("?")[0]
                          .replace(/\/$/, "")}
                        onClick={() => gtm.trackEvent("listing_click")}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {row.contestItem.formattedPlacard.shortAddress}
                      </a>
                    </>
                  ) : (
                    <>{row.contestItem.formattedPlacard.shortAddress}</>
                  )}
                </span>
              </span>
            </div>
          </td>
          <td className="your-bet-cell">
            <BetCell
              row={row}
              parentTableIsMounted={isMounted}
              hideCurrentRow={hideCurrentRow}
              setHideCurrentRow={setHideCurrentRow}
            />
          </td>
          <td
            className={classNames(
              "scoreboard-cell-bid",
              "current-bid",
              AnimationSetting[bidCellAnimationType].className
            )}
          >
            {contestStatus === ContestStatus.NotStarted
              ? NumberFormatter.FormatCurrency(row.startingBid, "--")
              : NumberFormatter.FormatCurrency(row.currentBid, "--")}
          </td>
          <td className="scoreboard-cell-increment">
            {NumberFormatter.FormatCurrency(row.bidIncrement)}
          </td>
          <td
            className={classNames(
              "scoreboard-cell-bidCount",
              AnimationSetting[bidCountCellAnimationType].className
            )}
          >
            {NumberFormatter.FormatNumber(row.bidCount, "-")}
          </td>
          <td className="scoreboard-cell-remaining">
            {hasStarted(row.localStartDate) ? (
              <RemainingCell targetLocalDate={row.localEndDate} item={row.entryItem} />
            ) : (
              <> - </>
            )}
          </td>
          <td
            className={classNames(
              "scoreboard-cell-status",
              AnimationSetting[statusCellAnimationType].className
            )}
          >
            <span className={statusColorClass()}>
              {row.status === AuctionStatus.NotSold && contestStatus === ContestStatus.Ended
                ? AuctionStatus.DidNotSell
                : row.status}
            </span>
            {/* <span className={statusColorClass()}>
              {(previousRow === undefined || previousRow === null || isSocketConnected) &&
              contestStatus === ContestStatus.Ended
                ? getItemStatus(row.status === AuctionStatus.NotSold)
                : null}
            </span>
            <span className={statusColorClass()}>
              {previousRow !== undefined &&
              previousRow !== null &&
              !isSocketConnected &&
              contestStatus === ContestStatus.Ended
                ? AuctionStatus.Finalizing
                : null}
            </span> */}
            <div />
          </td>
          <td
            className={classNames(
              "scoreboard-cell-points",
              AnimationSetting[pointCellAnimationType].className
            )}
          >
            <span className="right-table-padding">{NumberFormatter.FormatNumber(row.points)}</span>
          </td>
        </tr>
      )}
    </>
  );
};
