import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useUserContext } from "../../common/user-context";
import { useRoundContext } from "../../common/round-context";
import { PageView } from "../../common/constants";
import { Loading } from "../../components/marketing-views/loading";
import { checkEligibility } from "../../helpers/eligibility-helper";

type Props = {
  path: string;
};

/**
 * Ensure only authenticated user can access a protected route. If current user is authenticated, render the route, otherwise call UserContext.signIn to attempt signin in the user.
 *
 * @param param0
 * @returns
 */

export function ProtectedRoute({ children, path }: React.PropsWithChildren<Props>) {
  const { round } = useRoundContext();
  const { isAuthenticated, userEligibiltiy } = useUserContext();
  const [accessAvailable, setAccessAvailable] = React.useState(false);
  const [timedOut, setTimedOut] = useState(false);

  useEffect(() => {
    let timer: any;
    const checkAccess = () => {
      const ACCESS = checkEligibility(round?.contestRoundType, userEligibiltiy) && isAuthenticated;
      setAccessAvailable(ACCESS);
    };

    const timeout = 3000;

    checkAccess();

    if (!accessAvailable) {
      timer = setTimeout(() => {
        setTimedOut(true);
      }, timeout);
    }

    return () => clearTimeout(timer);
  }, [round?.contestRoundType, userEligibiltiy, isAuthenticated, accessAvailable]);
  if (!accessAvailable && !timedOut) {
    return <Loading />;
  } else if (accessAvailable) {
    return <Route path={path}>{children}</Route>;
  } else if (timedOut) {
    return <Redirect to={PageView.Splash} />;
  } else {
    return <Redirect to={PageView.Splash} />;
  }
}
