export const FaqTables = [
  {
    key: "1",
    header: "CUSTOMER SERVICE HOURS",
    items: [
      {
        key: "What are customer service hours?",
        value: "9am ET - 8pm ET"
      }
    ]
  },
  {
    key: "2",
    header: "TROUBLE LOGGING IN",
    items: [
      {
        key: "How do I log in to Battle of the Bids?",
        value:
          'Log in to Battle of the Bids using your CoStar credentials. Complete your entry by clicking "Get in Game" button. Provide requested information.'
      },
      {
        key: "Forgot my password?",
        value:
          "If you forgot your password, please follow steps to reset your password through the Log in flow."
      },
      {
        key: "I received the error message; I am not an authorized player?",
        value:
          "• Please confirm you meet the eligibility criteria for participating in Battle of the Bids. See Official Battle of the Bid Rules. \n• If you meet the eligibility requirements. Please send an email to battleofthebids@ten-x.com and we will review your account and provide you with access to play the game."
      }
    ]
  },
  // {
  //   key: "3",
  //   header: "ELIGIBILITY",
  //   items: [
  //     {
  //       key: "I am a Ten-X Employee, can I play in the Costar or External round?",
  //       value: "No, if you play in a round that you are not designated for you cannot win."
  //     }
  //   ]
  // },
  {
    key: "4",
    header: "PRIZES",
    items: [
      {
        key: "When will the winners be announced?",
        value:
          "Potential winners will be notified via email sent within seven (7) days following the end of the Round."
      }
    ]
  },
  {
    key: "5",
    header: "GAME PLAY",
    items: [
      {
        key: "When does betting open?",
        value: "See the dates below:"
      },
      // {
      //   key: "When does betting close?",
      //   value: "Betting will close January 31st, 2022, at 10:45 AM ET"
      // },
      // {
      //   key: "When will scores be finalized?",
      //   value: "All round points will be finalized February 3rd, 2022, at 11:59 PM PT"
      // },
      {
        key:
          "Can I access the data room or speak to the brokers/sellers/deal team about a property?",
        value:
          "No, players cannot access confidential information, or contact seller or broker, except in connection with good faith, legitimate interest in participating in the Property auction. Players could be disqualified if they do not adhere to the official game rules."
      },
      {
        key: "What do I do if I cannot place a bet?",
        value:
          "Log on to the Battle of the Bids website, check to see if the auction has started. All bets must be placed before the auction begins. If the round has not started  and you cannot place a bet, please reach out to Battle of the Bids customer service at (888) 619-5750 or battleofthebids@ten-x.com."
      },
      {
        key: "What happens if an asset is moved from one auction event to another?",
        value:
          "If an asset is moved to another auction event, players will need to place a new bet on an alternate asset prior to the auction opening. Players will not be able to bet on alternate assets after the auction opens. All players should review the website and ensure assets have not moved to an alternate round prior to the auction opening."
      },
      {
        key: "What happens if there is a tie?",
        value: "Bet accuracy will determine the tie breaker."
      },
      {
        key: "How do I change my two bets?",
        value:
          "Open My Scoreboard on the Play the Game page.  The Change Bet field will be enabled  when a property has less than 10 minutes remaining in the auction. Update your bet and click “Change Bet”. You can change both bets at once or throughout the course of the auction round. (Note, a specific property auction may be extended due to bidding activity)."
      },
      {
        key: "Can I use my change bet option on the same property? ",
        value:
          'Yes, you can use both of your "Change Bet" options on the same property if you like. Keep in mind that this will count as two changes. You can "change bet" on two separate properties or you can change two bets on one property.'
      },
      {
        key: "What is an overtime period?",
        value:
          "An auction will go into overtime anytime there is a bid placed within the last two minutes of the auction. When a bid is placed in the last two minutes of the auction, the auction time clock will reset to 3 minutes and 30 seconds remaining in the auction. The overtime period will continue in perpetuity until no more bids are placed within the last 2 minutes of the auction."
      },
      {
        key: "Why is there an overtime period?",
        value:
          "Ten-X utilizes the overtime feature to allow all bidders the opportunity to respond to last second bids and place additional bids should they choose. The overtime feature is also used to ensure our sellers achieve the highest and best price possible for their property."
      },
      {
        key: "How long is the typical overtime period?",
        value:
          "We typically see about 20 minutes of overtime. This specific timeframe is not guaranteed and is based on bidding activity."
      },
      {
        key: "What is the starting bid?",
        value:
          "Ten-X utilizes the overtime feature to allow all bidders the opportunity to respond to last second bids and place additional bids should they choose. The overtime feature is also used to ensure our sellers achieve the highest and best price possible for their property."
      },
      {
        key: "What are bid increments?",
        value:
          "• The minimum amount an auction bid must be increased during bidding.\n• Bid increment amounts can be any of the following: $1,000,000, $500,000, $250,000, $100,000, $50,000, $25,000, $10,000, $5,000, $2,500, $1,000"
      },
      {
        key: "Can a bidder place a bid higher than the current bid increment?",
        value:
          "Yes, a bidder can place a bid higher than the preset bid increment. However, a bidder cannot place a bid lower than the preset bid increments."
      },
      {
        key: "Can bid increments change?",
        value: "Yes, bid increments can decrease but will never increase."
      },
      {
        key: "What is a power bid?",
        value:
          "A power bid is when a bidder manually places their bid amount. This is an auction tactic used by bidders  to indicate they are placing their best and final bid. Power bids can be at any amount that is higher than the current bid increment."
      },
      {
        key: "What is a Reserve?",
        value:
          "The Reserve price is the minimum required sales price for the property to be sold at auction."
      },
      {
        key: "What does Reserve Met mean?",
        value:
          "Reserve Met means the property has achieved the minimum sales price in auction. Player will be awarded points based on the scoring matrix provided."
      },
      {
        key: "What happens if a property I bet on was removed from the round?",
        value:
          "A small percentage of properties may move from one auction to another. Players will be notified by email if a property they have placed a bet on has moved to another auction date. Best practice is to check your properties the weekend before the bidding opens to ensure a property has not moved."
      },
      {
        key: "Will I receive points if the property does not sell?",
        value:
          'No points are awarded if a property does not sell at auction. If a property does not sell it will be categorized as "Trade Continues"'
      },
      {
        key: "How is the game scoring calculated?",
        value: "See scoring chart below:"
      },
      {
        key: "How can I see my current rank?",
        value: ` A player’s current round rank is displayed in the upper right-hand corner of My Scoreboard on the Play the Game page. You can also see how your points compare to other players in the Round or Season Leaderboard on the Current Season Winner’s Circle page.`
      }
    ]
  },
  {
    key: "6",
    header: "BROWSERS SUPPORTED",
    items: [
      {
        key: "What Browser types are supported?",
        value:
          "• Chrome, Safari, Edge, and FireFox all the latest version.\n• Internet Explorer is not supported."
      }
    ]
  }
];
