/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * None&nbsp;(0)<br/>PacificHonolulu&nbsp;(1)<br/>AmericaAnchorage&nbsp;(2)<br/>AmericaLosAngeles&nbsp;(3)<br/>USArizona&nbsp;(4)<br/>AmericaDenver&nbsp;(5)<br/>AmericaChicago&nbsp;(6)<br/>AmericaNewYork&nbsp;(7)<br/>CanadaAtlantic&nbsp;(8)<br/>CanadaNewfoundland&nbsp;(9)<br/>AmericaGodthab&nbsp;(10)<br/>AtlanticAzores&nbsp;(11)<br/>Greenwich&nbsp;(12)<br/>EuropeBerlin&nbsp;(13)
 */
export enum CoStar_Api_Listings_ViewModels_TimeZone {
  NONE = "None",
  PACIFIC_HONOLULU = "PacificHonolulu",
  AMERICA_ANCHORAGE = "AmericaAnchorage",
  AMERICA_LOS_ANGELES = "AmericaLosAngeles",
  USARIZONA = "USArizona",
  AMERICA_DENVER = "AmericaDenver",
  AMERICA_CHICAGO = "AmericaChicago",
  AMERICA_NEW_YORK = "AmericaNewYork",
  CANADA_ATLANTIC = "CanadaAtlantic",
  CANADA_NEWFOUNDLAND = "CanadaNewfoundland",
  AMERICA_GODTHAB = "AmericaGodthab",
  ATLANTIC_AZORES = "AtlanticAzores",
  GREENWICH = "Greenwich",
  EUROPE_BERLIN = "EuropeBerlin"
}
