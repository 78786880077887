import createNumberMask from "text-mask-addons/dist/createNumberMask";

/**
 * currecy mask for MaskedInput component
 */
export const currencyMask = createNumberMask({
  prefix: "$",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 9, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false
});
