export const AnimationType = {
  NONE: 0,
  ROW_NEW_BID: 1,
  BID_CELL_NEW_BID: 2,
  BID_COUNT_CELL_NEW_BID: 3,
  POINTS_CELL_POINT_INCREASE: 4,
  POINTS_CELL_POINT_DECREASE: 5,
  ROW_STATUS_SOLD: 6,
  STATUS_CELL_SOLD: 7,
  ROW_STATUS_NOT_SOLD: 8,
  STATUS_CELL_TIME_EXTENDED_RESERVE_MET: 9,
  STATUS_CELL_TIME_EXTENDED_RESERVE_NOT_MET: 10,
  STATUS_CELL_TIME_EXTENDED_NEXT_BID_MEETS_RESERVE: 11
};

export const AnimationSetting = {
  [AnimationType.NONE]: {
    className: "",
    duration: 0,
    postAnimClassName: ""
  },
  [AnimationType.ROW_NEW_BID]: {
    className: "anim-row-new-bid",
    duration: 5_000,
    postAnimClassName: ""
  },
  [AnimationType.BID_CELL_NEW_BID]: {
    className: "anim-cell-text-flash",
    duration: 5_000,
    postAnimClassName: ""
  },
  [AnimationType.BID_COUNT_CELL_NEW_BID]: {
    className: "anim-cell-text-flash",
    duration: 5_000,
    postAnimClassName: ""
  },
  [AnimationType.POINTS_CELL_POINT_INCREASE]: {
    className: "anim-cell-point-increase",
    duration: 20_000,
    postAnimClassName: ""
  },
  [AnimationType.POINTS_CELL_POINT_DECREASE]: {
    className: "anim-cell-point-decrease",
    duration: 20_000,
    postAnimClassName: ""
  },
  [AnimationType.ROW_STATUS_SOLD]: {
    className: "anim-row-status-sold",
    duration: 5_000,
    postAnimClassName: "post-anim-row-status-sold"
  },
  [AnimationType.ROW_STATUS_NOT_SOLD]: {
    className: "",
    duration: 0,
    postAnimClassName: "post-anim-row-status-not-sold"
  },
  [AnimationType.STATUS_CELL_TIME_EXTENDED_RESERVE_MET]: {
    className: "anim-cell-status-time-extended-reserve-met",
    duration: 180_000,
    postAnimClassName: ""
  },
  [AnimationType.STATUS_CELL_TIME_EXTENDED_RESERVE_NOT_MET]: {
    className: "anim-cell-status-time-extended-reserve-not-met",
    duration: 180_000,
    postAnimClassName: ""
  },
  [AnimationType.STATUS_CELL_TIME_EXTENDED_NEXT_BID_MEETS_RESERVE]: {
    className: "anim-cell-status-time-extended-next-bid-meets-reserve",
    duration: 180_000,
    postAnimClassName: ""
  }
};
