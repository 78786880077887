import React from "react";
import "../../content/styles/common/prizes.scss";
import goldTrophy from "../../content/images/marketing/trophy_gold.png";
import confetti from "../../content/images/marketing/confetti.gif";
import silveTrophy from "../../content/images/marketing/trophy_silver.png";
import bobText from "../../content/images/marketing/bob_text.png";
import wreathBg from "../../content/images/marketing/wreaths-bg.png";
import smallHeroCloud from "../../../src/content/images/marketing/prizes-cloud-right.png";
import smallPrizesCloud from "../../../src/content/images/marketing/small-prizes-cloud.png";
import bottomLeftCloud from "../../../src/content/images/marketing/prizes-bottom-left-cloud.png";
import bottomRightCloud from "../../../src/content/images/marketing/prizes-bottom-right-cloud.png";
import smallJet from "../../../src/content/images/marketing/smallf35jet.png";

export const Prizes = ({ feBobShowJets }: { feBobShowJets?: boolean }) => {
  return (
    <>
      <div className="prizes">
        <div className="prizes-container">
          <div className="prizes-hero-container">
            <div className="prizes-logo-container">
              <img className="bob-logo" src={bobText} alt="battle of the bids logo" />
            </div>
            <img className="small-hero-cloud" src={smallHeroCloud} alt="hero cloud" />
            <img className="small-prizes-cloud" src={smallPrizesCloud} alt="hero cloud" />
            <div className="prizes-title-section">
              <div className="prizes-section-title">SET YOUR SIGHTS ON</div>
              <div className="prizes-section-title prizes-section-gradient-title">$3,000,000</div>
              <div className="prizes-section-title">IN PRIZES</div>
            </div>
            <div className="prizes-trophy-section">
              <div className="prize">
                <div className="prizes-section-title prizes-section-gradient-title gradient-bigger">
                  GRAND PRIZE
                </div>
                <div className="prizes-section-title title-bigger">WINNER</div>
              </div>
              <div className="trophy-container">
                <img className="confetti" src={confetti} alt="" />
                <img className="trophy" src={goldTrophy} alt="gold trophy" />
              </div>
              <div className="rewards">
                <div className="prizes-trophy-title-number">$1,000,000</div>
                <div className="prizes-trophy-title-value">IN CASH</div>
                <div className="prizes-trophy-title-plus">+</div>
                <div></div>
                <div className="prizes-trophy-title-number">$100,000</div>
                <div className="prizes-trophy-title-charity">TO DONATE TO A CHARITY</div>
                <div className="prizes-trophy-title-charity">OF YOUR CHOICE</div>
              </div>
            </div>
            <div className="divider-container">
              <div className="divider"></div>
            </div>
          </div>

          <div className="prizes-trophy-section">
            <div className="prize">
              <div className="prizes-section-title prizes-section-gradient-title gradient-bigger">
                SECOND PLACE
              </div>
              <div className="prizes-section-title title-bigger">WINNER</div>
            </div>
            <div className="trophy-container silver">
              <img className="confetti" src={confetti} alt="" />
              <img className="trophy" src={silveTrophy} alt="silver trophy" />
            </div>
            <div className="rewards">
              <div className="prizes-trophy-title-number">$100,000</div>
              <div className="prizes-trophy-title-value">MYSTERY PRIZE</div>
              <div className="prizes-trophy-title-plus">+</div>
              <div className="prizes-trophy-title-number">$10,000</div>
              <div className="prizes-trophy-title-charity">TO DONATE TO A CHARITY</div>
              <div className="prizes-trophy-title-charity">OF YOUR CHOICE</div>
            </div>
          </div>

          <div className="prizes-bottom-section-bg">
            <div className="prizes-bottom-section-container">
              <div className="prizes-title-section">
                <div className="prizes-section-title">OVER $250,000 CASH PRIZES IN EACH ROUND</div>
              </div>
              <div className="marketing-footer-text">
                Battle of the Bids is taking prizes to new heights for top-performing players each
                round. Ready to soar above the competition for amazing cash prizes? Join the game
                and claim your share of the high-flying rewards!
              </div>

              <div className="wreath-section-container">
                <div className="wreath-container">
                  <img className="wreath-img" src={wreathBg} alt="wreath" />
                </div>
              </div>

              <div className="divider-container">
                <div className="divider"></div>
              </div>

              <div className="bonus-section-container">
                <div className="prizes-title-section">
                  <div className="prizes-section-title">BONUS PRIZE DRAW</div>
                </div>

                <div className="bonus-footer-text">
                  Introducing the player participation prize! After each round, starting after Round
                  2, one lucky player who completed all ten bets and has played all previous rounds
                  will be eligible to win the prizes below.
                </div>
                <div className="round-awards-container">
                  <div className="round-awards">
                    <div className="prizes-trophy-title-number">$10,000</div>
                    <div className="footer-text">ROUND 2-5</div>
                  </div>
                  <div className="awards-border"></div>
                  <div className="round-awards">
                    <div className="prizes-trophy-title-number">$25,000</div>
                    <div className="footer-text">FOR FINAL ROUND</div>
                  </div>
                </div>
                <img className="small-bottom-left-cloud" src={bottomLeftCloud} alt="small cloud" />
                {feBobShowJets && <img className="small-jet" src={smallJet} alt="jet" />}
                <img
                  className="small-bottom-right-cloud"
                  src={bottomRightCloud}
                  alt="small cloud"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
