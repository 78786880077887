import React, { useState, useEffect } from "react";
import {
  Prizes,
  HowItWorks,
  CanIPlay,
  SignUpToPlaySection,
  HallOfFame,
  CompetitiveEdge,
  StayInformed,
  PrepareForBattle
} from "./";
import "../../content/styles/marketing/splash-screen.scss";
import { MessageModalData } from "../../types/message-modal-data";
import { MessageModal } from "../../components/common/message-modal";
import { useParams } from "react-router-dom";
import { useUserContext } from "../../common/user-context";
import { PlayerDomainInterfacesService } from "../../player-api-client";
import { useHistory } from "react-router-dom";
import { LoginModal } from "../../components/login-signup/login";

type Props = {
  campaignId?: string;
  isCampaign?: boolean;
  feBobShowJets?: boolean;
  feBobEndOfSeason?: boolean;
};

export const SplashScreen: React.FC<Props> = ({
  isCampaign,
  feBobShowJets,
  feBobEndOfSeason,
  campaignId
}) => {
  const history = useHistory();
  const { isAuthenticated, signInCA } = useUserContext();
  const { email } = useParams<{ email: string }>();
  const defaultMessageData: MessageModalData = {
    message: undefined,
    messageTitle: undefined,
    videoUrl: ""
  };

  const [theMessageData, setTheMessageData] = useState<MessageModalData>(defaultMessageData);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [showMessageModal, setShowMessageModal] = React.useState(false);
  const [permission, setPermission] = useState("");
  const hideMessageModal = () => {
    setShowMessageModal(false);
    const messageData: MessageModalData = {
      message: undefined,
      messageTitle: undefined,
      videoUrl: ""
    };
    setTheMessageData(messageData);
  };

  const showTheMessageModal = () => {
    setShowMessageModal(true);
  };

  useEffect(() => {
    if (localStorage.getItem("intialLogin") === "RELOGIN") {
      setShowLoginModal(true);
    }
  }, []);

  useEffect(() => {
    const autoSubmit = async () => {
      try {
        const response = await PlayerDomainInterfacesService.apiPlayerElegibility(email);

        setPermission(response.action);
        switch (response.action) {
          case "Register":
            setShowLoginModal(true);
            break;
          case "DoPardot":
            localStorage.setItem("intialLogin", "");
            setShowLoginModal(true);
            break;
          case "LoginToPlay":
          case "LoginButCanNotPlay":
            history.push("/splash");
            signInCA(email);
            localStorage.setItem("intialLogin", "INITIAL_LOGIN");
            break;
          default:
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (isCampaign && !isAuthenticated) {
      autoSubmit();
    }
  }, [isCampaign, email, isAuthenticated, history, signInCA]);

  return (
    <>
      {showLoginModal && (
        <div className="modal-wrapper active">
          <LoginModal
            loginSignupModal=""
            setLoginSignupModal={setShowLoginModal}
            permission={permission}
            campaignEmail={email}
          />
        </div>
      )}
      <MessageModal isShown={showMessageModal} hideModal={hideMessageModal} data={theMessageData} />

      <div className="text-center">
        {feBobEndOfSeason ? (
          <>
            <HallOfFame feBobEndOfSeason />
            <PrepareForBattle campaignId={campaignId} />
            <CompetitiveEdge feBobEndOfSeason={feBobEndOfSeason} />
            <StayInformed feBobShowJets={feBobShowJets} />
          </>
        ) : (
          <>
            <Prizes showTheMessageModal={showTheMessageModal} setMessageData={setTheMessageData} />
            <HowItWorks />
            <CanIPlay feBobShowJets={feBobShowJets} />
            <SignUpToPlaySection />
            <HallOfFame />
            <CompetitiveEdge />
            <StayInformed feBobShowJets={feBobShowJets} />
          </>
        )}
      </div>
    </>
  );
};
