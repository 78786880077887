export const AppConfig = {
  API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT || "",
  ADMIN_API_ENDPOINT: process.env.REACT_APP_ADMIN_API_ENDPOINT || "",
  COGNITO_REGION: process.env.REACT_APP_COGNITO_REGION,
  COGNITO_POOL_ID: process.env.REACT_APP_COGNITO_POOL_ID,
  COGNITO_APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  COGNITO_OAUTH_DOMAIN: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN,
  COGNITO_OAUTH_REDIRECT_SIGNIN: process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGNIN,
  COGNITO_OAUTH_REDIRECT_SIGNOUT: process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGNOUT,
  COGNITO_OIDC_PROVIDER: process.env.REACT_APP_COGNITO_OIDC_PROVIDER || "",
  ENV: process.env.REACT_APP_ENV || "dvm",
  GTM_ID: process.env.REACT_APP_GTM_ID || "",
  LOOPNET_URL: process.env.REACT_APP_LOOPNET_URL,
  LOOPNET_ABOUT_TENX_URL: process.env.REACT_APP_LOOPNET_ABOUT_TENX_URL,
  TENX_SERVICE_ROOT: process.env.REACT_APP_TENX_SERVICE_ROOT,
  WSS_ENDPOINT: process.env.REACT_APP_WSS_ENDPOINT || "",
  REVIEW_PROPERTIES_DAYS: process.env.REACT_APP_REVIEW_PROPERTIES_DAYS || 0,
  LOGROCKET_ID: process.env.REACT_APP_LOGROCKET || "",
  COSTAR_URL: process.env.REACT_APP_COSTAR_URL,
  REACT_APP_BOB_ROOT_URL: process.env.REACT_APP_BOB_ROOT_URL,
  LD_CLIENT_ID: process.env.REACT_APP_LD_CLIENT_ID || "",
  DD_CLIENT_TOKEN: process.env.REACT_APP_DD_CLIENT_TOKEN || "",
  DD_APPLICATION_ID: process.env.REACT_APP_DD_APPLICATION_ID || "",
  DD_SITE: process.env.REACT_APP_DD_SITE || "",
  DD_SERVICE: process.env.REACT_APP_DD_SERVICE || "",
  DD_ENV: process.env.REACT_APP_DD_ENV || "",
  DD_SESSION_SAMPLE_RATE: process.env.REACT_APP_DD_SESSION_SAMPLE_RATE || "100",
  DD_SESSION_REPLAY_SAMPLE_RATE: process.env.REACT_APP_DD_SESSION_REPLAY_SAMPLE_RATE || "0"
};
