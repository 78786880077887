import { ApiContest, ApiContestItem } from "./api-models";

export type FormattedPlacard = {
  address: string;
  brokerageFirmLogoUri: string;
  brokerageFirmName: string;
  listingUri: string;
  primaryPhotoUri: string;
  auctionUri: string;
  propertyTypeLabel: string;
  shortAddress: string;
  startingBid: string;
  highlights: string[];
};

export type ContestItem = ApiContestItem & {
  localStartDate?: Date;
  localEndDate?: Date;
  formattedPlacard: FormattedPlacard;
  averageBid?: number;
  allBids?: Array<number> | undefined;
};

export type Contest = Omit<ApiContest, "contestItems"> & {
  localStartDate?: Date;
  localEndDate?: Date;
  contestItems: Array<ContestItem>;
  contestRoundType: string;
  contestRoundState: string;
  firstRound?: boolean;
};

export enum ContestStatus {
  Unknown = 0,
  NotStarted = 1,
  InProgress = 2,
  Ended = 3
}

export enum LoginSignup {
  Unknown = 0,
  NotStarted = 1,
  InProgress = 2,
  Ended = 3
}
