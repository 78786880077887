import { EListingStatus } from "../../types/auction";
import { ContestStatus } from "../../types/contest";
import { NumberFormatter } from "../../types/formatter";

type Props = {
  contestStatus: ContestStatus;
  currentBid?: number;
  startingBid?: number;
  isKentucky?: boolean;
  listingStatus?: string | null;
};

export function PropertyBidField({
  contestStatus,
  currentBid,
  startingBid,
  isKentucky,
  listingStatus
}: Props) {
  return (
    <div className="property-bid">
      {contestStatus === ContestStatus.NotStarted && !isKentucky && startingBid !== 0 && (
        <div className="starting-bid">
          Starting Bid: <strong>{NumberFormatter.FormatCurrency(startingBid || 0)}</strong>
        </div>
      )}

      {contestStatus === ContestStatus.InProgress && currentBid && (
        <div className="current-bid">
          Current Bid: <strong>{NumberFormatter.FormatCurrency(currentBid || 0)}</strong>
        </div>
      )}

      {contestStatus === ContestStatus.InProgress && !currentBid && (
        <div className="starting-bid">
          Starting Bid: <strong>{NumberFormatter.FormatCurrency(startingBid || 0)}</strong>
        </div>
      )}

      {contestStatus === ContestStatus.Ended &&
        currentBid &&
        listingStatus === EListingStatus.SOLD && (
          <div className="sold-unsold">
            SOLD: <strong>{NumberFormatter.FormatCurrency(currentBid || 0)}</strong>
          </div>
        )}

      {contestStatus === ContestStatus.Ended && listingStatus !== EListingStatus.SOLD && (
        <div className="sold-unsold">UNSOLD</div>
      )}
    </div>
  );
}
