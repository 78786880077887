import moment from "moment";
import { PlayerDomainInterfacesService } from "../player-api-client";
import { ContestStatus } from "../types/contest";

export type Countdown = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  totalRemainingSeconds: number;
  done: boolean;
};

let localDiffFromTenX: Countdown = {
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
  totalRemainingSeconds: 0,
  done: false
};

//let isAfterTenX = false;

export function getTenXTime() {
  PlayerDomainInterfacesService.apiTenxServertimeGet().then((data) => {
    //console.log("Got TenX Time!");
    //isAfterTenX = true;
    const localTime = moment().local();
    //localTime.add(2, "minutes");
    // localTime.set("hours", 15);
    // localTime.set("minutes", 12);
    // localTime.set("seconds", 34);
    if (data.now !== undefined && data.now !== null) {
      //data.now = "2022-02-07T23:14:09.76+00:00";
      const now = new Date(Date.parse(data.now));
      const differenceTime = moment.duration(localTime.diff(now));
      localDiffFromTenX = {
        days: Math.trunc(differenceTime.asDays()),
        hours: Math.trunc(differenceTime.hours()),
        minutes: Math.trunc(differenceTime.minutes()),
        seconds: Math.trunc(differenceTime.seconds()),
        done: differenceTime.asSeconds() < 0,
        totalRemainingSeconds: Math.trunc(differenceTime.asSeconds())
      };
    }
  });
}

export async function showPropertiesPriorToOpeningBets(): Promise<boolean | undefined> {
  let result: boolean | undefined = false;
  result = await PlayerDomainInterfacesService.apiTenxServertimeGet().then((data) => {
    const configuredDate = new Date(Date.parse("2022-03-07T22:00:00.00+00:00"));
    if (data.now !== undefined && data.now !== null) {
      const now = new Date(Date.parse(data.now));
      const differenceTime = configuredDate.getTime() - now.getTime();
      return differenceTime > 1;
    }
  });
  return result;
}

/**
 * Calculates the Countdown to a given target date.
 * @param targetLocalDate
 * @returns
 */
export function getCountDown(targetLocalDate: Date): Countdown {
  if (targetLocalDate === undefined || targetLocalDate === null)
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      done: true,
      totalRemainingSeconds: 0
    };
  //if(isAfterTenX)
  //targetLocalDate = new Date(Date.parse("2022-02-15T23:14:09.76+00:00"));
  const now = moment().local();
  // if(isAfterTenX)
  // {
  // now.add(2, "minutes");
  // console.log("Before Change.");
  // console.log(now);
  now.subtract(localDiffFromTenX.days, "days");
  now.subtract(localDiffFromTenX.hours, "hours");
  now.subtract(localDiffFromTenX.minutes, "minutes");
  now.subtract(localDiffFromTenX.seconds, "seconds");
  // console.log("After Change.");
  // console.log(now);
  //}
  const target = moment(targetLocalDate);
  const remainingDuration = moment.duration(target.diff(now));
  // if(isAfterTenX)
  // {
  //   console.log("Remaining Time!");
  //   console.log(remainingDuration);
  // }
  return {
    days: Math.max(Math.floor(remainingDuration.asDays()), 0),
    hours: Math.max(remainingDuration.hours(), 0),
    minutes: Math.max(remainingDuration.minutes(), 0),
    seconds: Math.max(remainingDuration.seconds(), 0),
    done: remainingDuration.asSeconds() < 0,
    totalRemainingSeconds: Math.max(Math.floor(remainingDuration.asSeconds()), 0)
  };
}

/**
 * Determines if an event has started given the event start date.
 * @param localStartDate
 * @returns
 */
export function hasStarted(localStartDate: Date) {
  const now = moment().local();
  now.add(localDiffFromTenX.days, "days");
  now.add(localDiffFromTenX.hours, "hours");
  now.add(localDiffFromTenX.minutes, "minutes");
  now.add(localDiffFromTenX.seconds, "seconds");
  return now.isAfter(moment(localStartDate));
}

/**
 * Determines if an event has ended given the event end date.
 * @param localEndDate
 * @returns
 */
export function hasEnded(localEndDate: Date) {
  const now = moment().local();
  now.add(localDiffFromTenX.days, "days");
  now.add(localDiffFromTenX.hours, "hours");
  now.add(localDiffFromTenX.minutes, "minutes");
  now.add(localDiffFromTenX.seconds, "seconds");
  return now.isAfter(moment(localEndDate));
}

/**
 * Convert an utc date value (in Date or string) to Date object in local timezone.
 * @param utcDateValue
 * @returns
 */
export function toLocalDate(utcDateValue?: Date | string | null | undefined): Date {
  return moment.utc(utcDateValue).local().toDate();
}

/**
 * Convert an utc date value (in Date or string) to Date object in utc timezone.
 *
 */
export function toUtcDate(utcDateValue?: Date | string | null | undefined): Date {
  return moment.utc(utcDateValue).toDate();
}

export function getContestStatus(localStartDate?: Date, localEndDate?: Date): ContestStatus {
  const localNowMoment = moment();
  localNowMoment.add(localDiffFromTenX.days, "days");
  localNowMoment.add(localDiffFromTenX.hours, "hours");
  localNowMoment.add(localDiffFromTenX.minutes, "minutes");
  localNowMoment.add(localDiffFromTenX.seconds, "seconds");
  const localNow = localNowMoment.toDate();

  if (!localStartDate || !localEndDate) {
    return ContestStatus.Unknown;
  } else if (localNow < localStartDate) {
    return ContestStatus.NotStarted;
  } else if (localNow > localEndDate) {
    return ContestStatus.Ended;
  } else {
    return ContestStatus.InProgress;
  }
}
