import { useState } from "react";
import { MessageModalData } from "../types/message-modal-data";

export const useMessageModal = () => {
  const defaultMessageData: MessageModalData = {
    message: undefined,
    messageTitle: undefined,
    videoUrl: ""
  };

  const [theMessageData, setTheMessageData] = useState<MessageModalData>(defaultMessageData);
  const [isShowMessageModal, setShowMessageModal] = useState(false);

  const showMsgModal = () => {
    setShowMessageModal(true);
  };

  const hideMsgModal = () => {
    setShowMessageModal(false);
  };

  const setMsgData = (data: MessageModalData) => {
    setTheMessageData(data);
  };

  return { theMessageData, setMsgData, isShowMessageModal, showMsgModal, hideMsgModal };
};
