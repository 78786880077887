import React, { useEffect, useRef } from "react";
import { useUserContext } from "../../common/user-context";
import { NavLink } from "react-router-dom";
import { PageView } from "../../common/constants";

type Props = {
  showPointsWinnings: boolean;
  showPrizeMoneyAddress: boolean;
  setShowPointsWinnings(showPointsWinnings: boolean): void;
  setShowProfileNav(showProfileNav: boolean): void;
  setShowPrizeMoneyAddress(showPrizeMoneyAddress: boolean): void;
};

export const ProfileMenu: React.FC<Props> = ({
  setShowPointsWinnings,
  setShowProfileNav,
  setShowPrizeMoneyAddress
}) => {
  const { signOut, userInfo } = useUserContext();

  const profileMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (event.target instanceof Element) {
        if (
          profileMenuRef &&
          profileMenuRef.current &&
          !profileMenuRef.current.contains(event.target)
        ) {
          setShowProfileNav(false);
        }
      }
    };
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [profileMenuRef, setShowProfileNav]);

  const isAdminUser = userInfo?.email!.includes("@testqa.com");

  return (
    <div className="profile-menu" ref={profileMenuRef}>
      <ul>
        {isAdminUser && (
          <li>
            <NavLink
              bob-ga-data-on="click"
              bob-ga-data-event-category="Header"
              bob-ga-data-event-action="Click"
              bob-ga-data-event-label="Score Card"
              activeClassName="navlink"
              to={PageView.ScoreCard}
              onClick={() => setShowProfileNav(false)}
            >
              Score Card
            </NavLink>
          </li>
        )}

        {isAdminUser && (
          <li>
            <NavLink
              bob-ga-data-on="click"
              bob-ga-data-event-category="Header"
              bob-ga-data-event-action="Click"
              bob-ga-data-event-label="Property Stats"
              to={PageView.PropertyStats}
              onClick={() => setShowProfileNav(false)}
            >
              Property Stats
            </NavLink>
          </li>
        )}
        <li
          onClick={signOut}
          bob-ga-data-on="click"
          bob-ga-data-event-category="Profile"
          bob-ga-data-event-action="Click"
          bob-ga-data-event-label="SignOut"
          bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
        >
          Sign Out
        </li>
      </ul>
    </div>
  );
};
