import "../../content/styles/game/scoreboard.scss";
import { useUserContext } from "../../common/user-context";
import { getStorageRepo, StorageOption } from "../../helpers/storage";
import { useEffect, useState } from "react";
import { useContestContext } from "../../common/contest-context";
import { ContestStatus } from "../../types/contest";

type Props = {
  showNoMoreBetsModal: boolean;
  setShowNoMoreBetsModal: (showNoMoreBetsModal: boolean) => void;
};

type NoMoreBetsWarningModal = {
  dismissdate?: Date;
};

let noMoreBetsWarningModal: NoMoreBetsWarningModal = {
  dismissdate: undefined
};

export const NoMoreBetsModal: React.FC<Props> = (props) => {
  const { userInfo, isAuthenticated } = useUserContext();
  const { contest, status } = useContestContext();
  const [showModal, setShowModal] = useState(false);

  const handleDismiss = () => {
    setShowModal(false);
    noMoreBetsWarningModal.dismissdate = new Date();
    noMoreBetsWarningModalStorage.set(noMoreBetsWarningModal);
  };

  let noMoreBetsWarningModalStorage = getStorageRepo<NoMoreBetsWarningModal>(
    "no_more_bets",
    noMoreBetsWarningModal,
    StorageOption.Local
  );

  useEffect(() => {
    noMoreBetsWarningModal.dismissdate = undefined;
    const shoudShow = () => {
      let review = false;
      if (status === ContestStatus.InProgress) {
        const storageDate = noMoreBetsWarningModalStorage.get().dismissdate;
        if (!storageDate && contest?.localStartDate) return true;

        if (storageDate && contest?.localStartDate) {
          if (new Date(storageDate.toString()) < contest?.localStartDate) {
            review = true;
          }
        }
      }
      return review;
    };

    if (isAuthenticated && userInfo) {
      if (shoudShow()) {
        setShowModal(true);
      } else {
        setShowModal(false);
      }
    }
  }, [isAuthenticated, noMoreBetsWarningModalStorage, userInfo, status, contest, props]);

  return (
    <div
      className={isAuthenticated && showModal ? "review-properties" : "review-properties hidden"}
    >
      <div className="rules-overlay" style={{ zIndex: 3 }}></div>
      <div className="no-more-bets-dialog">
        <button className="btn-close" onClick={handleDismiss}>
          &#10006;
        </button>
        <div className="heading">Current Round is in Progress. No more bets.</div>
        <div className="body-text">
          <div>New bets cannot be placed once the auction round begins. </div>
          <div>
            You can change your bet on up to two properties, but only within the last 10 minutes of
            the remaining auction time for each property.
          </div>
        </div>
        <div className="action-button" onClick={handleDismiss}>
          DISMISS
        </div>
      </div>
    </div>
  );
};
