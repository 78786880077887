// These types can be found here:
// https://ten-x.atlassian.net/wiki/spaces/LI/pages/520454470/Websocket+Integration+with+Loopnet#Flow-to-render-data-and-get-real-time-updates

export interface IListingUpdatedMsg {
  listingId: number;
  eventDetails: Partial<IBidBoxListing>;
}

export interface IBidBoxListing {
  listing_id: number;
  listing_start_date: string;
  listing_end_date: string;
  listing_status: EListingStatus;
  current_bid_amt?: number;
  next_bid_amt: number;
  starting_bid_amt: number;
  bid_increment: number;
  is_highest_bidder: boolean;
  is_winning_bidder: boolean;
  is_reserve_met: boolean;
  next_bid_meets_reserve: boolean;
  is_to_be_sold_subject_to: boolean;
  bid_type: EBidType;
  // This only is updated via websockets
  is_outbidded_bidder?: boolean;
  bid_history?: Array<IBidBoxBidHistory>;
  internal_contact_first_name: string;
  internal_contact_last_name: string;
  internal_contact_phone: string;
  country_subdivision: string;
  reset_timer?: boolean;
  listing_external_status?: string;
  number_of_bids: number;
  external_facing_number_of_bids: number;
  /**
   * https://ten-x.atlassian.net/wiki/spaces/SM/pages/789610504/bidding+box+endpoints+for+marketing+campaign+information
   * AuctionListingStatusEnum
   * value | Conditions
   * NOT_STARTED | currentDate is before listingStartDate
   * LIVE | listingStartDate is before currentDate, currentDate is before listingEndDate
   * SOLD_SUBJECT_TO | listingEndDate is before currentDate, listingStatus is SOLD, isToBeSoldSubjectTo is true
   * SOLD | listingEndDate is before currentDate, listingStatus is SOLD, isToBeSoldSubjectTo is false
   * GONE | listingEndDate is before currentDate, listingStatus is not SOLD
   */
  auction_status?: string;
  is_next_bid_meets_reserve_on: boolean;
}

export enum EListingStatus {
  DRAFT = "DRAFT",
  ACTIVE_READY = "ACTIVE_READY",
  ACTIVE = "ACTIVE",
  SOLD = "SOLD",
  INACTIVE = "INACTIVE",
  CLOSED = "CLOSED",
  UNSOLD = "UNSOLD",
  IN_NEGOTIATION = "IN_NEGOTIATION",
  ESCROW_FAILED = "ESCROW_FAILED",
  UNDER_CONTINGENCY = "UNDER_CONTINGENCY",
  EMD_HARD = "EMD_HARD",
  FALLOUT = "FALLOUT",
  UNDER_CONTRACT = "UNDER_CONTRACT",
  CANCELLED = "CANCELLED",
  REMOVED = "REMOVED",
  WITHDRAWN = "WITHDRAWN",
  GONE = "GONE"
}

export interface IBidBoxBidHistory {
  bid_amt: number;
  bid_date_time: string;
  current_user_bid: boolean;
}

export enum EBidType {
  BID = "BID",
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL"
}
