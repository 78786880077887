import * as React from "react";
import { useContext, useEffect, useState, useCallback } from "react";
import { ClarityTagKey, setClarityTag } from "../helpers/clarity-helpers";
import { mapContest } from "../helpers/contest-mapper";
import { getContestStatus } from "../helpers/date-helpers";
import { PlayerDomainInterfacesService } from "../player-api-client";
import { Contest, ContestStatus } from "../types/contest";

export type ContestContextType = {
  contest?: Contest;
  status: ContestStatus;
  error: string;
  setError: (error: string) => void;
  loginSignupModal?: string;
  setLoginSignupModal?: (loginSignupModal?: string) => void;
  endedContest?: Contest;
  propertyTypes?: number[];
  onPropertyTypeChange: (propertyTypes: number[]) => void;
  gotBonusDrawData: boolean,
  setGotBonusDrawData: (gotBonusDrawData: boolean) => void;
};

export const ContestContext = React.createContext<ContestContextType>(null!);

export const ContestContextProvider = (props: React.PropsWithChildren<{}>) => {
  const [contest, setContest] = useState<Contest | undefined>();
  const [status, setStatus] = useState<ContestStatus>(ContestStatus.Unknown);
  const [error, setError] = useState<string>("");
  const [endedContest, setEndedContest] = useState<Contest | undefined>();
  const [propertyTypes, setPropertyType] = useState<number[]>([]);
  const [gotBonusDrawData, setGotBonusDrawData] = useState<boolean>(false);

  const fetchCurrentContest = async (): Promise<Contest | undefined> => {
    const response = await PlayerDomainInterfacesService.apiContestCurrentGet();
    let responseContest = response.contest as Contest;
    return responseContest ? mapContest(responseContest) : responseContest;
  };

  const fetchLastEndedContest = async (): Promise<Contest | undefined> => {
    try {
      const response = await PlayerDomainInterfacesService.apiContestLastEndedGet();
      let responseContest = response.contest as Contest;
      return responseContest ? mapContest(responseContest) : responseContest;
    } catch (ex) {
      console.log("fetchCurrentContest:");
      console.log(ex);
      return undefined;
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const fetchedContest = await fetchCurrentContest();
      if (fetchedContest) {
        setContest(fetchedContest);
        setStatus(getContestStatus(fetchedContest?.localStartDate, fetchedContest?.localEndDate));
        setClarityTag({ key: ClarityTagKey.ContestId, value: fetchedContest.contestId });
      }
    };
    fetch();
    const endedFetch = async () => {
      const fetchedContest = await fetchLastEndedContest();
      if (fetchedContest) {
        setEndedContest(fetchedContest);
      }
    };
    endedFetch();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const newStatus = getContestStatus(contest?.localStartDate, contest?.localEndDate);
      if (newStatus !== status) {
        setStatus(newStatus);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [contest?.localStartDate, contest?.localEndDate, status]);

  const onPropertyTypeChange = useCallback((propertyTypes: number[]) => {
    setPropertyType(propertyTypes);
  }, []);

  return (
    <ContestContext.Provider
      value={{
        contest,
        status,
        error,
        setError,
        endedContest,
        propertyTypes,
        onPropertyTypeChange,
        gotBonusDrawData,
        setGotBonusDrawData
      }}
    >
      {props.children}
    </ContestContext.Provider>
  );
};

export const useContestContext = () => {
  const context = useContext(ContestContext);
  if (!context) {
    throw new Error("useContestContext must be used within the ContestContextProvider");
  }
  return context;
};
