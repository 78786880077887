export const RulesText: React.FC = () => {
  return (
    <>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <strong>BATTLE OF THE BIDS 2</strong>
      </div>
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <strong>Official Rules</strong>
      </div>

      <p>
        <strong>NO PURCHASE NECESSARY TO ENTER OR WIN.</strong> A purchase does not increase your
        chances of winning. Void where prohibited by law. By participating in Battle of the Bids 2
        you agree to and accept these Official Rules and the decisions of Sponsor, which are final
        and binding in all matters.
      </p>
      <h5>
        <strong>1. INTRODUCTION.</strong>
      </h5>
      <p>
        These are the Official Rules (“Rules”) for Battle of the Bids 2 (the “Promotion”), sponsored
        by Ten-X, LLC (“Sponsor”). This Promotion is in no way sponsored, endorsed or administered
        by, or associated with any social media platform, including but not limited to LinkedIn. You
        are providing your information to Sponsor and not to any social media platform. You agree to
        contact Sponsor and not any social media platform with questions or comments relating to the
        Promotion. The information you provide will only be used as stated in these Rules.
      </p>
      <h5>
        <strong>2. ELIGIBILITY.</strong>
      </h5>
      <p>
        The Promotion is open to legal residents of the 50 United States or the District of Columbia
        who are at least 18 years of age and of the age of majority in their state of residence at
        the time of entry. Excludes U.S. territories and possessions. Entrants must, as of the date
        of entry, meet at least one of the following criteria:
      </p>
      <ul>
        <li>
          Entrant must be a commercial real estate broker known to CoStar and its affiliates within
          CoStar's database; or
        </li>
        <li>
          Entrant must be, at the time of entry, a paying user of CoStar, LoopNet, Apartments.com,
          Land.com, BizBuySell, or STR services; or
        </li>
        <li>
          Entrant must have ever listed an asset, registered to bid, or represented a bidder or
          seller on ten-x.com (“Ten-X”)—registration to bid on Ten-X is free; or
        </li>
        <li>
          Entrant must have signed a confidentiality agreement to underwrite an asset on Ten-X prior
          to May 1, 2023; or
        </li>
        <li>
          Entrant must have otherwise received an invitation from Sponsor to participate in the
          Promotion (which may be extended to active investors, brokers, and professionals in the
          commercial real estate industry).
        </li>
      </ul>
      <p>
        Promotion not open to (a) persons banned by Sponsor or banned from participating in Ten-X
        transactions, (b) Sponsor, its respective parent companies, subsidiaries and affiliates,
        distributors, dealerships and companies involved in the implementation and execution of the
        Promotion, and their employees (and any former employee who was employed for any period
        after January 1, 2023), agents, directors and officers (“Promotion Entities”) and each of
        their immediate families (spouses and partners, siblings, parents, children, and each of
        their respective spouses and partners) and household members, or (c) any other person who
        Sponsor determines as ineligible, in Sponsor's sole and absolute discretion. Sponsor may
        limit the number of participants to 250,000 in its sole and absolute discretion. The
        Promotion is subject to all applicable federal, state, and local laws and regulations and is
        void where prohibited.
      </p>
      <h5>
        <strong>3. TIMING.</strong>
      </h5>
      <p>
        Promotion begins September 8, 2023 at 12:00 AM Eastern Time and ends December 1, 2023 at
        11:59 PM Eastern Time (“Promotion Period”). The Promotion Period covers 6 separate auction
        rounds, each consisting of a series of Ten-X auctions within a given calendar week (each
        such auction week, an “Auction Round”).
      </p>
      <h5>
        <strong>4. HOW TO ACCESS AND ENTER.</strong>
      </h5>
      <p>
        To access the Promotion, log on to the Promotion page at battleofthebids.com using your
        CoStar credentials and click the “Play The Game” button. You may also be asked to provide
        basic contact information or other qualifying information to access the Promotion. To submit
        an entry, follow instructions described in “How to Play” section below. Accessing and
        entering the promotion is free. Entries in excess of the stated limits will be disqualified.
        Illegible, late, lost, or incomplete entries are not eligible. No group entries. Any attempt
        to obtain more than the stated number of entries by using multiple/different social media
        accounts, email addresses, identities, registrations, logins, or any other methods may cause
        disqualification. If a dispute occurs, entries will be deemed made by the authorized account
        holder of the email address submitted during entry. The authorized account holder is the
        natural person assigned to the email address by an internet access provider, online service
        provider, or other organization responsible for assigning email addresses or the domain
        associated with the submitted email address. The potential winner may be required to show
        proof of eligibility and/or being the authorized account holder. No mechanical reproductions
        permitted. Sponsor is not liable for lost, late, misdirected, or corrupted entries whether
        due to a technical error or otherwise. Entries become the property of Sponsor and will not
        be acknowledged or returned.
      </p>
      <h5>
        <strong>5. HOW TO PLAY.</strong>
      </h5>
      <p>
        The objective of the Promotion is to accumulate the most points by accurately projecting the
        winning bid amount for assets in each Auction Round (each, an “Asset”) during the Promotion
        Period. The closer your estimate is to the winning bid amount, the more points you earn. You
        may enter your projections online by typing into the “Enter Bet” field and clicking
        “Submit”. You may enter projections for up to ten (10) Assets for each Auction Round. For
        each Auction Round, your projections must be made before bidding opens for any Asset in the
        Auction Round. After the Auction Round begins, you may change projections for up to two (2)
        Assets within the last ten (10) minutes before the scheduled end of the auction for such
        Assets. Any projections submitted after the applicable deadline will not be valid. Sponsor's
        computer is the official timekeeping device for the Promotion.
      </p>
      <p>
        For each Asset, you will be awarded points based on the difference between your projected
        winning bid amount and the actual winning bid amount upon conclusion of the Asset's auction,
        as follows:
      </p>
      <table className="rules-table">
        <thead>
          <tr>
            <th>% Difference Between Selection and Actual Winning Bid Amount</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>0%</td>
            <td>1000</td>
          </tr>
          <tr>
            <td>{">"}0% and &#x2264;1%</td>
            <td>800</td>
          </tr>
          <tr>
            <td>{">"}1% and &#x2264;2%</td>
            <td>700</td>
          </tr>
          <tr>
            <td>{">"}2% and &#x2264;3%</td>
            <td>600</td>
          </tr>
          <tr>
            <td>{">"}3% and &#x2264;4%</td>
            <td>500</td>
          </tr>
          <tr>
            <td>{">"}4% and &#x2264;5%</td>
            <td>400</td>
          </tr>
          <tr>
            <td>{">"}5% and &#x2264;10%</td>
            <td>300</td>
          </tr>
          <tr>
            <td>{">"}10% and &#x2264;20%</td>
            <td>200</td>
          </tr>
          <tr>
            <td>{">"}20% and &#x2264;30%</td>
            <td>150</td>
          </tr>
          <tr>
            <td>{">"}30% and &#x2264;40%</td>
            <td>100</td>
          </tr>
          <tr>
            <td>{">"}40% and &#x2264;50%</td>
            <td>50</td>
          </tr>
          <tr>
            <td>{">"}50%</td>
            <td>0</td>
          </tr>
        </tbody>
      </table>
      <p>
        If any Asset is removed from the Auction Round, or no winning bidder is declared by Ten-X
        upon conclusion of the Asset's auction, no points will be awarded for such Asset, and such
        Asset will not be factored into the bet accuracy calculation.
      </p>
      <p>
        Ties at the end of each Auction Round will be resolved by giving priority to the entrant
        with the highest bet accuracy for that Auction Round, and if still a tie, then the
        applicable prize will be divided equally among the tying entrants.
      </p>
      <p>
        After each Auction Round (except for the last Auction Round), each entrant who earned a
        prize for that Auction Round will be awarded an additional 200 points towards eligibility
        for the grand prize if the entrant publicly posts their prize on LinkedIn using the hashtag
        #TenXSweepstakesBonusPoints, and tagging @ten-x, within five (5) days following notification
        of prize earning. Entrant posts that are inappropriate or that violate the social media
        platform terms and conditions, as determined by Sponsor in its sole discretion, will not
        qualify for the additional points towards eligibility for the grand prize.
      </p>
      <h5>
        <strong>6. PRIZES.</strong>
      </h5>
      <p>
        Prizes will be awarded to entrants following the conclusion of each Auction Round based on
        the number of points earned during the Auction Round. Each Auction Round (6 Auction Round
        total) will have 31 winners, with prizes awarded as follows.
      </p>
      <ul>
        <li>$100,000 for the entrant with the most points earned during the Auction Round</li>
        <li>
          $10,000 for each of the next 10 entrants with the most points earned during the Auction
          Round
        </li>
        <li>
          $5,000 for each of the next 10 entrants with the most points earned during the Auction
          Round
        </li>
        <li>
          $1,000 for each of the next 10 entrants with the most points earned during the Auction
          Round
        </li>
      </ul>
      <p>
        Upon conclusion of each of Auction Rounds 2 through 5, a $10,000 prize will be awarded to
        each of 4 randomly selected entrants who have fully participated (including having placed 10
        bets) in each of the prior Auction Rounds.
      </p>

      <p>
        Upon conclusion of Auction Round 6, a $25,000 prize will be awarded to one randomly selected
        entrant who has fully participated (including having placed 10 bets) in all 6 Auction
        Rounds.
      </p>

      <p>
        One grand prize will be awarded to the entrant who has accumulated the most total points at
        the end of the Promotion Period. Ties for the grand prize will be resolved by giving
        priority to the entrant with the highest bet accuracy for the Promotion, and if still a tie,
        then then the grand prize will be divided equally among the tying entrants.
      </p>

      <p>The grand prize will consist of:</p>
      <ul>
        <li>$1,000,000; and</li>
        <li>$100,000 donation to the charity of winner’s choice</li>
      </ul>

      <p>
        One runner up prize will be awarded to the entrant who has accumulated the second most total
        points at the end of the Promotion Period. Ties for the runner up prize will be resolved by
        giving priority to the entrant with the highest bet accuracy for the Promotion, and if still
        a tie, then then the runner up prize will be divided equally among the tying entrants.
      </p>

      <p>The runner up prize will consist of:</p>
      <ul>
        <li>A mystery prize with a value of at least $100,000; and</li>
        <li>$10,000 donation to the charity of winner's choice</li>
      </ul>
      <p>
        Promotion swag, Promotion celebration events, and miscellaneous bonus prizes will also be
        given to various entrants selected at Sponsor’s discretion with a total aggregate
        approximate value of at least $165,000.
      </p>
      <p>
        <strong>TOTAL ARV OF ALL PRIZES: </strong>$3,000,000. Odds of winning depend on the number
        of eligible entries received during the Promotion Period and the relative skill of the
        participants.
      </p>
      <h5>
        <strong>7. PRIZE NOTIFICATION AND TERMS</strong>
      </h5>
      <p>
        Potential winners will be notified via email sent within seven (7) days following the end of
        the applicable scoring period. It is the sole responsibility of each entrant to notify the
        Sponsor if his or her e-mail address has changed.
      </p>
      <p>
        All potential winning entries are subject to verification by Sponsor. The decisions of
        Sponsor shall be final and binding regarding the outcome, scoring, and bet accuracy.
        Potential winner may be required to show proof of identification, and Sponsor may require
        that the potential winner provide proof that his/her identification matches the user
        information associated with the winning entry. Sponsor reserves the right to conduct a
        background check concerning any potential winner, including without limitation checking
        publicly available information contained in civil and criminal court records, police
        reports, and social media. To the extent necessary under law, potential winner shall
        authorize such background check and acknowledge that Sponsor has a legitimate business need
        for such background check. Sponsor may also take all steps necessary to corroborate any
        information provided to Sponsor by winner. In that regard, winner will be obligated to
        provide necessary contacts and information (including but not limited to full legal name,
        home address, telephone number and date of birth) so that Sponsor may conduct such
        background check and internal vetting. Sponsor reserves the right, in its sole and absolute
        discretion, to disqualify any person for all or any portion of the contest for any reason,
        including without limitation the results of any background check or internal vetting, a
        determination that a participant is in violation of these rules, or a determination that a
        participant is in any way damaging to the image or reputation of Sponsor.
      </p>
      <p>
        Prizes may not be transferred or assigned. No cash or other substitution may be made, except
        by the Sponsor, who reserves the right to substitute a prize in whole or in part with
        another prize of comparable or greater value if the intended prize is not available for any
        reason, or if distribution of the intended prize to winner is not possible for any reason,
        as determined by the Sponsor in its sole discretion. The prize winner will be responsible
        for all other fees and expenses related to accepting and/or using the prizes not listed in
        these Rules. The prize winner will be responsible for all taxes (including but not limited
        to federal, state, local and/or income) on the prize. Winner will be issued an IRS 1099-MISC
        tax form for the prize retail value, if applicable. No warranty or guarantee will be given
        by Sponsor with respect to any prize. Any and all guarantees and warranties are subject to
        the manufacturer's or service provider's terms and conditions, and the winners agree to look
        solely to such entity for any such warranty or guarantee claim.
      </p>
      <p>
        As a condition of receiving a prize, each potential winner may be required to complete, sign
        and return an affidavit of eligibility, a liability release, and, except where prohibited, a
        publicity release, within fifteen (15) days of the date stated on the prize notice. If a
        prize is refused, if a prize winner does not timely return the necessary releases, if a
        prize notification is returned or deemed undeliverable, or if the potential winner is
        disqualified, an alternate winner will be selected. Except where prohibited, acceptance of
        the prize constitutes each winner's consent to Sponsor's use of winner's name, likeness,
        voice, opinions and biographical information for publicity, advertising, trade or
        promotional purposes in any media or manner, now known or hereafter devised, worldwide,
        without further payment, consideration, notice or approval.
      </p>
      <p>
        The charitable donation prizes will be made in Sponsor's name to a charity of winner's
        choice. Selection of charity is subject to approval by Sponsor in its sole and absolute
        discretion, and may also be subject to charity's approval of receipt of the donation. If an
        acceptable charity cannot be identified and cleared to accept the donation within three (3)
        weeks of initial selection by winner, the donation may be made to a charity of Sponsor's
        choice.
      </p>
      <h5>
        <strong>8. GENERAL CONDITIONS/RELEASE.</strong>
      </h5>
      <p>
        By entering or participating in the Promotion, entrants waive the right to claim any
        ambiguity or error in these Rules or in the Promotion itself. By entering or participating,
        entrants agree to (a) release Promotion Entities, their respective directors, officers,
        agents and employees from all liability for any claims, costs, injuries, losses or damages
        of any kind, in whole or in part, caused by (i) entrant’s participation, including the
        unauthorized or illegal access to personally identifiable or sensitive information or the
        acceptance, possession, use, or misuse of the prize, (ii) any Promotion Entities’
        sponsorship or administration of the Promotion, and (iii) entrants’ and/or any third party’s
        use, sale or disposal of the prize; (b) acknowledge that said parties have neither made nor
        are in any manner responsible or liable for any warranty, representation or guarantee,
        expressed or implied, in fact or in law, relative to any prize, or the actual or perceived
        fairness of the Promotion. Neither Promotion Entities nor any service providers are
        responsible for incorrect or inaccurate transcription of entry information, or for any human
        or other error, technical malfunctions, lost/delayed data or voice transmission, omission,
        interruption, deletion, defect, line failures of any telephone network, computer equipment,
        software, inability to access any online service or website or to complete a telephone call
        or facsimile transaction, or any other error or malfunction, or late, lost or misdirected
        mail, or any injury or damage to participant’s or any other person’s computer related to or
        resulting from participation in this Promotion. Winners must meet all local laws and
        regulations. Winners assume all liability for any injury, death, or damage caused, or
        allegedly caused, in whole or in part, by participating in this Promotion or receipt, use,
        or redemption of any prize. CAUTION: ANY ATTEMPT BY AN ENTRANT OR ANY OTHER INDIVIDUAL TO
        DELIBERATELY DAMAGE ANY WEBSITE, TAMPER WITH THE ENTRY PROCESS, OR OTHERWISE UNDERMINE THE
        LEGITIMATE OPERATION OF THE PROMOTION MAY BE A VIOLATION OF CRIMINAL AND CIVIL LAWS AND
        SHOULD SUCH AN ATTEMPT BE MADE, SPONSOR RESERVES THE RIGHT TO COOPERATE IN THE PROSECUTION
        OF ANY SUCH INDIVIDUAL(S) AND TO PURSUE ALL REMEDIES TO THE FULLEST EXTENT PERMITTED BY LAW.
      </p>
      <p>
        Entrants may not access the confidential information associated with any Property, or
        contact the seller or broker or any of their representatives, except in connection with a
        good faith, legitimate interest in participating in the Property auction.
      </p>
      <p>
        Failure to enforce any term of these Rules shall not constitute a waiver of that provision.
        If for any reason the Promotion is not capable of being executed as planned, or infection by
        computer virus, bugs, tampering, unauthorized intervention, fraud, action of entrants,
        technical failures or any other causes which in the opinion of Sponsor, corrupt or affect
        the administration, security, fairness, integrity, or proper conduct of this Promotion,
        Sponsor reserves the right at its sole discretion to disqualify any suspect entry or entrant
        and/or to cancel, terminate, modify or suspend the Promotion. If Sponsor reconvenes the
        Promotion after a suspension, determining the prize winner will be made, from among all
        eligible, non-suspect entries received as of the date of the suspension, as Sponsor
        determines in its sole discretion. Entries or entrants not complying with all rules are
        subject to disqualification.
      </p>
      <h5>
        <strong>9. DISPUTES/CHOICE OF LAW. </strong>
      </h5>
      <p>
        Except where prohibited, each entrant agrees that (a) Delaware law, without reference to
        such state’s choice of law rules, governs the Promotion and all aspects related thereto, (b)
        any and all disputes, claims and causes of action arising out of or connected with the
        Promotion or any prize awarded shall be resolved individually, without resort to any form of
        class action, and exclusively by state or federal courts situated in Delaware; (c) any and
        all claims, judgments and awards shall be limited to actual out-of-pocket costs incurred,
        but in no event attorneys' fees; and (d) no punitive, incidental, special, consequential or
        other damages, including without limitation lost profits may be awarded, and each entrant
        hereby waives any rights to such damages.
      </p>
      <h5>
        <strong>10. PRIVACY. </strong>
      </h5>
      <p>
        Personally identifiable information that is submitted by entrants as part of the Promotion
        will be treated in accordance with Sponsor's privacy policy accessible on its website at
        www.costar.com. Each entry submitted becomes the sole property of Sponsor and may be used
        for any purpose in Sponsor’s sole discretion.
      </p>
      <h5>
        <strong>11. ADDRESS; COPYRIGHT. </strong>
      </h5>
      <p>
        Sponsor is responsible for the Promotion and awarding of prizes. Sponsor’s address is 17600
        Laguna Canyon Road, Irvine, CA 92618. The Promotion and all accompanying materials are
        Copyright © 2023 Ten-X, LLC. All rights reserved.
      </p>
      <h5>
        <strong>12. WINNER LIST. </strong>
      </h5>
      <p>
        The Promotion winners will appear at battleofthebids.com for at least two months following
        the Promotion Period.
      </p>
    </>
  );
};
