import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { PlayerDomainInterfacesService } from "../player-api-client";

export type FeatureFlagType = {
  featureFlags: {
    [key: string]: boolean | undefined;
  };
};

export const FeatureFlagContext = React.createContext<FeatureFlagType>({ featureFlags: {} });

export const FeatureFlagProvider = (props: React.PropsWithChildren<{}>) => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagType>({ featureFlags: {} });

  const flags = [
    "fe.bob-public-season",
    "fe.bob-icsc-show",
    "fe.bob-show-login",
    "fe.Bob-show-watch-now",
    "fe.bob-show-player-count",
    "fe.bob-show-jets",
    "fe.bob-end-of-season"
  ];

  type FeatureFlagsResponse = {
    data: {
      [key: string]: boolean | undefined;
    };
  };

  const fetchFeatureFlags = async (): Promise<FeatureFlagsResponse | undefined> => {
    const response = await PlayerDomainInterfacesService.apiTenFeaturFlagPost(flags);
    return response as FeatureFlagsResponse;
  };

  useEffect(() => {
    const fetch = async () => {
      const fetchedFlags = await fetchFeatureFlags();
      if (fetchedFlags) {
        const updatedFeatureFlags = {
          feBobPublicSeason: fetchedFlags.data["fe.bob-public-season"],
          feBobIcscShow: fetchedFlags.data["fe.bob-icsc-show"],
          feBobShowLogin: fetchedFlags.data["fe.bob-show-login"],
          feBobShowWatchNow: fetchedFlags.data["fe.Bob-show-watch-now"],
          feBobShowJets: fetchedFlags.data["fe.bob-show-jets"],
          feBobShowPlayerCout: fetchedFlags.data["fe.bob-show-player-count"],
          feBobEndOfSeason: fetchedFlags.data["fe.bob-end-of-season"],
        };
        setFeatureFlags({ featureFlags: updatedFeatureFlags });
      }
    };
    fetch();
  }, []);

  return (
    <FeatureFlagContext.Provider
      value={featureFlags}
    >
      {props.children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlagContext = () => {
  const context = useContext(FeatureFlagContext);
  if (!context) {
    throw new Error("useFeatureFlag must be used within the FeatureFlagProvider");
  }
  return context;
};
