import React, { useEffect, useState } from "react";
import classNames from "classnames";
import "../../content/styles/marketing/marketing.scss";
import morePlayers from "../../content/images/marketing/more-players.svg";
import biggerPrizes from "../../content/images/marketing/bigger-prizes.svg";
import newfeatures from "../../content/images/marketing/new-game-features.svg";
import moreWins from "../../content/images/marketing/more-ways-to-win.svg";
import watchLive from "../../content/images/marketing/watch-it-live.svg";
import { NavLink } from "react-router-dom";
import { PageView } from "../../common/constants";
import moment from "moment";
import { ContestStatus } from "../../types/contest";
import { buildGamePageRoute } from "../../helpers/game-route-builder";
import { useUserContext } from "../../common/user-context";
import { useRoundContext } from "../../common/round-context";
import { useContestContext } from "../../common/contest-context";
import { MessageModalData } from "../../types/message-modal-data";
import { Countdown, getCountDown } from "../../helpers/date-helpers";
import { LoginModal } from "../login-signup/login";
import { GameScheduleModal } from "../common/game-schedule-modal";

type Props = {
  showLeaderboard: boolean;
  setTheMessageData: any;
  showTheMessageModal: () => void;
  isBettingOpened: boolean;
  currentPageIndex?: number;
  isPublicUser: boolean;
  feBobShowJets?: boolean;
};

export const HeroSection: React.FC<Props> = ({
  setTheMessageData,
  showTheMessageModal,
  isBettingOpened,
  currentPageIndex,
  isPublicUser,
  feBobShowJets
}) => {
  const { round } = useRoundContext();
  const { userInfo, isAuthenticated } = useUserContext();
  const { contest, status } = useContestContext();
  const formatDateRange = () => {
    const startDateMonth = `${moment(contest?.localStartDate).format("MMMM")}`;
    const startDateDay = moment(contest?.localStartDate).format("DD");
    const endDateMonth = `${moment(contest?.localEndDate).format("MMMM")}`;
    const endDateDay = moment(contest?.localEndDate).format("DD");

    if (startDateMonth === endDateMonth) {
      return `${startDateMonth} ${startDateDay}-${endDateDay}`;
    } else {
      return `${startDateMonth} ${startDateDay}-${endDateMonth} ${endDateDay}`;
    }
  };

  const [isCountdownLoaded, setIsCountdownLoaded] = useState(false);
  const [countdown, setCountdown] = useState<Countdown>();

  const showPlayButton = !isAuthenticated;

  let chosenRound = contest;
  if (!contest) {
    chosenRound = round;
  }
  let staticDate = isPublicUser ? "September 8, 2023" : undefined;

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showGameSchedule, setShowGameSchedule] = useState(false);

  const handleLoginModal = () => {
    setShowLoginModal(!showLoginModal);
    document.body.classList.add("modal-active");
  };

  useEffect(() => {
    const publicRound = new Date("Wed Sept 7 2023 21:00:00 GMT-0700 (Pacific Daylight Time)");

    var targetLocalDate;
    if (status === ContestStatus.NotStarted && isPublicUser === false) {
      targetLocalDate = chosenRound?.localStartDate;
    } else {
      targetLocalDate = chosenRound?.localEndDate;
    }
    let countDownDate = isPublicUser ? publicRound : targetLocalDate;

    const interval = setInterval(() => {
      if (countDownDate !== undefined && countDownDate !== null)
        setCountdown(getCountDown(countDownDate));
      if (!isCountdownLoaded) setIsCountdownLoaded(true);
    }, 1000);

    return () => clearInterval(interval);
  }, [
    isCountdownLoaded,
    chosenRound?.localEndDate,
    chosenRound?.localStartDate,
    status,
    isPublicUser
  ]);

  const playVideo = () => {
    const messageData: MessageModalData = {
      message: undefined,
      messageTitle: undefined,
      videoUrl: "https://www.youtube.com/embed/V2LNpxSbsCE?rel=0"
    };
    setTheMessageData(messageData);
    showTheMessageModal();
  };

  const handleGameSchedule = () => {
    setShowGameSchedule(true);
  };

  const hideGameScheduleModal = () => {
    setShowGameSchedule(false);
  };

  return (
    <>
      {showLoginModal && (
        <div className="modal-wrapper active">
          <LoginModal loginSignupModal="" setLoginSignupModal={handleLoginModal} />
        </div>
      )}

      {showGameSchedule && <GameScheduleModal hideModal={hideGameScheduleModal} />}
      <div
        className="hero-block hero-image-container hero-background"
        id="hero-block__video"
        style={{ position: "relative" }}
      >
        <div
          className={classNames({
            "d-lg-block": true,
            "header-bob-section": true,
            "hero-image-content": true,
            "with-jets": feBobShowJets
          })}
        ></div>
        <section id="cs-hero">
          <div
            id="hero-content-"
            className={classNames({
              "hero-image-logo": true,
              "with-jets": feBobShowJets
            })}
          >
            <div className="bob-top container max-1920 pt-0 pb-0">
              <div className="row g-0 hero-content-wrapper">
                <div className="bob-logo-bg">
                  {feBobShowJets && (
                    <div
                      className={classNames({
                        "open-video-modal-button": true,
                        "video-circle": true,
                        "with-jets": feBobShowJets
                      })}
                    >
                      <>
                        <div className="play-button">
                          <button className="open-video-button" onClick={() => playVideo()}>
                            <span className="arrow-button">▶</span>
                          </button>
                        </div>
                        <div onClick={() => playVideo()} className="play-text">
                          Play Video
                        </div>
                      </>
                    </div>
                  )}

                  <div className="col-lg-12 header-section bob-info-block">
                    <div className="hero-bullet-container">
                      <div className="hero-bullet">
                        <img src={morePlayers} alt="More Players" />
                        <span className="bullet-text">More players</span>
                      </div>
                      <div className="hero-bullet">
                        <img src={biggerPrizes} alt="Bigger Prizes" />
                        <span className="bullet-text">Bigger prizes</span>
                      </div>
                      <div className="hero-bullet">
                        <img src={newfeatures} alt="New Game Features" />
                        <span className="bullet-text">New game features</span>
                      </div>
                      <div className="hero-bullet ">
                        <img src={moreWins} alt="More ways to win" />
                        <span className="bullet-text">More ways to win</span>
                      </div>
                      <div className="hero-bullet ">
                        <img src={watchLive} alt="Watch Live" />
                        <span className="bullet-text">Watch the action</span>
                      </div>
                    </div>
                    <div className="hero-text-block">
                      Predict the final sale price of properties in Ten-X's online auctions for a
                      chance to win hundreds of thousands of dollars and the{" "}
                      <span className="small-gradient-text"> $1,000,000</span> grand prize. With
                      only 6 rounds this season, we've made it faster to win. <br />
                      Ready to play? Buckle up for takeoff!
                    </div>
                  </div>
                </div>
                {(!(
                  countdown?.days === 0 &&
                  countdown?.hours === 0 &&
                  countdown?.minutes === 0 &&
                  countdown?.seconds === 0
                ) ||
                  status === ContestStatus.Ended) && (
                  <>
                    <div id="hero-ticker" className="col-lg-10 header-section round-block">
                      <div className="row header-countdown">
                        <div
                          className={classNames(
                            "col-lg-3 white-bg  hero-count-down first-col-min limited-column date-start-text-desktop",
                            {
                              "first-col-min": showPlayButton,
                              "first-col-max": !showPlayButton
                            }
                          )}
                        >
                          <span className="hero-count-text">
                            {staticDate ? "Season 2" : contest?.name}
                            {status === ContestStatus.NotStarted || staticDate
                              ? " betting closes:"
                              : " in progress:"}
                          </span>
                          <br />
                          {staticDate ? staticDate : formatDateRange()}
                        </div>
                        <div className="date-start-text">
                          <span className="hero-count-text">
                            {staticDate ? "Season 2" : contest?.name}
                            {status === ContestStatus.NotStarted || staticDate
                              ? " betting closes:"
                              : " in progress:"}
                          </span>{" "}
                          {staticDate ? staticDate : formatDateRange()}
                        </div>
                        <div
                          className={`col-lg-${
                            showPlayButton ? `6` : `9`
                          } white-bg second-col countdown-text-container`}
                        >
                          <div className="row">
                            <div
                              className={classNames("col-4 borderdivider", {
                                "count-down-box": !showPlayButton
                              })}
                            >
                              <div className="large-gradient-text ">
                                {countdown?.days && countdown?.days < 10
                                  ? "0" + countdown?.days
                                  : countdown?.days || 0}
                              </div>
                              <span className="counter-time-text">DAYS</span>
                            </div>
                            <div
                              className={classNames("col-4 borderdivider", {
                                "count-down-box": !showPlayButton
                              })}
                            >
                              <div className="large-gradient-text ">
                                {countdown?.hours && countdown?.hours < 10
                                  ? "0" + countdown?.hours
                                  : countdown?.hours || 0}
                              </div>
                              <span className="counter-time-text">HRS</span>
                            </div>
                            <div
                              className={classNames("col-4", {
                                "count-down-box": !showPlayButton
                              })}
                            >
                              <div className="large-gradient-text">
                                {countdown?.minutes && countdown?.minutes < 10
                                  ? "0" + countdown?.minutes
                                  : countdown?.minutes || 0}
                              </div>
                              <span className="counter-time-text">MINS</span>
                            </div>
                          </div>
                        </div>
                        {showPlayButton && (
                          <>
                            <div
                              className="col-lg-3 red-bg text-center game-entry"
                              onClick={handleLoginModal}
                            >
                              GET IN THE GAME
                            </div>
                            <div
                              className="col-lg-3 bg-sign-up text-center game-entry"
                              onClick={handleLoginModal}
                            >
                              SIGN UP
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    <div id="game-schedule-container" className="header-section">
                      <button className="game-schedule-btn" onClick={handleGameSchedule}>
                        See full game schedule &#8594;
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
