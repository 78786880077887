import { useContext, createContext, useState } from "react";
import { useContestContext } from "./contest-context";
import { useUserContext } from "./user-context";
import { AppConfig } from "./app_config";
import useWebSocket from "react-use-websocket";

export type BroadcastContextType = {
  leaderboardChanged: string;
};

export const BroadcastContext = createContext<BroadcastContextType>(null!);

export const BroadcastContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { contest } = useContestContext();
  const { userInfo } = useUserContext();
  const [leaderboardChanged, setLeaderboardChanged] = useState("");
  const WS_URL =
    AppConfig.WSS_ENDPOINT + "?contestId=" + contest?.contestId + "&playerId=" + userInfo?.playerId;

  useWebSocket(WS_URL, {
    share: true,
    onOpen: () => {
      console.log("WebSocket connection established.");
    },
    onClose: () => {
      console.log("The connection was closed.");
    },
    onError: () => {
      console.log("connection errored out");
    },
    onMessage: (evt) => {
      if (evt.data !== "PONG") {
        let curTime = Date.now().toString();
        console.log("processing broadcast message " + evt.data + curTime);
        setLeaderboardChanged(evt.data + curTime);
      }
    },
    shouldReconnect: (closeEvent) => true
  });

  return (
    <BroadcastContext.Provider value={{ leaderboardChanged }}>
      {props.children}
    </BroadcastContext.Provider>
  );
};

export const useBroadcastContext = () => {
  const context = useContext(BroadcastContext);
  if (!context) {
    throw new Error("useBroadcastContext must be used within the BroadcastContextProvider");
  }
  return context;
};
