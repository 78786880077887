import React, { ReactNode, useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import Caret from "../../content/images/caret.svg";
import classnames from "classnames";

interface AccordionProps {
  children: ReactNode;
  title: string;
  hrefs: Array<string>;
}

const Accordion: React.FC<AccordionProps> = ({ children, title, hrefs }) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    history.listen(() => {
      setOpen(false);
    });
  }, []);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div className="bob-accordion">
      <div onClick={handleClick}>
        <div className="nav-link mobile-link">
          <span className={classnames({ selected: hrefs.indexOf(location.pathname) >= 0 })}>
            {title}{" "}
          </span>
          <img
            className={classnames({
              "caret-image": true,
              active: open
            })}
            src={Caret}
          />
        </div>
        {open && <div className="content-container">{children}</div>}
      </div>
    </div>
  );
};

export default Accordion;
