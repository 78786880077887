export const HEADER = {
  WELCOME: "WELCOME TO THE GAME",
  GET_IN_THE_GAME: "LET'S GET YOU IN THE GAME!",
  JOIN_MAILING: "WE ARE SORRY!",
  GET_INVITED: "GET ON THE LIST TO PLAY",
  CREATE_ACCOUNT: "CREATE AN ACCOUNT"
};

export const MODAL_CONTENT = {
  WELCOME: "Please fill in the form below to register",
  GET_IN_THE_GAME:
    "Please tell us more about your commercial real estate (CRE) background.  Have you owned or sold a CRE property in the last three years?",
  JOIN_MAILING:
    "Unfortunately, you are not eligible to participate in the game this season. To stay up to date please join our mailing list.",
  GET_INVITED: "Please submit the following information:"
};
