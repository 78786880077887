declare var clarity: Function;

export enum ClarityTagKey {
  CaSubjectId = "ca_subject_id",
  ContestId = "contest_id",
  SubjectId = "subject_id"
}

/**
 * Set a custom tag in Clarity.
 * @param key: the custom tag key
 * @param value: the custom tag value
 * @see https://docs.microsoft.com/en-us/clarity/custom-tags
 */
export function setClarityTag({
  key,
  value
}: {
  key: ClarityTagKey;
  value: string | null | undefined;
}) {
  if (typeof window !== undefined && typeof clarity === "function" && value) {
    clarity("set", key, value);
  }
}
