import React from "react";
import ReactTooltip from "react-tooltip";

type IPotentialWinnings = {
  round?: number | undefined | null;
};

type IPointsCalculated = {
  contestStatus?: boolean;
  contestName?: string | undefined | null;
};

type IAccuracyCalculated = {
  isSeasonList: boolean;
  contestName?: string;
};

export const BonusPoints = () => {
  return (
    <ReactTooltip
      id="bonuspoints"
      globalEventOff="mousedown"
      border={true}
      textColor="#000"
      backgroundColor="#fff"
      className="points-tooltip-wrapper"
      place="left"
      overridePosition={({ left, top }, currentEvent, currentTarget, node) => {
        console.log(currentEvent.type, currentTarget.dispatchEvent);
        const d = document.documentElement;
        left = Math.min(d.clientWidth - node!.clientWidth, left);
        top = Math.min(d.clientHeight - node!.clientHeight, top);
        left = Math.max(0, left);
        top = Math.max(0, top);
        return { top, left };
      }}
    >
      <div className="points-calculated-tooltip">
        <h6>
          <b>Bonus Points</b>
        </h6>
        <p style={{ whiteSpace: "normal" }}>
          Bonus points awarded for winners who post winnings on social media.
        </p>
      </div>
    </ReactTooltip>
  );
};

export const PotentialWinnings = (props: IPotentialWinnings) => {
  return (
    <ReactTooltip
      id="potential-winnings"
      globalEventOff="mousedown"
      textColor="#000"
      backgroundColor="#fff"
      className="points-tooltip-wrapper"
      place="left"
      overridePosition={({ left, top }, currentEvent, currentTarget, node) => {
        console.log(currentEvent.type, currentTarget.dispatchEvent);
        const d = document.documentElement;
        left = Math.min(d.clientWidth - node!.clientWidth, left);
        top = Math.min(d.clientHeight - node!.clientHeight, top);
        left = Math.max(0, left);
        top = Math.max(0, top);
        return { top, left };
      }}
    >
      <div className="points-calculated-tooltip">
        <h6>
          <b>Round {props.round}</b>
        </h6>
        <p style={{ whiteSpace: "normal" }}>
          This column shows the prize you would earn if the round ended now. Actual prize awarded
          may change up or down until the round is over.
        </p>
      </div>
    </ReactTooltip>
  );
};

export const AccuracyCalculated = ({ isSeasonList, contestName }: IAccuracyCalculated) => {
  return (
    <ReactTooltip
      id="accuracycalculated"
      globalEventOff="mousedown"
      border={true}
      textColor="#000"
      backgroundColor="#fff"
      className="points-tooltip-wrapper"
      place="bottom"
      overridePosition={({ left, top }, currentEvent, currentTarget, node) => {
        console.log(currentEvent.type, currentTarget.dispatchEvent);
        const d = document.documentElement;
        left = Math.min(d.clientWidth - node!.clientWidth, left);
        top = Math.min(d.clientHeight - node!.clientHeight, top);
        left = Math.max(0, left);
        top = Math.max(0, top);
        return { top, left };
      }}
    >
      <div className="bet-accuracy-tooltip">
        <h6>
          {" "}
          <b>{isSeasonList ? "Season Accuracy" : contestName}</b>
        </h6>
        <p style={{ whiteSpace: "normal" }}>
          Average of accuracy based on the number of valid bets made.
        </p>
      </div>
    </ReactTooltip>
  );
};

export const PointsCalculated = ({ contestStatus, contestName }: IPointsCalculated) => {
  return (
    <ReactTooltip
      id="pointscalculated"
      globalEventOff="mousedown"
      textColor="#000"
      border={true}
      backgroundColor="#fff"
      className="points-tooltip-wrapper"
      place="left"
      overridePosition={({ left, top }, currentEvent, currentTarget, node) => {
        console.log(currentEvent.type, currentTarget.dispatchEvent);
        const d = document.documentElement;
        left = Math.min(d.clientWidth - node!.clientWidth, left);
        top = Math.min(d.clientHeight - node!.clientHeight, top);
        left = Math.max(0, left);
        top = Math.max(0, top);
        return { top, left };
      }}
    >
      <div className="points-calculated-tooltip">
        <h6>
          <b>{contestName}</b>
        </h6>
        <p style={{ whiteSpace: "normal" }}>
          {contestStatus
            ? "The number of points awarded for the round."
            : "This column shows the points you would earn if the round ended now. Actual points awarded may change up or down until the round is over."}
        </p>
      </div>
    </ReactTooltip>
  );
};

export const ToolTipButton = ({ dataFor }: any) => {
  return (
    <button
      data-tip="0"
      data-for={dataFor}
      data-event="click"
      className="info-i"
      style={{
        backgroundColor: "transparent",
        color: "#000",
        fontWeight: "bold",
        border: "1px solid #000",
        fontSize: "10px",
        borderRadius: "50%",
        position: "relative",
        top: "-12px",
        zIndex: 0
      }}
    >
      i
    </button>
  );
};
