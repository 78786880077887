import React, { useState, useEffect } from "react";
import { HEADER, MODAL_CONTENT } from "./constants";
import { PlayerDomainInterfacesService } from "../../../player-api-client";

interface FormState {
  firstName: string;
  lastName: string;
  company: string;
  email: string;
}

type Props = {
  currentEmail: string;
};

export const Welcome: React.FC<Props> = ({ currentEmail }) => {
  const initialFormState: FormState = {
    firstName: "",
    lastName: "",
    company: "",
    email: currentEmail
  };

  const initialFormErrors: FormState = {
    firstName: "",
    lastName: "",
    company: "",
    email: ""
  };

  const [state, setState] = useState<FormState>(initialFormState);
  const [errors, setErrors] = useState<FormState>(initialFormErrors);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (currentEmail) {
      setState({ ...state, email: currentEmail });
    }
  }, [currentEmail]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    localStorage.setItem("intialLogin", "INITIAL_LOGIN");
    event.preventDefault();

    let hasErrors = false;
    const updatedErrors: FormState = { ...initialFormErrors };

    // Validation rules
    if (!state.firstName) {
      updatedErrors.firstName = "First name is required";
      hasErrors = true;
    }

    if (!state.lastName) {
      updatedErrors.lastName = "Last name is required";
      hasErrors = true;
    }

    if (!state.email) {
      updatedErrors.email = "Email is required";
      hasErrors = true;
    } else if (!validateEmail(state.email)) {
      updatedErrors.email = "Invalid email format";
      hasErrors = true;
    }

    if (hasErrors) {
      setErrors(updatedErrors);
    } else {
      try {
        setLoading(true);

        const payload = {
          caSignUpPlayer: {
            email: state.email,
            givenName: state.firstName,
            familyName: state.lastName,
            company: state.company
          }
        };

        await PlayerDomainInterfacesService.apiPlayerSignUpPost(payload).then(async (res) => {
          const codePayload = {
            code: res?.caSignUpPlayer.code,
            url: window.location.href
          };
          await PlayerDomainInterfacesService.apiPlayerSignURLPost(codePayload).then((data) => {
            window.location.replace(data.startUrl);
          });
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const validateEmail = (email: string): boolean => {
    // Email validation regex pattern
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <div>
      <div className="login-modal-header">{HEADER.WELCOME}</div>
      <div className="login-modal-content">{MODAL_CONTENT.WELCOME}</div>
      <div className="login-modal-header">{HEADER.CREATE_ACCOUNT}</div>
      <div className="input-wrapper">
        <form onSubmit={handleSubmit}>
          <div className="input-wrapper">
            <input
              className="login-input-field"
              type="text"
              name="firstName"
              value={state.firstName}
              onChange={handleChange}
              placeholder="Your First Name"
            />
            {errors.firstName && <span className="error-message">{errors.firstName}</span>}
          </div>
          <div className="input-wrapper">
            <input
              className="login-input-field"
              type="text"
              name="lastName"
              value={state.lastName}
              onChange={handleChange}
              placeholder="Your Last Name"
            />
            {errors.lastName && <span className="error-message">{errors.lastName}</span>}
          </div>
          <div className="input-wrapper">
            <input
              className="login-input-field"
              type="text"
              name="company"
              value={state.company}
              onChange={handleChange}
              placeholder="Company Name"
            />
          </div>
          <div className="input-wrapper">
            <input
              className="login-input-field read-only"
              type="text"
              placeholder="Your Email Address"
              name="email"
              onChange={handleChange}
              value={state.email}
              disabled={true}
            />
            {errors.email && <span className="error-message">{errors.email}</span>}
          </div>
          <button className="button-continue" disabled={loading}>
            CONTINUE
          </button>
        </form>
      </div>
    </div>
  );
};
