import Auth from "@aws-amplify/auth";
import React, { useContext, useEffect, useState } from "react";
import { ClarityTagKey, setClarityTag } from "../helpers/clarity-helpers";
import { PlayerDomainInterfacesService as playerAPI } from "../player-api-client";
import { AppConfig } from "./app_config";
import { getGoogleId } from "../helpers/ga-helper";
import { getStorageRepo, StorageOption } from "../helpers/storage";
import { datadogRum } from "@datadog/browser-rum";
import { ELIGIBILITY } from "./constants";
import { PlayerDomainInterfacesService } from "../player-api-client";

export type CurrentPlayer = {
  player?: {
    contactId: number;
    eligibleRoundType: string;
    email: string;
    firstName: string;
    lastName: string;
    playerId: string;
    ruleAcceptanceDate: string;
    subjectId: string;
  };
};

export type UserContextValue = {
  loading?: boolean;
  isAuthenticated: boolean;
  signIn: () => Promise<void>;
  signInCA: (email: string) => Promise<void>;
  signOut: () => Promise<void>;
  userEligibiltiy: number;
  userEligibleRoundType: string;
  updateUserInfo: (updatedUserInfo: Partial<UserInfo>) => void;
  userInfo?: Partial<UserInfo>;
  currentPlayer: CurrentPlayer;
};

export type UserInfo = {
  caSubject: string;
  contactId: number;
  email: string;
  firstName: string;
  lastName: string;
  playerId: string;
  ruleAccepted: boolean;
  hasPrizeAddress: boolean;
  subject: string;
  addressDismissed: boolean;
  confettiDismissed: Date;
  profileImage: string;
  userLoggedIn: boolean;
};

const addressDismissedStorage = getStorageRepo<boolean>(
  "address_dismissed_storage",
  false,
  StorageOption.Local
);

const signIn = async () => {
  await Auth.federatedSignIn({
    customProvider: AppConfig.COGNITO_OIDC_PROVIDER
  })
    .then((cred) => {
      console.log(cred);
      return Auth.currentAuthenticatedUser();
    })
    .catch((e) => {
      console.log(e);
    });
};

const signInCA = async (email: string) => {
  const loginPayload = {
    email: email,
    url: window.location.href
  };
  try {
    let res = await PlayerDomainInterfacesService.apiPlayerLoginURLPost(loginPayload);
    window.location.replace(res.startUrl);
  } catch (err) {
    console.log("Error in login to CA");
    console.log(err);
  }
};

const signOut = async () => {
  if (localStorage.getItem("intialLogin") !== "RELOGIN") {
    localStorage.setItem("intialLogin", "");
  }
  try {
    await Auth.signOut();
    // this is for if we are running local env, in other env, should redirect automatically
    window.location.href = "/";
  } catch (err) {
    console.error(err);
  }
};

const mapUserInfo = (cognitoUser: any): Partial<UserInfo> | undefined => {
  const payload = cognitoUser?.signInUserSession?.idToken?.payload;
  const mappedUserInfo: Partial<UserInfo> | undefined = payload
    ? {
        caSubject: payload["custom:CASubject"],
        contactId: payload["custom:ContactID"],
        email: payload.email,
        firstName: payload.given_name,
        lastName: payload.family_name,
        subject: payload.sub,
        playerId: payload.player_id,
        ruleAccepted: String(payload.ruleAccepted).toLowerCase() === "true",
        hasPrizeAddress: payload.hasPrizeAddress || false,
        addressDismissed: payload.addressDismissed,
        confettiDismissed: payload.confettiDismissed,
        profileImage: payload.profileImage,
        userLoggedIn: true
      }
    : {
        userLoggedIn: false
      };
  return mappedUserInfo;
};

const initialContextValue = {
  signIn,
  signInCA,
  signOut,
  currentPlayer: {
    player: {
      contactId: 0,
      eligibleRoundType: "",
      email: "",
      firstName: "",
      lastName: "",
      playerId: "",
      ruleAcceptanceDate: "",
      subjectId: ""
    }
  },
  userEligibiltiy: 0,
  userEligibleRoundType: "NA",
  isAuthenticated: false,
  updateUserInfo: (_: Partial<UserInfo>) => {}
};
export const UserContext = React.createContext<UserContextValue>(initialContextValue);

export function UserContextProvider({ children }: React.PropsWithChildren<{}>) {
  const [userInfo, setUserInfo] = useState<Partial<UserInfo> | undefined>();
  const [userEligibiltiy, setUserEligibitly] = useState(0);
  const [userEligibleRoundType, setUserEligibleRoundType] = useState("NA");
  const [currentPlayer, setCurrentPlayer] = useState({});
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const firstAccountUse = localStorage.getItem("intialLogin") === "INITIAL_LOGIN";
    if (firstAccountUse) {
      localStorage.setItem("intialLogin", "LOGGING_IN");
      signIn();
    }
  }, []);

  useEffect(() => {
    const refreshUser = async () => {
      setLoading(true);
      const cognitoUser = await Auth.currentAuthenticatedUser()
        .then((user) => user)
        .catch((err) => {
          console.log(err);
          return undefined;
        });

      if (cognitoUser) {
        let currentUser = await playerAPI.apiPlayerCurrentGet();
        setCurrentPlayer(currentUser);
        if (
          currentUser &&
          typeof currentUser?.player?.addressWarningDismissalDate === "undefined"
        ) {
          addressDismissedStorage.set(false);
          cognitoUser.signInUserSession.idToken.payload.addressDismissed = false;
        } else {
          addressDismissedStorage.set(true);
          cognitoUser.signInUserSession.idToken.payload.addressDismissed = true;
        }
      }

      const refreshUserInfo = cognitoUser ? mapUserInfo(cognitoUser) : { userLoggedIn: false };
      if (refreshUserInfo?.email && cognitoUser) {
        const resp = await playerAPI.apiPlayerElegibility(refreshUserInfo?.email);
        const elgibility = ELIGIBILITY.findIndex((item) => item === resp.status.eligibleRoundType);
        setUserEligibitly(elgibility);
        setUserEligibleRoundType(resp.status.eligibleRoundType);
      }

      if (refreshUserInfo?.contactId) {
        datadogRum.setUser({
          id: refreshUserInfo?.contactId.toString() || "",
          email: refreshUserInfo?.email || ""
        });
      }
      setLoading(false);
      console.log("UserContext refreshUser", refreshUserInfo);
      setUserInfo(refreshUserInfo);
      setClarityTag({ key: ClarityTagKey.SubjectId, value: refreshUserInfo?.subject });
      setClarityTag({ key: ClarityTagKey.CaSubjectId, value: refreshUserInfo?.caSubject });
    };
    refreshUser();
  }, []);

  const updateUserInfo = (updatedUserInfo: Partial<UserInfo>) => {
    if (!!userInfo?.userLoggedIn) {
      setUserInfo(updatedUserInfo);
    }
  };

  return (
    <UserContext.Provider
      value={{
        loading,
        signIn,
        signInCA,
        signOut,
        currentPlayer,
        updateUserInfo,
        userEligibiltiy,
        userEligibleRoundType,
        userInfo,
        isAuthenticated: !!userInfo?.userLoggedIn
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("userUserContext must be used within the UserContextProvider");
  }
  return context;
}
