import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HighChartsAnnotate from "highcharts/modules/annotations";
import { Options } from "highcharts";
import { annotationLabel, lineAnnotation } from "./helper";

HighChartsAnnotate(Highcharts);

export interface DataPoint {
  x?: string;
  y?: number;
}

interface IProps {
  bettingData: Array<DataPoint>;
  currentBid: number;
  averageBet: number;
  marketGuid: number;
  maxNumBets: number;
}

const ColumnGraph = (props: IProps) => {
  const { bettingData, currentBid, averageBet, marketGuid, maxNumBets } = props;

  const categories: Array<string> = [];
  const data: Array<number> = [];
  bettingData.forEach((point) => {
    const { x, y } = point;
    categories.push(x ?? "");
    data.push(y ?? 0);
  });

  // Calculate other coordinates in one pass
  let maxNumBetsX = -1;
  let currentBidX = -1;
  let averageBetX = -1;
  let marketGuidX = -1;
  bettingData.forEach((point, index) => {
    const { x, y } = point;
    if (x && x.length > 0) {
      const [rangeMin, rangeMax] = x.slice(0, x.length - 1).split(" - ");

      const min = Number(rangeMin) * 1000000;
      const max = Number(rangeMax) * 1000000;

      if (currentBid >= min && currentBid < max) {
        currentBidX = index;
      }
      if (averageBet >= min && averageBet < max) {
        averageBetX = index;
      }
      if (marketGuid && marketGuid >= min && marketGuid < max) {
        marketGuidX = index;
      }
      if (y === maxNumBets) {
        maxNumBetsX = index;
      }
    }
  });

  let options: Options = {
    chart: {
      backgroundColor: "rgba(255,255,255,0)",
      plotBackgroundColor: "#2D394D",
      type: "area",
      scrollablePlotArea: {
        minWidth: 600
      },
      style: {
        color: "#FFFFFF"
      }
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0
      }
    },
    title: {
      text: ""
    },
    legend: {
      enabled: false
    },
    series: [
      {
        type: "column",
        name: "",
        color: "rgba(196, 196, 196, 0.25)",
        data,
        threshold: 0
      }
    ],
    xAxis: {
      title: {
        text: "Bet Amount",
        style: {
          color: "#FFFFFF",
          fontSize: "16px"
        }
      },
      labels: {
        style: {
          color: "#FFFFFF",
          fontSize: "14px"
        }
      },
      categories
    },
    yAxis: {
      title: {
        text: "Number of Bets",
        style: {
          color: "#FFFFFF",
          fontSize: "16px"
        }
      },
      labels: {
        style: {
          color: "#FFFFFF",
          fontSize: "14px"
        }
      },
      gridLineColor: "transparent",
      maxPadding: 0.2
    },

    credits: {
      enabled: false
    },
    annotations: [
      {
        draggable: "",

        shapes: [
          lineAnnotation("vertical", "#34D5DF", averageBetX),
          lineAnnotation("vertical", "#49F250", currentBidX),
          lineAnnotation("vertical", "#D2CD42", marketGuidX),
          lineAnnotation("horizon", "#DF1E2D", maxNumBets)
        ],
        labels: [annotationLabel("Max Number Of Bets", maxNumBetsX, maxNumBets)]
      }
    ]
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default ColumnGraph;
