export const REQUIRED_RULES_VERSION = "Thurs Aug 24 2023 15:04:29 GMT-0700 (Pacific Daylight Time)";

export const validateRulesAccepted = (rulesVersion: Date) => {
  const postVersionDate = new Date(REQUIRED_RULES_VERSION);
  postVersionDate.setDate(postVersionDate.getDate() - 1);

  const requiredVersion = new Date(REQUIRED_RULES_VERSION);
  const acceptedVersion = rulesVersion ? new Date(rulesVersion) : postVersionDate;

  if (requiredVersion > acceptedVersion) {
    return true;
  } else return false;
};
