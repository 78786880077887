import React, { useEffect, useState, useRef } from "react";
import "../content/styles/marketing/marketing.scss";
import { Footer } from "../components/footer/footer";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { PageView, Pages } from "../common/constants";
import { useContestContext } from "../common/contest-context";
import { useUserContext } from "../common/user-context";
import { ContestStatus } from "../types/contest";
import { LeaderBoard } from "../components/leaderboard/leaderboard";
import { useRoundContext } from "../common/round-context";
import { MessageModalData } from "../types/message-modal-data";
import { MessageModal } from "../components/common/message-modal";
import { SplashScreen } from "../components/splash/splash-screen";
import { ProtectedRoute } from "../components/common/protected-route";
import { ScoreBoardScreen } from "../containers/scoreboard-screen";
import EndOfSeasonHero from "../components/end-of-season-hero";
import {
  HeroSection,
  GameRules,
  Prizes,
  AboutTenX,
  Landing,
  HallOfFame,
  Help,
  WatchLive
} from "../components/marketing-views";
import { ELIGIBILITY_LEVEL, ELIGIBILITY } from "../common/constants";
import gtm from "../helpers/gtm-helper";
import { StorageOption, getStorageRepo } from "../helpers/storage";
import moment from "moment";

type Props = {
  isPublicUser: boolean;
  isICSCShow?: boolean;
  feBobShowJets?: boolean;
  feBobEndOfSeason?: boolean;
};

export const MarketingScreen: React.FC<Props> = ({
  isPublicUser,
  isICSCShow,
  feBobShowJets,
  feBobEndOfSeason
}) => {
  const { round } = useRoundContext();
  const { userEligibiltiy, isAuthenticated, userInfo } = useUserContext();
  const { contest, status } = useContestContext();
  const [isBettingOpened, setIsBettingOpened] = useState(false);

  const location = useLocation();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const utmCampaignId = params.get("utm_campaign_id");

  type UtmCampaignStorageData = {
    utm_campaign_id: string;
    date: Date;
  };

  const utmCampaignIdData: UtmCampaignStorageData = {
    utm_campaign_id: "",
    date: new Date()
  };

  const utmCampaignIdStorage = getStorageRepo(
    "utm_campaign_id",
    utmCampaignIdData,
    StorageOption.Local
  );
  const campaignId = utmCampaignIdStorage.get().utm_campaign_id;

  useEffect(() => {
    if (utmCampaignId && utmCampaignId !== campaignId) {
      utmCampaignIdStorage.set({
        utm_campaign_id: utmCampaignId,
        date: new Date()
      });
    }
  }, [utmCampaignId]);

  useEffect(() => {
    const currentDate = moment();
    const storageDate = moment(utmCampaignIdStorage.get().date);
    const daysDiff = currentDate.diff(storageDate, "days");

    if (daysDiff >= 7) {
      utmCampaignIdStorage.delete();
    }
  }, []);

  let chosenRound = contest;
  if (!contest) {
    chosenRound = round;
  }

  const roundType = ELIGIBILITY.findIndex((item) => item === round?.contestRoundType);
  const ACCESS = roundType >= userEligibiltiy && isAuthenticated;

  let countdownInterval: NodeJS.Timeout;

  let currentPage = location.pathname.substring(1, location.pathname.length);
  const excludedPages = new RegExp(/^game/);
  let currentPageIndex =
    currentPage && currentPage !== "error" && !currentPage.match(excludedPages)
      ? Pages.findIndex((item) => item[0] === currentPage)
      : 0;
  let nextIndex = currentPageIndex === Pages.length - 1 ? 0 : currentPageIndex + 1;

  useEffect(() => {
    return () => {
      clearInterval(countdownInterval);
      hideMessageModal();
    };
  }, [status, location]);

  /* Commented out for interim marketing. Will put back once new game goint to start. */
  // useEffect(() => {
  //   const showProperties = () => {
  //     showPropertiesPriorToOpeningBets().then((data) => {
  //       setIsBettingOpened(data!);
  //     });
  //   };
  //   showProperties();
  // }, []);

  const defaultMessageData: MessageModalData = {
    message: undefined,
    messageTitle: undefined,
    videoUrl: ""
  };

  const [theMessageData, setTheMessageData] = useState<MessageModalData>(defaultMessageData);
  const [showMessageModal, setShowMessageModal] = React.useState(false);

  const hideMessageModal = () => {
    setShowMessageModal(false);
    const messageData: MessageModalData = {
      message: undefined,
      messageTitle: undefined,
      videoUrl: ""
    };
    setTheMessageData(messageData);
  };

  const showTheMessageModal = () => {
    setShowMessageModal(true);
  };

  const isCampaign = location.pathname.includes("welcome") && location.pathname.includes("@");
  const isHeroPage =
    isCampaign ||
    location.pathname === PageView.Splash ||
    location.pathname === PageView.PropertySelectionPage ||
    location.pathname === `${PageView.Splash}/`; // Marketing campaign includes this extra slash will remove after campaign
    
    if(feBobEndOfSeason === undefined) {return null}
  return (
    <>
      <MessageModal isShown={showMessageModal} hideModal={hideMessageModal} data={theMessageData} />
      {isHeroPage &&
        (feBobEndOfSeason ? (
          <EndOfSeasonHero
            setMessageData={setTheMessageData}
            showTheMessageModal={showTheMessageModal}
          />
        ) : (
          <HeroSection
            showLeaderboard
            setTheMessageData={setTheMessageData}
            showTheMessageModal={showTheMessageModal}
            isBettingOpened={isBettingOpened}
            currentPageIndex={currentPageIndex}
            isPublicUser={isPublicUser}
            feBobShowJets={feBobShowJets}
          />
        ))}
      {/* CONTENT SECTION */}
      <section
        id="cs-section-content"
        className={location.pathname === PageView.LandingPage ? "landing-page" : ""}
      >
        <div>
          <div id="pagecontent" />
          <Switch>
            <Route path={[PageView.Splash, PageView.Welcome]}>
              <SplashScreen
                campaignId={campaignId}
                feBobShowJets={feBobShowJets}
                isCampaign={isCampaign}
                feBobEndOfSeason={feBobEndOfSeason}
              />
            </Route>
            <Route path={PageView.RulesPage}>
              <GameRules
                showTheMessageModal={showTheMessageModal}
                setMessageData={setTheMessageData}
                feBobShowJets={feBobShowJets}
              />
            </Route>
            <Route path={PageView.PrizesPage}>
              <Prizes feBobShowJets={feBobShowJets} />
            </Route>
            <Route path={`${PageView.WinnersList}`}>
              <LeaderBoard
                showTheMessageModal={showTheMessageModal}
                setMessageData={setTheMessageData}
                feBobShowJets={feBobShowJets}
              />
            </Route>
            <Route path={PageView.AboutTenX}>
              <AboutTenX campaignId={campaignId} />
            </Route>
            <Route path={PageView.HallOfFame}>
              <HallOfFame
                showTheMessageModal={showTheMessageModal}
                setMessageData={setTheMessageData}
                campaignId={campaignId}
              />
            </Route>
            <Route path={PageView.Help}>
              <Help feBobShowJets={feBobShowJets} />
            </Route>
            <Route path={PageView.WatchNow}>
              <WatchLive
                isAuthenticated={isAuthenticated}
                setMessageData={setTheMessageData}
                showTheMessageModal={showTheMessageModal}
                feBobShowJets={feBobShowJets}
              />
            </Route>
            <Route path="*">
              <Redirect to={PageView.Splash} />
            </Route>
          </Switch>
        </div>
      </section>
      {/* FOOTER SECTION */}
      <Footer feBobShowJets={feBobShowJets} />
    </>
  );
};
