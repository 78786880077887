import { ApiLeaderboardPlayerScore } from "../types/api-models";
import { ContestStatus } from "../types/contest";

/**
 * Map an ApiLeaderboardPlayerScore item.
 * @param item
 * @param round
 * @param status
 * @returns mapped ApiLeaderboardPlayerScore item.
 */
export function mapLeaderboardPlayerScore(
  item: ApiLeaderboardPlayerScore,
  round: number | null | undefined,
  status: ContestStatus | null | undefined
): ApiLeaderboardPlayerScore {
  // handle backend starts ranking at 0
  item.eventPointsRank = (item.eventPointsRank || 0) + 1;
  item.eventPointsGroupRank = (item.eventPointsGroupRank || 0) + 1;
  item.seasonPointsRank = (item.seasonPointsRank || 0) + 1;
  item.seasonPointsGroupRank = (item.seasonPointsGroupRank || 0) + 1;
  // handle initial round - suppress winnings if no points yet
  if (round === 1 && status !== ContestStatus.Ended && item.seasonPoints === 0) {
    item.eventWinnings = 0;
    item.seasonWinnings = 0;
  }

  return item;
}
