import { useEffect, useState } from "react";
import "../../content/styles/game/lottery-winner.scss";
import { lotteryTableData } from "../leaderboard/constants";
import Jets from "../../content/images/marketing/costarF35.png";
import { useContestContext } from "../../common/contest-context";
import { PlayerDomainInterfacesService } from "../../player-api-client/services/PlayerDomainInterfacesService";
import classNames from "classnames";

interface ISeasonBonusDrawWinner {
  round?: string;
  playerName?: string;
  company?: string;
  winnings?: string;
}
interface ISeasonBonusDrawWinnerResponse {
  seasonId?: string;
  bonusDrawWinners?: ISeasonBonusDrawWinner[];
}

const LotteryWinners = ({ feBobShowJets }: { feBobShowJets?: boolean }) => {
  return (
    <div id="lottery-container">
      <h2 className="section-title">BONUS DRAW WINNERS</h2>
      <div
        className={classNames({
          "leaderboard-table-container": true,
          "with-jets": feBobShowJets
        })}
      >
        <LotteryTable />
        {feBobShowJets ? (
          <img className="jets" src={Jets} />
        ) : (
          <div className="what-is-content-container">
            <h2>WHAT IS BONUS PRIZE DRAWING?</h2>
            <p>
              Introducing the new player participation prize! After each round, starting after Round
              2, one lucky player who completed all ten bets and has played all previous rounds will
              be eligible to win a prizes of $10,000 and $25,000.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

const LotteryTable = () => {
  const { contest, setGotBonusDrawData } = useContestContext();
  const [bonusDrawWinners, setBonusDrawWinners] = useState<ISeasonBonusDrawWinnerResponse>();

  useEffect(() => {
    const getBonusDrawWinners = async () => {
      try {
        setGotBonusDrawData(false);
        const bonusDrawWinners = await PlayerDomainInterfacesService.apiSeasonBonusDrawWinners(
          contest?.seasonId
        );
        setBonusDrawWinners(bonusDrawWinners);
        setGotBonusDrawData(true)
      } catch (error) {
        console.log(error);
      }
    };

    getBonusDrawWinners();
  }, [contest]);

  return (
    <table>
      <thead>
        <tr>
          <th className="round-header">Round</th>
          <th className="player-header">Player</th>
          <th className="company-header">Company</th>
          <th className="winnings-header">Winnings</th>
        </tr>
      </thead>
      <tbody>
        {bonusDrawWinners?.bonusDrawWinners &&
          bonusDrawWinners?.bonusDrawWinners.map((winner, index) => (
            <tr key={index} className="table-row">
              <td className="round-cell">{winner.round}</td>
              <td className="player-cell">{winner.playerName}</td>
              <td className="company-cell">{winner.company}</td>
              <td className="winnings-cell">{winner.winnings}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default LotteryWinners;
