import { useRef } from "react";
import { useEffect } from "react";
import "../../content/styles/common/confetti-cannon.scss";

type ConfettiCannonProps = {
  maxParticleCount: number;
  particleSpeed: number;
  colors?: Array<string>;
};

export const ConfettiCannon = (props: ConfettiCannonProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const {
    maxParticleCount,
    particleSpeed,
    colors = [
      "DodgerBlue",
      "OliveDrab",
      "Gold",
      "Pink",
      "SlateBlue",
      "LightBlue",
      "Violet",
      "PaleGreen",
      "SteelBlue",
      "SandyBrown",
      "Chocolate",
      "Crimson"
    ]
  } = props;

  useEffect(() => {
    let animationTimer: any = null;
    let waveAngle = 0;
    let particles: any = [];

    const drawParticles = (context: any) => {
      let particle;
      let x;
      for (let i = 0; i < particles.length; i++) {
        particle = particles[i];
        context.beginPath();
        context.lineWidth = particle.diameter;
        context.strokeStyle = particle.color;
        x = particle.x + particle.tilt;
        context.moveTo(x + particle.diameter / 2, particle.y);
        context.lineTo(x, particle.y + particle.tilt + particle.diameter / 2);
        context.stroke();
      }
    };

    const updateParticles = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      let particle;
      waveAngle += 0.01;
      for (let i = 0; i < particles.length; i++) {
        particle = particles[i];
        particle.tiltAngle += particle.tiltAngleIncrement;
        particle.x += Math.sin(waveAngle);
        particle.y += (Math.cos(waveAngle) + particle.diameter + particleSpeed) * 0.5;
        particle.tilt = Math.sin(particle.tiltAngle) * 15;

        if (particle.x > width + 20 || particle.x < -20 || particle.y > height) {
          if (particles.length <= maxParticleCount) {
            resetParticle(particle, width, height);
          } else {
            particles.splice(i, 1);
            i--;
          }
        }
      }
    };

    const performAnimation = () => {
      if (canvasRef && canvasRef.current) {
        const context = canvasRef.current.getContext("2d");
        context?.clearRect(0, 0, window.innerWidth, window.innerHeight);
        updateParticles();
        drawParticles(context);
      }
    };

    const resetParticle = (particle: any, width: number, height: number) => {
      particle.color = colors[(Math.random() * colors.length) | 0];
      particle.x = Math.random() * width;
      particle.y = Math.random() * height - height;
      particle.diameter = Math.random() * 10 + 5;
      particle.tilt = Math.random() * 10 - 10;
      particle.tiltAngleIncrement = Math.random() * 0.07 + 0.05;
      particle.tiltAngle = 0;
      return particle;
    };

    const startConfetti = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      while (particles.length < maxParticleCount) {
        particles.push(resetParticle({}, width, height));
      }
      if (animationTimer === null) {
        animationTimer = setInterval(() => {
          performAnimation();
        }, 17);
      }
    };

    if (canvasRef && canvasRef.current) {
      canvasRef.current.width = window.innerWidth;
      canvasRef.current.height = window.innerHeight;
      startConfetti();
    }
  }, [canvasRef, colors, maxParticleCount, particleSpeed]);

  return (
    <div className="confetti-cannon-div">
      <canvas ref={canvasRef} id="confetti-canvas" />
    </div>
  );
};
