/**
 * accuracy to points mapping
 */
const ACCURACY_TO_POINTS = [
  { accuracy: 100, points: 1000 },
  { accuracy: 99, points: 800 },
  { accuracy: 98, points: 700 },
  { accuracy: 97, points: 600 },
  { accuracy: 96, points: 500 },
  { accuracy: 95, points: 400 },
  { accuracy: 90, points: 300 },
  { accuracy: 80, points: 200 },
  { accuracy: 70, points: 150 },
  { accuracy: 60, points: 100 },
  { accuracy: 50, points: 50 }
];

/**
 * calcuate points given the player's bet and current bid.
 * @param bet player's bet
 * @param bid current bid in auction
 * @param ended has the auction ended
 * @param sold is the property sold
 * @returns points
 */
export function calculatePoints(bet: number, bid: number, ended: boolean, sold: boolean) {
  if (bid === 0) {
    return 0;
  }

  // special case - property not sold at auction end, player would get zero points
  if (ended && !sold) {
    return 0;
  }

  // calculate percentage difference between bet and actual bid
  const percentDiff = (Math.abs(bet - bid) / bid) * 100;

  // calculate accuracy
  const accuracy = 100 - percentDiff;

  // calculate points
  const pointMap = ACCURACY_TO_POINTS.find((x) => accuracy >= x.accuracy);
  return pointMap ? pointMap.points : 0;
}
