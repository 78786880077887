import { useEffect, useRef } from "react";

/**
 * return previous value before render
 * @param value value to track
 * @returns previous value
 * @see https://usehooks.com/usePrevious/
 */
export function usePrevious<T>(value: T): T {
  const ref: any = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}
