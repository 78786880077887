import React, { useState, useEffect } from "react";
import { useContestContext } from "../../common/contest-context";
import { useUserContext } from "../../common/user-context";
import { PlayerDomainInterfacesService } from "../../player-api-client";
import { NumberFormatter } from "../../types/formatter";
import spinningTenX from "../../content/images/tenx-pinwheel.svg";
import { ApiLeaderboardPlayerScore } from "../../types/api-models";
import { mapLeaderboardPlayerScore } from "../../helpers/leaderboard-player-score-mapper";
import { ContestStatus } from "../../types/contest";

type Props = {
  showPointsWinnings: boolean;
  setShowPointsWinnings(showPointsWinnings: boolean): void;
};

export const PointsWinnings: React.FC<Props> = ({ showPointsWinnings, setShowPointsWinnings }) => {
  const { contest, status } = useContestContext();
  const { userInfo } = useUserContext();
  const [isLoading, setIsLoading] = useState(true);
  const [playerScore, setPlayerScore] = useState<ApiLeaderboardPlayerScore>();

  useEffect(() => {
    const getPlayerScore = async () => {
      if (contest?.seasonId && contest.contestId && userInfo?.playerId) {
        setIsLoading(true);
        const response = await PlayerDomainInterfacesService.apiLeaderboardBySeasonIdByContestIdByPlayerIdGet(
          contest.seasonId,
          contest.contestId,
          userInfo.playerId
        );
        if (response) {
          setPlayerScore(mapLeaderboardPlayerScore(response, contest?.round, status));
        }
        setIsLoading(false);
      }
    };

    document.body.style.overflow = "hidden";
    document.body.classList.add("locked");
    if (contest) {
      getPlayerScore();
    }

    return () => {
      document.body.style.overflow = "scroll";
      document.body.classList.remove("locked");
    };
  }, [contest, status, userInfo?.playerId]);

  return (
    <div className="modal-box">
      {isLoading && (
        <div className="spinner">
          <img src={spinningTenX} className="spinner-logo" alt="Ten-X Logo" />
        </div>
      )}
      {!isLoading && (
        <div>
          <div className="close-button">
            <span onClick={() => setShowPointsWinnings(!showPointsWinnings)}>&#10006;</span>
          </div>
          <div className="flex-modal-box">
            <div>
              <b>
                {status === ContestStatus.NotStarted
                  ? NumberFormatter.FormatNumber(0)
                  : NumberFormatter.FormatNumber(playerScore?.seasonPoints || 0)}{" "}
                points
              </b>
              <br />
              My Season Points
            </div>
            <div>
              <b>
                {status === ContestStatus.NotStarted
                  ? NumberFormatter.FormatCurrency(0)
                  : NumberFormatter.FormatCurrency(playerScore?.seasonWinnings || 0)}
              </b>
              <br />
              My Season Winnings
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
