// Example errors:
// /?error_description=PreAuthentication+failed+with+error+Cannot+allow+72fc7357-3af8-4210-90c2-00000178d6c9+because+they+are+not+a+valid+player.+&state=kv4MRGP7o9iSU1VMIpzmT28Xxfx23fWH&error=invalid_request
// /?error_description=PreSignUp+failed+with+error+Cannot+allow+ca1f24a7-501f-46e0-7933-0000015d7fab+to+impersonate+10edbb2e-c550-476f-7775-00000167aa39.+&state=J4pSzp05vizGgUnjhxsOVdJnMVoO7GTA&error=invalid_request

type CognitoError = {
  error_description: string;
  error: string;
  state: string;
};

export const parseCognitoError = (fragment: string): CognitoError => {
  const withoutHash = fragment[0] === "?" ? fragment.slice(1) : fragment;
  const parts = withoutHash.split("&");

  let ir: any = {};

  parts.forEach((part) => {
    const kv = part.split("=");
    ir[kv[0]] = kv[1];
  });

  return ir as CognitoError;
};

export const isInvalidPlayerError = (error: CognitoError): boolean => {
  const errorIndicator = "invalid_request";
  const invalidPlayerPattern = /Cannot\+allow\+[A-Fa-f0-9]+-[A-Fa-f0-9]+-[A-Fa-f0-9]+-[A-Fa-f0-9]+-[A-Fa-f0-9]+\+because\+they\+are\+not\+a\+valid\+player/;

  return (
    error !== undefined &&
    error.error === errorIndicator &&
    invalidPlayerPattern.test(error.error_description)
  );
};

export const isImpersonationError = (error: CognitoError): boolean => {
  const errorIndicator = "invalid_request";
  const impersonationPattern = /Cannot\+allow\+[A-Fa-f0-9]+-[A-Fa-f0-9]+-[A-Fa-f0-9]+-[A-Fa-f0-9]+-[A-Fa-f0-9]+\+to\+impersonate\+[A-Fa-f0-9]+-[A-Fa-f0-9]+-[A-Fa-f0-9]+-[A-Fa-f0-9]+-[A-Fa-f0-9]+/;

  return (
    error !== undefined &&
    error.error === errorIndicator &&
    impersonationPattern.test(error.error_description)
  );
};
