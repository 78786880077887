import React, { useEffect } from "react";
import { useEntryContext } from "../../common/entry-context";
import exclamationImg from "../../content/images/exclamation.svg";

export const BetErrorDialog: React.FC = () => {
  const { dismissBetErrorDialog } = useEntryContext();

  const handleDismiss = () => {
    dismissBetErrorDialog();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = "hidden";
    document.body.classList.add("locked");

    return () => {
      document.body.style.overflow = "scroll";
      document.body.classList.remove("locked");
    };
  }, []);

  return (
    <div className="review-properties">
      <div className="rules-overlay" style={{ zIndex: 3 }}></div>
      <div className="bet-error-dialog">
        <button className="btn-close" onClick={handleDismiss}>
          &#10006;
        </button>
        <div className="heading">
          <img className="bet-warning-icon" src={exclamationImg} alt="Warning" />
          BET ERROR
        </div>
        <div className="body-text">
          <BetErrorContent />
        </div>
        <div className="action-button" onClick={handleDismiss}>
          DISMISS
        </div>
      </div>
    </div>
  );
};

function BetErrorContent() {
  return (
    <div>
      Your bet attempt encountered a problem. Please check your network connectivity, refresh your
      page and try again.
    </div>
  );
}
