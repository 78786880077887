import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LoginModal } from "../../login-signup/login";
import { useUserContext } from "../../../common/user-context";
import { PageView } from "../../../common/constants";
import linkedIn from "../../../content/images/marketing/linkedIn.png";
import faceBook from "../../../content/images/marketing/facebook.png";
import youTube from "../../../content/images/marketing/youtube.png";
import twitter from "../../../content/images/marketing/twitter.png";
import instagram from "../../../content/images/marketing/instagram.png";
import classNames from "classnames";

export const StayInformed = ({ feBobShowJets }: { feBobShowJets?: boolean }) => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const { isAuthenticated } = useUserContext();
  const handleLoginModal = () => {
    setShowLoginModal(!showLoginModal);
    document.body.classList.add("modal-active");
  };

  const handleClickScroll = () => {
    const element = document.getElementById("prepareform");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div
      className={classNames({
        "informed-bg": true,
        "with-jet": feBobShowJets
      })}
    >
      {showLoginModal && (
        <div className="modal-wrapper active">
          <LoginModal loginSignupModal="" setLoginSignupModal={handleLoginModal} />
        </div>
      )}
      <div className="slash-section-wrapper informed-logo">
        <div className="view-box-informed ">
          <div className="informed-block ">
            <div className="splash-header"> STAY INFORMED</div>

            <div className="informed-text">
              Follow us on social media to get the newest updates <br /> about the game, results,
              and winners
            </div>
            <div className="social-media-tags">
              <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/ten-x/">
                <img src={linkedIn} alt="LinkedIn" />
              </a>
              <a target="_blank" rel="noreferrer" href="https://www.facebook.com/tenxcommercial">
                <img src={faceBook} alt="FaceBook" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCZfrZg4UMz6U1tCYc-x5-_A"
              >
                <img src={youTube} alt="YouTube" />
              </a>
              <a target="_blank" rel="noreferrer" href="https://twitter.com/tenx_cre">
                <img src={twitter} alt="Twitter" />
              </a>
              <a target="_blank" rel="noreferrer" href="http://www.instagram.com/tenx_cre">
                <img src={instagram} alt="Instagram" />
              </a>
            </div>

            <div className="button-wrapper">
              {isAuthenticated ? (
                <Link className="informed-button" to={PageView.RulesPage}>
                  LEARN MORE
                </Link>
              ) : (
                <button className="informed-button" onClick={handleLoginModal}>
                  GET IN THE GAME
                </button>
              )}
            </div>
          </div>
        </div>
        {/* Hidding pending new designs for Help Center
        <div className="more-questions">Have more questions? Visit our Help Center</div> */}
      </div>
    </div>
  );
};
