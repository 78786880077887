import { AnnotationsShapesOptions, AnnotationShapePointOptions } from "highcharts";

const shapePoint = (x: number, y: number): AnnotationShapePointOptions => {
  return { x, y, xAxis: 0, yAxis: 0 };
};

export const lineAnnotation = (
  type: string,
  color: string,
  axis: number
): AnnotationsShapesOptions => {
  const points =
    type === "vertical"
      ? [shapePoint(axis, 0), shapePoint(axis, 1000)]
      : [shapePoint(-1, axis), shapePoint(Math.pow(10, 7), axis)];

  return {
    dashStyle: "Dash",
    fill: "none",
    stroke: color,
    strokeWidth: 1,
    type: "path",
    points
  };
};

export const circleAnnotation = (color: string, x: number, y: number) => {
  return {
    type: "circle",
    r: 6,
    fill: color,
    point: { x, y, xAxis: 0, yAxis: 0 }
  };
};

export const annotationLabel = (text: string, x: number, y: number) => {
  return {
    point: { xAxis: 0, yAxis: 0, x, y },
    y: 5,
    text,
    backgroundColor: "rgba(255,255,255,0)",
    borderColor: "rgba(255,255,255,0)",
    style: {
      color: "#DF1E2D",
      fontSize: "16px",
      verticalAlign: "top"
    }
  };
};
