import React, { useState, useEffect } from "react";
import "../../../content/styles/common/login-signup.scss";
import { useUserContext } from "../../../common/user-context";

import { Welcome } from "./welcome";
import { NotInvited } from "./not-invited";
import { Confirmation } from "./confirmation";
import { GetOnMailingList } from "./join-mailing-list";
import { RequestInvitation } from "./request-invitation";
import { InitialLogin } from "./initial-login";

type Props = {
  loginSignupModal: string;
  setLoginSignupModal: any;
  permission?: string;
  campaignEmail?: string;
};

export const LoginModal: React.FC<Props> = (props) => {
  const { permission, campaignEmail } = props;
  const { signInCA } = useUserContext();
  const [email, setEmail] = useState("");
  const [permissions, setPermissions] = useState<{ action?: string; confirmation?: string }>({
    action: permission
  });

  const toggleShowLogin = () => {
    props.setLoginSignupModal();
    document.body.classList.remove("modal-active");
  };

  useEffect(() => {
    if (campaignEmail) {
      setEmail(campaignEmail);
    }
  }, [campaignEmail]);

  const renderComponent = () => {
    switch (permissions?.action) {
      case "UnKnown ": {
        return <InitialLogin setPermissions={setPermissions} currentEmail={setEmail} />;
      }
      case "DoPardot": {
        return <NotInvited permission={setPermissions} currentEmail={setEmail} />;
      }
      case "Register": {
        return <Welcome currentEmail={email} />;
      }
      case "LoginButCanNotPlay":
      case "LoginButOnlyLeaderboard":
      case "LoginToPlay": {
        signInCA(email);
        localStorage.setItem("intialLogin", "INITIAL_LOGIN");
        return null;
      }

      case "RequestInvitation": {
        return <RequestInvitation email={email} />;
      }

      case "GetOnMailingList": {
        return <GetOnMailingList email={email} />;
      }

      case "Confirmation": {
        return <Confirmation confirmation={permissions?.confirmation} />;
      }

      default:
        return <InitialLogin setPermissions={setPermissions} currentEmail={setEmail} />;
    }
  };
  const showModal = renderComponent();

  return (
    <>
      {showModal && (
        <div className="login-signup-modal login-modal">
          <div className="close-modal">
            <span onClick={() => toggleShowLogin()}>&#10006;</span>
          </div>
          <div className="login-modal-wrapper">{renderComponent()}</div>
        </div>
      )}
    </>
  );
};
