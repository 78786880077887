import React, { useState } from "react";
import "../../../content/styles/common/login-signup.scss";
import { PlayerDomainInterfacesService } from "../../../player-api-client";
import gtm from "../../../helpers/gtm-helper";

type Props = {
  setPermissions: any;
  currentEmail: any;
};

export const InitialLogin: React.FC<Props> = ({ setPermissions, currentEmail }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [validation, setValidation] = useState<boolean>();

  function validateEmail(emailAddress: string) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(emailAddress);
  }

  const handleLogin = async () => {
    gtm.trackEvent("login_started");
    if (validateEmail(email)) {
      currentEmail(email);

      try {
        setLoading(true);
        const response = await PlayerDomainInterfacesService.apiPlayerElegibility(email);
        setPermissions(response);
      } catch (error) {
        console.log(error);
      } finally {
        localStorage.setItem("intialLogin", "INITIAL_LOGIN");
        setLoading(false);
      }
    } else {
      setValidation(true);
    }
  };

  return (
    <>
      <div className="login-signup-header">LOGIN/SIGN UP</div>
      <div>
        <input
          className="login-input-field"
          type="text"
          placeholder="Email Address"
          name="email"
          onChange={(e) => {
            setEmail(e.currentTarget.value);
            setValidation(false);
          }}
          value={email}
        />
      </div>
      {validation && <div className="error-message">Please enter a valid email address</div>}
      <button
        className="button-continue"
        onClick={handleLogin}
        disabled={loading || validation}
        bob-ga-data-on="click"
        bob-ga-data-event-category="Profile"
        bob-ga-data-event-action="Click"
        bob-ga-data-event-label="InitialLogin"
        bob-ga-data-dimension2={email}
      >
        CONTINUE
      </button>
    </>
  );
};
