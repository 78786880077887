import React from "react";

type Props = {
  isLeaderBoard?: boolean;
  officialDateMessage?: string | undefined | null;
};

const OfficialBanner: React.FC<Props> = (props: Props) => {
  const { officialDateMessage, isLeaderBoard } = props;

  return (
    <>
      {officialDateMessage && isLeaderBoard && (
        <div className="official-heading">
          <span className="results-bold">Unofficial Season Results</span> {officialDateMessage}
        </div>
      )}
    </>
  );
};

export default OfficialBanner;
