import React, { useState } from "react";
import PlayButtonSrc from "../../content/images/marketing/play-button.svg";
import "../../content/styles/common/video-player.scss";
import gtm from "../../helpers/gtm-helper";

type IProps = {
  embedId: string;
  thumbnailSrc: string;
  disableVideoSwitch?: boolean;
  video_section?: string;
};

const VideoPlayer = ({
  embedId,
  thumbnailSrc,
  disableVideoSwitch = false,
  video_section = ""
}: IProps) => {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <div className="video-player">
      {showVideo && !disableVideoSwitch ? (
        <iframe allowFullScreen={true} className="yt-video" title="video" src={embedId} />
      ) : (
        <div
          className="thumbnail-container"
          onClick={() => {
            setShowVideo(true);
            gtm.trackEvent("video_click", {
              video_section
            });
          }}
        >
          <img className="thumbnail-image" alt="thumbnail" src={thumbnailSrc} />
          <img className="play-button" alt="play button" src={PlayButtonSrc} />
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
