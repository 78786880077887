import React from "react";
import { PropertyTracker } from "../../types/property";
import { NumberFormatter } from "../../types/formatter";
import { EListingStatus } from "../../types/auction";
import classNames from "classnames";

type Props = {
  currentBid?: number;
  numberOfBets?: number;
  averageBet?: number;
  marketGuidance?: number;
  bidStatus?: string;
  currentBidAverage?: number;
  currentBidMarket?: number;
};

export const PizzaTracker: React.FC<Props> = (contestItem) => {
  const {
    currentBid,
    numberOfBets,
    averageBet,
    marketGuidance,
    bidStatus,
    currentBidAverage,
    currentBidMarket
  } = contestItem;
  const isSelected = Object.keys(contestItem).length === 0;

  const isPropertySold = bidStatus === EListingStatus.SOLD;
  const isTradeContinues = bidStatus === EListingStatus.GONE;

  return (
    <div className="pizza-tracker-container">
      <div className={"pizza-tracker-card"}>
        <div className={"pizza-tracker-element "}>
          {isPropertySold && (
            <>
              <div className="tracker-figure current-bid bid-glow">SOLD </div>
              <div className="tracker-deliminator">For</div>
            </>
          )}
          <div
            className={classNames("tracker-figure current-bid", {
              "bid-glow": !isTradeContinues,
              rolloff: isTradeContinues
            })}
          >
            {!isSelected ? (currentBid && NumberFormatter.FormatCurrency(currentBid!)) ?? "-" : "-"}
          </div>
          {!isPropertySold && !isTradeContinues && (
            <div className={"tracker-label bid-glow"}>{PropertyTracker.currentBid}</div>
          )}

          {isTradeContinues && (
            <div className={"tracker-label"}>{PropertyTracker.tradeContinues}</div>
          )}
        </div>
        <div className={"pizza-tracker-element"}>
          <div className={"tracker-figure"}>
            {!isSelected
              ? (numberOfBets && NumberFormatter.FormatNumber(numberOfBets!)) ?? "-"
              : "-"}
          </div>
          <div className="tracker-label">{PropertyTracker.numberOfBets}</div>
        </div>
      </div>

      <div className={"pizza-tracker-card"}>
        <div className={"pizza-tracker-element"}>
          <div className={"tracker-figure average-bet"}>
            {!isSelected ? (averageBet && NumberFormatter.FormatCurrency(averageBet!)) ?? "-" : "-"}
          </div>
          <div className="tracker-label"> {PropertyTracker.averageBet}</div>
        </div>

        <div className={"pizza-tracker-element"}>
          <div className={"tracker-figure"}>
            {!isSelected
              ? (currentBidAverage && NumberFormatter.FormatPercent(currentBidAverage!)) ?? "-"
              : "-"}
          </div>
          <div className="tracker-label"> {PropertyTracker.currentBidAverage}</div>
        </div>
      </div>

      <div className={"pizza-tracker-card"}>
        <div className={"pizza-tracker-element"}>
          <div className={"tracker-figure market-guidance"}>
            {!isSelected
              ? (marketGuidance && NumberFormatter.FormatCurrency(marketGuidance!)) ?? "-"
              : "-"}
          </div>
          <div className="tracker-label"> {PropertyTracker.marketGuidance}</div>
        </div>

        <div className={"pizza-tracker-element"}>
          <div className={"tracker-figure"}>
            {!isSelected
              ? (currentBidMarket && NumberFormatter.FormatPercent(currentBidMarket!)) ?? "-"
              : "-"}
          </div>
          <div className="tracker-label"> {PropertyTracker.CurrentBidMarket}</div>
        </div>
      </div>
    </div>
  );
};
