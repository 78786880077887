/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * None&nbsp;(0)<br/>Active&nbsp;(1)<br/>Escrow&nbsp;(2)<br/>Pending&nbsp;(3)<br/>UnderContract&nbsp;(4)<br/>Sold&nbsp;(5)<br/>Withdrawn&nbsp;(6)<br/>Leased&nbsp;(7)<br/>Rubbish&nbsp;(12)<br/>Occupied&nbsp;(13)
 */
export enum CoStar_Api_Listings_ViewModels_SaleStatus {
  NONE = "None",
  ACTIVE = "Active",
  ESCROW = "Escrow",
  PENDING = "Pending",
  UNDER_CONTRACT = "UnderContract",
  SOLD = "Sold",
  WITHDRAWN = "Withdrawn",
  LEASED = "Leased",
  RUBBISH = "Rubbish",
  OCCUPIED = "Occupied"
}
