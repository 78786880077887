import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { buildGamePageRoute } from "../../../helpers/game-route-builder";
import { PageView } from "../../../common/constants";

import HOF1 from "../../../content/images/marketing/hof-image-1.png";
import HOF2 from "../../../content/images/marketing/hof-image-2.png";
import HOF3 from "../../../content/images/marketing/hof-image-3.png";
import HOF4 from "../../../content/images/marketing/hof-image-4.png";

interface Props {
  feBobEndOfSeason?: boolean;
}

export const HallOfFame: React.FC<Props> = ({ feBobEndOfSeason }) => {
  const hofImages = [HOF1, HOF2, HOF3, HOF4];
  const history = useHistory();
  return (
    <>
      <div className="hof-container">
        <div className="splash-header text-center">
          {feBobEndOfSeason ? "SEASON 1" : "HAll OF FAME"}
        </div>
        <div className="hof-text">
          {feBobEndOfSeason
            ? "Learn about the experience from Season 1 winners and get tips on how to win."
            : "Hear the feedback and learn some tips from the Season 1 winners!"}
        </div>
        <div className="hof-wrapper">
          <div className="hof-section hof-button-wrapper">
            <button
              onClick={() => history.push(buildGamePageRoute(PageView.HallOfFame))}
              className="hof-button"
            >
              Winner Testimonials
            </button>
          </div>
          {hofImages.map((imgSrc, index) => (
            <div key={index} className="hof-section">
              <div className="image-container">
                {feBobEndOfSeason && <div className="blue-overlay" />}
                <img alt="Hall of fame winner" src={imgSrc} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
