import React from "react";
import iconOpen from "../../content/images/marketing/icon-open.svg";
import iconClosed from "../../content/images/marketing/icon-closed.svg";

import "../../../src/content/styles/common/help-questions.scss";
import { IGameScheduleItem, gameScheduleTable } from "../../common/game-schedule";
type AnswerProps = {
  isDisplay: boolean;
  value: string;
  question?: string;
};

const Answer: React.FC<AnswerProps> = (props: AnswerProps) => {
  return (
    <>
      <tr className="answer" style={{ display: props.isDisplay ? "block" : "none"}}>
        <td>
          <p>
            {props.value}
            {props.value.toLowerCase().includes("dates") && <RoundTable />}
            {props.value.toLowerCase().includes("scoring chart") && <ScoringTable />}
          </p>
        </td>
      </tr>
    </>
  );
};

const RoundTable = () => {
  return (
    <div className="round-table-container">
      <table className="round-table">
        <thead>
          <tr>
            {gameScheduleTable.header.map((col: string, idx: number) => (
              <th key={idx}>{col}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {gameScheduleTable.data.map((item: IGameScheduleItem, idx: number) => (
            <tr key={idx}>
              {gameScheduleTable.header.map((header, headerIdx: number) => (
                <td key={headerIdx}>{(item as any)[header.toLowerCase().replace(/ /g, "_")]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const ScoringTable = () => (
  <div className="rules-table-container">
    <table className="rules-table">
      <thead>
        <tr>
          <th>
            <div>% Difference</div>
          </th>
          <th>
            <div>Points</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Exact Estimate</td>
          <td>1000</td>
        </tr>
        <tr>
          <td>{">"}0% and &#x2264;1%</td>
          <td>800</td>
        </tr>
        <tr>
          <td>{">"}1% and &#x2264;2%</td>
          <td>700</td>
        </tr>
        <tr>
          <td>{">"}2% and &#x2264;3%</td>
          <td>600</td>
        </tr>
        <tr>
          <td>{">"}3% and &#x2264;4%</td>
          <td>500</td>
        </tr>
        <tr>
          <td>{">"}4% and &#x2264;5%</td>
          <td>400</td>
        </tr>
        <tr>
          <td>{">"}5% and &#x2264;10%</td>
          <td>300</td>
        </tr>
        <tr>
          <td>{">"}10% and &#x2264;20%</td>
          <td>200</td>
        </tr>
        <tr>
          <td>{">"}20% and &#x2264;30%</td>
          <td>150</td>
        </tr>
        <tr>
          <td>{">"}30% and &#x2264;40%</td>
          <td>100</td>
        </tr>
        <tr>
          <td>{">"}40% and &#x2264;50%</td>
          <td>50</td>
        </tr>
        <tr>
          <td>{">"}50%</td>
          <td>0</td>
        </tr>
      </tbody>
    </table>
  </div>
);

type QuestionProps = {
  question: string;
  answer: string;
};

export const Question: React.FC<QuestionProps> = (props: QuestionProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  return (
    <>
      <tr
        className="question"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <td>
          <p>{props.question}</p>
          {isOpen && <img className="caret" src={iconOpen} alt="Open icon" />}
          {!isOpen && <img className="caret" src={iconClosed} alt="Open icon" />}
        </td>
      </tr>
      <Answer isDisplay={isOpen} value={props.answer} />
    </>
  );
};
