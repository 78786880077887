export interface IStorageRepo<T> {
    get(): T;
    set(value: T): void;
    delete(): void;
}

export class SessionStorageRepo<T> implements IStorageRepo<T> {
    constructor(key: string, defaultValue: T) {
        this.key = key;
        this.defaultValue = defaultValue;
    }

    private key: string;
    private defaultValue: T;

    get(): T {
        var intermediateResult = sessionStorage.getItem(this.key);
        if (intermediateResult) {
            return JSON.parse(intermediateResult) as T;
        }
        return this.defaultValue;
    }

    set(value: T) {
        sessionStorage.setItem(this.key, JSON.stringify(value));
    }

    delete() {
        sessionStorage.removeItem(this.key);
    }
}

export class LocalStorageRepo<T> implements IStorageRepo<T> {
    constructor(key: string, defaultValue: T) {
        this.key = key;
        this.defaultValue = defaultValue;
    }

    private key: string;
    private defaultValue: T;

    get(): T {
        var intermediateResult = localStorage.getItem(this.key);
        if (intermediateResult) {
            return JSON.parse(intermediateResult) as T;
        }
        return this.defaultValue;
    }

    set(value: T) {
        localStorage.setItem(this.key, JSON.stringify(value));
    }

    delete() {
        localStorage.removeItem(this.key);
    }
}


export enum StorageOption {
    Session = 1,
    Local = 2,
};

export function getStorageRepo<T>(key: string, defaultValue : T, option: StorageOption = StorageOption.Session): IStorageRepo<T> {
    switch (option) {
        case StorageOption.Local:
            return new LocalStorageRepo<T>(key, defaultValue);
        case StorageOption.Session:
        default:
            return new SessionStorageRepo<T>(key, defaultValue);
    }
}