import React, { useEffect, useCallback, useState } from "react";
import { PlayerDomainInterfacesService } from "../../player-api-client";
import { PlayerDomain_ViewModels_PlayerSearchResult } from "../../player-api-client/models/PlayerDomain_ViewModels_PlayerSearchResult";
import { ContestDomainClient_ViewModels_LeaderboardPlayerScore } from "../../player-api-client/models/ContestDomainClient_ViewModels_LeaderboardPlayerScore";
import { ContestDomainClient_Models_LeaderboardSort } from "../../player-api-client";
import "../../content/styles/game/score-card.scss";
import { ScoreBoardScorePlayer } from "../scoreboard/scoreboard-score-player";
import { SubHeader } from "../sub-header/sub-header";
import { SearchBanner } from "./search-banner";
import { PlayerStats } from "./player-stats";
import { NumberFormatter } from "../../types/formatter";
import { useEntryPlayerContext } from "../../common/entry-player-context";
import { useStandingPlayerContext } from "../../common/standing-player-context";
import { useRoundContext } from "../../common/round-context";
import { useContestContext } from "../../common/contest-context";
import _ from "lodash";

const RequestType = {
  Season: 1,
  PlayerCount: 2
};

export const ScoreCard: React.FC = () => {
  const { standingInfo } = useStandingPlayerContext();
  const { round } = useRoundContext();
  const { contest } = useContestContext();

  const [players, setPlayers] = useState<PlayerDomain_ViewModels_PlayerSearchResult[]>([]);
  const [currentPlayer, setCurrentPlayer] = useState<PlayerDomain_ViewModels_PlayerSearchResult>();
  const [playerId, setPlayerID] = useState<string>("");
  const [previousRound, setPreviousRound] = useState<any>();
  const [playerRank, setPlayerRank] = useState<number>();
  const [numberOfPlayers, setNumberOfPlayers] = useState<number>();
  const [
    seasonStandings,
    setSeasonStandings
  ] = useState<ContestDomainClient_ViewModels_LeaderboardPlayerScore>();
  const { changePlayer } = useEntryPlayerContext();

  const searchPlayers = useCallback(async (searchCriteria: string = "", page: number = 1) => {
    let response = await PlayerDomainInterfacesService.apiPlayersSearchTypeaheadPost({
      searchCriteria: searchCriteria,
      page: page,
      pageSize: 10
    });

    if (response) {
      const newPlayers = response?.players! || [];
      setPlayers(newPlayers);
    }
  }, []);

  useEffect(() => {
    getSeasonRounds();
    if (previousRound?.contestId) {
      leaderBoard(playerId, previousRound?.contestId, RequestType.Season);
    }
  }, [playerId]);

  useEffect(() => {
    if (standingInfo?.contestId) {
      leaderBoard(standingInfo?.playerId!, standingInfo?.contestId, RequestType.PlayerCount);
    }
  }, [standingInfo]);

  const leaderBoard = async (playerId: string, contestID: string, request: number) => {
    try {
      const response = await PlayerDomainInterfacesService.apiContestLeaderboardPost({
        contestId: contestID,
        page: 1,
        pageSize: 51,
        playerId: playerId,
        requestGameSize: true,
        leaderboardSort: ContestDomainClient_Models_LeaderboardSort.SEASON_POINTS
      });
      if (response && RequestType.Season === request) {
        const seasonStats = response?.leaderboardPlayerScores!.find((p) => p.playerId === playerId);
        const offsetPosition = parseInt(
          _.findKey(response?.leaderboardPlayerScores!, { playerId: playerId })!
        );
        setSeasonStandings(seasonStats);
        setPlayerRank(offsetPosition + 1);
      }
      if (response && RequestType.PlayerCount === request) {
        setNumberOfPlayers(response?.gameSize!);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getSeasonRounds = async () => {
    const response = await PlayerDomainInterfacesService.apiSeasonBySeasonIdRoundsGet(
      contest?.seasonId!
    );
    if (response) {
      const previousContest = response?.rounds![response?.rounds?.length! - 2];
      setPreviousRound(previousContest);
    }
  };

  const handleSearch = useCallback(
    async (criteria: string = "") => {
      if (criteria !== "") {
        searchPlayers(criteria, 1);
      } else {
        setPlayers([]);
      }
    },
    [searchPlayers]
  );

  const handleSelectedOne = (playerId: string) => {
    setPlayerID(playerId);
    const player = players.find((x) => x.player?.playerId === playerId);
    if (player) {
      const selectedPlayer: PlayerDomain_ViewModels_PlayerSearchResult[] = [];
      selectedPlayer.push(player!);
      setCurrentPlayer(player!);
      setPlayers(selectedPlayer);
      changePlayer({ ...player.player });
    }
  };

  const roundRankStats = () => {
    return (
      <div className="">
        {round?.name + "  Rank: # "}
        <span className="green-rank">
          {NumberFormatter.FormatNumber(currentStandings?.eventPointsRank || 0)}
        </span>
        {" out of " + NumberFormatter.FormatNumber(numberOfPlayers || 0)}
      </div>
    );
  };

  const currentStandings =
    currentPlayer?.player?.playerId === standingInfo?.playerId ? standingInfo : {};

  return (
    <>
      <SearchBanner
        handleSearch={handleSearch}
        handleSelectedOne={handleSelectedOne}
        players={players}
      />

      <div className="score-card-container">
        <PlayerStats
          currentPlayer={currentPlayer}
          currentContestId={currentStandings?.contestId || ""}
        />
        <div className="col-2">
          <div className="players-card-header">
            <div className="players-card-label">PLAYER'S CARD</div>
            <div className="player-rank-label">
              {currentStandings?.eventPointsRank && roundRankStats()}
            </div>
          </div>
          <main className="content">
            <div className="score-card-table">
              <div>
                <SubHeader />
              </div>
              <div className="players-card-table">
                <ScoreBoardScorePlayer />
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
