import React from "react";
import trophyICon from "../../../content/images/marketing/trophy-icon.png";
import dollarIcon from "../../../content/images/marketing/dollar-icon.png";
import giftCardIcon from "../../../content/images/marketing/gift-card-icon.png";
import playBtn from "../../../content/images/marketing/play-btn.png";
import thumbNail3 from "../../../content/images/marketing/bob-prize-video-thumbnail.jpg";
import { MessageModalData } from "../../../types/message-modal-data";
import "../../../content/styles/common/prizes-section.scss";
import gtm from "../../../helpers/gtm-helper";

interface IProps {
  setMessageData: React.Dispatch<React.SetStateAction<MessageModalData>>;
  showTheMessageModal: () => void;
}

export const Prizes: React.FC<IProps> = ({ showTheMessageModal, setMessageData }: IProps) => {
  const handleMessageData = (data: MessageModalData) => {
    showTheMessageModal();
    setMessageData(data);
    gtm.trackEvent("video_click", {
      video_section: "Home Page Prizes Section"
    });
  };

  return (
    <>
      <div className="prizes-section-container">
        <div className="prizes-number-container">
          <div className="prizes-number-title">$3,000,000 IN PRIZES</div>
          <div className="prizes-sub-title">SIX ROUNDS TO PLAY</div>
        </div>

        <div className="prizes-places-container">
          <div className="place-container">
            <div className="icon-container">
              <img src={trophyICon} alt="icon 1" />
            </div>
            <div className="text">
              <div className="award">$1,000,000</div>
              <div className="place">grand prize</div>
              <div className="divider-container">
                <div className="prize-divider"></div>
              </div>
            </div>
          </div>
          <div className="place-container">
            <div className="icon-container">
              <img src={dollarIcon} alt="icon 2" />
            </div>
            <div className="text">
              <div className="award">$100,000</div>
              <div className="place">second place prize</div>
              <div className="divider-container">
                <div className="prize-divider"></div>
              </div>
            </div>
          </div>
          <div className="place-container">
            <div className="icon-container">
              <img src={giftCardIcon} alt="icon 3" />
            </div>
            <div className="text">
              <div className="award">$250,000+</div>
              <div className="place">prizes per round</div>
              <div className="divider-container">
                <div className="prize-divider"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="prizes-video-container">
          <img className="video-thumbnail" src={thumbNail3} alt="video thumbnail" />
          <img
            className="play-button cursor"
            src={playBtn}
            alt="play button"
            onClick={() => {
              handleMessageData({
                message: "",
                messageTitle: "",
                videoUrl: "https://www.youtube.com/embed/4mUjbR-W6uk?rel=0"
              });
            }}
          />
        </div>
      </div>
    </>
  );
};
