import React from "react";
import "../../content/styles/common/accept-rules.scss";
import { NumberFormatter } from "../../types/formatter";
import { useUserContext } from "../../common/user-context";

type Props = {
  isShown: boolean;
  winningAmount?: number;
  hideModal: () => void;
};

export const WinnerModal: React.FC<Props> = ({ isShown, hideModal, winningAmount }) => {
  const hideWinnerModal = () => {
    hideModal();
  };
  const { userInfo } = useUserContext();

  return (
    <div className={"winner-has-address "}>
      <div className="rules-overlay" style={{ zIndex: 3 }}></div>
      <div className="rules-modal winner-has-address-modal">
        <div className="rules-header">
          <button
            className="btn-close"
            onClick={hideWinnerModal}
            bob-ga-data-on="click"
            bob-ga-data-event-category="Confettit"
            bob-ga-data-event-action="Click"
            bob-ga-data-event-label="Dismissed"
            bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
          >
            &#10006;
          </button>
        </div>
        <div className="rules-body">
          <div className="congrats-header">CONGRATULATIONS!</div>
          <div className="congrats-background">
            <div className="congrats-text">
              YOU WON <br />
              {NumberFormatter.FormatCurrency(winningAmount || 0)}
            </div>
          </div>

          <div className="instruction-text">Check your email for further instructions</div>
        </div>
      </div>
    </div>
  );
};
