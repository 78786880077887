function ready() {
  return new Promise((resolve, reject) => {
    try {
      // Check if GTM is available or create empty array
      window.dataLayer = window.dataLayer || [];

      return resolve(window.dataLayer);
    } catch (e) {
      reject(e);
    }
  });
}

function trackEvent(eventName, properties) {
  return ready()
    .then((gtm) =>
      gtm.push({
        event: eventName,
        ...properties
      })
    )
    .catch((e) => {
      console.error(e);
    });
}

export default {
  trackEvent
};
