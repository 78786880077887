import React, { useState, useEffect } from "react";
import "../../content/styles/common/watch-live.scss";
import BobLogo from "../../content/images/marketing/bob_text.png";
import VideoPlayer from "../video-player";
import Jet from "../../content/images/marketing/f35jet.png";
import Cloud from "../../content/images/marketing/large-hero-cloud.png";
import SecondaryCloud from "../../content/images/marketing/small-hero-cloud.png";
import { MessageModalData } from "../../types/message-modal-data";
import { useHistory } from "react-router-dom";
import { PageView } from "../../common/constants";
import { LoginModal } from "../login-signup/login";
import { PlayerDomainInterfacesService } from "../../player-api-client";
import { useContestContext } from "../../common/contest-context";

type TProps = {
  isAuthenticated: boolean;
  setMessageData: React.Dispatch<React.SetStateAction<MessageModalData>>;
  showTheMessageModal: () => void;
  feBobShowJets?: boolean;
};

export type VideosSeries = {
  roundVideos: Array<VideoCollection>;
};

type VideoCollection = {
  round: number;
  videos: Array<Videos>;
};

type Videos = {
  embedId: string;
  title: string;
  description: string;
  thumbnail: string;
  sortOrder: number;
};

type VideoFeature = {
  video?: {
    description: string;
    sortOrder: number;
    title: string;
    embedId: string;
    thumbnail: string;
  };
};

export const WatchLive = ({
  isAuthenticated,
  setMessageData,
  showTheMessageModal,
  feBobShowJets
}: TProps) => {
  const handleMessageData = (data: MessageModalData) => {
    showTheMessageModal();
    setMessageData(data);
  };

  const { contest } = useContestContext();
  const [videoCollection, setVideoCollection] = useState<VideosSeries>();
  const [videoFeature, setVideoFeature] = useState<any>();
  useEffect(() => {
    const getVideos = async () => {
      try {
        const collection =
          contest?.seasonId &&
          (await PlayerDomainInterfacesService.apiVideoCollection(contest.seasonId));
        const featured =
          contest?.seasonId &&
          (await PlayerDomainInterfacesService.apiVideoFeatured(contest.seasonId));
        Promise.all([collection, featured]).then(() => {
          setVideoCollection(collection as any);
          setVideoFeature(featured);
        });
      } catch (error) {
        console.log(error);
      }
    };
    getVideos();
  }, [contest]);

  return (
    <div className="watch-live-container">
      <Hero handleMessageData={handleMessageData} videoFeature={videoFeature} />
      <PreviousRoundsSection
        handleMessageData={handleMessageData}
        videoCollection={videoCollection}
      />
      <GameRecapSection isAuthenticated={isAuthenticated} feBobShowJets={feBobShowJets} />
    </div>
  );
};

const Hero = ({
  handleMessageData,
  videoFeature
}: {
  handleMessageData: (data: MessageModalData) => void;
  videoFeature?: VideoFeature;
}) => (
  <div className="hero">
    <img className="bob-logo" alt="battle of the bids logo" src={BobLogo} />
    <img className="cloud" alt="cloud" src={Cloud} />
    <img className="second-cloud" alt="backgroud cloud" src={SecondaryCloud} />
    <h2 className="title">{videoFeature?.video?.title}</h2>
    <p className="subtitle">{videoFeature?.video?.description}</p>
    <div
      className="hero-video-container"
      onClick={() =>
        handleMessageData({
          message: "",
          messageTitle: "",
          videoUrl: videoFeature?.video?.embedId ?? null
        })
      }
    >
      <VideoPlayer
        thumbnailSrc={videoFeature?.video?.thumbnail ?? ""}
        embedId={videoFeature?.video?.embedId ?? ""}
        disableVideoSwitch={true}
        video_section="Watch Now Page"
      />
    </div>
  </div>
);

const PreviousRoundsSection = ({
  handleMessageData,
  videoCollection
}: {
  handleMessageData: (data: MessageModalData) => void;
  videoCollection?: VideosSeries;
}) => (
  <div className="previous-round-container">
    {videoCollection &&
      videoCollection.roundVideos.map((round, index) => (
        <>
          <h2 className="title">ROUND {index + 1}</h2>
          <div className="video-section-container">
            {round.videos.map((video) => (
              <>
                <VideoContainer
                  handleMessageData={handleMessageData}
                  embedId={video.embedId}
                  title={video.title}
                  description={video.description}
                  thumbnail={video.thumbnail}
                />
              </>
            ))}
          </div>
        </>
      ))}
  </div>
);

const VideoContainer = ({ embedId, thumbnail, title, description, handleMessageData }: any) => (
  <div
    className="video-player-container"
    onClick={() =>
      handleMessageData({
        message: "",
        messageTitle: "",
        videoUrl: embedId
      })
    }
  >
    <VideoPlayer
      disableVideoSwitch={true}
      thumbnailSrc={thumbnail}
      embedId={embedId}
      video_section="Watch Now Page"
    />
    <p className="title">{title}</p>
    <p className="description">{description}</p>
  </div>
);

const GameRecapSection = ({
  isAuthenticated,
  feBobShowJets
}: {
  isAuthenticated: boolean;
  feBobShowJets?: boolean;
}) => {
  const history = useHistory();
  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleButtonClick = () => {
    if (isAuthenticated) {
      history.push(`${PageView.WinnersList}?season=Season%20Leaderboard`);
    } else {
      handleLoginModal();
    }
  };

  const handleLoginModal = () => {
    setShowLoginModal(!showLoginModal);
    document.body.classList.add("modal-active");
  };

  return (
    <>
      {showLoginModal && (
        <div className="modal-wrapper active">
          <LoginModal loginSignupModal="" setLoginSignupModal={handleLoginModal} />
        </div>
      )}
      <div className="game-recap-container">
        <div className="content-container">
          <h2 className="title">WINNER CIRCLE</h2>
          <p className="subtitle">
            Find out who has accelerated to the top of the leaderboard in the Battle of the Bids.
          </p>
          <div className="button" role="button" onClick={() => handleButtonClick()}>
            {isAuthenticated ? "SEASON LEADERBOARD" : "GET IN THE GAME"}
          </div>
        </div>
        {feBobShowJets && <img className="jet" alt="jet" src={Jet} />}
      </div>
    </>
  );
};
