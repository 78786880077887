import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useBroadcastContext } from "./broadcast-socket-context";
import { useReadOnly } from "../hooks/use-read-only";
import {
  ContestDomainClient_ViewModels_LeaderboardPlayerScore,
  ContestDomainClient_ViewModels_Prize,
  PlayerDomainInterfacesService
} from "../player-api-client";
import { PrizePoints } from "../types/leaderboard";
import { useRoundContext } from "./round-context";
import { useUserContext } from "./user-context";

export type StandingContextType = {
  standingInfo?: ContestDomainClient_ViewModels_LeaderboardPlayerScore;
  prizePoints?: PrizePoints;
  isMinRankForPrize: boolean;
  winnings?: number;
  leaderboardChanged: string;
};

export const StandingContext = React.createContext<StandingContextType>(null!);

export const StandingContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { round } = useRoundContext();
  const { userInfo, isAuthenticated } = useUserContext();
  const { leaderboardChanged } = useBroadcastContext();
  const [prizePoints, setPrizePoints] = useState<PrizePoints>();
  const [isMinRankForPrize, setIsMinRankForPrize] = useState<boolean>(false);
  const [
    standingInfo,
    setStandingInfo
  ] = useState<ContestDomainClient_ViewModels_LeaderboardPlayerScore>();
  const [winnings, setWinnings] = useState<number>(0);
  const [isMounted, setIsMounted] = useState(false);
  const [reFetchCount, setReFetchCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const isReadOnly = useReadOnly();

  const fetchPlayerStanding = async (
    seasonId: string | null,
    contestId: string | null,
    playerId: string | null
  ): Promise<ContestDomainClient_ViewModels_LeaderboardPlayerScore | undefined> => {
    const response = await PlayerDomainInterfacesService.apiLeaderboardBySeasonIdByContestIdByPlayerIdGet(
      seasonId,
      contestId,
      playerId
    );
    let responseStanding = response as ContestDomainClient_ViewModels_LeaderboardPlayerScore;
    responseStanding.contestId = contestId;
    return responseStanding;
  };

  const calculateMinRank = React.useCallback(
    (prizes: ContestDomainClient_ViewModels_Prize[]) => {
      const minRank = prizes?.reduce((total, current) => total + current.available!, 0);

      return standingInfo?.eventPointsRank! <= minRank;
    },
    [standingInfo?.eventPointsRank]
  );

  useEffect(() => {
    const fetch = async () => {
      if (isAuthenticated && userInfo?.playerId && round?.seasonId && round?.contestId) {
        const fetchedPlayerStanding = await fetchPlayerStanding(
          round.seasonId,
          round.contestId,
          userInfo.playerId
        );

        setStandingInfo(fetchedPlayerStanding);

        const fetchedPrizePoints = await PlayerDomainInterfacesService.apiContestByContestIdPrizeGet(
          round.contestId
        );
        const [first, second, third] = fetchedPrizePoints.prizes || [];

        // from total number of available prizes, determine the min rank that will win prizes
        const isMinRankForPrizeCheck = calculateMinRank(fetchedPrizePoints?.prizes!);
        setIsMinRankForPrize(isMinRankForPrizeCheck);

        if (isMinRankForPrizeCheck) {
          setWinnings(fetchedPlayerStanding?.eventWinnings!);
        }

        setPrizePoints({
          firstPrizePoints: first.minPoint || 0,
          secondPrizePoints: second.minPoint || 0,
          thirdPrizePoints: third.minPoint || 0
        });
      }
    };

    setIsMounted(true);
    fetch();

    return () => {
      console.log("standing-context unmounted");
      setIsMounted(false);
    };
  }, [
    round?.contestId,
    round,
    isAuthenticated,
    userInfo?.playerId,
    calculateMinRank,
    reFetchCount,
    leaderboardChanged
  ]); // reFetchCount is used to fire the useEffect

  return (
    <StandingContext.Provider
      value={{ standingInfo, prizePoints, isMinRankForPrize, winnings, leaderboardChanged }}
    >
      {props.children}
    </StandingContext.Provider>
  );
};

export const useStandingContext = () => {
  const context = useContext(StandingContext);
  if (!context) {
    throw new Error("useStandingContext must be used within the StandingContextProvider");
  }
  return context;
};
