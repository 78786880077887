import { useRef } from "react";
import { PageView } from "../../common/constants";
import "../../content/styles/common/accept-rules.scss";
import { useHistory } from "react-router-dom";
import { useRoundContext } from "../../common/round-context";
import { buildGamePageRoute } from "../../helpers/game-route-builder";
import { useUserContext } from "../../common/user-context";
import { getStorageRepo, StorageOption } from "../../helpers/storage";
import { useEffect, useState } from "react";
import { useContestContext } from "../../common/contest-context";
import { ContestStatus } from "../../types/contest";
import moment from "moment";
import { AppConfig } from "../../common/app_config";
import { showPropertiesPriorToOpeningBets } from "../../helpers/date-helpers";

type Props = {
  showReviewPropertiesModal: boolean;
  setShowReviewPropertiesModal: (showReviewPropertiesModal: boolean) => void;
};

type ContestStartingWarningModal = {
  acceptance: boolean;
  expire?: Date;
};

let contestStartingWarningModal: ContestStartingWarningModal = {
  acceptance: false,
  expire: new Date()
};

export const ReviewPropertiesModal: React.FC<Props> = (props) => {
  const history = useHistory();
  const { round } = useRoundContext();
  const { userInfo, isAuthenticated } = useUserContext();
  const { contest, status } = useContestContext();
  const [showReview, setShowReview] = useState(false);
  const [showInThreeDays, setShowInThreeDays] = useState(false);
  const [isBettingOpened, setIsBettingOpened] = useState(false);

  const goToPropertySelectionPage = () => {
    setShowReview(false);
    contestStartingWarningModal.acceptance = true;
    contestStartingWarningModal.expire = contest?.localStartDate;
    contestStartingWarningModalStorage.set(contestStartingWarningModal);
    if (!isBettingOpened) {
      history.push(buildGamePageRoute(PageView.PropertySelectionPage, round?.contestId!));
    }
  };

  const roundMonth = useRef<string>();
  const roundDay = useRef<number>();

  let contestStartingWarningModalStorage = getStorageRepo<ContestStartingWarningModal>(
    "game_review_acceptance",
    contestStartingWarningModal,
    StorageOption.Local
  );

  useEffect(() => {
    contestStartingWarningModal.acceptance = false;
    contestStartingWarningModal.expire = new Date();

    const monthtext = [
      "JANUARY",
      "FEBRUARY",
      "MARCH",
      "APRIL",
      "MAY",
      "JUNE",
      "JULY",
      "AUGUST",
      "SEPTEMBER",
      "OCTOBER",
      "NOVEMBER",
      "DECEMBER"
    ];
    const shoudReview = () => {
      let review = false;
      let now = moment();
      const startDate = moment(round?.localStartDate || contest?.localStartDate);
      const endDate = moment(round?.localEndDate || contest?.localEndDate);
      const daysFromEnd = endDate.diff(now, "days");
      const daysFromStart = startDate.diff(now, "days");

      if (status === ContestStatus.NotStarted) {
        if (daysFromStart <= AppConfig.REVIEW_PROPERTIES_DAYS && daysFromEnd > 0) {
          review = true;
        }
      } else {
        if (
          status === ContestStatus.InProgress &&
          daysFromStart < AppConfig.REVIEW_PROPERTIES_DAYS &&
          !contestStartingWarningModalStorage.get().acceptance
        ) {
          contestStartingWarningModal.acceptance = false;
          contestStartingWarningModal.expire = new Date();
          contestStartingWarningModalStorage.set(contestStartingWarningModal);
          review = true;
        }
      }
      return review;
    };

    const warningModalExpired = () => {
      let reviewExpired = false;
      const storageDate = contestStartingWarningModalStorage.get().expire;
      if (storageDate && contest?.localStartDate) {
        if (new Date(storageDate.toString()) < contest?.localStartDate) {
          reviewExpired = true;
        }
      }
      return reviewExpired;
    };

    if (isAuthenticated && userInfo) {
      if (
        shoudReview() &&
        (!contestStartingWarningModalStorage.get().acceptance || warningModalExpired())
      ) {
        setShowReview(true);
      } else {
        setShowReview(false);
      }
    }

    if (contest) {
      const showDate = contest?.startDate;
      roundMonth.current = monthtext[parseInt(showDate!.substring(5, 7)) - 1];
      roundDay.current = parseInt(showDate!.substring(8, 10));
      let datesDiff = moment(showDate).diff(moment(), "minutes");
      if (datesDiff > 0 && datesDiff < 4320) {
        setShowInThreeDays(true);
      }
    }
  }, [
    isAuthenticated,
    contestStartingWarningModalStorage,
    userInfo,
    status,
    contest,
    props,
    round?.localStartDate,
    round?.localEndDate
  ]);

  useEffect(() => {
    const showProperties = () => {
      showPropertiesPriorToOpeningBets().then((data) => {
        setIsBettingOpened(data!);
      });
    };
    showProperties();
  }, []);

  return (
    <div
      className={
        isAuthenticated && showReview && showInThreeDays
          ? "review-properties"
          : "review-properties hidden"
      }
    >
      <div className="rules-overlay" style={{ zIndex: 3 }}></div>
      <div className="review-properties-dialog">
        <button className="btn-close" onClick={goToPropertySelectionPage}>
          &#10006;
        </button>
        <div className="heading">
          {round?.name} BEGINS ON {roundMonth.current} {roundDay.current}!<br></br>ESTIMATE THE
          FINAL BID AND WIN!
        </div>
        <div className="body-text">
          <div>
            Please, review your property selections and bets to ensure that none of the properties
            have been moved to another auction round. Once the auction round begins, you cannot
            change your property selections or bets.
          </div>
        </div>
        <div className="action-button" onClick={goToPropertySelectionPage}>
          DISMISS
        </div>
      </div>
    </div>
  );
};
