import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import { Options } from "highcharts";

NoDataToDisplay(Highcharts);

// bettingData - array of [x, y] points for Bet Amount x Number of Bets>

const EmptyGraph = () => {
  const dataUnits = {
    K: 1000,
    M: 1000000
  };
  const unitKey = "M";

  let options: Options = {
    lang: {
      noData: "Please, select the Property to see the live Data"
    },
    noData: {
      style: {
        color: "#FFFFF",
        fontSize: "16px"
      }
    },
    chart: {
      backgroundColor: "rgba(255,255,255,0)",
      plotBackgroundColor: "#2D394D",
      type: "area",
      scrollablePlotArea: {
        minWidth: 600
      },
      style: {
        color: "#FFFFFF",
        padding: "0px 0px 0px 20px"
      }
    },
    title: {
      text: ""
    },
    legend: {
      enabled: false
    },
    series: [
      {
        type: "area",
        name: "",
        data: [],
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, "#B1BED5"],
            [0.3, "#8C929B"],
            [1, "#2D394D"]
          ]
        },
        threshold: 0
      }
    ],
    xAxis: {
      title: {
        text: "Bet Amount",
        style: {
          color: "#FFFFFF",
          fontSize: "16px"
        }
      },
      labels: {
        style: {
          color: "#FFFFFF",
          fontSize: "14px"
        },
        formatter: function () {
          let num = this.value as number;
          return (num / dataUnits[unitKey]).toFixed(1) + unitKey;
        }
      },
      tickInterval: dataUnits[unitKey] / 4
    },
    yAxis: {
      title: {
        text: "Number of Bets",
        style: {
          color: "#FFFFFF",
          fontSize: "16px"
        }
      },
      tickInterval: 10,
      gridLineWidth: 0,
      labels: {
        style: {
          color: "#FFFFFF",
          fontSize: "14px"
        }
      },
      maxPadding: 0.2
    },
    credits: {
      enabled: false
    }
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default EmptyGraph;
