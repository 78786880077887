import React, { useEffect, useRef } from "react";
import "../../content/styles/common/accept-rules.scss";
import { RulesText } from "../common/rules-text";

type Props = {
  isShown: boolean;
  hideModal: () => void;
};

export const StaticRulesModal: React.FC<Props> = ({ isShown, hideModal }) => {
  const rulesBodyRef = useRef<HTMLDivElement>(null);

  const hideRulesModal = () => {
    if (rulesBodyRef?.current?.scrollTo) {
      rulesBodyRef.current.scrollTo(0, 0);
    }
    hideModal();
  };

  useEffect(() => {
    if (rulesBodyRef?.current?.scrollTo) {
      rulesBodyRef?.current?.scrollTo(0, 0);
    }
  }, []);

  return (
    <div className={isShown === true ? "static-rules" : "static-rules hidden"}>
      <div className="rules-overlay"></div>
      <div className="rules-content-modal">
        <div className="rules-header">
          <button className="btn-close" onClick={hideRulesModal}>
            &#10006;
          </button>
          <h2>Official Rules</h2>
        </div>
        <div className="rules-body" ref={rulesBodyRef}>
          <RulesText />
        </div>
      </div>
    </div>
  );
};
