import React, { useState } from "react";
import arrowLeft from "../../content/images/arrow-left.svg";
import arrowRight from "../../content/images/arrow-right.svg";
import quote from "../../content/images/quote.svg";
import unquote from "../../content/images/unquote.svg";
interface IProps {
  testimonialData: Array<ITestimonialData>;
}

interface ITestimonialData {
  name: string;
  title: string;
  company: string;
  text: string;
  image: string;
}

export const TestimonialCarousel = ({ testimonialData }: IProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { name, title, company, text, image } = testimonialData[activeIndex];

  const handlePrev = () => {
    setActiveIndex((activeIndex + testimonialData.length - 1) % testimonialData.length);
  };

  const handleNext = () => {
    setActiveIndex((activeIndex + 1) % testimonialData.length);
  };

  return (
    <div className="testimonial-carousel">
      <div className="section-title bold-title">TESTIMONIALS FROM SEASON 1 PLAYERS</div>
      <div className="testimonial">
        <div className="testimonial-image-container">
          <img alt="left-arrow" className="arrows cursor" onClick={handlePrev} src={arrowLeft} />
          <img
            alt="testimonial-player-image"
            className="testimonial-winner-image-1"
            src={
              testimonialData[(activeIndex + testimonialData.length - 2) % testimonialData.length]
                .image
            }
          />
          <img
            alt="testimonial-player-image"
            className="testimonial-winner-image-2"
            src={
              testimonialData[(activeIndex + testimonialData.length - 1) % testimonialData.length]
                .image
            }
          />
          <img
            src={image}
            alt={`${name}'s profile`}
            className="testimonial-winner-image-3 active"
          />
          <img
            alt="testimonial-player-image"
            className="testimonial-winner-image-4"
            src={
              testimonialData[(activeIndex + testimonialData.length + 1) % testimonialData.length]
                .image
            }
          />
          <img
            alt="testimonial-player-image"
            className="testimonial-winner-image-5"
            src={
              testimonialData[(activeIndex + testimonialData.length + 2) % testimonialData.length]
                .image
            }
          />

          <img alt="right-arrow" className="arrows cursor" onClick={handleNext} src={arrowRight} />
        </div>
        <div className="testimonial-content-container">
          <div className="testimonial-name bold-title">{name}</div>
          <div className="testimonial-name bold-title">{company}</div>
          <p className="testimonial-title">{title}</p>
          <div className="testimonial-quote-container">
            <img alt="left-arrow" className="arrows cursor" onClick={handlePrev} src={arrowLeft} />
            <div className="testimonial-text-container">
              <img alt="quote" className="testimonial-quote" src={quote} />
              <p className="testimonial-text">{text}</p>
              <img alt="unquote" className="testimonial-unquote" src={unquote} />
            </div>
            <img
              alt="right-arrow"
              className="arrows cursor"
              onClick={handleNext}
              src={arrowRight}
            />
          </div>
        </div>
      </div>
      <div className="testimonial-controls"></div>
    </div>
  );
};
