import React from "react";
import "../../../src/content/styles/marketing/game-rules.scss";
import playBtn from "../../../src/content/images/marketing/play-btn.png";
import bobLogo from "../../../src/content/images/marketing/bob-lines-logo.svg";
import { MessageModalData } from "../../types/message-modal-data";
import { PageView } from "../../common/constants";
import htpCloud from "../../../src/content/images/marketing/htp-cloud.png";
import htpCloudRight from "../../../src/content/images/marketing/htp-cloud-right.png";
import gtm from "../../../src/helpers/gtm-helper";
import classNames from "classnames";

interface IHowToProps {
  howto: IHowToCard;
  handleMessageData: (data: MessageModalData) => void;
}
interface IHowToCard {
  id: number;
  title: string;
  subTitle?: string;
  videoSrc: string;
  thumbnail?: string;
}

interface IHowToSectionProps {
  data: IHowToCard[];
  handleMessageData: (data: MessageModalData) => void;
  sectionTitle?: string;
}

interface IProps {
  setMessageData: React.Dispatch<React.SetStateAction<MessageModalData>>;
  showTheMessageModal: () => void;
  feBobShowJets?: boolean;
}

export const GameRules: React.FC<IProps> = ({
  setMessageData,
  showTheMessageModal,
  feBobShowJets
}: IProps) => {
  const handleMessageData = (data: MessageModalData) => {
    showTheMessageModal();
    setMessageData(data);
    gtm.trackEvent("video_click", {
      video_section: "Rules"
    });
  };

  return (
    <div className="game-rules-container">
      <HeroSection />
      <HowToSection
        data={howtos}
        handleMessageData={handleMessageData}
        sectionTitle="HOW AUCTIONS WORK"
      />
      <FooterSection feBobShowJets={feBobShowJets} />
    </div>
  );
};

const HeroSection = () => (
  <div className="game-rules-hero-container">
    <div className="game-rules-logo-container">
      <img className="game-rules-bob-logo" src={bobLogo} />
    </div>
    <div className="game-rules-title game-rules-section-title bold-title">HOW TO PLAY</div>
    <img className="hero-cloud" src={htpCloud} alt="big cloud" />
    <img className="hero-cloud-right" src={htpCloudRight} alt="small cloud" />
    <div className="game-rules-section-steps-container">
      <div className="steps-sub-title">
        <div className="heading">
          <span className="number">1</span>{" "}
          <span>
            SELECT <br /> PROPERTIES
          </span>
        </div>
        <div className="text">For each round, select 10 Properties you want to place bets on.</div>
      </div>
      <div className="steps-sub-title">
        <div className="heading">
          <span className="number">2</span>{" "}
          <span>
            UNDERWRITE <br />
            THE PROPERTY
          </span>
        </div>
        <div className="text">
          Learn more about a property by clicking the “See more” link, which will take you to the
          listing details page.
        </div>
      </div>
      <div className="steps-sub-title">
        <div className="heading">
          <span className="number">3</span>{" "}
          <span>
            ENTER <br />
            YOUR BETS
          </span>
        </div>
        <div className="text">
          From the PROPERTIES page or the listing details page, enter your bet for each property.
        </div>
      </div>
      <div className="steps-sub-title">
        <div className="heading">
          <span className="number">4</span>{" "}
          <span>
            CHANGE <br />
            TWO BETS
          </span>
        </div>
        <div className="text">
          Watch the auction for your selected properties and change up to 2 bets within the final 10
          minutes of the auction.
        </div>
      </div>
      <div className="steps-sub-title">
        <div className="heading">
          <span className="number">5</span>{" "}
          <span>
            CHECK YOUR <br />
            SCORE
          </span>
        </div>
        <div className="text">Follow the game and your score on the My Scorecard page.</div>
      </div>
    </div>
  </div>
);

const FooterSection = ({ feBobShowJets }: { feBobShowJets?: boolean }) => (
  <div
    className={classNames({
      "game-rules-footer-container": true,
      "with-jets": feBobShowJets
    })}
  >
    <div className="game-rules-footer-container-hall-of-fame-section">
      <div className="game-rules-title game-rules-section-title bold-title">
        WANT TO HEAR FROM OUR SEASON 1 WINNERS?
      </div>
      <div className="text-center">
        <div>
          <a className="btn btn-rules" href={PageView.HallOfFame}>
            HALL OF FAME
          </a>
        </div>
      </div>
    </div>
    {/* <div className="row text-center">
      <div className="col question-col">
        <p className="question">
          Have more questions? Visit our <a>HELP</a> center.
        </p>
      </div>
    </div> */}
  </div>
);

const HowToSection = ({ handleMessageData, data, sectionTitle }: IHowToSectionProps) => (
  <div className="game-rules-howto-container">
    {data.map((howto: IHowToCard) => (
      <HowToCard howto={howto} handleMessageData={handleMessageData} key={howto.id} />
    ))}
    <div className="divider-container">
      <div className="divider"></div>
    </div>
  </div>
);

const HowToCard: React.FC<IHowToProps> = ({ howto, handleMessageData }: IHowToProps) => (
  <div className="howto-card-container">
    <div className="title-text">{howto.title}</div>
    <div className="subtitle-text">{howto.subTitle}</div>
    <div className="video-container">
      {/* TODO: video-thumbnail might be temporary since the real thumbnails will be from the videos */}
      <img className="video-thumbnail" src={howto.thumbnail} alt="video thumbnail" />
      <img
        className="play-button cursor"
        src={playBtn}
        alt="play button"
        onClick={() => {
          handleMessageData({
            message: "",
            messageTitle: "",
            videoUrl: `https://www.youtube.com/embed/${howto.videoSrc}?rel=0`
          });
        }}
      />
    </div>
  </div>
);

const howtos: Array<IHowToCard> = [
  {
    id: 0,
    title: "How to get started",
    subTitle: `In this video, we'll guide you step-by-step on how to get started to play Battle of the Bids, detailing the eligibility criteria and the registration process. If you've received an invitation from Ten-X or another CoStar Group brand, signing up is just a click away. If you haven't, don't worry - we'll walk you through how to create an account and request an invitation.`,
    videoSrc: "fRbMMBmEFqY",
    thumbnail: `https://img.youtube.com/vi/fRbMMBmEFqY/0.jpg`
  },
  {
    id: 1,
    title: "How to place your bets",
    subTitle: `This video provides an easy-to-follow guide on how to place bets. Understand how predicting the final sale price can earn you more points - the more precise, the higher your score! We'll highlight the importance of strategic property selection and underwriting for your betting success. Leverage our new underwriting tools and CoStar's best-in-class market data for each property to make informed decisions.`,
    videoSrc: "M-BLBVS80R4",
    thumbnail: "https://img.youtube.com/vi/M-BLBVS80R4/0.jpg"
  },
  {
    id: 2,
    title: "Track your progress",
    subTitle: `In Battle of the Bids 2, it's not just about placing your bets; it's about tracking your progress and staying ahead of the competition. Join us in this video as we delve into how you can watch the auction, monitor your points, and understand your rankings.`,
    videoSrc: "e3MvuEJUirU",
    thumbnail: "https://img.youtube.com/vi/e3MvuEJUirU/0.jpg"
  },
  {
    id: 3,
    title: "Tips for Auction Success",
    subTitle: `The goal of Battle of the Bids is to earn as many points as possible by accurately predicting what properties will sell for during an auction. Here are three tips on how to elevate your odds of winning the game.`,
    videoSrc: "v1RNu3DSpB8",
    thumbnail: "https://img.youtube.com/vi/v1RNu3DSpB8/0.jpg"
  }
];
