import React, { useEffect } from "react";
import { useEntryContext } from "../../common/entry-context";
import redExclamation from "../../content/images/red-exclamation.svg";

export enum ChangeBetAlertType {
  Error
}

type Props = {
  type: ChangeBetAlertType;
};

export const ChangeBetAlert: React.FC<Props> = ({ type }) => {
  const { dismissChangeBetError } = useEntryContext();

  const handleDismiss = () => {
    switch (type) {
      case ChangeBetAlertType.Error:
        dismissChangeBetError();
        break;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = "hidden";
    document.body.classList.add("locked");

    return () => {
      document.body.style.overflow = "scroll";
      document.body.classList.remove("locked");
    };
  }, []);

  return (
    <div className="change-bet-container">
      <div className="change-bet-overlay" style={{ zIndex: 3 }}></div>
      <div className="change-bet-dialog">
        <button className="btn-close" onClick={handleDismiss}>
          &#10006;
        </button>
        <div className="heading">
          <img src={redExclamation} alt="Exclamation" style={{ marginRight: 10 }} />
          CHANGE BET FAILED
        </div>

        <div className="body-text">{type === ChangeBetAlertType.Error && <ErrorContent />}</div>
        <div className="action-button" onClick={handleDismiss}>
          DISMISS
        </div>
      </div>
    </div>
  );
};

function ErrorContent() {
  return (
    <div>
      Your change bet attempt encountered a problem.
      <br />
      Please refresh your page and if the issue persists call
      <br />
      support at 888-619-5750.
    </div>
  );
}
