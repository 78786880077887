import React, { useState, useEffect } from "react";
import { PropertySelectionItem } from "./property-selection-item";
import "../../content/styles/game/property-placard.scss";
import { useContestContext } from "../../common/contest-context";
import spinningTenX from "../../content/images/tenx-pinwheel.svg";
import { ContestItem, ContestStatus } from "../../types/contest";
import { PageView } from "../../common/constants";
import { useHistory } from "react-router-dom";
import { useRoundContext } from "../../common/round-context";
import { useReadOnly } from "../../hooks/use-read-only";
import { buildGamePageRoute } from "../../helpers/game-route-builder";
import { LimitReachedModal } from "./limit-reached-modal";
import { useEntryContext } from "../../common/entry-context";
import { InvalidBetDialog } from "../common/invalid-bet-dialog";
import { BetErrorDialog } from "../common/bet-error-dialog";
import { PropertyTypeDropdown } from "../../types/property";
import { showPropertiesPriorToOpeningBets } from "../../helpers/date-helpers";

export const PropertySelection: React.FC = () => {
  const { contest, status, propertyTypes } = useContestContext();
  const { round, roundStatus } = useRoundContext();
  const history = useHistory();
  const isReadOnly = useReadOnly();

  const { showInvalidBetDialog, showBetErrorDialog } = useEntryContext();

  const selectedContest = isReadOnly ? contest : round;
  const selectedStatus = isReadOnly ? status : roundStatus;

  const [showLimitModal, setShowLimitModal] = useState<boolean>(false);
  const [isBettingOpened, setIsBettingOpened] = useState(false);

  const goToPropertySelection = () =>
    history.push(buildGamePageRoute(PageView.PropertySelectionPage, round?.contestId!));

  const limitReachedModal = () => {
    setShowLimitModal(!showLimitModal);
  };

  const hideLimitModal = () => {
    setShowLimitModal(false);
  };

  const renderBettingOpensMessage = () => {
    return (
      <div>
        <div className="page-heading-no-properties">
          Betting opens <span className="date">March 28th.</span> Round 1 auctions begin{" "}
          <span className="date">April 11th.</span>
          <br />
          Check back <span className="date">March 28th</span> to see available properties and place
          your bets!
        </div>
      </div>
    );
  };

  const renderBettingOpensLimitedMessage = () => {
    return (
      <div>
        <div className="page-heading-no-properties">
          Betting opens <span className="date">March 28th.</span> Round 1 auctions begin{" "}
          <span className="date">April 11th.</span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const showProperties = () => {
      showPropertiesPriorToOpeningBets().then((data) => {
        setIsBettingOpened(data!);
      });
    };
    showProperties();
  }, []);

  return (
    <>
      <div>
        {showInvalidBetDialog && <InvalidBetDialog />}
        {showBetErrorDialog && <BetErrorDialog />}
        <div className="placard-layout">
          {!isReadOnly ? (
            selectedStatus === ContestStatus.NotStarted &&
            (!isBettingOpened ? (
              <h2>
                {/* Select ten properties and enter your bets for what those properties will sell for. */}
              </h2>
            ) : (
              renderBettingOpensMessage()
            ))
          ) : (
            <>
              {isBettingOpened && renderBettingOpensLimitedMessage()}
              <div className="landing-header">
                <h2 className="page-heading">{selectedContest?.name} Properties</h2>
                {!isBettingOpened && (
                  <button onClick={goToPropertySelection}>Select Properties</button>
                )}
              </div>
            </>
          )}

          <div className="placard-grid">
            {(selectedContest &&
              (propertyTypes?.length === 0 || isReadOnly) &&
              selectedContest.contestItems.map((value: ContestItem) => (
                <PropertySelectionItem
                  contestId={selectedContest?.contestId?.toString()}
                  contestItem={value}
                  key={value.itemId}
                  setLimitReached={limitReachedModal}
                  isBettingOpened={isBettingOpened}
                />
              ))) ||
              (selectedContest &&
                !propertyTypes?.includes(PropertyTypeDropdown[0].id) &&
                selectedContest.contestItems
                  .filter((property) => propertyTypes?.includes(property.propertyTypeId!))
                  .map((value: ContestItem) => (
                    <PropertySelectionItem
                      contestId={selectedContest?.contestId?.toString()}
                      contestItem={value}
                      key={value.itemId}
                      setLimitReached={limitReachedModal}
                      isBettingOpened={isBettingOpened}
                    />
                  ))) || (
                <div className="spinner">
                  <img src={spinningTenX} className="spinner-logo" alt="Ten-X Logo" />
                </div>
              )}
          </div>
        </div>
      </div>
      <LimitReachedModal isShown={showLimitModal} hideModal={hideLimitModal} />
    </>
  );
};
