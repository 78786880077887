import * as React from "react";
import Auth from "@aws-amplify/auth";
import { AppConfig } from "../common/app_config";

class GatewayContainer extends React.Component {
  componentDidMount() {
    Auth.federatedSignIn({ customProvider: AppConfig.COGNITO_OIDC_PROVIDER });
  }

  render() {
    return <div />;
  }
}

export default GatewayContainer;
