import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useRoundContext } from "../common/round-context";
import { PlayerDomainInterfacesService } from "../player-api-client";
import { IBidBoxListing, IListingUpdatedMsg } from "../types/auction";
import { ContestStatus } from "../types/contest";
import { useAuctionSocket } from "./use-auction-socket";

/**
 * fetch tenx auction listings for the listings in current contest.
 * @returns
 */
export const useAuctionListings = () => {
  const { round, roundStatus } = useRoundContext();

  const [auctionListings, setAuctionListings] = useState<IBidBoxListing[]>([]);
  const auctionListingsRef = useRef(auctionListings);

  const inContestTenXListingIds = useMemo(() => {
    return roundStatus === ContestStatus.InProgress
      ? round?.contestItems?.filter((x) => x.tenXListingId)?.map((x) => x.tenXListingId!) || []
      : [];
  }, [roundStatus, round]);
  const [isLoading, setIsLoading] = useState(inContestTenXListingIds.length > 0);

  const listingUpdatedMessageHandler = useCallback((message: IListingUpdatedMsg) => {
    console.log("listingUpdatedMessageHandler", message, auctionListingsRef.current);

    if (!message.eventDetails) {
      return;
    }

    if (!message.eventDetails.current_bid_amt) {
      return;
    }

    const target = auctionListingsRef.current.find((x) => x.listing_id === message.listingId);
    if (target) {
      const updated = {
        ...target,
        ...message.eventDetails
      };
      const allOthers = auctionListingsRef.current.filter(
        (x) => x.listing_id !== message.listingId
      );
      const updatedAuctionListings = [...allOthers, updated];
      auctionListingsRef.current = updatedAuctionListings;
      setAuctionListings(updatedAuctionListings);
    }
  }, []);

  useAuctionSocket(
    auctionListings?.length ? inContestTenXListingIds : [],
    listingUpdatedMessageHandler
  );

  useEffect(() => {
    const fetchListings = async () => {
      const response = await PlayerDomainInterfacesService.apiTenxListingPost({
        listing_ids: inContestTenXListingIds
      });
      const responseListings = response as IBidBoxListing[];
      auctionListingsRef.current = responseListings;

      // batch multiple state updaters in async call - see: https://github.com/facebook/react/issues/16377
      ReactDOM.unstable_batchedUpdates(() => {
        if (round && round.contestRoundState !== "Past") {
          setAuctionListings(responseListings);
        }
        setIsLoading(false);
      });
    };

    if (inContestTenXListingIds?.length) {
      fetchListings();
    }
  }, [inContestTenXListingIds, round]);

  return { auctionListings, isLoading };
};
