import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import { MAX_BETS, PageView } from "../../common/constants";
import { useContestContext } from "../../common/contest-context";
import { useRoundContext } from "../../common/round-context";
import { useUserContext } from "../../common/user-context";
import { useEntryContext } from "../../common/entry-context";
import "../../content/styles/game/sub-header.scss";
import { buildGamePageRoute } from "../../helpers/game-route-builder";
import { RoundSelection } from "./round-selection";
import { PropertyTypeSelection } from "./property-type-selection";
import OfficialBanner from "../common/official-banner";
import { ContestStatus } from "../../types/contest";
//import { PropertyTypeDropdown } from "../../types/property";

interface Props {
  feBobEndOfSeason?: boolean;
}

export const SubHeader: React.FC<Props> = ({ feBobEndOfSeason }) => {
  const { contest } = useContestContext();
  const { round, roundStatus } = useRoundContext();
  const { userInfo } = useUserContext();
  const { items } = useEntryContext();
  const location = useLocation();

  const { pathname } = location;
  const sideLinkRef = useRef<null | HTMLDivElement>(null);

  const isLocationLeadboard = location?.pathname.includes("leaderboard");
  const isScoreCardPage = location.pathname.includes(PageView.ScoreCard);
  const isPropertySelectionPage = location.pathname.includes(PageView.PropertySelectionPage);
  const isScoreBoard = location.pathname.includes(PageView.ScoreboardPage);

  useEffect(() => {
    if (location.pathname !== PageView.LandingPage && sideLinkRef?.current?.scrollIntoView) {
      sideLinkRef?.current?.scrollIntoView({ behavior: "auto" });
    }
  }, [sideLinkRef, location]);

  useEffect(() => {
    const subheaderContainer = document.querySelector(".sub-header-container");
    const observer = new IntersectionObserver(
      ([e]) => {
        return e.target.classList.toggle("sticky-active", e.boundingClientRect.top < 0);
      },
      { threshold: 1 }
    );
    observer.observe(subheaderContainer as Element);
  }, []);

  return (
    <>
      <div
        className={classNames("sub-header-container", {
          "end-of-season": feBobEndOfSeason
        })}
      >
        <OfficialBanner
          isLeaderBoard={!!isLocationLeadboard}
          officialDateMessage={contest?.officialDateMessage}
        />
        <div className="sub-header">
          {!isScoreCardPage && (
            <div
              className={
                round?.round! < contest?.round!
                  ? "sub-header-nav no-property-selection"
                  : "sub-header-nav"
              }
            >
              <NavLink
                activeClassName="selected"
                to={buildGamePageRoute(PageView.PropertySelectionPage, contest?.contestId!)}
                bob-ga-data-on="click"
                bob-ga-data-event-category="RoundHeader"
                bob-ga-data-event-action="Click"
                bob-ga-data-event-label="PropertySelection"
                bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
                bob-ga-data-dimension3={round?.contestId}
              >
                Property Selection
              </NavLink>

              <NavLink
                activeClassName="selected"
                to={buildGamePageRoute(PageView.ScoreboardPage, round?.contestId!)}
                bob-ga-data-on="click"
                bob-ga-data-event-category="RoundHeader"
                bob-ga-data-event-action="Click"
                bob-ga-data-event-label="ScoreBoard"
                bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
                bob-ga-data-dimension3={round?.contestId}
              >
                My Scoreboard
              </NavLink>

              <NavLink
                activeClassName="selected"
                to={buildGamePageRoute(PageView.LeaderboardPage, round?.contestId!)}
                bob-ga-data-on="click"
                bob-ga-data-event-category="RoundHeader"
                bob-ga-data-event-action="Click"
                bob-ga-data-event-label="RoundLeaderBoard"
                bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
                bob-ga-data-dimension3={round?.contestId}
              >
                Round Leaderboard
              </NavLink>
            </div>
          )}
        </div>

        {!isScoreBoard && (
          <div className="sub-header">
            <div className="round-property-filter-container">
              {round?.round! >= contest?.round! && pathname === PageView.PropertySelectionPage && (
                <PropertyTypeSelection />
              )}
            </div>
            <div className="property-count-container">
              <div className="property-count">
                {isPropertySelectionPage && roundStatus === ContestStatus.NotStarted && (
                  <>
                    <span>{items.length}</span> of <span>{MAX_BETS}</span> bets placed
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
