import React from "react";
import "../../content/styles/common/end-of-season-hero.scss";

import WinnerSection from "./WinnerSection";
import { WinnerProps } from "./types";
import { MessageModalData } from "../../types/message-modal-data";
import BOBLogo from "../../content/images/marketing/bob-logo-desktop2.png";
import Season2Number from "../../content/images/marketing/season2.png";
import KevinHeadshot from "../../content/images/bob-winners/kevin-headshot.png";
import KyleHeadshot from "../../content/images/bob-winners/kyle-headshot.png";

const winners: Array<WinnerProps> = [
  {
    betAccuracy: "75.86%",
    company: "BUXTON CAPITAL GROUP LLC",
    donation: "$100,000 TO CHARITY (THE MIRACLE LEAGUE - PALM BEACH COUNTY)",
    excerpt:
      "Pretty pumped I played Battle of the Bids! I founded Buxton Capital Group a little over a year ago, and this will effectively fast-forward my company and career by two to three years. And I’m looking forward to giving back to my home in Delray and the Miracle League of Palm Beach County. I get to make a difference too, and that’s really nice.",
    headshotSrc: KevinHeadshot,
    name: "KEVIN BUXTON",
    title: "GRAND PRIZE WINNER",
    totalPoints: "19,200",
    videoSrc: "https://www.youtube.com/embed/UpSUkoxrK_o?rel=0",
    winnings: "$1,000,000"
  },
  {
    betAccuracy: "88.16%",
    company: "FIRST NATIONAL REALTY PARTNERS",
    donation: "$100,000 TO CHARITY (SCENIC HUDSON)",
    excerpt:
      "Who knew betting on CRE would be so fun? It was a nail-biter. I had a ton of fun going for that top prize. Thanks, Ten-X and Battle of the Bids!",
    headshotSrc: KyleHeadshot,
    name: "KYLE CAMILLIERE",
    title: "RUNNER-UP",
    totalPoints: "18,900",
    videoSrc: "https://www.youtube.com/embed/E07DYLy0A9Y?rel=0",
    winnings: "$100,000"
  }
];

interface Props {
  setMessageData: React.Dispatch<React.SetStateAction<MessageModalData>>;
  showTheMessageModal: () => void;
}

const EndOfSeasonHero: React.FC<Props> = ({ setMessageData, showTheMessageModal }) => {
  return (
    <div className="end-of-season-container">
      <div className="logo-container">
        <img className="bob-logo" src={BOBLogo} />
        <img className="season-2-number" src={Season2Number} />
      </div>
      <p className="subtitle">IS OVER!</p>
      {winners.map((winner) => (
        <WinnerSection
          key={winner.name}
          {...winner}
          setMessageData={setMessageData}
          showTheMessageModal={showTheMessageModal}
        />
      ))}
      <a className="season-2-button" href="/leaderboard">
        SEASON 2 RESULTS
      </a>
    </div>
  );
};

export default EndOfSeasonHero;
