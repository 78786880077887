import "./content/styles/index.scss";
import { AppConfig } from "./common/app_config";
import { ContestRootContainer } from "./containers/contest-root-container";
import GatewayContainer from "./containers/gateway-container";
import { Switch, Route } from "react-router-dom";


function App() {
  return (
    <div>
      <Switch>
        <Route path="/gateway">
          <GatewayContainer />
        </Route>

        <Route path="/">
          <ContestRootContainer />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
