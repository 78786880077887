import React, { useEffect } from "react";
import { HEADER, MODAL_CONTENT } from "./constants";
import gtmHelper from "../../../helpers/gtm-helper";

export const NotInvited: React.FC<any> = ({ permission }) => {
  useEffect(() => {
    gtmHelper.trackEvent("not_eligible_displayed");
  }, []);
  return (
    <>
      <div className="login-modal-header">{HEADER.GET_IN_THE_GAME}</div>
      <div className="login-modal-content">{MODAL_CONTENT.GET_IN_THE_GAME}</div>
      <div className="button-wrapper">
        <div className="button-deny">
          <button
            className="button-continue deny"
            onClick={() => {
              gtmHelper.trackEvent("not_eligible_no_click");
              permission({ action: "GetOnMailingList" });
            }}
          >
            NO
          </button>
        </div>
        <div className="button-confirm">
          <button
            className="button-continue confirm"
            onClick={() => {
              gtmHelper.trackEvent("not_eligible_yes_click");
              permission({ action: "RequestInvitation" });
            }}
          >
            YES
          </button>
        </div>
      </div>
    </>
  );
};
