import React, { useState, useEffect } from "react";
import logoTen_X from "../../content/images/logo-ten-x.svg";
import logoLoopnet from "../../content/images/logo-loopnet.svg";
import logoCoStar from "../../content/images/logo-costar.svg";
import goToTopIcon from "../../content/images/down-caret.svg";
import { useUserContext } from "../../common/user-context";
import { createUtmFragment } from "../../helpers/utm-helpers";
import { StaticRulesModal } from "../marketing-views/static-rules-modal";
import { AppConfig } from "../../common/app_config";

import linkedIn from "../../content/images/marketing/linkedIn_black.png";
import faceBook from "../../content/images/marketing/facebook_black.png";
import youTube from "../../content/images/marketing/youtube_black.png";
import x from "../../content/images/marketing/x_black.png";
import instagram from "../../content/images/marketing/instagram_black.png";

export const Footer = ({ feBobShowJets }: { feBobShowJets?: boolean }) => {
  const [showRulesModal, setShowRulesModal] = useState<boolean>(false);
  const [goToTopButton, setGoToTopButton] = useState<boolean>(false);

  const { userInfo } = useUserContext();
  const subject = userInfo ? userInfo.caSubject : "";

  const showModal = () => {
    document.body.classList.add("locked");
    setShowRulesModal(true);
  };

  const hideModal = () => {
    document.body.classList.remove("locked");
    setShowRulesModal(false);
  };

  const goToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const handleScroll = () => {
    if (window.scrollY > 150) {
      setGoToTopButton(true);
    } else {
      setGoToTopButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleCloseRulesModal = (e: any) => {
      let rulesContentModal = document.querySelector(".rules-content-modal");
      if (!rulesContentModal?.contains(e.target) && showRulesModal) {
        document.body.classList.remove("locked");
        setShowRulesModal(false);
      }
    };

    window.addEventListener("click", handleCloseRulesModal);
    return () => {
      window.removeEventListener("click", handleCloseRulesModal);
    };
  }, [showRulesModal]);

  return (
    <>
      <section id="cs-footer">
        <div className="container-fluid" style={{ maxWidth: "624px" }}>
          <div className="row footer-logos">
            <div className="footer-logo-col  footer-logo">
              <div className="row">
                <div className="col-md-12 mb-3 text-center">
                  <a
                    id="logo-tenx"
                    href={
                      AppConfig.TENX_SERVICE_ROOT +
                      createUtmFragment("BoBGameSite", "BoB", "footer", subject!)
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={logoTen_X} alt="Ten-X Logo" />
                  </a>
                </div>
              </div>
            </div>
            <div className="footer-logo-col footer-logo">
              <div className="row">
                <div className="col-md-12 mb-3 text-center">
                  <a
                    id="logo-costar"
                    href={
                      AppConfig.COSTAR_URL +
                      createUtmFragment("BoBGameSite", "BoB", "footer", subject!)
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className="logo-image" src={logoCoStar} alt="CoStar Logo" />
                  </a>
                </div>
              </div>
            </div>
            <div className="footer-logo-col footer-logo">
              <div className="row">
                <div className="col-md-12 mb-3 text-center">
                  <a
                    id="logo-loopnet"
                    href={
                      AppConfig.LOOPNET_URL +
                      createUtmFragment("BoBGameSite", "BoB", "footer", subject!)
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className="logo-image" src={logoLoopnet} alt="LoopNet Logo" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center mt-3 mb-3">
              <a
                href={
                  AppConfig.TENX_SERVICE_ROOT +
                  "/company/legal/terms/" +
                  createUtmFragment("BoBGameSite", "BoB", "footer", subject!)
                }
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use
              </a>{" "}
              |&nbsp;
              <a
                href={
                  AppConfig.TENX_SERVICE_ROOT +
                  "/company/legal/privacy" +
                  createUtmFragment("BoBGameSite", "BoB", "footer", subject!).substring(1)
                }
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>{" "}
              |&nbsp;
              <a
                href={
                  "https://www.costar.com/about/do-not-sell-my-personal-information-california-residents?_gl=1%2a3jrt5n%2a_ga%2aNzYzNzA5MTUxLjE2MzUxODQyMzE.%2a_ga_V05WP76GZL%2aMTY0MDAzMzYyNS44OS4xLjE2NDAwMzM4NzAuNw..&" +
                  createUtmFragment("BoBGameSite", "BoB", "footer", subject!).substring(2)
                }
                target="_blank"
                rel="noreferrer"
              >
                CA: Do Not Sell My Info
              </a>{" "}
              |&nbsp;
              <button
                onClick={showModal}
                className="game-rules-trigger game-rules-trigger-button"
                bob-ga-data-on="click"
                bob-ga-data-event-category="Header"
                bob-ga-data-event-action="Click"
                bob-ga-data-event-label="GameRules"
                bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
              >
                Official Rules
              </button>
            </div>
            <div className="footer-social-media">
              <a target="_blank" rel="noreferrer" title="LinkedIn" href="https://www.linkedin.com/company/ten-x/">
                <img src={linkedIn} alt="LinkedIn" />
              </a>
              <a target="_blank" rel="noreferrer" title="Facebook" href="https://www.facebook.com/tenxcommercial">
                <img src={faceBook} alt="FaceBook" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                title="Youtube"
                href="https://www.youtube.com/channel/UCZfrZg4UMz6U1tCYc-x5-_A"
              >
                <img src={youTube} alt="YouTube" />
              </a>
              <a target="_blank" rel="noreferrer" title="X/Twitter" href="https://twitter.com/tenx_cre">
                <img src={x} alt="X/Twitter" />
              </a>
              <a target="_blank" rel="noreferrer" title="Instagram" href="http://www.instagram.com/tenx_cre">
                <img src={instagram} alt="Instagram" />
              </a>
            </div>
          </div>
          <div className={`go-to-top-button ${goToTopButton ? "show" : ""}`}>
            <div onClick={goToTop}>
              <img src={goToTopIcon} alt="Go To Top" />
            </div>
          </div>
        </div>
        <br />
        <br />
      </section>
      <StaticRulesModal isShown={showRulesModal} hideModal={hideModal} />
    </>
  );
};
