import { useEffect, useRef } from "react";

export function useUpdate(callBack: any) {
  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return callBack();
    }
  });
  return;
}
