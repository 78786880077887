import React from "react";
import "../../../content/styles/common/how-it-works.scss";

interface IBoxData {
  bigNumber: string;
  subtitle: string;
  description: string;
}

export const HowItWorks: React.FC = () => {
  return (
    <>
      <div className="how-it-works-container">
        <div className="title bthen-header">BY THE NUMBERS</div>
        <div className="game-explanation-container">
          {gameExplanationArray.map((elm, index) => (
            <GameExplanationBox
              bigNumber={elm.bigNumber}
              subtitle={elm.subtitle}
              description={elm.description}
              key={index}
            />
          ))}
        </div>
        <div className="game-explanation-subtext">
          Get in the game for a chance to win <span className="highlighted-color">$1,000,000</span>{" "}
          and become the next ace.
        </div>
        <div className="game-explanation-subtext">Over $250,000 in cash prizes each round.</div>
        <div className="learn-more-button">
          <a href="/prizes">MORE ABOUT PRIZES</a>
        </div>
      </div>
    </>
  );
};

const GameExplanationBox: React.FC<IBoxData> = ({ bigNumber, subtitle, description }) => (
  <div className="explanation-box">
    <div className="number">{bigNumber}</div>
    <div className="left-border">
      <div className="subtitle">{subtitle}</div>
      <div className="description">{description}</div>
    </div>
  </div>
);

const gameExplanationArray: Array<IBoxData> = [
  {
    bigNumber: "6",
    subtitle: "Rounds this season",
    description:
      "This season, there are six rounds to play. The more you play, the more chances of winning."
  },
  {
    bigNumber: "10",
    subtitle: "Properties to pick",
    description:
      "In each round, predict the final sale price of 10 properties in the Ten-X online auction."
  },
  {
    bigNumber: "31",
    subtitle: "Winners per round",
    description:
      "At the end of each round, the top 31 players will receive a cash prize ranging from $100,000 to $1,000."
  },
  {
    bigNumber: "1",
    subtitle: "Second place winner",
    description:
      "New to this season, a runner-up winner will take away a $100,000 prize plus $10,000 to give to a charity."
  },
  {
    bigNumber: "1",
    subtitle: "Grand prize winner",
    description:
      "At the end of the season, one grand prize winner will take away $1,000,000 plus $100,000 to give to a charity."
  }
];
