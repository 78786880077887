/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContestDomainClient_ViewModels_AcceptChangeBetLimitationsRequest } from "../models/ContestDomainClient_ViewModels_AcceptChangeBetLimitationsRequest";
import type { ContestDomainClient_ViewModels_AcceptChangeBetLimitationsResponse } from "../models/ContestDomainClient_ViewModels_AcceptChangeBetLimitationsResponse";
import type { ContestDomainClient_ViewModels_ContestPrizeResponse } from "../models/ContestDomainClient_ViewModels_ContestPrizeResponse";
import type { ContestDomainClient_ViewModels_GetLeaderboardPageRequest } from "../models/ContestDomainClient_ViewModels_GetLeaderboardPageRequest";
import type { ContestDomainClient_ViewModels_GetLeaderboardPageResponse } from "../models/ContestDomainClient_ViewModels_GetLeaderboardPageResponse";
import type { ContestDomainClient_ViewModels_GetPlayerResponse } from "../models/ContestDomainClient_ViewModels_GetPlayerResponse";
import type { ContestDomainClient_ViewModels_GetPlayerRoundScoreCardResponse } from "../models/ContestDomainClient_ViewModels_GetPlayerRoundScoreCardResponse";
import type { ContestDomainClient_ViewModels_GetSeasonRoundsResponse } from "../models/ContestDomainClient_ViewModels_GetSeasonRoundsResponse";
import type { ContestDomainClient_ViewModels_LeaderboardPlayerScore } from "../models/ContestDomainClient_ViewModels_LeaderboardPlayerScore";
import type { ContestDomainClient_ViewModels_SubmitEntryRequest } from "../models/ContestDomainClient_ViewModels_SubmitEntryRequest";
import type { ContestDomainClient_ViewModels_SubmitEntryResponse } from "../models/ContestDomainClient_ViewModels_SubmitEntryResponse";
import type { ContestDomainClient_ViewModels_SubmitRestrictedEntryRequest } from "../models/ContestDomainClient_ViewModels_SubmitRestrictedEntryRequest";
import type { ContestDomainClient_ViewModels_SubmitRestrictedEntryResponse } from "../models/ContestDomainClient_ViewModels_SubmitRestrictedEntryResponse";
import type { ContestDomainClient_ViewModels_PropertyStats } from "../models/ContestDomainClient_ViewModels_PropertyStats";
import type { PlayerDomain_ViewModels_ContestModels_GetContestResponse } from "../models/PlayerDomain_ViewModels_ContestModels_GetContestResponse";
import type { PlayerDomain_ViewModels_EntryModels_GetEntryResponse } from "../models/PlayerDomain_ViewModels_EntryModels_GetEntryResponse";
import type { PlayerDomain_ViewModels_PlayerAcceptRulesResponse } from "../models/PlayerDomain_ViewModels_PlayerAcceptRulesResponse";
import type { PlayerDomain_ViewModels_PlayerAddressWarningDismissalResponse } from "../models/PlayerDomain_ViewModels_PlayerAddressWarningDismissalResponse";
import type { PlayerDomain_ViewModels_PlayerConfettiDismissalResponse } from "../models/PlayerDomain_ViewModels_PlayerConfettiDismissalResponse";
import type { PlayerDomain_ViewModels_PlayerUpdateAddressRequest } from "../models/PlayerDomain_ViewModels_PlayerUpdateAddressRequest";
import type { PlayerDomain_ViewModels_PlayerUpdateAddressResponse } from "../models/PlayerDomain_ViewModels_PlayerUpdateAddressResponse";
import type { PlayerDomain_ViewModels_TenXAuctionData } from "../models/PlayerDomain_ViewModels_TenXAuctionData";
import type { PlayerDomain_ViewModels_TenXAuctionDataRequest } from "../models/PlayerDomain_ViewModels_TenXAuctionDataRequest";
import type { PlayerDomain_ViewModels_TenXServerTime } from "../models/PlayerDomain_ViewModels_TenXServerTime";
import type { PlayerDomain_ViewModels_PlayerSearchRequest } from "../models/PlayerDomain_ViewModels_PlayerSearchRequest";
import type { PlayerDomain_ViewModels_PlayerSearchResponse } from "../models/PlayerDomain_ViewModels_PlayersSearchResponse";
import type { PlayerDomain_ViewModels_PlayerEligibilityResponse } from "../models/PlayerDomain_ViewModels_PlayerEligibilityResponse";
import type { PlayerDomain_ViewModels_Player_Signup } from "../models/PlayerDomain_ViewModels_Player_Signup";
import type { PlayerDomain_ViewModels_PlayerSignupResponse } from "../models/PlayerDomain_ViewModels_Players_SignupResponse";
import type { PlayerDomain_ViewModels_Player_GetAuthUrl } from "../models/PlayerDomain_ViewModels_Player_GetAuthUrl";
import type { PlayerDomain_ViewModels_Player_GetLoginUrl } from "../models/PlayerDomain_ViewModels_Player_GetLoginUrl";
import type { PlayerDomain_ViewModels_Player_GetAuthUrlResponse } from "../models/PlayerDomain_ViewModels_Player_GetAuthUrlResponse";
import { ApiEndpoints } from "../../types/api-endpoints";
import { request as __request } from "../core/request";
import { ContestDomainClient_ViewModels_GetPlayerRankingRequest } from "../models/ContestDomainClient_ViewModels_GetPlayerRankingRequest";
import { ContestDomainClient_ViewModels_GetPlayerRankingResponse } from "../models/ContestDomainClient_ViewModels_GetPlayerRankingResponse";
import { ContestDomainClient_ViewModels_GetPlayerSeasonStatsRequest } from "../models/ContestDomainClient_ViewModels_GetPlayerSeasonStatsRequest";
import { ContestDomainClient_ViewModels_GetPlayerSeasonStatsResponse } from "../models/ContestDomainClient_ViewModels_GetPlayerSeasonStatsResponse";
import { PlayerDomain_ViewModels_listingStatisticsResponse } from "../models/PlayerDomain_ViewModels_listingStatisticsResponse";
import { PlayerDomain_ViewModels_VideoCollectionResponse } from "../models/PlayerDomain_ViewModels_VideoCollectionResponse";
import { PlayerDomain_ViewModels_VideoFeatureResponse } from "../models/PlayerDomain_ViewModels_VideoFeatureResponse";
import { ApiError } from "../core/ApiError";
import { PlayerDomain_ViewModels_SeasonRoundWinnersResponse } from "../models/PlayerDomain_ViewModels_SeasonRoundWinnersResponse";
import { PlayerDomain_ViewModels_SeasonBonusDrawWinnerResponse } from "../models/PlayerDomain_ViewModels_SeasonBonusDrawWinnerResponse";
import { PlayerDomain_ViewModels_FeatureFlagResponse } from "../models/PlayerDomain_ViewModels_FeatureFlagResponse";

export class PlayerDomainInterfacesService {
  public static async apiPlayersSearchTypeaheadPost(
    requestBody?: PlayerDomain_ViewModels_PlayerSearchRequest | null
  ): Promise<PlayerDomain_ViewModels_PlayerSearchResponse> {
    const result = await __request({
      method: "POST",
      path: `/api/admin/players/search`,
      body: requestBody,
      api: ApiEndpoints.Admin
    });
    return result.body;
  }

  // get url for player to set password on CA
  public static async apiPlayerSignURLPost(
    requestBody?: PlayerDomain_ViewModels_Player_GetAuthUrl | null
  ): Promise<PlayerDomain_ViewModels_Player_GetAuthUrlResponse> {
    const result = await __request({
      method: "POST",
      path: `/api/player/GenerateAuthUrl`,
      body: requestBody
    });
    return result.body;
  }

  // login to CA
  public static async apiPlayerLoginURLPost(
    requestBody?: PlayerDomain_ViewModels_Player_GetLoginUrl | null
  ): Promise<PlayerDomain_ViewModels_Player_GetAuthUrlResponse> {
    const result = await __request({
      method: "POST",
      path: `/api/SignUp/GetLoginUrl`,
      body: requestBody
    });
    return result.body;
  }

  // create a CA (sign up in CA), then player need to set password later
  public static async apiPlayerSignUpPost(
    requestBody?: PlayerDomain_ViewModels_Player_Signup | null
  ): Promise<PlayerDomain_ViewModels_PlayerSignupResponse> {
    const result = await __request({
      method: "POST",
      path: `/api/signUpPlayer/submit`,
      body: requestBody
    });
    return result.body;
  }

  public static async apiVideoCollection(
    seasonId?: string
  ): Promise<PlayerDomain_ViewModels_VideoCollectionResponse> {
    const result = await __request({
      method: "GET",
      path: `/api/season/video/${seasonId}`
    });
    return result.body;
  }

  public static async apiVideoFeatured(
    seasonId?: string
  ): Promise<PlayerDomain_ViewModels_VideoFeatureResponse> {
    const result = await __request({
      method: "GET",
      path: `/api/season/video/featured/${seasonId}`
    });
    return result.body;
  }

  public static async apiPlayerElegibility(
    email?: string
  ): Promise<PlayerDomain_ViewModels_PlayerEligibilityResponse> {
    const result = await __request({
      method: "GET",
      path: `/api/player/status/${email}`
    });
    return result.body;
  }

  public static async propertyStatistics(
    constestId?: string,
    itemId?: string
  ): Promise<PlayerDomain_ViewModels_listingStatisticsResponse> {
    const result = await __request({
      method: "GET",
      path: `/api/contest/${constestId}/${itemId}/graph-data-point-property-statistic`
    });
    return result.body;
  }

  /**
   * Get contest by contest id
   * @param contestId
   * @returns PlayerDomain_ViewModels_ContestModels_GetContestResponse Success
   * @throws ApiError
   */
  public static async apiContestByContestIdGet(
    contestId: string | null
  ): Promise<PlayerDomain_ViewModels_ContestModels_GetContestResponse> {
    const result = await __request({
      method: "GET",
      path: `/api/contest/${contestId}`
    });
    return result.body;
  }

  /**
   * Retrieve the contest prizes.
   * @param contestId
   * @returns ContestDomainClient_ViewModels_ContestPrizeResponse Success
   * @throws ApiError
   */
  public static async apiContestByContestIdPrizeGet(
    contestId: string | null
  ): Promise<ContestDomainClient_ViewModels_ContestPrizeResponse> {
    const result = await __request({
      method: "GET",
      path: `/api/contest/${contestId}/prize`
    });
    return result.body;
  }

  /**
   * Get current contest.
   * @returns PlayerDomain_ViewModels_ContestModels_GetContestResponse Success
   * @throws ApiError
   */
  public static async apiContestCurrentGet(): Promise<PlayerDomain_ViewModels_ContestModels_GetContestResponse> {
    const result = await __request({
      method: "GET",
      path: `/api/contest/current`
    });
    return result.body;
  }

  /**
   * Get current contest.
   * @returns PlayerDomain_ViewModels_ContestModels_GetContestResponse Success
   * @throws ApiError
   */
  public static async apiContestLastEndedGet(): Promise<PlayerDomain_ViewModels_ContestModels_GetContestResponse> {
    const result = await __request({
      method: "GET",
      path: `/api/contest/ended/latest`
    });
    return result.body;
  }

  /**
   * to get a specified leaderboard page
   * @param requestBody
   * @returns ContestDomainClient_ViewModels_GetLeaderboardPageResponse Success
   * @throws ApiError
   */
  public static async apiContestLeaderboardPost(
    requestBody?: ContestDomainClient_ViewModels_GetLeaderboardPageRequest | null
  ): Promise<ContestDomainClient_ViewModels_GetLeaderboardPageResponse> {
    const result = await __request({
      method: "POST",
      path: `/api/contest/leaderboard`,
      body: requestBody
    });
    return result.body;
  }

  /**
   * get the ranking for current round for a player
   * @param requestBody
   * @returns ContestDomainClient_ViewModels_GetPlayerRankingResponse Success
   * @throws ApiError
   */
  public static async apiContestPlayerRankingPost(
    requestBody?: ContestDomainClient_ViewModels_GetPlayerRankingRequest | null
  ): Promise<ContestDomainClient_ViewModels_GetPlayerRankingResponse> {
    const result = await __request({
      method: "POST",
      path: `/api/leaderboard/player-ranking`,
      body: requestBody
    });
    if (result.status == 404) throw new ApiError(result, "player ranking not found");

    return result.body;
  }

  /**
   * get the season stats for a player
   * @param requestBody
   * @returns ContestDomainClient_ViewModels_GetPlayerSeasonStatsResponse Success
   * @throws ApiError
   */
  public static async apiContestPlayerSeasonStatsPost(
    requestBody?: ContestDomainClient_ViewModels_GetPlayerSeasonStatsRequest | null
  ): Promise<ContestDomainClient_ViewModels_GetPlayerSeasonStatsResponse> {
    const result = await __request({
      method: "POST",
      path: `/api/leaderboard/player-season-stats`,
      body: requestBody
    });
    if (result.status == 404) throw new ApiError(result, "player season stats not found");

    return result.body;
  }

  /**
   * Get graph data for a property in contest.
   * @param contestId
   * @param tenXPropertyId
   * @returns ContestDomainClient_ViewModels_LeaderboardPlayerScore Success
   * @throws ApiError
   */
  public static async apiPropertyStatsGraphPointsByContestIdByTenXPropertyIdGet(
    contestId: string | null,
    tenXPropertyId: string | null
  ): Promise<ContestDomainClient_ViewModels_PropertyStats> {
    const result = await __request({
      method: "GET",
      path: `/api/contest/${contestId}/${tenXPropertyId}/graph-data-point-property-statistic`
    });
    return result.body;
  }

  /**
   * Get All Entries for the contest per player
   * @param contestId
   * @returns PlayerDomain_ViewModels_EntryModels_GetEntryResponse Success
   * @throws ApiError
   */
  public static async apiEntryByContestIdByPlayerIdGet(
    contestId: string | null
  ): Promise<PlayerDomain_ViewModels_EntryModels_GetEntryResponse> {
    const result = await __request({
      method: "GET",
      path: `/api/entry/${contestId}`
    });
    return result.body;
  }

  /**
   * Get All Entries for the contest per player
   * @param contestId
   * @param playerId
   * @returns PlayerDomain_ViewModels_EntryModels_GetEntryResponse Success
   * @throws ApiError
   */
  public static async apiEntryPlayerByContestIdByPlayerIdGet(
    contestId: string | null,
    playerId: string
  ): Promise<PlayerDomain_ViewModels_EntryModels_GetEntryResponse> {
    const result = await __request({
      method: "GET",
      path: `/api/entry/${contestId}/${playerId}`
    });
    return result.body;
  }

  /**
   * Accept the limites of change bets.
   * @param requestBody
   * @returns ContestDomainClient_ViewModels_AcceptChangeBetLimitationsResponse Success
   * @throws ApiError
   */
  public static async apiEntryAccemptLimitsPost(
    requestBody?: ContestDomainClient_ViewModels_AcceptChangeBetLimitationsRequest | null
  ): Promise<ContestDomainClient_ViewModels_AcceptChangeBetLimitationsResponse> {
    const result = await __request({
      method: "POST",
      path: `/api/entry/accemptLimits`,
      body: requestBody
    });
    return result.body;
  }

  /**
   * Delete an entry before the contest starts.
   * After contest starts, this API will return an error for delete single entry.
   * Will also throw invalid request if player already has enough entries.
   * @param itemId
   * @returns ContestDomainClient_ViewModels_SubmitEntryResponse Success
   * @throws ApiError
   */
  public static async apiEntrySingleDelete(
    itemId: string | undefined
  ): Promise<ContestDomainClient_ViewModels_SubmitEntryResponse> {
    const result = await __request({
      method: "POST",
      path: `/api/entry/single/delete/${itemId}`
    });
    return {
      ...result.body,
      statusCode: result.status
    };
  }

  /**
   * Submits entries before the contest starts.
   * @param requestBody
   * @returns ContestDomainClient_ViewModels_SubmitEntryResponse Success
   * @throws ApiError
   */
  public static async apiEntrySingleSubmitPost(
    requestBody?: ContestDomainClient_ViewModels_SubmitEntryRequest | null
  ): Promise<ContestDomainClient_ViewModels_SubmitEntryResponse> {
    const result = await __request({
      method: "POST",
      path: `/api/entry/single/submit`,
      body: requestBody
    });
    return {
      ...result.body,
      statusCode: result.status
    };
  }

  /**
   * Submits entries before the contest starts.
   * @param requestBody
   * @returns ContestDomainClient_ViewModels_SubmitEntryResponse Success
   * @throws ApiError
   */
  public static async apiEntrySubmitPost(
    requestBody?: ContestDomainClient_ViewModels_SubmitEntryRequest | null
  ): Promise<ContestDomainClient_ViewModels_SubmitEntryResponse> {
    const result = await __request({
      method: "POST",
      path: `/api/entry/submit`,
      body: requestBody
    });
    return result.body;
  }

  /**
   * Submits restricted entries after the contest starts.
   * @param requestBody
   * @returns ContestDomainClient_ViewModels_SubmitRestrictedEntryResponse Success
   * @throws ApiError
   */
  public static async apiEntrySubmitRestrictedPost(
    requestBody?: ContestDomainClient_ViewModels_SubmitRestrictedEntryRequest | null
  ): Promise<ContestDomainClient_ViewModels_SubmitRestrictedEntryResponse> {
    const result = await __request({
      method: "POST",
      path: `/api/entry/submitRestricted`,
      body: requestBody
    });
    if (result.status === 403)
      alert("Sorry, but you have already used up your chances to change this amount.");
    return {
      ...result.body,
      statusCode: result.status
    };
  }

  /**
   * Get the specified player's leaderboard entry.
   * @param seasonId
   * @param contestId
   * @param playerId
   * @returns ContestDomainClient_ViewModels_LeaderboardPlayerScore Success
   * @throws ApiError
   */
  public static async apiLeaderboardBySeasonIdByContestIdByPlayerIdGet(
    seasonId: string | null,
    contestId: string | null,
    playerId: string | null
  ): Promise<ContestDomainClient_ViewModels_LeaderboardPlayerScore> {
    const result = await __request({
      method: "GET",
      path: `/api/leaderboard/${seasonId}/${contestId}/${playerId}`
    });
    return result.body;
  }

  /**
   * Get the current Player.
   * @returns ContestDomainClient_ViewModels_GetPlayerResponse Success
   * @throws ApiError
   */
  public static async apiPlayerCurrentGet(): Promise<ContestDomainClient_ViewModels_GetPlayerResponse> {
    const result = await __request({
      method: "GET",
      path: `/api/player/current`
    });
    return result.body;
  }

  /**
   * Get the current player's score card for a round.
   * @param seasonId
   * @param contestId
   * @returns ContestDomainClient_ViewModels_GetPlayerRoundScoreCardResponse Success
   * @throws ApiError
   */
  public static async apiPlayerCurrentScorecardBySeasonIdByContestIdGet(
    seasonId: string | null,
    contestId: string | null
  ): Promise<ContestDomainClient_ViewModels_GetPlayerRoundScoreCardResponse> {
    const result = await __request({
      method: "GET",
      path: `/api/player/current/scorecard/${seasonId}/${contestId}`
    });
    return result.body;
  }

  /**
   * Get the season rounds.
   * @param seasonId
   * @returns ContestDomainClient_ViewModels_GetSeasonRoundsResponse Success
   * @throws ApiError
   */
  public static async apiSeasonBySeasonIdRoundsGet(
    seasonId: string | null
  ): Promise<ContestDomainClient_ViewModels_GetSeasonRoundsResponse> {
    const result = await __request({
      method: "GET",
      path: `/api/season/${seasonId}/rounds`
    });
    return result.body;
  }

  public static async apiSeasonTopRoundWinners(
    seasonId?: string | null
  ): Promise<PlayerDomain_ViewModels_SeasonRoundWinnersResponse> {
    const result = await __request({
      method: 'GET',
      path: `/api/season/round/winner/${seasonId}`
    });
    return result.body;
  }

  public static async apiSeasonBonusDrawWinners(
    seasonId?: string | null
  ): Promise<PlayerDomain_ViewModels_SeasonBonusDrawWinnerResponse> {
    const result = await __request({
      method: 'GET',
      path: `/api/season/round/winner/bonus/${seasonId}`
    });
    return result.body;
  }

  public static async apiTenFeaturFlagPost(
    requestBody: string[]
  ): Promise<PlayerDomain_ViewModels_FeatureFlagResponse> {
    const result = await __request({
      method: "POST",
      path: `/api/tenx/featureflags`,
      body: requestBody
    });
    return result.body;
  }


  /**
   * Returns auction data for tenx listing.
   * @param requestBody
   * @returns PlayerDomain_ViewModels_TenXAuctionData Success
   * @throws ApiError
   */
  public static async apiTenxListingPost(
    requestBody?: PlayerDomain_ViewModels_TenXAuctionDataRequest | null
  ): Promise<Array<PlayerDomain_ViewModels_TenXAuctionData>> {
    const result = await __request({
      method: "POST",
      path: `/api/tenx/listing`,
      body: requestBody
    });
    return result.body;
  }

  /**
   * Return TenX server time in UTC.
   * @returns PlayerDomain_ViewModels_TenXServerTime Success
   * @throws ApiError
   */
  public static async apiTenxServertimeGet(): Promise<PlayerDomain_ViewModels_TenXServerTime> {
    const result = await __request({
      method: "GET",
      path: `/api/tenx/servertime`
    });
    return result.body;
  }

  /**
   * A POST RPC to allow the user to accept the game rules.
   * @param requestBody
   * @returns PlayerDomain_ViewModels_PlayerAcceptRulesResponse Success
   * @throws ApiError
   */
  public static async playerAcceptRulesPost(): Promise<PlayerDomain_ViewModels_PlayerAcceptRulesResponse> {
    const result = await __request({
      method: "POST",
      path: `/player/acceptRules`
    });
    return result.body;
  }

  /**
   * A POST RPC to allow the record the date Address modal was dismissed/accepted.
   * @param requestBody
   * @returns PlayerDomain_ViewModels_PlayerAddressWarningDismissalResponse Success
   * @throws ApiError
   */
  public static async playerDismissAddressWarningPost(): Promise<PlayerDomain_ViewModels_PlayerAddressWarningDismissalResponse> {
    const result = await __request({
      method: "POST",
      path: `/player/dismissAddressWarning`
    });
    return result.body;
  }

  /**
   * A POST RPC to allow the record the date the Confetti modal was dismissed/accepted
   * @param requestBody
   * @returns PlayerDomain_ViewModels_PlayerConfettiDismissalResponse Success
   * @throws ApiError
   */
  public static async playerDismissConfettiWarningPost(): Promise<PlayerDomain_ViewModels_PlayerConfettiDismissalResponse> {
    const result = await __request({
      method: "POST",
      path: `/player/dismissConfettiWarning`
    });
    return result.body;
  }

  /**
   * A POST RPC to allow the user to add the address they want any prizes to be mailed to.
   * @param requestBody
   * @returns PlayerDomain_ViewModels_PlayerUpdateAddressResponse Success
   * @throws ApiError
   */
  public static async playerUpdateAddressPost(
    requestBody?: PlayerDomain_ViewModels_PlayerUpdateAddressRequest | null
  ): Promise<PlayerDomain_ViewModels_PlayerUpdateAddressResponse> {
    const result = await __request({
      method: "POST",
      path: `/player/updateAddress`,
      body: requestBody
    });
    return result.body;
  }
}
