import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";
import { useContestContext } from "../../common/contest-context";
import { useFeatureFlagContext } from "../../common/feature-flag-context";
import { LeaderBoardItem } from "./leaderboard-item";
import { ContestStatus } from "../../types/contest";
import {
  ContestDomainClient_Models_LeaderboardSort,
  PlayerDomainInterfacesService
} from "../../player-api-client";
import { useUserContext } from "../../common/user-context";
import { ApiLeaderboardPlayerScore, ApiSeasonRound } from "../../types/api-models";
import "../../content/styles/game/leaderboard-v2.scss";
import spinningTenX from "../../content/images/tenx-pinwheel.svg";
import { useRoundContext } from "../../common/round-context";
import { useBroadcastContext } from "../../common/broadcast-socket-context";
import { useReadOnly } from "../../hooks/use-read-only";
import { PageSize, stickyTableRow } from "../../common/constants";
import { mapLeaderboardPlayerScore } from "../../helpers/leaderboard-player-score-mapper";
import { PageView } from "../../common/constants";
import { useLocation } from "react-router-dom";
import "../../content/styles/game/points-tooltip.scss";
import { MainLeaderBoardSelection } from "../sub-header/main-leaderboard-selection";
import { WinnerCard } from "../../components/marketing-views/hall-of-fame";
import { MessageModalData } from "../../types/message-modal-data";
import { RoundSelection } from "../sub-header/round-selection";
import BobLogo from "../../content/images/marketing/bob-lines-logo.svg";
import { Link, NavLink } from "react-router-dom";

import jet from "../../content/images/marketing/help-footer-jet.png";
import cloudL from "../../content/images/marketing/help-footer-cloud-l.png";
import cloudR from "../../content/images/marketing/help-footer-cloud-r.png";
import seasonarrow from "../../content/images/seasonarrow.png";
import gtm from "../../helpers/gtm-helper";

import {
  BonusPoints,
  PotentialWinnings,
  PointsCalculated,
  AccuracyCalculated,
  ToolTipButton
} from "./tooltips";
import LotteryWinners from "../lottery-winners";
import { topRoundWinners } from "./constants";

interface IProps {
  setMessageData: React.Dispatch<React.SetStateAction<MessageModalData>>;
  showTheMessageModal: () => void;
  roundLeaderBoard?: boolean;
  feBobShowJets?: boolean;
}

interface IPlayer {
  avatarSrc?: string;
  betAccuracy?: string;
  company?: string;
  name?: string;
  prize?: string;
  round?: string;
  totalPoints?: string;
  ytSrc?: string;
  inactive?: boolean;
  date?: string;
}
interface ISeasonRoundWinner {
  seasonId?: string;
  topRoundWinners?: IPlayer[];
}

export const LeaderBoard = ({
  showTheMessageModal,
  setMessageData,
  roundLeaderBoard,
  feBobShowJets
}: IProps) => {
  const { featureFlags } = useFeatureFlagContext()
  const location = useLocation();
  const { contest, status, endedContest, gotBonusDrawData } = useContestContext();
  const { round, roundStatus, seasonSelected, setSeasonSelected } = useRoundContext();
  const { userInfo, isAuthenticated } = useUserContext();
  const { leaderboardChanged } = useBroadcastContext();
  const isReadOnly = useReadOnly();

  const handleMessageData = (data: MessageModalData) => {
    showTheMessageModal();
    setMessageData(data);
    gtm.trackEvent("video_click", {
      video_section: "Leaderboard"
    });
  };

  let selectedContest = round;
  selectedContest = isReadOnly ? contest : round;

  if (selectedContest?.round! > endedContest?.round!) {
    selectedContest = endedContest;
  }

  if (!seasonSelected) {
    selectedContest = round;
  }

  const isCurrentRound = selectedContest?.round === contest?.round;
  const selectedStatus = isReadOnly ? status : roundStatus;
  const endDate = new Date(round?.endDate!);
  const now = new Date(Date.now());
  const currentPlayerRef = useRef<HTMLElement>();
  const [leaderboardScores, setLeaderboardScores] = useState<ApiLeaderboardPlayerScore[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasLoaded, setHasLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [tableFixed, setTableFixed] = useState(false);
  const [tableSticky, setTableSticky] = useState(false);
  const [tableHeaderLeft, setTableHeaderLeft] = useState(0);
  const [showList, setShowList] = useState(false);
  const [scrollYStop, setScrollYStop] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [hasPreviousRoundsForWinnersList, setHasPreviousRoundsForWinnersList] = useState(false);
  const [isHeaderOnly, setIsHeaderOnly] = useState(false);
  const [currentBroadcastMessage, setCurrentBroadcastMessage] = useState("");
  const leaderboardScroll = useRef<null | HTMLDivElement>(null);
  let winnersListScrollYNum = 0;
  const [roundWinners, setRoundWinners] = useState<ISeasonRoundWinner>();

  const [scrollIntoViewTimer, setScrollIntoViewTimer] = useState<any>();
  const delay = 1000;

  useEffect(() => {
    if (gotBonusDrawData) {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const scrollToLeaderboard = params.get("round") || params.get("season");
      if (scrollToLeaderboard) {
        setScrollIntoViewTimer(
          setTimeout(() => {
            leaderboardScroll?.current?.scrollIntoView({ behavior: "smooth" });
          }, delay)
        );
      }
    } else {
      setScrollIntoViewTimer(clearTimeout(scrollIntoViewTimer));
    }
  }, [gotBonusDrawData]);

  const isLimitedColumns =
    (selectedStatus === ContestStatus.InProgress || selectedStatus === ContestStatus.Ended) &&
    !seasonSelected;

  if (isAuthenticated) {
    winnersListScrollYNum = stickyTableRow.winnersListIsAuthenticatedScrollY;
  } else {
    winnersListScrollYNum = stickyTableRow.winnersListScrollY;
  }

  const getLeaderboard = useCallback(
    async (page: number = 1, forPlayerId: string = "") => {
      if (currentBroadcastMessage === undefined || currentBroadcastMessage === null) return;

      const checkShowList = () => {
        if (selectedStatus !== ContestStatus.NotStarted) {
          setShowList(true);
        } else if (!isCurrentRound || seasonSelected) {
          setHasLoading(true);
          setShowList(true);
          if (!hasPreviousRoundsForWinnersList) {
            setIsHeaderOnly(true);
            return true;
          }
          setIsHeaderOnly(false);
        } else {
          setShowList(false);
        }
        return false;
      };

      if (checkShowList()) return;
      setIsLoading(true);
      setPage(page);

      const sortOrder = seasonSelected
        ? ContestDomainClient_Models_LeaderboardSort.SEASON_POINTS
        : ContestDomainClient_Models_LeaderboardSort.EVENT_POINTS;
      if (selectedContest && selectedContest?.contestId) {
        if (selectedContest?.seasonId !== contest?.seasonId) {
          //last ended contest was different season
          setIsLoading(false);
          setHasLoading(true);
        } else {
          try {
            let response = await PlayerDomainInterfacesService.apiContestLeaderboardPost({
              contestId: selectedContest?.contestId,
              playerId: forPlayerId === "" ? userInfo?.playerId : forPlayerId,
              page: page,
              pageSize: PageSize.Medium,
              requestGameSize: true,
              leaderboardSort: sortOrder
            });
            if (response) {
              setTotalCount(response.gameSize!);
              let newLeaderboardScores = (response.leaderboardPlayerScores || []).map((item) =>
                mapLeaderboardPlayerScore(item, selectedContest?.round || undefined, selectedStatus)
              );
              if (sortOrder === "EventPoints") {
                console.log("sort by event points");
                newLeaderboardScores = newLeaderboardScores.sort((a, b) =>
                  (a?.eventPointsRank || 0) > (b?.eventPointsRank || 0) ? 1 : -1
                );
              } else {
                console.log("sort by season points");
                newLeaderboardScores = newLeaderboardScores.sort((a, b) =>
                  (a?.seasonPointsRank || 0) > (b?.seasonPointsRank || 0) ? 1 : -1
                );
              }

              if (newLeaderboardScores && newLeaderboardScores.length > 0) {
                setLeaderboardScores(newLeaderboardScores);
              }
            }
          } catch (err) {
            console.error(err);
          }
          setIsLoading(false);
          setHasLoading(true);
        }
      }
      setCurrentBroadcastMessage("");
    },
    [
      currentBroadcastMessage,
      hasPreviousRoundsForWinnersList,
      selectedContest,
      selectedStatus,
      contest?.seasonId,
      userInfo?.playerId,
      location?.search,
      isCurrentRound,
      setSeasonSelected,
      seasonSelected
    ]
  );

  useEffect(() => {
    const getRoundWinners = async () => {
      try {
        const roundWinners = await PlayerDomainInterfacesService.apiSeasonTopRoundWinners(
          contest?.seasonId
        );
        setRoundWinners(roundWinners);
      } catch (error) {
        console.log(error);
      }
    };

    getRoundWinners();
  }, [contest]);

  const tablewrapper = document.querySelector(".table-wrapper");
  tablewrapper?.addEventListener(
    "scroll",
    () => {
      setTableHeaderLeft(-1 * tablewrapper.scrollLeft);
    },
    { passive: true }
  );

  useEffect(() => {
    setCurrentBroadcastMessage("load");
    getLeaderboard();
    setCurrentBroadcastMessage("");
    ReactTooltip.rebuild();
    setIsMounted(true);
    return () => setIsMounted(false);
  }, [getLeaderboard, hasLoaded, leaderboardChanged]);

  useEffect(() => {
    window?.addEventListener(
      "scroll",
      () => {
        if (
          (window.scrollY > winnersListScrollYNum &&
            window.innerWidth <= stickyTableRow.innerWidth) ||
          (window.scrollY > stickyTableRow.leaderboardScrollY &&
            window.innerWidth <= stickyTableRow.innerWidth)
        ) {
          setTableFixed(true);
          setTableSticky(false);
        } else if (
          window.scrollY > stickyTableRow.leaderboardScrollY &&
          window.innerWidth > stickyTableRow.innerWidth
        ) {
          setTableFixed(false);
          setTableSticky(true);
        } else {
          setTableFixed(false);
          setTableSticky(false);
        }
      },
      { passive: true }
    );

    if (seasonSelected) {
      setScrollYStop(winnersListScrollYNum);
    } else if (location.pathname === PageView.LeaderboardPage) {
      setScrollYStop(stickyTableRow.leaderboardScrollY);
    }

    return () => {
      window?.removeEventListener("scroll", () => {});
      //setHasLoading(false);
    };
  }, [
    getLeaderboard,
    location.pathname,
    page,
    winnersListScrollYNum,
    location?.search,
    seasonSelected
  ]);

  useEffect(() => {
    const getSeasonRounds = async () => {
      const response = await PlayerDomainInterfacesService.apiSeasonBySeasonIdRoundsGet(
        contest?.seasonId!
      );
      if (response) {
        const roundsData = response.rounds as ApiSeasonRound[];
        const sortPreviousRounds = roundsData
          ?.filter(
            (round) =>
              new Date(round!.startDate!) < new Date(Date.now()) &&
              new Date(round!.endDate!) < new Date(Date.now())
          )
          ?.sort((a, b) => b.round! - a.round!);

        setHasPreviousRoundsForWinnersList(sortPreviousRounds.length > 0);
      }
    };

    if (contest) {
      getSeasonRounds();
    }
  }, [contest]);

  const handleSeeMyRankClick = () => {
    currentPlayerRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const contestName =
    selectedContest?.seasonId !== contest?.seasonId ? contest?.name : selectedContest?.name;

  const seeMyRankButton = () => {
    return (
      showList &&
      hasLoaded &&
      !isHeaderOnly && (
        <div className="round-property-filter-container">
          {isAuthenticated && (
            <div className="see-my-rank-button">
              <button
                className="leaderboard-my-rank"
                onClick={() => handleSeeMyRankClick()}
                bob-ga-data-on="click"
                bob-ga-data-event-category="LeaderBoard"
                bob-ga-data-event-action="Click"
                bob-ga-data-event-label="See My Rank"
                bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
                bob-ga-data-dimension3={round?.contestId!}
              >
                See My Rank
              </button>
            </div>
          )}
        </div>
      )
    );
  };

  const showfirstRound =
    seasonSelected && contest?.firstRound && roundStatus !== ContestStatus.Ended;

  const Leaderboard = ({ feBobShowJets }: { feBobShowJets?: boolean }) => (
    <>
      {showList && hasLoaded && !showfirstRound && (
        <div className="table-wrapper">
          <table id="leaderboard-table" className={classNames("winners-list", {})}>
            <thead>
              <tr>
                <th className="rank-header">Rank</th>
                <th>Player</th>
                <th className="company-header">Company</th>
                <th className="points-header">{seasonSelected ? "Points" : "Round Points"}</th>
                {seasonSelected && (
                  <th className="text-right bonus-points-header">
                    Bonus Points <ToolTipButton dataFor="bonuspoints" />
                  </th>
                )}
                {seasonSelected && <th className="total-points-container">Total Points</th>}
                <th className="bet-accuracy-header text-right">
                  Bet Accuracy <ToolTipButton dataFor="accuracycalculated" />
                </th>
                <th className="text-right">Winnings</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && !hasLoaded ? (
                <tr>
                  <td style={{ textAlign: "center", paddingTop: 30 }} colSpan={10}>
                    <div className="spinner">
                      <img src={spinningTenX} className="spinner-logo" alt="Ten-X Logo" />
                    </div>
                  </td>
                </tr>
              ) : (
                leaderboardScores.map((score, index) => (
                  <LeaderBoardItem
                    entry={score}
                    index={index}
                    key={index}
                    currentPlayerRef={
                      score.playerId === userInfo?.playerId ? currentPlayerRef : null
                    }
                    currentPlayerId={userInfo?.playerId || ""}
                    isSeasonView={seasonSelected}
                  />
                ))
              )}
            </tbody>
          </table>

          <AccuracyCalculated isSeasonList={seasonSelected} contestName={contestName?.toString()} />
          <PointsCalculated
            contestStatus={selectedStatus === ContestStatus.Ended}
            contestName={
              selectedContest?.seasonId !== contest?.seasonId
                ? contest?.name
                : selectedContest?.name
            }
          />
          <BonusPoints />
          <PotentialWinnings round={round?.round} />
        </div>
      )}

      {!isLoading && selectedStatus === ContestStatus.NotStarted && !seasonSelected && (
        <div className="text-center leaderboard-message">{`Please check back when ${round?.name} has begun.`}</div>
      )}
      {showList && hasLoaded && !isHeaderOnly && !showfirstRound && featureFlags?.feBobShowPlayerCount && (
        <div className="total-players">
          Total players: <span className="total-players__total">{totalCount}</span>
        </div>
      )}

      {showfirstRound && (
        <div className="text-center leaderboard-message">
          Season leaderboard will become available once round 1 has concluded.
        </div>
      )}
    </>
  );

  // TODO update pending design changes
  // const HelpCenter = () => (
  //   <div className="help-center-container">
  //     <p>
  //       Have more questions? Visit our <a>Help Center</a>
  //     </p>
  //     <img className="jet" src={Jet} />
  //     <img className="cloud" src={Cloud} />
  //   </div>
  // );

  if (roundLeaderBoard) {
    setSeasonSelected(false);
    return (
      <>
        {!seasonSelected ? (
          <div className="table-containers">
            <div className="round-selection-header">
              <div className="content">
                <div className="col-2">
                  <RoundSelection />
                </div>
                <div className="col-2 content-align-end">
                  <NavLink
                    className="season-board-link"
                    to={`${PageView.WinnersList}?season=Season%20Leaderboard`}
                  >
                    <div className="leaderboard-link">SEASON LEADERBOARD</div>
                    <div className="leaderboard-link">
                      <img className="cloud-l" src={seasonarrow} alt="season leaderboard" />
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className={"leaderboard-table-container table-container__winners-list"}>
              <Leaderboard />
            </div>
          </div>
        ) : (
          <div className="spinner">
            <img src={spinningTenX} className="spinner-logo" alt="Ten-X Logo" />
          </div>
        )}
        <div className="help-footer-container">
          <div className="help-footer-text">
            {"Have more questions? Email us at "}
            <Link
              bob-ga-data-on="click"
              bob-ga-data-event-category="Header"
              bob-ga-data-event-action="Click"
              bob-ga-data-event-label="Help"
              bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
              to={PageView.Help}
            >
              {"HELP"}
            </Link>{" "}
            Center
          </div>
          {feBobShowJets && <img className="jet" src={jet} alt="small jet" />}
          <img className="cloud-r" src={cloudR} alt="small cloud" />
          <img className="cloud-l" src={cloudL} alt="small cloud" />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="leaderboard-container">
        <div className="leaderboard-hero-container">
          <img className="bob-logo" src={BobLogo} alt="Battle of the Bids logo" />
          <h2 className="section-title">WINNERS CIRCLE</h2>
          <div className="winners-container">
            {roundWinners?.topRoundWinners &&
              roundWinners?.topRoundWinners.map((elm) => (
                <WinnerCard handleMessageData={handleMessageData} player={elm} key={elm.name} />
              ))}
          </div>
        </div>
      </div>
      <div className="table-containers main">
        <LotteryWinners feBobShowJets={feBobShowJets} />
        <div ref={leaderboardScroll} className="leaderboard-header-padding"></div>
        <div
          className={classNames("leaderboard-table-container table-container__winners-list", {
            "table-container--season-view": seasonSelected
          })}
        >
          <MainLeaderBoardSelection />
          <Leaderboard feBobShowJets={feBobShowJets} />
        </div>
      </div>

      {/* <HelpCenter /> */}
    </>
  );
};
