import React, { useEffect, useState } from "react";
import classNames from "classnames";
import "../../content/styles/game/search-banner.scss";
import { PlayerDomain_ViewModels_PlayerSearchResult } from "../../player-api-client/models/PlayerDomain_ViewModels_PlayerSearchResult";
import { debounce } from "lodash";

const DEBOUNCE_WAIT_TIME_MS = 600;
const MIN_SEARCH_TERM_LENGTH = 3;

export interface Props {
  handleSearch: (criteria: string) => void;
  handleSelectedOne?: (playerId: string) => void;
  players?: PlayerDomain_ViewModels_PlayerSearchResult[];
  clearText?: boolean;
}

export const SearchBanner: React.FC<Props> = ({
  handleSearch,
  handleSelectedOne,
  players,
  clearText
}) => {
  const [searchText, setSearchText] = useState("");
  const [typeaheadShowToggle, setTypeaheadShowToggle] = useState(false);

  useEffect(() => {
    const handleClick = (e: any) => {
      if (e.target.value === "") {
        handleSearch("");
      }
    };
    document.addEventListener("input", handleClick);
    return () => {
      document.removeEventListener("input", handleClick);
    };
  }, [handleSearch]);

  const setTypeaheadToFalse = () => {
    setTypeaheadShowToggle(false);
  };

  useEffect(() => {
    if (clearText) setSearchText("");
  }, [clearText]);

  const handleSearchBoxOnChange = (e: any) => {
    const searchTerm = e.currentTarget.value;
    setSearchText(searchTerm);

    if (searchTerm.length >= MIN_SEARCH_TERM_LENGTH) {
      debounce(async () => {
        await handleSearch(searchTerm);
        setTypeaheadShowToggle(true);
      }, DEBOUNCE_WAIT_TIME_MS)();
    } else {
      players = [];
      setTypeaheadShowToggle(false);
    }
  };

  const handleSelectedTypeaheadPlayer = (player: any) => {
    const fullName = player?.firstName + " " + player?.lastName;
    const displayText = fullName.includes("undefined") ? "No Name Provided" : fullName;
    setSearchText(displayText);
    handleSelectedOne!(player?.playerId);
  };

  const handleEnterOrOnClick = () => {
    if (searchText.length === 0) {
      handleSearch("");
    }
    if (searchText.length > 0 && searchText.length < 3) {
      handleSearch(searchText);
    }
  };

  return (
    <>
      <div className="search-banner">
        <input
          className="input-field search-box input"
          type="search"
          placeholder="Search player name"
          name="search"
          onChange={(e) => handleSearchBoxOnChange(e)}
          autoComplete="off"
          onKeyPress={(e) => e.key === "Enter" && handleEnterOrOnClick()}
          value={clearText ? "" : searchText}
        />
        <div
          className={classNames("search-banner__results", {
            "search-banner__results--show": typeaheadShowToggle
          })}
          onClick={() => setTypeaheadShowToggle(false)}
        >
          {players?.map((item: any) => (
            <div
              key={item.player?.playerId!}
              className="search-banner__results-row"
              onClick={() => handleSelectedTypeaheadPlayer(item.player)}
            >
              <div>
                {(item.player?.firstName === undefined ? " " : item.player?.firstName) +
                  " " +
                  (item.player?.lastName === undefined ? " " : item.player?.lastName) +
                  " - " +
                  item.player?.locationName}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div
        className={classNames("typeahead-background", {
          "typeahead-background--show": typeaheadShowToggle
        })}
        onClick={() => setTypeaheadToFalse()}
      ></div>
    </>
  );
};
