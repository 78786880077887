import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import "../../content/styles/game/scoreboard.scss";
import "../../content/styles/game/score-card.scss";
import { ContestStatus } from "../../types/contest";
import { PointsTooltip } from "../points-tooltip/points-tooltip";
import { ScoreBoardItem } from "./scoreboard-item";
import spinningTenX from "../../content/images/tenx-pinwheel.svg";
import { useAuctionListings } from "../../hooks/use-auction-listings";
import { useEntryContext } from "../../common/entry-context";
import { mapScoreboardDataTable } from "./scoreboard-mapper";
import { usePrevious } from "../../hooks/use-previous";
import { ChangeBetDialog, ChangeBetDialogType } from "./change-bet-dialog";
import { ScoreboardActions } from "./scoreboard-actions";
import { useRoundContext } from "../../common/round-context";
import { useContestContext } from "../../common/contest-context";
import { useUserContext } from "../../common/user-context";
import { stickyTableRow } from "../../common/constants";
import { useStandingContext } from "../../common/standing-context";
import { WinnersList } from "../winner-scroll/winners-scroll";
import { InvalidBetDialog } from "../common/invalid-bet-dialog";
import { NumberFormatter } from "../../types/formatter";
import { useReadOnly } from "../../hooks/use-read-only";
import { ChangeBetAlert, ChangeBetAlertType } from "./change-bet-alert";
import { PlayerStats } from "../score-card/player-stats";
import { RoundSelection } from "../sub-header/round-selection";
import { PlayerRanking } from "./player-ranking";

let isMounted = true;

export const ScoreBoard: React.FC<{}> = () => {
  const [tableFixed, setTableFixed] = useState(false);
  const [tableSticky, setTableSticky] = useState(false);
  const [scrollYStop, setScrollYStop] = useState(0);
  const [tableHeaderLeft, setTableHeaderLeft] = useState(0);

  const { contest } = useContestContext();
  const { userInfo } = useUserContext();
  const { round, roundStatus } = useRoundContext();
  const { standingInfo, isMinRankForPrize, leaderboardChanged } = useStandingContext();
  const {
    items,
    showZeroBetLeftDialog,
    showOneBetLeftDialog,
    showTwoBetLeftDialog,
    showInvalidBetDialog,
    showChangeBetError
  } = useEntryContext();
  const { auctionListings, isLoading } = useAuctionListings();
  const scoreboardDataTable = mapScoreboardDataTable(
    roundStatus,
    round?.contestItems || [],
    items,
    auctionListings,
    false // is not score card page
  );
  const previousScoreboardDataTable = usePrevious(
    auctionListings?.length ? scoreboardDataTable : undefined
  );
  const [showPointsModal, setShowPointsModal] = useState(false);
  const [rankingChanged, setRankingChanged] = useState("");
  const isReadOnly = useReadOnly();

  useEffect(() => {
    isMounted = true;
    const tablewrapper = document.querySelector(".table-wrapper");
    const handleTableWrapperScroll = () => {
      if (isMounted && tablewrapper !== undefined && tablewrapper !== null)
        setTableHeaderLeft(-1 * tablewrapper.scrollLeft);
    };
    tablewrapper?.addEventListener("scroll", handleTableWrapperScroll, { passive: true });

    const handleScroll = () => {
      if (isMounted) {
        if (
          window.scrollY > stickyTableRow.scoreboardScrollY &&
          window.innerWidth <= stickyTableRow.innerWidth
        ) {
          setTableFixed(true);
          setTableSticky(false);
        } else if (
          window.scrollY > stickyTableRow.scoreboardScrollY &&
          window.innerWidth > stickyTableRow.innerWidth
        ) {
          setTableFixed(false);
          setTableSticky(true);
        } else {
          setTableFixed(false);
          setTableSticky(false);
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    if (isMounted) setScrollYStop(stickyTableRow.scoreboardScrollY);
    return () => {
      tablewrapper?.removeEventListener("scroll", handleTableWrapperScroll);
      window.removeEventListener("scroll", handleScroll);
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    setRankingChanged(leaderboardChanged);
  }, [leaderboardChanged]);

  const bidType = () => {
    if (roundStatus === ContestStatus.Ended) {
      return "Final Bid";
    } else if (roundStatus === ContestStatus.NotStarted) {
      return "Starting Bid";
    } else {
      return "Current Bid";
    }
  };

  const rankPlacement = (rank: number) => {
    const digits = [rank % 10, rank % 100],
      ordinals = ["st", "nd", "rd", "th"],
      oPattern = [1, 2, 3, 4],
      tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19];

    return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
      ? rank + ordinals[digits[0] - 1]
      : rank + ordinals[3];
  };

  return (
    <div className="score-card-container">
      {showInvalidBetDialog && !isReadOnly && <InvalidBetDialog />}
      {showZeroBetLeftDialog && !isReadOnly && (
        <ChangeBetDialog type={ChangeBetDialogType.ZeroLeft} />
      )}
      {showOneBetLeftDialog && !isReadOnly && (
        <ChangeBetDialog type={ChangeBetDialogType.OneLeft} />
      )}
      {showTwoBetLeftDialog && !isReadOnly && (
        <ChangeBetDialog type={ChangeBetDialogType.TwoLeft} />
      )}
      {showChangeBetError && !isReadOnly && <ChangeBetAlert type={ChangeBetAlertType.Error} />}
      {showPointsModal && !isReadOnly && (
        <PointsTooltip showPointsModal={showPointsModal} setShowPointsModal={setShowPointsModal} />
      )}

      <PlayerStats
        firstName={userInfo?.firstName}
        lastName={userInfo?.lastName}
        playerId={userInfo?.playerId}
        currentContestId={round?.contestId ?? ""}
      />
      <div className="table-container col-2 score-table">
        <div className="content">
          <div className="col-2">
            <RoundSelection />
          </div>
          <div className="col-2 content-align-end">
            {roundStatus === ContestStatus.InProgress && (
              <PlayerRanking playerId={userInfo?.playerId} leaderBoardChanged={rankingChanged} />
            )}
          </div>
        </div>
        {round?.round! >= contest?.round! && <ScoreboardActions />}
        <div className="table-wrapper" id="scroll-x-style">
          <table id="scoreboard-table">
            <thead
              className={`
              ${
                tableFixed && scrollYStop === stickyTableRow.scoreboardScrollY
                  ? "default"
                  : tableSticky
                  ? "sticky"
                  : "default"
              }
            `}
              style={{ left: tableFixed ? tableHeaderLeft : "" }}
            >
              <tr>
                <td className="thead_property">&nbsp;Property</td>
                <td className="thead_yourbet">Your Bet</td>
                <td className="thead_bidtype" align="right">
                  {bidType()}
                </td>
                <td className="thead_bidincrement" align="right">
                  Bid Increment
                </td>
                <td className="bids-column" align="right">
                  Bids
                </td>
                <td className="thead_remaining" align="right">
                  Remaining
                </td>
                <td className="header-status">Status</td>
                <td
                  className="thead_points tooltip-wrapper"
                  align="right"
                  onClick={() => setShowPointsModal(!showPointsModal)}
                >
                  <div className="tooltip-element">
                    <span className="change-bet-info-points">
                      <button className="change-bet-info-i purple">i</button>
                    </span>
                  </div>
                  <div className="points-link right-table-padding tooltip-element">Points</div>
                </td>
              </tr>
            </thead>
            <tbody>
              {isMounted && isLoading ? (
                <tr>
                  <td style={{ textAlign: "center", paddingTop: 30 }} colSpan={8}>
                    <div className="spinner">
                      <img src={spinningTenX} className="spinner-logo" alt="Ten-X Logo" />
                    </div>
                  </td>
                </tr>
              ) : (
                scoreboardDataTable.rows.map((row) => (
                  <ScoreBoardItem
                    key={row.contestItem.itemId}
                    row={row}
                    previousRow={previousScoreboardDataTable?.rows?.find(
                      (x) => x.contestItem.itemId === row.contestItem.itemId
                    )}
                    contestStatus={roundStatus}
                  />
                ))
              )}
            </tbody>
          </table>
        </div>
        {roundStatus === ContestStatus.InProgress && (
          <div className="total-wrapper">
            {!isMinRankForPrize && standingInfo?.eventWinnings! > 0 ? (
              <div>
                You Are Currently In {rankPlacement(standingInfo?.eventPointsRank || 0)} Place
              </div>
            ) : (
              <>
                <div className="rank-messagew">
                  <span className="change-bet-info-button">
                    <button
                      data-tip="0"
                      data-for="pointscalculated"
                      data-event="click"
                      className="change-bet-info-i"
                    >
                      i
                    </button>
                    <ReactTooltip
                      id="pointscalculated"
                      globalEventOff="click"
                      textColor="#000"
                      backgroundColor="#fff"
                      multiline={true}
                      place="bottom"
                    >
                      <div className="change-bet-info-style">
                        Points are finalized
                        <br />
                        when auction round ends.
                      </div>
                    </ReactTooltip>
                  </span>
                  &nbsp;
                </div>
                {/* <div className="rank-message"> */}
                My Preliminary Points:&nbsp;
                {/* </div> */}
                <div className="total-points">
                  {NumberFormatter.FormatNumber(scoreboardDataTable?.totalPoints!)}
                </div>
              </>
            )}
          </div>
        )}
        {roundStatus === ContestStatus.Ended && (
          <>
            <div className="total-wrapper">
              <div className="rank-message">Today's Final Points:</div>
              <div className="total-points">
                {NumberFormatter.FormatNumber(scoreboardDataTable?.totalPoints!)}
              </div>
            </div>
            {!isReadOnly && <WinnersList />}
          </>
        )}
      </div>
    </div>
  );
};
