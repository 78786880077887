import * as React from "react";
import { FeatureFlagProvider } from "../common/feature-flag-context"
import { ContestContextProvider } from "../common/contest-context";
import { ScreenContainer } from "./screen-container";
import { UserContextProvider } from "../common/user-context";
import { EntryContextProvider } from "../common/entry-context";
import { RoundContextProvider } from "../common/round-context";
import { BroadcastContextProvider } from "../common/broadcast-socket-context";
import { StandingContextProvider } from "../common/standing-context";
import { EntryPlayerContextProvider } from "../common/entry-player-context";
import { StandingPlayerContextProvider } from "../common/standing-player-context";

export const ContestRootContainer: React.FC = () => {
  return (
    <div className="contest-root-container">
    <FeatureFlagProvider>
      <ContestContextProvider>
        <RoundContextProvider>
          <UserContextProvider>
            <BroadcastContextProvider>
              <StandingContextProvider>
                <EntryContextProvider>
                  <EntryPlayerContextProvider>
                    <StandingPlayerContextProvider>
                      <ScreenContainer />
                    </StandingPlayerContextProvider>
                  </EntryPlayerContextProvider>
                </EntryContextProvider>
              </StandingContextProvider>
            </BroadcastContextProvider>
          </UserContextProvider>
        </RoundContextProvider>
      </ContestContextProvider>
    </FeatureFlagProvider>
    </div>
  );
};
