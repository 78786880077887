import React from "react";
import { PrepareForBattleForm } from "../../sign-up-form";

type Props = {
  campaignId?: string;
};

export const PrepareForBattle: React.FC<Props> = ({ campaignId }) => {
  return (
    <div id="prepareform" className="splash sign-up-form-container">
      <div className="title">LEARN MORE ABOUT PARTNERING WITH TEN-X</div>
      <b className="text">YOU COULD CLOSE IN UNDER 100 DAYS</b>
      <p className="text">
        Use the power of Ten-X to close more sales. Call us at (888) 770-7332 or fill out the form
        below to find out how we can help you go from list to close in under 90 days on Ten–X.
      </p>
      <iframe
        src={`https://go.ten-x.com/l/667613/2023-05-12/5n7dq?SourceURL=${window.location.href}&utm_campaign_id=${campaignId}`}
        width="100%"
        height="750"
        frameBorder="0"
        allowTransparency={true}
        style={{ border: 0 }}
      ></iframe>
    </div>
  );
};
