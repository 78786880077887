import React from "react";
import "../../content/styles/common/not-authorized-to-play.scss";

const TIMER_COUNT_SECONDS = 10;
const TICK_MILISECONDS = 1000;

type Props = {};

type State = {
  countDown: number;
};

export class NotAuthorizedToPlay extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { countDown: TIMER_COUNT_SECONDS };
  }

  componentDidMount() {
    setInterval(() => {
      if (this.state.countDown !== 0) {
        this.setState({ countDown: this.state.countDown - 1 });
      }
    }, TICK_MILISECONDS);

    setTimeout(() => {
      window.location.href = "/";
    }, TIMER_COUNT_SECONDS * TICK_MILISECONDS);
  }

  render() {
    return (
      <div className="not-authorized-player">
        <h2>You are not an authorized player.</h2>
        <p>We will redirect you in {this.state.countDown}...</p>
      </div>
    );
  }
}
