import React from "react";
import { WinnerSectionProps } from "./types";
import PlayButton from "../../content/images/marketing/play-button.svg";
import gtm from "../../helpers/gtm-helper";

const WinnerSection: React.FC<WinnerSectionProps> = ({
  betAccuracy,
  company,
  donation,
  excerpt,
  headshotSrc,
  name,
  setMessageData,
  showTheMessageModal,
  title,
  totalPoints,
  videoSrc,
  winnings
}) => {
  const playButtonClick = () => {
    showTheMessageModal();
    setMessageData({
      message: "",
      messageTitle: "",
      videoUrl: videoSrc
    });
    gtm.trackEvent("video_click", {
      video_section: "winner_videos"
    });
  };

  return (
    <div className="winner-container">
      <div className="player-highlight">
        <div className="headshot-container">
          <img className="player-headshot" src={headshotSrc} />
          <img className="play-button" onClick={playButtonClick} src={PlayButton} />
        </div>
        <div className="player-content">
          <p className="title">{title}</p>
          <p className="name">{name}</p>
          <p className="company">{company}</p>
          <p className="excerpt tablet">"{excerpt}"</p>
        </div>
      </div>
      <p className="excerpt mobile">"{excerpt}"</p>
      <div className="stats">
        <div className="charity">
          <p>{winnings} +</p>
          <p>{donation}</p>
        </div>
        <div className="padding-left">
          <p>
            TOTAL POINTS:
            <br />
            {totalPoints}
          </p>
        </div>
        <div className="padding-left">
          <p>
            BET ACCURACY:
            <br />
            {betAccuracy}
          </p>
        </div>
      </div>
    </div>
  );
};

export default WinnerSection;
