import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useContestContext } from "../../common/contest-context";
import { PlayerDomainInterfacesService } from "../../player-api-client";
import "../../content/styles/game/round-selection.scss";
import downCaretIcon from "../../content/images/down-caret.svg";
import { useRoundContext } from "../../common/round-context";
import { PageView } from "../../common/constants";
import { formatDateRangeLong } from "../../types/formatter";
import { buildGamePageRoute } from "../../helpers/game-route-builder";
import { ApiSeasonRound } from "../../types/api-models";
import { useUserContext } from "../../common/user-context";
import classNames from "classnames";

export const RoundSelection: React.FC = () => {
  const { contest } = useContestContext();
  const { round } = useRoundContext();
  const { pathname } = useLocation();
  const history = useHistory();

  const [rounds, setRounds] = useState<ApiSeasonRound[]>();
  const [selectedRound, setSelectedRound] = useState(contest?.round);
  const [upcomingRounds, setUpcomingRounds] = useState<ApiSeasonRound[]>();
  const [previousRounds, setPreviousRounds] = useState<ApiSeasonRound[]>();
  const [currentRound, setCurrentRound] = useState<ApiSeasonRound>();
  const [isActive, setIsActive] = useState<boolean>(false);
  const { userInfo } = useUserContext();

  const toggleDropdown = () => {
    const active = isActive;
    setIsActive(!active);
  };

  const updateRoundSelection = (roundSelected: number, contestId: string = "") => {
    const round = roundSelected;
    const isExpiredRound =
      contest?.round && round < contest.round && pathname.includes(PageView.PropertySelectionPage);

    if (isExpiredRound) {
      history.push(buildGamePageRoute(PageView.ScoreboardPage, contestId));
    }

    if (!isExpiredRound) {
      setSelectedRound(round);
      history.push(buildGamePageRoute(pathname, contestId));
    }
    toggleDropdown();
  };

  useEffect(() => {
    const getSeasonRounds = async () => {
      const response = await PlayerDomainInterfacesService.apiSeasonBySeasonIdRoundsGet(
        contest?.seasonId!
      );
      if (response) {
        const roundsData = response.rounds as ApiSeasonRound[];
        setRounds(roundsData);
      }
    };

    if (contest) {
      getSeasonRounds();
    }
  }, [contest, pathname]);

  useEffect(() => {
    const sortRounds = () => {
      const sortUpcomingRounds = rounds
        ?.filter((round) => new Date(round!.startDate!) > new Date(Date.now()))
        ?.sort((a, b) => b.round! - a.round!);
      const sortPreviousRounds = rounds
        ?.filter(
          (round) =>
            new Date(round!.startDate!) < new Date(Date.now()) &&
            new Date(round!.endDate!) < new Date(Date.now())
        )
        ?.sort((a, b) => b.round! - a.round!);
      let sortCurrentRound = rounds?.filter(
        (round) =>
          new Date(round!.startDate!) <= new Date(Date.now()) &&
          new Date(round!.endDate!) >= new Date(Date.now())
      )[0];

      if (sortCurrentRound === undefined) {
        sortCurrentRound = sortUpcomingRounds![sortUpcomingRounds!.length - 1];
        sortUpcomingRounds!.splice(sortUpcomingRounds!.length - 1, 1);
      }

      setUpcomingRounds(sortUpcomingRounds);
      setPreviousRounds(sortPreviousRounds);
      setCurrentRound(sortCurrentRound);
    };

    if (rounds && rounds?.length > 0) {
      sortRounds();
      setSelectedRound(round?.round);
    }
  }, [round?.round, rounds]);

  useEffect(() => {
    const closeModals = () => {
      if (isActive) setIsActive(false);
    };

    window.addEventListener("click", closeModals);
    return () => {
      window.removeEventListener("click", closeModals);
    };
  }, [isActive]);

  return (
    <>
      <div
        className={classNames("round-selection-scoreboard", { active: isActive })}
        onClick={toggleDropdown}
      >
        {round?.name}
        {rounds && rounds.length > 1 && <img src={downCaretIcon} alt="expand" />}
        {isActive && rounds && rounds.length > 1 && (
          <div className="round-selection">
            <div className="round-selection-dropdown">
              <ul>
                {upcomingRounds &&
                  upcomingRounds.map((round, index) => (
                    <li
                      key={index}
                      className={classNames("", {
                        current: selectedRound === round?.round
                      })}
                    >
                      <button
                        onClick={() => updateRoundSelection(round.round!, round.contestId!)}
                        bob-ga-data-on="click"
                        bob-ga-data-event-category="RoundHeader"
                        bob-ga-data-event-action="Click"
                        bob-ga-data-event-label="RoundSelection"
                        bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
                        bob-ga-data-dimension3={round ? round.contestId : ""}
                      >
                        <span>Upcoming:</span> {round.name} -{" "}
                        {formatDateRangeLong(round.startDate!, round.endDate!)}
                      </button>
                    </li>
                  ))}

                {currentRound?.round && (
                  <li
                    className={classNames("", {
                      current: selectedRound === currentRound?.round
                    })}
                  >
                    <button
                      onClick={() =>
                        updateRoundSelection(currentRound?.round!, currentRound?.contestId!)
                      }
                      bob-ga-data-on="click"
                      bob-ga-data-event-category="RoundHeader"
                      bob-ga-data-event-action="Click"
                      bob-ga-data-event-label="RoundSelection"
                      bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
                      bob-ga-data-dimension3={round ? round.contestId : ""}
                    >
                      <span>Current:</span> {currentRound?.name} -{" "}
                      {formatDateRangeLong(currentRound?.startDate!, currentRound?.endDate!)}
                    </button>
                  </li>
                )}

                {previousRounds &&
                  previousRounds.map((round, index) => (
                    <li
                      key={index}
                      className={classNames("", {
                        current: selectedRound === round?.round
                      })}
                    >
                      <button
                        onClick={() => updateRoundSelection(round.round!, round.contestId!)}
                        bob-ga-data-on="click"
                        bob-ga-data-event-category="RoundHeader"
                        bob-ga-data-event-action="Click"
                        bob-ga-data-event-label="RoundSelection"
                        bob-ga-data-dimension2={userInfo ? userInfo.contactId : ""}
                        bob-ga-data-dimension3={round ? round.contestId : ""}
                      >
                        <span>Previous:</span> {round.name} -{" "}
                        {formatDateRangeLong(round.startDate!, round.endDate!)}
                      </button>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
